const localization = {
  en: {
    ecm_title: 'Enterprise Case Management',
    ecm_icon_button: 'ECM',
    header_case: 'Case: ',
    header_no_caseId: 'None selected',
    type_here: 'Type here',
    select_existing_case: 'Select an existing case or create case',
    create_case_button: 'Create Case',
    table_column_case_title: 'CASE TITLE',
    table_column_last_modified: 'LAST MODIFIED',
    table_column_status_reason: 'STATUS REASON',
    table_column_case_id: 'CASE ID',
    customer_selected_notification: 'Customer selected.',
    case_linked_notification: 'Case linked with this conversation.',
    case_unlinked_notification:
      'Case unlinked. You can now select a different case.',
    unlink_case_tooltip: 'Unlink case before selecting a new one.',
    case_information: 'Case Information',
    case_title_create_case: 'Case Title',
    case_category: 'Case Category',
    case_type: 'Case Type',
    back: 'Back',
    administration: 'Administration',
    product: 'Product',
    navigate_to_dynamics:
      'Please navigate to Dynamics to create a case for this customer.',
    select: 'Select...',
    'loading_customer_details-msg': 'Loading customer details...',
    getting_cases_loader_msg: 'Getting cases...',
    creating_case_loader_msg: 'Creating Case...',
    no_open_cases_msg: 'No open cases for this customer',
    search_page_title: 'Customer Search',
    search_page_subtitle: 'Please enter customer information :',
    search_page_or: 'or',
    search_page_field_email: 'Adobe ID (email address)',
    search_page_field_phone: 'Phone number',
    search_page_search_button: 'Search',
    search_page_type_here: 'Type here',
    search_page_email_place_holder: 'Type here',
    loader_loading_customer_profiles: 'Loading customer profile...',
    case_list_change_customer: 'Change Customer',
    customer_not_found_text: 'Customer not Found',
    customer_invalid_phone_err_msg: 'Please enter correct phone number',
    customer_invalid_email_err_msg: 'Please enter correct email Id',
    customer_invalid_input_err_msg: 'Please enter correct inputs',
    go_back_link_label: 'Back',
    customer_table_c1_name: 'Name',
    customer_table_c2_email: 'Email ID',
    customer_table_c3_org_id: 'Org ID',
    customer_table_c4_org_name: 'Org Name',
    customer_table_c5_role: 'Role',
    customer_table_c6_type: 'Type',
    customer_table_top_title: 'Select an Org',
    zendesk_message: "This is a Sign Enterprise legacy customer. Zendesk has been decommissioned. No case will be created in Zendesk. Please create a support ticket in C14E manually.",
    zendesk_hypertext: "Customer Search C14E page link"
  },
  ja: {
    ecm_title: 'エンタープライズケースの管理',
    ecm_icon_button: 'ECM',
    header_case: 'ケース',
    header_no_caseId: '未選択',
    type_here: 'ここに入力',
    select_existing_case: '既存のケースを選択または新規作成',
    create_case_button: 'ケースを作成',
    table_column_case_title: 'ケースのタイトル',
    table_column_last_modified: '修正日',
    table_column_status_reason: 'ステータスの理由',
    table_column_case_id: 'ケース番号',
    customer_selected_notification: 'お客様を選択しました',
    case_linked_notification: ' この会話にケースを紐づけました',
    case_unlinked_notification:
      'ケースの紐づけを解除しました。他のケースを選択できます。',
    unlink_case_tooltip: '他のケースを選択する前にケースの紐づけを解除します',
    case_information: 'ケース情報',
    case_title_create_case: 'ケースタイトル',
    case_category: 'ケースカテゴリー',
    case_type: 'ケースの種類',
    back: '戻る',
    administration: '管理者',
    navigate_to_dynamics:
      'Dynamics に移動して、このお客様のケースを作成してください',
    product: '製品',
    select: '選択する...',
    'loading_customer_details-msg': 'お客様の詳細情報を読み込んでいます...',
    getting_cases_loader_msg: 'ケースを取得しています...',
    creating_case_loader_msg: 'ケースを作成しています',
    no_open_cases_msg: 'このお客様にはオープンケースがありません。',
    search_page_title: 'お客様を検索',
    search_page_subtitle: 'お客様の情報を入力 :',
    search_page_or: '-または-',
    search_page_field_email: 'Adobe ID (メールアドレス)',
    search_page_field_phone: '電話番号',
    search_page_search_button: '検索',
    search_page_type_here: 'ここに入力',
    search_page_email_place_holder: 'ここに入力',
    loader_loading_customer_profiles: 'お客様のプロファイルを読み込んでいます...',
    customer_not_found_text: 'お客様が見つかりません',
    customer_invalid_phone_err_msg: '正しい電話番号を入力してください',
    customer_invalid_email_err_msg: '正しいメールアドレスを入力してください。',
    customer_invalid_input_err_msg: '正しく入力してください',
    go_back_link_label: '前へ戻る',
    case_list_change_customer: 'お客様を変更',
    customer_table_c1_name: '名前',
    customer_table_c2_email: 'EメールID',
    customer_table_c3_org_id: '組織 ID',
    customer_table_c4_org_name: '組織名',
    customer_table_c5_role: '役割',
    customer_table_c6_type: 'お客様タイプ',
    customer_table_top_title: '組織を選択',
    zendesk_message: "これはサインエンタープライズの従来のお客様です。Zendeskは廃止されました。Zendeskではケースは作成されません。C14E でサポートチケットを手動で作成してください。",
    zendesk_hypertext: "カスタマー検索 C14E ページのリンク"
  },
  fr: {
    ecm_title: 'Gestion des dossiers de l’entreprise',
    ecm_icon_button: 'ECM',
    header_case: 'Dossier: ',
    header_no_caseId: 'Sélection vide',
    type_here: 'Saisissez ici',
    select_existing_case:
      'Sélectionnez un dossier existant ou créez un dossier',
    create_case_button: 'Créer un dossier',
    table_column_case_title: 'TITRE DU DOSSIER',
    table_column_last_modified: 'DERNIERE MODIFICATION',
    table_column_status_reason: 'Raison du statut',
    table_column_case_id: 'ID DU DOSSIER',
    customer_selected_notification: 'Sélectionné par le client.',
    case_linked_notification: 'Dossier lié à cette conversation.',
    case_unlinked_notification:
      'Dossier non lié. Vous pouvez maintenant sélectionner un autre dossier.',
    unlink_case_tooltip:
      'Supprimez le lien du dossier avant d’en sélectionner un nouveau.',
    case_information: 'Informations du dossier',
    case_title_create_case: 'Titre Du Dossier',
    case_category: 'Catégorie du dossier',
    case_type: 'Type de dossier',
    back: 'Retour',
    administration: 'Administration',
    product: 'Produit',
    navigate_to_dynamics:
      'Veuillez accéder à Dynamics pour créer un dossier pour ce client.',
    select: 'Sélectionner...',
    'loading_customer_details-msg': 'Chargement des détails du client...',
    getting_cases_loader_msg: 'Récupération des dossiers...',
    creating_case_loader_msg: 'Création du dossier...',
    no_open_cases_msg: 'Aucun dossier ouvert pour ce client',
    search_page_title: 'Recherche client',
    search_page_subtitle: 'Veuillez saisir des informations client :',
    search_page_or: 'ou',
    search_page_field_email: 'Identifiant Adobe (adresse email/ courriel électronique)',
    search_page_field_phone: 'Numéro de téléphone',
    search_page_search_button: 'Rechercher',
    search_page_type_here: 'Saisissez ici',
    search_page_email_place_holder: 'Saisissez ici',
    loader_loading_customer_profiles: 'Chargement des profils client...',
    customer_not_found_text: 'Client introuvable',
    customer_invalid_phone_err_msg: 'Veuillez saisir un numéro de téléphone correct',
    customer_invalid_email_err_msg: 'Veuillez saisir une adresse électronique correcte',
    customer_invalid_input_err_msg: 'Veuillez saisir des entrées correctes',
    case_list_change_customer: 'Changer de client',
    go_back_link_label: 'Retour',
    customer_table_c1_name: 'Nom',
    customer_table_c2_email: 'Identifiant courrier électronique',
    customer_table_c3_org_id: 'Identifiant de l’organisation',
    customer_table_c4_org_name: 'Nom de l’organisation',
    customer_table_c5_role: 'Rôle',
    customer_table_c6_type: 'Type',
    customer_table_top_title: 'Sélectionner une organisation',
    zendesk_message: "Il s'agit d'un ancien outil de Sign Enterprise. Zendesk a été mis hors service. Aucun dossier ne sera créé depuis Zendesk d'ici en avance. Veuillez créer manuellement un ticket d'assistance dans C14E",
    zendesk_hypertext: "Lien vers la page C14E de la recherche client"
  },
  de: {
    ecm_title: 'Fall-Management für Unternehmen',
    ecm_icon_button: 'ECM',
    header_case: 'Fall: ',
    header_no_caseId: 'Keine ausgewählt',
    type_here: 'Hier eingeben',
    select_existing_case: 'Bestehenden Fall auswählen oder neuen erstellen',
    create_case_button: 'Fall erstellen',
    table_column_case_title: 'FALLTITEL',
    table_column_last_modified: 'ZULETZT GEANDERT',
    table_column_status_reason: 'GRUND für STATUS ',
    table_column_case_id: 'FALL-ID',
    customer_selected_notification: 'Ausgewählter Kunde',
    case_linked_notification: 'Der Fall ist mit dieser Unterhaltung verknüpft.',
    case_unlinked_notification:
      'Fall nicht mehr verknüpft. Sie können nun einen anderen Fall auswählen.',
    unlink_case_tooltip:
      'Heben Sie die Verknüpfung auf, bevor Sie einen neuen Fall auswählen.',
    case_information: 'Informationen zum Fall',
    case_title_create_case: 'FALLTITEL',
    case_category: 'Fallkategorie',
    case_type: 'Falltyp',
    back: 'Zurück',
    administration: 'Administration',
    product: 'Produkt',
    navigate_to_dynamics:
      'Bitte navigieren Sie zu Dynamics, um einen Fall für diesen Kunden zu erstellen',
    select: 'Auswählen...',
    'loading_customer_details-msg': 'Kundendaten werden geladen...',
    getting_cases_loader_msg: 'Fälle werden abgerufen...',
    creating_case_loader_msg: 'Fall wird erstellt...',
    no_open_cases_msg: 'Keine offenen Fälle für diesen Kunden',
    search_page_title: 'Kundensuche',
    search_page_subtitle: 'Bitte geben Sie die Kundeninformationen ein:',
    search_page_or: 'oder',
    search_page_field_email: 'Adobe ID (E-Mail-Adresse)',
    search_page_field_phone: 'Telefonnummer',
    search_page_search_button: 'Suche',
    search_page_type_here: 'Hier eingeben',
    search_page_email_place_holder: 'Hier eingeben',
    loader_loading_customer_profiles: 'Kundenprofile werden geladen...',
    customer_not_found_text: 'Kunde nicht gefunden',
    customer_invalid_phone_err_msg: 'Bitte geben Sie die korrekte Telefonnummer ein',
    customer_invalid_email_err_msg: 'Bitte geben Sie die korrekte E-Mail-ID ein',
    customer_invalid_input_err_msg: 'Bitte achten Sie auf korrekte Angaben',
    go_back_link_label: 'Zurück',
    case_list_change_customer: 'Kunden ändern',
    customer_table_c1_name: 'Name',
    customer_table_c2_email: 'E-Mail-ID',
    customer_table_c3_org_id: 'Org-ID',
    customer_table_c4_org_name: 'Org.-Name',
    customer_table_c5_role: 'Rolle',
    customer_table_c6_type: 'Typ',
    customer_table_top_title: 'Org auswählen',
    zendesk_message: "Dies ist ein älterer Kunde von Sign Enterprise. Zendesk wurde außer Betrieb genommen. In Zendesk wird kein Fall erstellt. Bitte erstellen Sie manuell ein Support-Ticket in C14E.",
    zendesk_hypertext: "Link zur Seite „Kundensuche“ (C14E)"
  },
  es: {
    ecm_title: 'Gestión de casos de empresa',
    ecm_icon_button: 'ECM',
    header_case: 'Caso: ',
    header_no_caseId: 'Ninguna selección',
    type_here: 'Escriba aquí',
    select_existing_case: 'Seleccionar un caso existente o crear un caso',
    create_case_button: 'Crear caso',
    table_column_case_title: 'TÍTULO DE CASO',
    table_column_last_modified: 'ÚLTIMA MODIFICACION',
    table_column_status_reason: 'MOTIVO DEL ESTADO',
    table_column_case_id: 'ID DE CASO',
    customer_selected_notification: 'Cliente seleccionado.',
    case_linked_notification: 'Caso vinculado con esta conversación.',
    case_unlinked_notification:
      'Caso desvinculado. Ahora puede seleccionar un caso diferente.',
    unlink_case_tooltip: 'Desvincule el caso antes de seleccionar uno nuevo.',
    case_information: 'Información del caso',
    case_title_create_case: 'Título de caso',
    case_category: 'Categoría del caso',
    case_type: 'Tipo de caso',
    back: 'Atrás',
    administration: 'Administración',
    product: 'Producto',
    navigate_to_dynamics:
      'Navegue a Dynamics para crear un caso para este cliente.',
    select: 'Seleccionar...',
    'loading_customer_details-msg': 'Cargando detalles de cliente...',
    getting_cases_loader_msg: 'Obteniendo casos...',
    creating_case_loader_msg: 'Creando caso...',
    no_open_cases_msg: 'No hay casos abiertos para este cliente',
    search_page_title: 'Búsqueda de clientes',
    search_page_subtitle: 'Por favor, introduzca la información del cliente:',
    search_page_or: 'o',
    search_page_field_email: 'Adobe ID (correo electrónico)',
    search_page_field_phone: 'Número de teléfono',
    search_page_search_button: 'Buscar',
    search_page_type_here: 'Escriba aquí',
    search_page_email_place_holder: 'Escriba aquí',
    loader_loading_customer_profiles: 'Cargando perfiles de cliente...',
    customer_not_found_text: 'Cliente no encontrado',
    customer_invalid_phone_err_msg: 'Por favor, introduzca el número de teléfono correcto',
    customer_invalid_email_err_msg:
      'Por favor, introduzca la dirección de correo electrónico correcta',
    customer_invalid_input_err_msg: 'Por favor, introduzca los datos correctos',
    go_back_link_label: 'Atrás',
    case_list_change_customer: 'Cambiar cliente',
    customer_table_c1_name: 'Nombre',
    customer_table_c2_email: 'ID del correo electrónico',
    customer_table_c3_org_id: 'ID de la organización',
    customer_table_c4_org_name: 'Nombre de la organización',
    customer_table_c5_role: 'Función',
    customer_table_c6_type: 'Escribir',
    customer_table_top_title: 'Seleccione una organización',
    zendesk_message: "Se trata de una herramienta tradicional de Sign Enterprise. Zendesk ha sido dado de baja. No se creará ningún caso en Zendesk de ahora en adelante. Cree un ticket de soporte en el C14E de forma manual.",
    zendesk_hypertext: "Enlace a la página C14E de Customer Search"
  },
  it: {
    ecm_title: 'Gestione dei casi aziendali',
    ecm_icon_button: 'ECM',
    header_case: 'Caso: ',
    header_no_caseId: 'Nessuna selezionata',
    type_here: 'Digita qui',
    select_existing_case: 'Seleziona un caso esistente o creane uno nuovo',
    create_case_button: 'Crea caso',
    table_column_case_title: 'TITOLO DEL CASO',
    table_column_last_modified: 'ULTIMA MODIFICA',
    table_column_status_reason: 'MOTIVO STATO',
    table_column_case_id: 'ID CASO',
    customer_selected_notification: 'Cliente selezionato.',
    case_linked_notification: 'Caso collegato a questa conversazione.',
    case_unlinked_notification:
      'Caso scollegato. È ora possibile selezionare un altro richiesto.',
    unlink_case_tooltip: 'Scollega il caso prima di selezionarne uno nuovo.',
    case_information: 'Informazioni sul caso',
    case_title_create_case: 'Titolo del caso',
    case_category: 'Categoria del caso',
    case_type: 'Tipo di caso',
    back: 'Indietro',
    administration: 'Amministrazione',
    product: 'Prodotto',
    navigate_to_dynamics:
      'Passare a Dynamics per creare un caso per questo cliente.',
    select: 'Selezionare...',
    'loading_customer_details-msg':
      'Caricamento dei dati del cliente in corso......',
    getting_cases_loader_msg: 'Raccolta delle richieste in corso......',
    creating_case_loader_msg: 'Richiesta in fase di creazione...',
    no_open_cases_msg: 'Nessun caso aperto per questo cliente',
    search_page_title: 'Ricerca cliente',
    search_page_subtitle: 'Per favore, inserisci le informazioni sul cliente:',
    search_page_or: 'o',
    search_page_field_email: 'Adobe ID (indirizzo e-mail)',
    search_page_field_phone: 'Numero di telefono',
    search_page_search_button: 'Cerca',
    search_page_type_here: 'Digita qui',
    search_page_email_place_holder: 'Digita qui',
    loader_loading_customer_profiles: 'Caricamento dei profili cliente in corso...',
    customer_not_found_text: 'Cliente non trovato',
    customer_invalid_phone_err_msg: 'Per favore, immetti il numero di telefono corretto',
    customer_invalid_email_err_msg: 'Per favore, immetti l`e-mail corretta',
    customer_invalid_input_err_msg: 'Per favore, immetti i dati corretti',
    go_back_link_label: 'Indietro',
    case_list_change_customer: 'Cambia cliente',
    customer_table_c1_name: 'Nome',
    customer_table_c2_email: 'ID e-mail',
    customer_table_c3_org_id: 'ID org',
    customer_table_c4_org_name: 'Nome organizzazione',
    customer_table_c5_role: 'Ruolo',
    customer_table_c6_type: 'Digitare',
    customer_table_top_title: 'Seleziona un’org',
    zendesk_message: "Si tratta di un cliente legacy di Sign Enterprise. Zendesk è stato dismesso. Nessun case verrà creato in Zendesk. Crea manualmente un ticket di supporto in C14E.",
    zendesk_hypertext: "Link alla pagina Customer Search C14E"
  },
  'pt-BR': {
    ecm_title: 'Gerenciamento de Caso Corporativo',
    ecm_icon_button: 'ECM',
    header_case: 'Caso: ',
    header_no_caseId: 'Nenhum selecionado',
    type_here: 'Digite aqui',
    select_existing_case: 'Selecione um caso existente ou crie um caso',
    create_case_button: 'Criar Caso',
    table_column_case_title: 'TÍTULO DE CASO',
    table_column_last_modified: 'ÚLTIMA MODIFICAção',
    table_column_status_reason: 'STATUS - MOTIVO',
    table_column_case_id: 'ID DO CASO',
    customer_selected_notification: 'Cliente selecionado.',
    case_linked_notification: 'Caso vinculado a esta conversa.',
    case_unlinked_notification:
      'Caso desvinculado. Agora você pode selecionar um caso diferente.',
    unlink_case_tooltip: 'Desvincule caso antes de selecionar outro.',
    case_information: 'Informações do caso',
    case_title_create_case: 'Titulo de caso',
    case_category: 'Categoria do caso',
    case_type: 'Tipo de caso',
    back: 'Voltar',
    administration: 'Administração',
    product: 'Produto',
    navigate_to_dynamics:
      'Navegue até Dynamics para criar um caso para este cliente.',
    select: 'Selecionar...',
    'loading_customer_details-msg': 'Carregando detalhes de cliente...',
    getting_cases_loader_msg: 'Obtendo casos...',
    creating_case_loader_msg: 'Criando caso...',
    no_open_cases_msg: 'Não há casos abertos para este cliente',
    search_page_title: 'Pesquisa de Cliente',
    search_page_subtitle: 'Por favor digite as informações do cliente:',
    search_page_or: 'ou',
    search_page_field_email: 'Adobe ID (endereço Eletrônico)',
    search_page_field_phone: 'Número de telefone',
    search_page_search_button: 'Pesquisar',
    search_page_type_here: 'Digite aqui',
    search_page_email_place_holder: 'Digite aqui',
    loader_loading_customer_profiles: 'Carregamento Perfis de Cliente...',
    customer_not_found_text: 'Cliente não Encontrado',
    customer_invalid_phone_err_msg: 'Por favor digite o número de telefone correto',
    customer_invalid_email_err_msg: 'Por favor digite o email Id correto',
    customer_invalid_input_err_msg: 'Por favor digite os dados corretos',
    go_back_link_label: 'Voltar',
    case_list_change_customer: 'Alterar cliente',
    customer_table_c1_name: 'Nome',
    customer_table_c2_email: 'ID de e-mail',
    customer_table_c3_org_id: 'ID da organização',
    customer_table_c4_org_name: 'Nome Org',
    customer_table_c5_role: 'Papel',
    customer_table_c6_type: 'Digitar',
    customer_table_top_title: 'Escolha uma organização',
    zendesk_message: "Este cliente tem a versão antiga do Sign Enterprise (legacy). O Zendesk foi desativado pelo que nenhum caso poderá ser criado usando o Zendesk. Por favor crie manualmente um ticket de suporte no C14E.",
    zendesk_hypertext: "Link para a página de pesquisa de cliente no C14E"
  },
  'zh-TW': {
    ecm_title: '企業個案管理',
    ecm_icon_button: 'ECM',
    header_case: '個案: ',
    header_no_caseId: '未選取',
    type_here: '在此輸入',
    select_existing_case: '選取既有個案或建立個案',
    create_case_button: '建立個案',
    table_column_case_title: '個案標題',
    table_column_last_modified: '上次修改日期',
    table_column_status_reason: '狀態原因',
    table_column_case_id: '個案 ID',
    customer_selected_notification: '已選取的客戶.',
    case_linked_notification: '連結至此對話的個案。',
    case_unlinked_notification: '個案已解除連結。您現在可以選取其他個案。',
    unlink_case_tooltip: '請在選取新個案前取消個案連結。',
    case_information: '個案資訊',
    case_title_create_case: '個案標題',
    case_category: '個案類別',
    case_type: '個案類型',
    back: '返回',
    administration: '管理',
    product: '產品',
    navigate_to_dynamics: '請導覽至 Dynamics 為該客戶建立個案.',
    select: '選取...',
    'loading_customer_details-msg': '正在載入客戶詳細資訊...',
    getting_cases_loader_msg: '正在取得個案資訊...',
    creating_case_loader_msg: '正在建立個案...',
    no_open_cases_msg: '此客戶沒有進行中的個案',
    search_page_title: '客戶搜尋',
    search_page_subtitle: '請輸入正確客戶資訊：',
    search_page_or: '或',
    search_page_field_email: 'Adobe ID (電子郵件地址)',
    search_page_field_phone: '電話號碼',
    search_page_search_button: '搜尋',
    search_page_type_here: '在此輸入',
    search_page_email_place_holder: '在此輸入',
    loader_loading_customer_profiles: '正在載入客戶個人資料…',
    customer_not_found_text: '查無客戶',
    customer_invalid_phone_err_msg: '請輸入正確電話號碼',
    customer_invalid_email_err_msg: '請輸入正確的電子郵件 ID',
    customer_invalid_input_err_msg: '請輸入正確資訊',
    go_back_link_label: '返回',
    case_list_change_customer: '變更客戶',
    customer_table_c1_name: '姓名',
    customer_table_c2_email: '電子郵件 ID',
    customer_table_c3_org_id: '組織 ID',
    customer_table_c4_org_name: '組織名稱',
    customer_table_c5_role: '職位',
    customer_table_c6_type: '輸入',
    customer_table_top_title: '請選取一個組織',
    zendesk_message: "這是 Sign 企業傳統客戶。Zendesk 已停用。不會在 Zendesk 中創建任何案例。請手動在 C14E 中創建支持票證。",
    zendesk_hypertext: "客戶搜尋 C14E 頁面連結"
  },
  'zh-CN': {
    ecm_title: '企业案例管理',
    ecm_icon_button: 'ECM',
    header_case: '案例：',
    header_no_caseId: '未选择',
    type_here: '在此键入',
    select_existing_case: '选择现有案例或创建案例',
    create_case_button: '创建案例',
    table_column_case_title: '案例标题',
    table_column_last_modified: '上次修改日期',
    table_column_status_reason: '状态原因',
    table_column_case_id: '案例 ID',
    customer_selected_notification: '客户已选中.',
    case_linked_notification: '本次对话关联的案例。',
    case_unlinked_notification: '案例未关联。您现可选择其他的案例。',
    unlink_case_tooltip: '选择新案例之前，请先解除案例关联。',
    case_information: '案例信息',
    case_title_create_case: '案例标题',
    case_category: '案例类别',
    case_type: '案例类型',
    back: '返回',
    administration: '管理',
    product: '产品',
    navigate_to_dynamics: '请导航到 Dynamics 为客户创建案例。',
    select: '选择...',
    'loading_customer_details-msg': '正在加载客户详细信息...',
    getting_cases_loader_msg: '正在获取案例...',
    creating_case_loader_msg: '正在创建案例...',
    no_open_cases_msg: '该客户没有未结案例',
    search_page_title: '客户搜索',
    search_page_subtitle: '请输入客户信息：',
    search_page_or: '或',
    search_page_field_email: 'Adobe ID（电子邮件地址）',
    search_page_field_phone: '电话号码',
    search_page_search_button: '搜索',
    search_page_type_here: '在此键入',
    search_page_email_place_holder: '在此键入',
    loader_loading_customer_profiles: '正在加载客户个人资料...',
    customer_not_found_text: '客户未找到',
    customer_invalid_phone_err_msg: '请输入正确的电话号码',
    customer_invalid_email_err_msg: '请输入正确的电子邮件 ID',
    customer_invalid_input_err_msg: '请输入正确的信息',
    go_back_link_label: '返回',
    case_list_change_customer: '更改客户',
    customer_table_c1_name: '姓名',
    customer_table_c2_email: '电子邮件地址',
    customer_table_c3_org_id: '组织 ID',
    customer_table_c4_org_name: '组织名称',
    customer_table_c5_role: '角色',
    customer_table_c6_type: '键入',
    customer_table_top_title: '选择组织',
    zendesk_message: "这是 Sign Enterprise 的传统客户。Zendesk 已退役。Zendesk 中不会创建任何案例。请在 C14E 中手动创建支持票。",
    zendesk_hypertext: "客户搜索 C14E 页面链接"
  },
  ko: {
    ecm_title: '엔터프라이즈 사례 관리',
    ecm_icon_button: 'ECM',
    header_case: '사례: ',
    header_no_caseId: '선택하지 않음',
    type_here: '여기에 입력',
    select_existing_case: '기존 사례를 선택하거나 사례를 만드십시오',
    create_case_button: '사례 만들기',
    table_column_case_title: '사례 제목',
    table_column_last_modified: '마지막 업데이트 날짜',
    table_column_status_reason: '상태 이유',
    table_column_case_id: '사례 ID',
    customer_selected_notification: '선택된 고객.',
    case_linked_notification: '이 대화와 연결된 사례입니다.',
    case_unlinked_notification:
      '사례 연결이 해제되었습니다. 이제 다른 사례를 선택할 수 있습니다.',
    unlink_case_tooltip:
      '새로운 사례를 선택하기 전에 사례를 연결 해제하십시오.',
    case_information: '사례 정보',
    case_title_create_case: '사례 제목',
    case_category: '사례 범주',
    case_type: '사례 유형',
    back: '뒤로',
    administration: '관리',
    product: '제품',
    navigate_to_dynamics:
      'Dynamics로 이동하여 이 고객에 대한 사례를 생성하십시오',
    select: '선택...',
    'loading_customer_details-msg': '고객 세부 사항 로드 중...',
    getting_cases_loader_msg: '사례를 가져오는 중...',
    creating_case_loader_msg: '사례를 만드는 중...',
    no_open_cases_msg: '이 고객에 대한 미완료 사례 없음',
    search_page_title: '고객 검색',
    search_page_subtitle: '다음 고객 정보를 입력하십시오.',
    search_page_or: '또는',
    search_page_field_email: 'Adobe ID(이메일 주소)',
    search_page_field_phone: '전화 번호',
    search_page_search_button: '검색',
    search_page_type_here: '여기에 입력',
    search_page_email_place_holder: '여기에 입력',
    loader_loading_customer_profiles: '고객 프로필 로드 중',
    customer_not_found_text: '고객을 찾을 수 없음',
    customer_invalid_phone_err_msg: '올바른 전화 번호를 입력하십시오',
    customer_invalid_email_err_msg: '올바른 이메일 ID를 입력하십시오',
    customer_invalid_input_err_msg: '올바른 정보를 입력하십시오',
    go_back_link_label: '뒤로',
    case_list_change_customer: '고객 변경',
    customer_table_c1_name: '이름',
    customer_table_c2_email: '이메일 ID',
    customer_table_c3_org_id: '조직 ID',
    customer_table_c4_org_name: '조직 이름',
    customer_table_c5_role: '역할',
    customer_table_c6_type: '유형',
    customer_table_top_title: '조직 선택',
    zendesk_message: "이 고객은 Sign Enterprise의 기존 고객입니다.Zendesk는 서비스 종료되었습니다.Zendesk에서는 어떤 케이스도 생성되지 않습니다.C14E에서 수동으로 지원 티켓을 만드세요.",
    zendesk_hypertext: "고객 검색 C14E 페이지 링크"
  }
}
export default localization
