import React from 'react';
import { connect } from 'react-redux';

import linkCase from '../api/linkCase';
import { getCaseAPI, setActiveCase } from '../Store/Actions/caseActions';
import styles from '../Theme/Styles/styles.css';
import { setLocalStorage } from '../utils/localStorage';

type propsData = {
  getCasesAfterUCIFormChanges: () => void;
  UCIFormURL: string;
  assignedAgentLDAP: string;
  conversationId: string;
  activeCustomer: any;
  isConversationStillOpen: boolean;
  activeCase: any;
  isConversationTransferred: boolean;
  origin: string;
  getCaseAPIDispatch: (payload: any) => void;
  setActiveCaseDispatch: (payload: any) => void;
  handleUCIEventType: (payload: string) => void;
};

class UCIForm extends React.Component<propsData> {
  onUCI: boolean;
  iFrameSource: any;
  targetOrigin: any;
  constructor(props: propsData) {
    super(props);
    this.onUCI = true;
    this.iFrameSource = null;
    this.targetOrigin = null;
  }

  /* istanbul ignore next */
  handleMessageListeners = (event: any) => {
    const {conversationId: uciConvId, assignedAgentLDAP: uciAgent, origin, isConversationStillOpen } = this.props;
    const CLOSEWITHREFRESHSTATUS = ['RESOLVE', 'DISCONNECT'];
    let linkCaseDetails: any = {};
    const JSONData = event.data;
    const caseData = JSONData.DATA;
    try {
      console.log(`UCI Form Flags  : onUCI = ${this.onUCI}, origin = ${origin}, conversationOpen = ${!isConversationStillOpen}, $windowUCIContactID = ${(window as any).UCIContactID}, uciConvId = ${uciConvId}`);
      // Handling edge case
      if(!this.onUCI || (origin !== 'chat-to-phone' && !isConversationStillOpen && (window as any).UCIContactID !== uciConvId)) {
        console.log('Blocking UCI Transaction');
        return;
      }
      console.log('UCI form action :', JSONData);
      if (JSONData?.ACTION) {
        // Event is create then update the caseID in DB
        if (JSONData.ACTION === 'CREATE') {
          linkCaseDetails = {
            id: caseData.ticketnumber,
            guid: caseData.id,
            caseCreated: true,
            linkedByAgent: uciAgent,
          };
          this.props.setActiveCaseDispatch(linkCaseDetails);
          const linkCasePayload = {
            conversationId: uciConvId,
            linkedCaseData_CCM: linkCaseDetails,
          };
          linkCase(linkCasePayload);
          setLocalStorage(uciConvId, 'subPage', 'UCI_manageCase');
          console.log('event Triggered: CREATE with linkCaseData : ', linkCasePayload);
        }
        // Event is UPDATE then Refresh Needed
        if (JSONData.ACTION === 'UPDATE') {
          console.log('UCI form action update');
        }

        // Event is CLOSE then Refresh Needed based on Condition and Redirection
        if (JSONData.ACTION === 'CLOSE' || CLOSEWITHREFRESHSTATUS.includes(JSONData.ACTION)) {
          this.onUCI = false;
          this.refreshCaseList();
          this.props.getCasesAfterUCIFormChanges();
          console.log(`UCI form action close`);
          setLocalStorage(uciConvId, 'subPage', 'NOT_APPLICABLE');
        }
        // Event is "FORM_LOAD" then update Target origin and I frame Source
        if (JSONData.ACTION === 'FORM_LOAD') {
          this.targetOrigin = event.origin;
          this.iFrameSource = event.source;
          console.log('UCI form action: FORM Load', event.origin, event.source);
        }
        // Event is RESOLVE/DISCONNECT Then Refresh and Redirected to openCase page
        if (JSONData.ACTION === 'RESOLVE') {
          console.log(`UCI form action: RESOLVE`);
          this.props.handleUCIEventType('RESOLVE');
          this.refreshCaseList();
          this.props.getCasesAfterUCIFormChanges();
          setLocalStorage(uciConvId, 'subPage', 'NOT_APPLICABLE');
        }
        if (JSONData.ACTION === 'DISCONNECT') {
          console.log(`UCI form action: DISCONNECT`);
          this.props.handleUCIEventType('DISCONNECT');
          this.refreshCaseList();
          this.props.getCasesAfterUCIFormChanges();
          setLocalStorage(uciConvId, 'subPage', 'NOT_APPLICABLE');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  /* istanbul ignore next */
  componentDidMount() {
    this.onUCI = true;
    window.addEventListener('message', this.handleMessageListeners);
  }

  /* istanbul ignore next */
  componentWillUnmount() {
    console.log('UCI Form: will unmount and removing listeners');
    window.removeEventListener('message', this.handleMessageListeners);
  }

  /* istanbul ignore next */
  componentWillReceiveProps(nextProps: any) {
    const { caseCreated = false } = nextProps?.activeCase;
    const { id: activeCaseId } = this.props.activeCase;
    const convStatusChanged =
      !nextProps.isConversationStillOpen &&
      nextProps.isConversationStillOpen !== this.props.isConversationStillOpen;
    console.log(
      `UCI form convStatusChanged : ${convStatusChanged}, nextProps.isConversationStillOpen : ${nextProps.isConversationStillOpen}, this.props.isConversationStillOpen : ${this.props.isConversationStillOpen}`,
    );
    if (convStatusChanged) {
      const isTransferredWithoutCaseCreation = nextProps.isConversationTransferred;
      // If the conversation is transferred without any action inside UCI Form OR has an activeCase Linked, then navigate to CaseList Page
      if (isTransferredWithoutCaseCreation || activeCaseId) {
        this.refreshCaseList();
        this.props.getCasesAfterUCIFormChanges();
      }
      // If the conversation is closed without any action inside UCI Form, then show Pop-up(auto case creation
      else if (!caseCreated && !!this.iFrameSource) {
        const messageToSend = JSON.stringify({
          data: { conversationClosed: true },
          command: 'block-create-case',
          surface: 'LE',
        });
        this.iFrameSource.postMessage(messageToSend, this.targetOrigin);
        console.log('Event Posted to UCI Form Successfully for Conv Closed');
        this.refreshCaseList();
      }
    }
  }

  /* istanbul ignore next */
  refreshCaseList() {
    if (this.props.activeCustomer.rengaId) {
      const payload = {
        rengaId: this.props.activeCustomer.rengaId,
        conversationId: this.props.conversationId,
      };
      this.props.getCaseAPIDispatch(payload);
    }
  }

  render() {
    return (
      <div className={styles['uci-iframe-container']}>
        <iframe
          width="95%"
          height="94%"
          src={this.props.UCIFormURL}
          sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-forms allow-modals"
        />
      </div>
    );
  }
}

export const mapStateToProps = (state: any) => ({
  conversationId: state.oc.conversationId,
  assignedAgentLDAP: state.oc.assignedAgentLDAP,
  activeCustomer: state.customer.activeCustomer,
  isConversationStillOpen: state.oc.isConversationStillOpen,
  activeCase: state.case.activeCase,
  isConversationTransferred: state.oc.isConversationTransferred,
  origin: state.oc.origin,
});

export const mapDispatchToProps = (dispatch: any) => ({
  setActiveCaseDispatch: function (data: any) {
    dispatch(setActiveCase(data));
  },
  getCaseAPIDispatch: function (data: any) {
    dispatch(getCaseAPI(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UCIForm);
