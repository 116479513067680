import { Flex, Link, ProgressCircle, Tooltip, TooltipTrigger, View } from '@adobe/react-spectrum'
import React, { useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import localization from '../Language/localization'
import { COPY_PASTE_MESSAGE } from '../Store/constants/AAConstants'
import { AdobeIcon, ShareToChat } from '../Themes/Icons'
import AgentFeedback from './AgentFeedback'
import styles from '../Themes/Styles/styles.module.css'
import themes from '../Themes/Themes'
import { initDunamis, renderAnswerEvent, trackPinnedRecentQueryAndCopyEvents, storeTheAdobeAnswers } from '../Analytics'
import TurndownService from 'turndown'
import { Query } from './AdobeAnswersFrame'

interface Props {
  error: boolean
  feedbackOptions?: {
    inappropriateFlagOptions: {[key: string]: any},
    thumbsDownFeedbackOptions: {[key: string]: any}
  }
  isLoading: boolean
  language: string
  darkMode: boolean
  conversationId: string
  currentQuery: Query
  callBackEvent: (event: any, eventData: object) => void
  sendFeedBack: (
    comment: string,
    feedbackType: string,
    options: any[]
  ) => void,
  currentSelectedQuery: any
  // regenerate: () => void
}

const AIAnswer = ({
  conversationId,
  currentQuery,
  darkMode,
  error,
  feedbackOptions,
  isLoading,
  language,
  callBackEvent,
  sendFeedBack,
  currentSelectedQuery
}: Props) => {
  const textRef = useRef<HTMLDivElement>(null)
  const { darkModeAIMessage, darkModeAIMessageContent, darkModeMarkdown, darkModeAnswerFooter } = themes(darkMode)

  const getSelectedHtmlFragment = (selection) => {
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const clonedFragment = range.cloneContents()
      const tempDiv = document.createElement('div')
      tempDiv.appendChild(clonedFragment)
      return tempDiv.innerHTML
    }
    return null
  }

  const copytoChat = () => {
    const selection = window.getSelection()
    const selectedText = selection?.toString()

    if (selectedText && textRef.current?.contains(selection?.anchorNode ?? null)) {
      const selectedHtmlFragment = getSelectedHtmlFragment(selection)
      if (selectedHtmlFragment) {
        var turndownService = new TurndownService()
        var markdown = turndownService.turndown(selectedHtmlFragment)
        callBackEvent(COPY_PASTE_MESSAGE, { message: { text: markdown } })
        storeTheAdobeAnswers(conversationId, currentQuery);
        trackPinnedRecentQueryAndCopyEvents(currentQuery?.assistId, "copy", "partial", "button");
      }
    } else if (!selectedText) {
      callBackEvent(COPY_PASTE_MESSAGE, { message: { text: currentQuery?.answer } })
      storeTheAdobeAnswers(conversationId, currentQuery);
      trackPinnedRecentQueryAndCopyEvents(currentQuery?.assistId, "copy", "complete", "default-click")
    }
  }

  useEffect(() => {
    initDunamis()
    // dunamis analytics event - render search results
    if (currentQuery?.answer) {
      renderAnswerEvent(currentQuery?.answer, currentQuery?.question)
    }
  }, [currentQuery?.answer])

  return (
    <div className={`${styles['aa_container']}`}>
      <Flex direction='column' alignItems='start' width='100%'>
        {isLoading ? (
          <div className={`${styles['aa_overlay']}`}>
            <ProgressCircle aria-label='Loading…' isIndeterminate />
            <div><span className={`${styles['aa_overlay-message']}`}>{localization[language]['Generating Response']}</span></div>
          </div>
        ) : (
        <View UNSAFE_className={`${styles['aa_ai_message'] + darkModeAIMessage}`}>
          <Flex direction='row'>
            <div>
              <div className={`${styles['aa_logo_icon_round']}`}>
                <AdobeIcon />
              </div>
              {/* answers?.length > 1 && (
                <div className={`${styles['answer-index-change']}`}>
                  <div className={`${styles['answer-index-button']}`} onClick={() => nextIndex(-1)}>
                    <div>&lt;</div>
                  </div>
                  {` ${currentIndex + 1} / ${answers?.length} `}
                  <div className={`${styles['answer-index-button']}`} onClick={() => nextIndex(1)}>
                    <div>&gt;</div>
                  </div>
                </div>
              ) */}
            </div>
            <div className={`${styles['aa_ai_message_content']} ${darkModeAIMessageContent}`} ref={textRef}>
              <ReactMarkdown className={`${styles['aa_ai_message_markdown']} ${darkModeMarkdown}`} linkTarget='_blank'>
                {currentQuery?.answer ?? ''}
              </ReactMarkdown>
              {currentSelectedQuery?.linkSources && (
                <div className={styles['aa-source-links-container']}> 
                  {currentSelectedQuery?.linkSources?.slice(0, 5).map((item, index) => (
                  <TooltipTrigger key={index} delay={0} placement='bottom' >
                    <Link variant='secondary' href={item?.sourceUrl} onPress={()=>window.open(item?.sourceUrl,"_blank" )} UNSAFE_className={`${styles['aa-source-link']}`}>
                        {`Source ${index + 1}`}
                    </Link>
                    <Tooltip>
                      {item?.sourceUrl}
                    </Tooltip>
                  </TooltipTrigger>
                  ))}
                </div>
                )} 
            </div>
          </Flex>
        </View>)}
      </Flex>
      
      {!isLoading && !error && (
        <Flex direction='row' width='100%' alignItems='center' UNSAFE_className={`${styles['answer-footer']} ${darkModeAnswerFooter}`}>
          <Link onPress={copytoChat}>
            <div className={`${styles['copy-to-chat']}`}> <ShareToChat darkMode={darkMode}/> </div>
          </Link>
          <AgentFeedback
            query={currentQuery}
            // regenerate={regenerate}
            darkMode={darkMode}
            feedbackOptions={feedbackOptions}
            language={language}
            sendFeedback={sendFeedBack}
            // regenerationDisabled={answers?.length <= 2}
          />
        </Flex>
      )}
    </div>
  )
}
export default AIAnswer
