import React, { useState, useEffect, useRef } from 'react'
import styles from './ACFilter.css'
import {
  SearchField,
  Text,
  Content,
  Dialog,
  DialogTrigger,
  ActionButton,
  Heading,
  Divider,
  ButtonGroup,
  Button,
  Flex,
  Checkbox,
  TextField
} from '@adobe/react-spectrum'
import { FilterIcon, ChevronDownIcon, CalenderIcon } from '../../Theme/Icons'
import Provider from '@react/react-spectrum/Provider';
import Datepicker from '@react/react-spectrum/Datepicker';
import Select from '@react/react-spectrum/Select';
import moment from 'moment';
import localization from '../lang/localization';

const options = [
  { label: '1', value: '20' },
  { label: '2', value: '40' },
  { label: '3', value: '60' },
  { label: '4', value: '80' },
  { label: '5', value: '100' }
];

export interface FilterState {
  isSearchByConversationId: boolean;
  searchConversationId: string;
  searchTranscripts: string;
  csat: any
  isFilter: boolean
  status: string
  channel: string
}

type propsData = {
  gridData: any
  dateFilter: any
  callBackEvent: (eventType: string, eventData: object) => void
  paginate: any
  darkTheme: boolean
  isApiCall: boolean
  filterBy: FilterState
  lang: any
  isInit: boolean
}

const ACFilter = (props: propsData) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const [csatOpen, setCsatOpen] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)
  const [channelOpen, setChannelOpen] = useState(false)
  const [dateOpen, setDateOpen] = useState(false)
  const [isNewDateFilter, setNewDateFilter] = useState(false);
  const [isSearchBoxEnable, setSearchBoxEnable] = useState(false);
  const myRef = useRef<any>(null);

  const handleClickOutside = (e: any) => {
    if (myRef?.current?.contains && !myRef.current.contains(e.target) && isNewDateFilter) {
      setDateOpen(false);
      setNewDateFilter(false);
      props.callBackEvent('FilterByDate', { callapi: true });
    }
  };

  const handleSetDateOpen = (e: any) => {
    if (isNewDateFilter) {
      setNewDateFilter(false);
      props.callBackEvent('FilterByDate', { callapi: true });
    }
    if (e?.target?.nodeName === 'BUTTON' && e?.target?.firstChild?.nodeName === 'svg'
      && e?.target?.ariaLabel === "Calendar") {
      setDateOpen(!dateOpen)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    const searchTranscriptBoxTimer = setTimeout(() => {
      if (isSearchBoxEnable) {
        props.filterBy.isFilter = true;
        props.callBackEvent('FilterBy', props.filterBy);
      }
    }, 2000)
    return () => clearTimeout(searchTranscriptBoxTimer);
  }, [props?.filterBy?.searchTranscripts]);

  const handleFilterBy = (val: any, key: any, searchBox?: boolean) => {
    setSearchBoxEnable(searchBox || false);
    props.callBackEvent('upsertFilterBy', { val, key })
  }

  function filterTextParser(filterData: any) {
    const list: any = [];
    const textShowString = {
      searchConversationId: localization[lang].ALL_CONVERSATIONS.CONV_ID,
      searchTranscripts: localization[lang].ALL_CONVERSATIONS.TRANSCRIPTS,
      csat: localization[lang].ALL_CONVERSATIONS.CSAT,
      status: localization[lang].ALL_CONVERSATIONS.STATUS,
      channel: localization[lang].ALL_CONVERSATIONS.CHANNEL
    }
    for (const property in filterData) {
      if (filterData[property]) {
        if (((property === 'csat' || property === 'channel' || property === 'status') && filterData[property].length > 0)
          || (property === 'searchConversationId' && filterData.isSearchByConversationId)
          || (property === 'searchTranscripts')) {
          list.push(textShowString[property])
        }
      }
    }
    return list.filter((n: any) => n !== undefined).join([', ']);
  }

  const handleFilterByResult = () => {
    props.filterBy.isFilter = true;
    props.callBackEvent('FilterBy', props.filterBy);
    setFilterOpen(!filterOpen);
  }

  const handleDateTime = (val: any) => {
    setNewDateFilter(true);
    props.callBackEvent('FilterByDate', val);
  }

  const resetFilter = () => {
    if (filterBy.isFilter) {
      props.callBackEvent('FilterBy', { reset: true });
      setFilterOpen(false);
    } else {
      props.callBackEvent('clearFilterBy', {})
    }
    setSearchBoxEnable(false);
    setFilterOpen(false);
    myRef.current.click();
    setDateOpen(false);
  }

  const changeSearchTranscripts = (e: string) => {
    if (props.isApiCall) { return }
    handleFilterBy(e, 'searchConversationId', true);
    props.filterBy.isFilter = true;
    props.callBackEvent('FilterBy', props.filterBy);
  }

  const { filterBy, darkTheme, dateFilter, lang, isInit } = props;

  const statusOptions = [
    { label: localization[lang].ALL_CONVERSATIONS.CONNECTED, value: localization[lang].ALL_CONVERSATIONS.CONNECTED},
    { label: localization[lang].ALL_CONVERSATIONS.NOT_CONNECTED, value: localization[lang].ALL_CONVERSATIONS.NOT_CONNECTED},
  ]

  const channelOptions = [
    { label: 'Voice', value: 'VOICE'},
    { label: 'Chat', value: 'CHAT'},
  ]

  const handleStatusOpen = () => {
      setStatusOpen(true)
  }

  const handleStatusClose = () => {
    setStatusOpen(false)
  }

  const handleChannelOpen = () => {
    setChannelOpen(true)
  }

  const handleChannelClose = () => {
    setChannelOpen(false)
  }

  return (
    <div>
      <div className={`${styles['ac-filter-container']} ${darkTheme && styles['ac-filter-container-dark']}`}>
        <div className={styles['search-container']}>
          <SearchField labelPosition='side' placeholder={localization[lang].ALL_CONVERSATIONS.ENTER_CONV_ID + '...'} width='255px' labelAlign='start'
            aria-label={localization[lang].ALL_CONVERSATIONS.SEARCH_TRANSCRIPTS}
            // isReadOnly={filterBy.isSearchByConversationId || !isInit}
            value={filterBy.searchConversationId}
            onChange={changeSearchTranscripts}
          />
          <div className={styles['filter-button-container']}>
            <DialogTrigger
              isDismissable
              type='popover'
              isOpen={filterOpen}
              placement='bottom'
              offset={-10}
              onOpenChange={(isOpen: boolean) => isInit && setFilterOpen(isOpen)}>
              <ActionButton UNSAFE_className={styles['all-con-filter-btn']}>
                <div className={styles.flex}>
                  <FilterIcon className={`${filterOpen ? styles['all-con-filter-icon-active'] : styles['all-con-filter-icon']}`} />
                </div>
              </ActionButton>
              {() => (
                <Dialog
                  size='S'
                  width={625}
                  height={csatOpen ? 570 : 480}
                  UNSAFE_className={`${styles['all-con-filter-model-container']} ${darkTheme && styles['all-con-filter-model-container-dark']}`}>
                  <div className={styles['all-con-filter-upper-content']}>
                    <Heading UNSAFE_className={styles['filter-by-text']}>{localization[lang].ALL_CONVERSATIONS.FILTER_BY}</Heading>
                    <Divider size="S" marginTop={5} marginBottom={35} />
                    <Content>
                      <Flex direction="row" UNSAFE_className={styles['all-con-row-filter']}>
                        <div className={styles['all-con-row-filter-left']}>
                          <Checkbox
                            aria-label={localization[lang].ALL_CONVERSATIONS.SEARCH_CONV_ID}
                            UNSAFE_className={styles['left-sec']}
                            isSelected={filterBy.isSearchByConversationId}
                            onChange={(val: boolean) => handleFilterBy(val, 'isSearchByConversationId')}>
                            {localization[lang].ALL_CONVERSATIONS.SEARCH_CONV_ID} :
                          </Checkbox>
                        </div>
                        <div className={styles['all-con-row-filter-right']}>
                          <TextField
                            aria-label={localization[lang].ALL_CONVERSATIONS.ENTER_CONV_ID}
                            UNSAFE_className={styles['right-sec']}
                            placeholder={localization[lang].ALL_CONVERSATIONS.ENTER_CONV_ID}
                            value={filterBy.searchConversationId}
                            maxLength={50}
                            isDisabled={!filterBy.isSearchByConversationId}
                            onChange={(val: string) => handleFilterBy(val, 'searchConversationId')}
                          />
                        </div>
                      </Flex>
                      <Flex direction="row" UNSAFE_className={styles['display-none']}>
                        <div className={styles['all-con-row-filter-left']}>
                          <Text UNSAFE_className={styles['left-sec']}>
                            {localization[lang].ALL_CONVERSATIONS.SEARCH_TRANSCRIPTS} :
                          </Text>
                        </div>
                        <div className={styles['all-con-row-filter-right']}>
                          <TextField
                            aria-label={localization[lang].ALL_CONVERSATIONS.ENTER_SEARCH_TERMS}
                            UNSAFE_className={styles['right-sec']}
                            placeholder={localization[lang].ALL_CONVERSATIONS.ENTER_SEARCH_TERMS + '...'}
                            value={filterBy.searchTranscripts}
                            maxLength={50}
                            isDisabled={filterBy.isSearchByConversationId}
                            onChange={(val: string) => handleFilterBy(val, 'searchTranscripts')} />
                        </div>
                      </Flex>
                    </Content>
                    <Flex direction='row' UNSAFE_className={styles['status-sec']}>
                      <div className={styles['all-con-row-filter-left']}>
                        <Text UNSAFE_className={styles['left-sec']}>
                          {localization[lang].ALL_CONVERSATIONS.STATUS} :
                        </Text>
                      </div>
                      <div className={styles['all-con-row-filter-right']}>
                        <Provider theme={darkTheme ? 'dark' : 'light'}>
                          <Select
                            aria-label='STATUS'
                            options={statusOptions}
                            open={statusOpen}
                            onOpen={handleStatusOpen}
                            onClose={handleStatusClose}
                            placeholder={
                              localization[lang].ALL_CONVERSATIONS.SELECT +
                              '...'
                            }
                            disabled={filterBy.isSearchByConversationId}
                            onChange={(val) => handleFilterBy(val, 'status')}
                            defaultValue={filterBy.status}
                            className={`${styles['right-sec']} ${styles['csat-select-sec']}`}
                          />
                        </Provider>
                      </div>
                    </Flex>
                    <Flex direction='row' UNSAFE_className={styles['csat-sec']}>
                      <div className={styles['all-con-row-filter-left']}>
                        <Text UNSAFE_className={styles['left-sec']}>
                          {localization[lang].ALL_CONVERSATIONS.CHANNEL} :
                        </Text>
                      </div>
                      <div className={styles['all-con-row-filter-right']}>
                        <Provider theme={darkTheme ? 'dark' : 'light'}>
                          <Select
                            aria-label='CHANNEL'
                            options={channelOptions}
                            open={channelOpen}
                            onOpen={handleChannelOpen}
                            onClose={handleChannelClose}
                            placeholder={
                              localization[lang].ALL_CONVERSATIONS.SELECT +
                              '...'
                            }
                            disabled={filterBy.isSearchByConversationId}
                            onChange={(val) => handleFilterBy(val, 'channel')}
                            defaultValue={filterBy.channel}
                            className={`${styles['right-sec']} ${styles['csat-select-sec']}`}
                          />
                        </Provider>
                      </div>
                    </Flex>
                  </div>
                  <Divider size="S" marginTop={0} marginBottom={14} />
                  <div className={styles['all-con-filter-middle-content']}>
                    <Flex direction="row" UNSAFE_className={styles['ag-survey']}>
                      <ChevronDownIcon className={styles['all-con-chevron-down-icon']} />
                      <Text UNSAFE_className={styles['ag-survey-text']}> {localization[lang].ALL_CONVERSATIONS.AGENT_SURVEY} </Text>
                    </Flex>
                    <Flex direction="row" UNSAFE_className={styles['csat-sec']}>
                      <div className={styles['all-con-row-filter-left']}>
                        <Text UNSAFE_className={styles['left-sec']}>
                          {localization[lang].ALL_CONVERSATIONS.CSAT} :
                        </Text>
                      </div>
                      <div className={styles['all-con-row-filter-right']}>
                        <Provider theme={darkTheme ? "dark" : "light"}>
                          <Select
                            aria-label="CSAT"
                            options={options}
                            multiple
                            onOpen={() => setCsatOpen(true)}
                            onClose={() => setCsatOpen(false)}
                            placeholder={localization[lang].ALL_CONVERSATIONS.SELECT + '...'}
                            disabled={filterBy.isSearchByConversationId}
                            onChange={(val) => handleFilterBy(val, 'csat')}
                            defaultValue={filterBy.csat}
                            className={`${styles['right-sec']} ${styles['csat-select-sec']}`}
                          />
                        </Provider>
                      </div>
                    </Flex>
                    <ButtonGroup UNSAFE_className={styles['all-con-filter-footer-content']}>
                      <Button variant="secondary" UNSAFE_className={styles['reset-btn']} onPress={resetFilter}>
                        {localization[lang].ALL_CONVERSATIONS.RESET}
                      </Button>
                      <Button variant="cta" UNSAFE_className={`${styles['filter-btn']} ${!filterTextParser(filterBy) && styles['filter-btn-disabled']}`} onPress={handleFilterByResult}>
                        {localization[lang].ALL_CONVERSATIONS.FILTER}
                      </Button>
                    </ButtonGroup>
                  </div>
                </Dialog>
              )}
            </DialogTrigger>
          </div>
        </div>
        <div className={`${styles['all-con-date-container']} ${(filterBy && filterBy.isFilter && filterBy.isSearchByConversationId) && styles['all-con-date-container-disabled']}`}>
          <div onClick={handleSetDateOpen} className={styles['date-button']} ref={myRef}>
            <Provider theme={darkTheme ? "dark" : "light"}>
              <div className={styles['ac-date-time-container']}>
                <Datepicker
                  type="datetime"
                  selectionType="range"
                  placeholder="YYYY-MM-DD hh:mm a"
                  displayFormat="YYYY-MM-DD hh:mm a"
                  valueFormat="YYYY-MM-DD hh:mm a"
                  min={moment().subtract(13, 'months')}
                  aria-label="Date &amp; Time"
                  placement='bottom'
                  max={moment(new Date())}
                  value={[moment(dateFilter.startDate, ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DDTHH:mm:ss.sssZ']).format('YYYY-MM-DD hh:mm a'), moment(dateFilter.endDate, ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DDTHH:mm:ss.sssZ']).format('YYYY-MM-DD hh:mm a')]}
                  defaultValue={[moment(dateFilter.startDate, ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DDTHH:mm:ss.sssZ']).format('YYYY-MM-DD hh:mm a'), moment(dateFilter.endDate, ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DDTHH:mm:ss.sssZ']).format('YYYY-MM-DD hh:mm a')]}
                  onChange={handleDateTime}
                  disabled={(filterBy && filterBy.isFilter && filterBy.isSearchByConversationId) || !isInit}
                />
                <CalenderIcon className={`${dateOpen ? styles['ac-active-date-icon'] : styles['ac-default-date-icon']}`} />
              </div>
            </Provider>
          </div>
          <Text UNSAFE_className={styles['date-text']}>
            <span className={styles['dt-sd']}>{moment(dateFilter.startDate, ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DDTHH:mm:ss.sssZ']).format('DD MMM YYYY hh:mm a')}</span>
            <span className={styles['dt-dash']}>-</span>
            <span className={styles['dt-ed']}> {moment(dateFilter.endDate, ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DDTHH:mm:ss.sssZ']).format('DD MMM YYYY hh:mm a')}</span>
          </Text>
        </div>
      </div>
      {
        filterBy.isFilter &&
        <Flex direction='row' UNSAFE_className={styles['all-con-filter-data-sec']}>
          <div className={styles['filter-val-result']}>
            <div> {localization[lang].ALL_CONVERSATIONS.CURRENT_FILTER} : </div>
            <div className={styles['filter-text-val']}>{filterTextParser(filterBy)}</div>
            {filterTextParser(filterBy) && <span className={styles['v-line']}>|</span>}
          </div>
          <div className={`${styles['reset-filter']}`} onClick={resetFilter}>{localization[lang].ALL_CONVERSATIONS.RESET_FILTERS} </div>
        </Flex>
      }
    </div>
  )
}
export default ACFilter;