import React, { useEffect } from 'react'
import styles from '../Themes/Styles/styles.module.css'
import localization from '../Language/localization'
import { Flex } from '@adobe/react-spectrum'

import themes from '../Themes/Themes'
import { ArrowReturnRight } from '../Themes/Icons'
import AIAnswer from './AIAnswer'
import { initDunamis } from '../Analytics'
import { Query } from './AdobeAnswersFrame'

interface Props {
  showSuggestions: boolean
  suggestions: string[]
  conversationId: string
  currentQuery?: Query
  error: boolean
  feedbackOptions?: {
    inappropriateFlagOptions: {[key: string]: any},
    thumbsDownFeedbackOptions: {[key: string]: any}
  }
  isLoading: boolean
  language: string
  darkMode: boolean
  agentDetails: any
  callBackEvent: (event: any, eventData: object) => void
  getSuggestion: (suggestions: string) => void
  sendFeedBack: (
    comment: string,
    feedbackType: string,
    options: any[]
  ) => void;
  currentSelectedQuery: any;
  // regenerate: () => void
}

const AAAdobeAnswers = ({
  conversationId = '',
  currentQuery,
  darkMode = false,
  error,
  feedbackOptions,
  language = 'en',
  isLoading,
  agentDetails = '',
  callBackEvent,
  showSuggestions = false,
  suggestions = [],
  getSuggestion,
  sendFeedBack,
  currentSelectedQuery
}: Props) => {
  const {
    darkModeStartTyping,
    darkModeAgentAvatar,
    darkModeSuggestionTitle,
    darkModeSuggestion,
    darkModeTitle,
    darkModeBG,
    darkModeBorder
  } = themes(darkMode)
  // const pinned = !!pinnedQueries?.find((query) => query?.id === currentQueryIndex)
  // const assistIdForAnalytics = currentQuery?.assistId ?? currentQuery.answers?.[0]?.assistId

  useEffect(() => {
    initDunamis()
  }, [])

  return currentQuery && currentQuery?.question?.length ? (
    <Flex direction='column' width='100%'>
      {/* <div className={`${styles['aa_left_header'] + darkModeBG + darkModeBorder + darkModeTitle}`}>
      <Flex direction='row' UNSAFE_style={{ justifyContent: 'space-between'}}>
        <div className={`${styles['aa_left_header_description']}`}>{description}</div>
        <AAQueryButtons assistIdForAnalytics={assistIdForAnalytics} id={currentQueryIndex} deleteQuery={deleteQuery} pinQuery={pinQuery} actionsDisabled={isAnswerLoading} numberOfPinnedQueries={pinnedQueries?.length} pinned={pinned} language={language} />
      </Flex>
      </div> */}

      <Flex direction='row' UNSAFE_style={{ padding: '16px', position: 'relative' }}>
        <div className={`${styles['aa_module_agent_avatar']}` + darkModeAgentAvatar}>
          {(agentDetails?.agentName?.[0] ?? 'N') + (agentDetails?.agentName?.split(' ')?.[1]?.[0] ?? 'A')}
        </div>
        <span style={{ padding: '8px', maxWidth: '265px' }}>{currentQuery.question}</span>
      </Flex>
      <AIAnswer
        language={language}
        darkMode={darkMode}
        conversationId={conversationId}
        currentQuery={currentQuery}
        callBackEvent={callBackEvent}
        error={error}
        feedbackOptions={feedbackOptions}
        isLoading={isLoading}
        sendFeedBack={sendFeedBack}
        currentSelectedQuery={currentSelectedQuery}
        // regenerate={regenerate}
      />
    </Flex>
  ) : showSuggestions ? (
    <Flex direction='column' justifyContent='space-between' height='100%'>
      <div>
        <div className = {darkMode ? darkModeSuggestionTitle : `${styles['aa_suggestion_title']}`}>{localization[language]['Here some suggestion you can try']}</div>
        {suggestions?.map((suggestion) => (
          <div 
            className={darkMode ? darkModeSuggestion : `${styles['aa_suggestion']}`} 
            onClick={() => getSuggestion(suggestion)}>
              <div className = {`${styles['aa_suggestion_icon']}`} >
                <ArrowReturnRight darkMode={darkMode} />
              </div>
              {suggestion}
          </div>)
        )}
      </div>
      <div className={`${styles['aa-start-typing']}` + darkModeStartTyping}>{localization[language]['Start typing']}</div>
    </Flex>
  ) : (
    <div className={`${styles['aa-start-typing']}` + darkModeStartTyping}>{localization[language]['Start typing']}</div>
  )
}

export default AAAdobeAnswers
