import React from 'react';
import { Text, DialogTrigger, Button } from '@adobe/react-spectrum';
import { RejectOfferDialog } from '../offer-rejection/offer-rejection-component';
import { CardDetails, URLS } from '../../interfaces';
import styles from './offer-make-confirm-component.module.css';
import { useTheme } from '../../themes/ThemeContext'
import { useSelector, useDispatch } from 'react-redux';
import { DISABLED_BTN_NO } from '../../redux/constants/ru-constants';
import { RootState } from '../../redux/reducers';

interface OfferConfirmationComponentProps {
  urls: URLS
  convId: string
  customerId: string
  offerId: string
  activeIndex: number
  isSalesContact: boolean
  cardDetails: CardDetails
  vaoWorkflowCategory: string,
  textContent: string,
  showOfferMakeBox?: boolean,
  handleYes: () => void,
  handleNo: () => void,
}

const OfferConfirmationComponent = (props: OfferConfirmationComponentProps) => {
  const {
    urls,
    convId,
    offerId,
    customerId,
    cardDetails,
    activeIndex,
    handleYes,
    handleNo,
    textContent,
    vaoWorkflowCategory,
    showOfferMakeBox = false
  } = props;
  const { offerStatus } = cardDetails;
  const { RUInfo: {  multipartyAssignedAgent = false, disabledNoButtonConversations = {} } = {}} = useSelector((state: RootState) => state)
  const disabledBtnNo = disabledNoButtonConversations?.[convId]?.[offerId]

  const { theme } = useTheme()
  const dispatch = useDispatch()

  return (
    <div style={{backgroundColor: theme['box1-color']}} className={`${styles.offer__acceptance__box}`}>
      <Text UNSAFE_style={{ fontSize: '13px', fontWeight: 'bold' }}>{textContent}</Text>
      <div style={{ flexShrink: '0' }}>
        <Button 
          variant="primary"
          isDisabled={offerStatus === 'accepted' || !multipartyAssignedAgent}
          onPress={handleYes}
          marginEnd='5px'
          minHeight='0'
          minWidth='0'
          height='24px'
        >
          Yes
        </Button>
        {!showOfferMakeBox ? (
          <DialogTrigger>
          <Button 
            variant="primary"
            isDisabled={offerStatus === 'rejected' || !multipartyAssignedAgent}
            onPress={handleNo}
            minHeight='0'
            minWidth='0'
            height='24px'
          >
            No
          </Button>
          {(close) => (
            <RejectOfferDialog
              urls={urls}
              convId={convId}
              index={activeIndex}
              close={() => close()}
              customerId={customerId}
              cardDetails={cardDetails}
              vaoWorkflowCategory={vaoWorkflowCategory}
            />
          )}
          </DialogTrigger>
        ): (
          <Button 
            variant="primary"
            isDisabled={!multipartyAssignedAgent || disabledBtnNo}
            onPress={() => {
              dispatch({ type: DISABLED_BTN_NO, payload: { offerId, convId, disabledBtnNo: true } })
            }}
            minHeight='0'
            minWidth='0'
            height='24px'
          >
            No
          </Button>
        )}
      </div>
    </div>
  )
}

export default OfferConfirmationComponent;