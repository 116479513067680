import { getTranslationForPDC } from "./../api/ccpAPI";

async function translatePDC(translateURL: string, pdc: Array<any>, sourceLang: string = "en", targetLang: string = "es") {
    if (pdc.length) {
        if (!sourceLang || !targetLang || !translateURL) return []
        //check no of iteration since  10 titles/messages are at a time to avoid max request error
        const n = Math.ceil(pdc.length / 10);
        let startIdx = 0;
        let endIdx = 10;
        let output: Array<any> = []

        for (let i = 0; i < n; i++) {
            const currentPDCToTranslate: Array<any> = pdc.slice(startIdx, endIdx);
            const formattedPDCInputTitle = currentPDCToTranslate?.map(pdcItem => ({ "id": pdcItem.messageId, "text": pdcItem.title, "doNotTranslate": false }))
            const formattedPDCInputMessage = currentPDCToTranslate?.map(pdcItem => ({ "id": pdcItem.messageId, "text": pdcItem.message, "doNotTranslate": false }))
            try {
                const res = await Promise.all([getTranslationForPDC(translateURL, sourceLang, targetLang, formattedPDCInputTitle), getTranslationForPDC(translateURL, sourceLang, targetLang, formattedPDCInputMessage)])
                const translatedTitle = res[0]?.data?.data?.texts
                const translatedMessage = res[1]?.data?.data?.texts
                currentPDCToTranslate.forEach((curr, idx) => {
                    output.push({ ...curr, title: translatedTitle[idx].text, message: translatedMessage[idx].text })
                    return
                })
            }
            catch (err) {
                console.error('Error Occured while translating PDC')
                currentPDCToTranslate.forEach((curr) => {
                    output.push({ ...curr })
                })
            }
            startIdx = endIdx;
            endIdx += 10;
        }
        return output;
    }
    return [];
}


export { translatePDC }