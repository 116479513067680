import React, { useState, useEffect } from 'react';
import { ActionButton, Heading, View, Flex, Image, Text, DialogTrigger, Dialog, Content, ButtonGroup, Button, Divider } from '@adobe/react-spectrum'
import { URLS, CardDetails } from '../../interfaces';
import '../../Utils/string-utils';
import styles from './card.module.css'
import '../../styles/CommonStyles.css'
import { COLLAPSE_RU_DETAILS_HANDLER, COLLAPSE, SEND_OFFER, SET_RU_WIDGET_SHOW } from '../../redux/constants/ru-constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateOfferStatus } from '../../redux/actions/ru-actions'
import {
    showOfferEvent,
    initDunamis,
    sendOfferEvent,
    openHendrixBtnEvent,
    sendOrCancelOfferDialogEvent,
} from '../../Analytics/vao-danalytics';
import { RootState } from '../../redux/reducers';

interface CardComponentProps {
    urls: URLS,
    index: number,
    convId: string,
    darkMode: boolean,
    channel: string,
    queueName: string,
    customerId: string,
    showDetails: boolean,
    setShowDetails: (showDetails: boolean) => void,
    activeIndex: number,
    isSalesContact: boolean,
    isVoiceContact: boolean,
    cardDetails: CardDetails,
    vaoWorkflowCategory: string,
    showCardExpandedView: boolean,
    collapseRUDetailsFromOAC: string,
    defaultImage: any,
    handleExpandView: (showDetails: boolean) => void,
    callBackEvent: (event: any, eventData: object) => void,
}

const CardComponent = ({
    urls,
    index,
    convId,
    customerId,
    queueName,
    cardDetails,
    darkMode,
    activeIndex,
    showDetails,
    setShowDetails,
    isSalesContact,
    isVoiceContact,
    callBackEvent,
    handleExpandView,
    defaultImage,
    vaoWorkflowCategory,
    collapseRUDetailsFromOAC,
}: CardComponentProps) => {
    const { title: campaignName = '', offerText = '', backgroungIMGs = {}, description = {}, offerId = '', rejectReason = '', offerStatus = '' } = cardDetails || {};
    const [showSendOfferBtn, setShowSendOfferBtn] = useState(false);
    const [showOpenHendrixBtn, setShowOpenHendrixBtn] = useState(false);
    const { RUInfo: { multipartyAssignedAgent = false } = {} } = useSelector((state: RootState) => state);
    const language = 'en';
    const dispatch: any = useDispatch();
    const img = backgroungIMGs?.['328x80'] || defaultImage;
    const message = description?.messageText?.[language] || '';
    const cardBorderBottom = darkMode ? '1px solid #464646' : '1px solid #efefef';

    useEffect(() => {
        initDunamis();
    }, [])

    useEffect(() => {
        shouldShowHendrixOrOfferBtn();
    }, [showDetails, offerStatus, convId, customerId, isSalesContact, isVoiceContact])

    useEffect(()=>{
        if (collapseRUDetailsFromOAC === COLLAPSE) {
            setShowDetails(false)
            handleExpandView(false);
            callBackEvent(SET_RU_WIDGET_SHOW, { ruWidgetBottomShow: true, convId });
        } 
        callBackEvent(COLLAPSE_RU_DETAILS_HANDLER, {})
    }, [collapseRUDetailsFromOAC])

    /**
     * This function show or hide the offer make and confirm boxes based on the channel type, sales/support and offer status
     */
    const shouldShowHendrixOrOfferBtn = () => {
        let showSendOfferButton = false;
        let showOpenHendrixButton = false;

        if (showDetails === true && offerStatus === 'pitched') {
            showOpenHendrixButton = true;
        } else if (showDetails === true && (offerStatus === 'rejected' || offerStatus === 'accepted')) {
            showOpenHendrixButton = true;
        } else if (showDetails === true && offerStatus === '') {
            if (!isSalesContact && !isVoiceContact) {
                // do nothing
                showSendOfferButton = true;
                showOpenHendrixButton = false;
            } else {
                showOpenHendrixButton = true;
            }
        }

        setShowSendOfferBtn(showSendOfferButton);
        setShowOpenHendrixBtn(showOpenHendrixButton);
    }

    const handleSendOfferDialog = () => {
        sendOfferEvent(vaoWorkflowCategory, offerId, offerText[0], campaignName)
    }

    const handleShowHideDetails = (e) => {
        const btnType = e.target.innerText?.toLowerCase().replace(" ", "-");
        const detailsStatus = !showDetails;

        setShowDetails(detailsStatus)
        handleExpandView(detailsStatus);

        // analytics
        showOfferEvent(vaoWorkflowCategory, btnType, offerId, offerText[0], campaignName);
    }

    const handleOpenHendrixBtn = () => {
        const url = `${urls.HENDRIX_DOMAIN}/app/search?guid=${customerId}&chat-queue-id=${queueName}&chat-session-id=${convId}&context=${description.hxParam}`;

        // analytics
        openHendrixBtnEvent(vaoWorkflowCategory, offerId, offerText[0], campaignName);
        window.open(url, '_blank');
    }

    const handleSendOffer = async (close) => {
        callBackEvent(SEND_OFFER, { message })
        dispatch(updateOfferStatus({ convId, customerId, index: activeIndex, offerId, offerStatus: 'pitched', rejectReason, url: urls.PERSONALIZATION_DOMAIN }));
        sendOrCancelOfferDialogEvent(vaoWorkflowCategory, 'send', offerId, offerText[0], campaignName);
        close()
    }

    const handleDialogCancel = (close) => {
        sendOrCancelOfferDialogEvent(vaoWorkflowCategory, 'cancel', offerId, offerText[0], campaignName)
        close()
    }

    return (
        <View
            borderWidth="thin"
            borderColor="dark"
            // marginY="size-200"
            width="270px"
            height="130px"
            marginEnd={12}
            UNSAFE_style={{ borderRadius: showDetails ? '5px 5px 0px 0px' : 5, borderBottom: !showDetails ? cardBorderBottom : 'none' }}
        >
            <Flex direction="row" flex="2" justifyContent="start" width={270} >
                <View UNSAFE_style={{ overflow: 'hidden', borderRadius: showDetails ? '5px 0 0 0' : '5px 0px 0px 5px' }}>
                    <Image
                        src={img}
                        alt="Placeholder Image"
                        objectFit="cover"
                        height='130px'
                        width='size-600'
                    />
                </View>
                <Flex direction="column" flex="2" justifyContent="space-between" marginBottom={10} UNSAFE_style={{ boxSizing: 'border-box', minWidth: 0, paddingRight: '10px', paddingLeft: '10px' }}>
                    <View paddingX="size-100" paddingTop={5}>
                        <Heading level={4} UNSAFE_style={{ marginLeft: -10, paddingBottom: 5 }}>{campaignName.toCamelCase()}</Heading>
                        <ul style={{ maxHeight: '75px', overflowY: 'hidden', listStylePosition: 'inside' }}>
                            {offerText?.map((text: string, index: number) => {
                                return <li key={index} className={`${styles.ru_card_text}`}>{text}</li>
                            })}
                        </ul>
                    </View>
                    <Flex direction="row" flex="2" justifyContent="space-between" alignItems={'end'} UNSAFE_style={{ position: 'relative' }}>
                        {
                            activeIndex === index && (
                                <ActionButton isQuiet UNSAFE_className={`${(showDetails === false) ? styles.show__details__button : styles.hide__details__button}`} isDisabled={!multipartyAssignedAgent} onPress={handleShowHideDetails}>
                                    <Text UNSAFE_style={{ padding: 0 }}>{(showDetails === false) ? "Show Details" : "Hide Details"}</Text>
                                </ActionButton>
                            )
                        }
                        {
                            showOpenHendrixBtn &&
                            (
                                <Button variant="cta" isDisabled={offerStatus !== 'accepted' || !multipartyAssignedAgent} UNSAFE_className={`${styles.show__open__hendrix__button}`} onPress={handleOpenHendrixBtn}>
                                    <Text>Open Hendrix</Text>
                                </Button>
                            )
                        }
                        {
                            showSendOfferBtn &&
                            <DialogTrigger>
                                <Button variant="primary" UNSAFE_className={`${styles.show__send__offer__button}`} isDisabled={!multipartyAssignedAgent} onPress={handleSendOfferDialog}>
                                    Send Offer
                                </Button>
                                {(close) => (
                                    <Dialog UNSAFE_style={{ width: '500px' }}>
                                        <Heading>Send Offer</Heading>
                                        <Divider />
                                        <Content>
                                            <Text>The following message will be sent to the customer along with a checkout link.</Text>

                                            <p style={{ paddingTop: '30px' }}>{description.messageText.en}</p>
                                        </Content>
                                        <ButtonGroup>
                                            <Button
                                                variant='secondary'
                                                onPress={() => {
                                                    handleDialogCancel(close)
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant='primary'
                                                UNSAFE_style={{
                                                    backgroundColor: '#0864d5',
                                                    color: 'white',
                                                    border: '1px solid #e1e1e1'
                                                }}
                                                onPress={() => {
                                                    handleSendOffer(close)
                                                }}
                                            >
                                                Send
                                            </Button>
                                        </ButtonGroup>
                                    </Dialog>
                                )}
                            </DialogTrigger>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </View>
    )
}

export default CardComponent
