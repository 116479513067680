import actionTypes from '../actionTypes'

const {
  CASE_LIST_PAGE_FLAG,
  SET_LANGUAGE,
  TOGGLE_DARK_MODE,
  SET_SERVICE_BASE_URL,
  SET_AGENT_CONSOLE_SERVICE_BASE_URL,
  SET_CUSTOMER_DEEPLINK_URL,
  SET_CASE_DEEPLINK_URL,
  TOGGLE_NOTIFICATION,
  READ_ONLY_MODE,
  CLEAR_UI_STATE,
  SET_CHANGE_CUSTOMER,
  SET_SEARCH_CUSTOMER
} = actionTypes

export const toggleNotification = (flag: boolean, message: string) => {
  return {
    type: TOGGLE_NOTIFICATION,
    value: flag,
    payload: message
  }
}

export const setCaseListPageFlag = (flag: boolean) => {
  return {
    type: CASE_LIST_PAGE_FLAG,
    value: flag
  }
}

export const setLanguage = (lang: string) => {
  return {
    type: SET_LANGUAGE,
    value: lang
  }
}

export const toggleDarkMode = (flag: boolean) => {
  return {
    type: TOGGLE_DARK_MODE,
    value: flag
  }
}

export const setServiceBaseUrl = (url: string) => {
  return {
    type: SET_SERVICE_BASE_URL,
    value: url
  }
}
export const setAgentConsoleServiceBaseUrl = (url: string) => {
  return {
    type: SET_AGENT_CONSOLE_SERVICE_BASE_URL,
    value: url
  }
}

export const setCustomerDeeplinkURL = (url: string) => {
  return {
    type: SET_CUSTOMER_DEEPLINK_URL,
    value: url
  }
}

export const setCaseDeeplinkURL = (url: string) => {
  return {
    type: SET_CASE_DEEPLINK_URL,
    value: url
  }
}

export const clearUIState = (payload: any) => {
  return {
    type: CLEAR_UI_STATE,
    value: payload
  }
}

export const setReadOnlyMode = (flag: boolean) => {
  return {
    type: READ_ONLY_MODE,
    value: flag
  }
}

export const setChangeCustomer = (flag: boolean) => {
  return {
    type: SET_CHANGE_CUSTOMER,
    value: flag
  }
}

export const setSearchCustomer = (flag: boolean) => {
  return {
    type: SET_SEARCH_CUSTOMER,
    value: flag
  }
}

