declare global {
    interface Window {
        msCrypto: any
        crypto: any
    }
}

// seconds To Day hours minutes and secnods convertion
export const timeParser = (seconds: number) => {
    if (!seconds) {
        return '';
    }
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + 'd ' : "";
    const hDisplay = h > 0 ? h + 'h ' : "";
    const mDisplay = m > 0 ? m + 'm ' : "";
    const sDisplay = s > 0 ? s + 's' : "";

    if (dDisplay) {
        return dDisplay + hDisplay;
    }
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const generateUniqueId = () => {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return array[0];
}   