import React from 'react'
import styles from '../../Theme/Styles/styles.module.css'

interface IChevronProps {
  className: string
  fill: string
  height?: number
  width: number
}

function Chevron(props: IChevronProps) {
  //split string by space
  const cssClassList: any = props.className.split(' ')
  let cssClass = styles[cssClassList[0]] + '  '
  if (cssClassList.length > 0) {
    cssClass = cssClass + styles[cssClassList[1]]
  }

  return (
    <svg
      viewBox='0 0 36 36'
      width={props.width}
      className={cssClass}
      focusable='false'
      aria-hidden='true'
      role='img'
      fill={props.fill}
    >
      <path d='M24 18a1.988 1.988 0 0 1-.585 1.409l-7.983 7.98a2 2 0 1 1-2.871-2.772l.049-.049L19.181 18l-6.572-6.57a2 2 0 0 1 2.773-2.87l.049.049 7.983 7.98A1.988 1.988 0 0 1 24 18z'></path>
    </svg>
  )
}

export default Chevron
