import searchCustomer from '../../api/searchCustomer';
import validateCustomer from '../../api/validateCustomer';
import loadCustomer from '../../api/loadCustomer';

type searchCustomerPayload = {
  conversationId: string;
  email: string | '';
  phone: string | '';
  caseId: string | '';
};

type validateCustomerPayload = {
  conversationId: string;
  rengaId: string;
};

type setActiveCustomerType = {
  rengaId: string;
  contactGuid: string;
  firstName: string;
  lastName: string;
  email: string;
  fraudStatus: string | '';
  phone?: string;
};

type loadCustomerPayload = {
  conversationId: string;
  linkedCustomer: {
    rengaId: string;
    contactGuid: string;
    lastName: string;
    firstName: string;
    email: string;
    fraudStatus: string;
  };
};

export const searchCustomerAPI = (payload: searchCustomerPayload) => {
  console.log('Triggered searchCustomerAPI with Payload', payload);
  return (dispatch: any) => {
    searchCustomer(payload.conversationId, payload.email, payload.phone, payload.caseId).then(data => {
      dispatch({ type: 'SEARCH_CUSTOMER_RESPONSE', payload: data });
    });
  };
};

export const validateCustomerAPI = (payload: validateCustomerPayload) => {
  console.log('Triggered validateCustomerAPI with Payload', payload);
  return (dispatch: any) => {
    validateCustomer(payload.conversationId, payload.rengaId).then(data => {
      dispatch({ type: 'VALIDATE_CUSTOMER_RESPONSE', payload: data });
    });
  };
};

export const setActiveCustomer = (payload: setActiveCustomerType) => {
  console.log('Triggered setActiveCustomer with Payload', payload);
  return { type: 'SET_ACTIVE_CUSTOMER', payload };
};

export const setCustomerPhoneNumber = (payload: string) => {
  console.log('Triggered setCustomerPhoneNumber with Payload', payload);
  return { type: 'SET_CUSTOMER_PHONE_NUMBER', payload };
};

export const setSearchParam = (payload: any) => {
  console.log('Triggered setSearchParam with Payload', payload);
  return { type: 'SET_SEARCH_PARAM', payload };
};

export const clearCustomerData = (payload: any) => {
  console.log('Triggered clearCustomerData with Payload', payload);
  return { type: 'CLEAR_CUSTOMER_DATA', payload };
};

export const loadCustomerAPI = (payload: loadCustomerPayload) => {
  console.log('Triggered loadCustomerAPI with Payload', payload);
  return (dispatch: any) => {
    loadCustomer(payload).then(data => {
      dispatch({ type: 'LOAD_CUSTOMER_RESPONSE', payload: data });
    });
  };
};
