import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './Reducers/rootReducer';

const middleWare = [thunk];

export type RootStore = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, compose(composeWithDevTools(applyMiddleware(...middleWare))));
export default store;
