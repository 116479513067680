export const DART_THEME = 'DART_THEME';
export const LANGUAGE = 'LANGUAGE';
export const UPDATE_CIP_DATA = 'UPDATE_CIP_DATA';
export const UPDATE_SCIP_DATA = 'UPDATE_SCIP_DATA';
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';
export const SEARCH_CUSTOMER_LOADING = 'SEARCH_CUSTOMER_LOADING';
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
export const UPDATE_IFRAME_HEIGHT = 'UPDATE_IFRAME_HEIGHT';
export const SHOW_SEARCH_CUSTOMER_TABLE = 'SHOW_SEARCH_CUSTOMER_TABLE';
export const CURRENT_CONTACT_ID = 'CURRENT_CONTACT_ID';
export const FRAUD_ALERT_STATUS = 'FRAUD_ALERT_STATUS';
export const USER_PERSISTED = 'USER_PERSISTED';
export const LOADER = 'LOADER';
export const CUSTOMER_OFFER = 'CUSTOMER_OFFER';
export const ORDER_QUOTE = 'ORDER_QUOTE';
export const SET_CIS_LOADER = 'SET_CIS_LOADER';
export const CONVERSATION_STATUS = 'CONVERSATION_STATUS';
export const JCAUTHDATA = 'JCAUTHDATA';
export const PRESISTCHATINFO = 'PRESISTCHATINFO';
export const CIS_ALLOWED_AGENT = 'CIS_ALLOWED_AGENT';
export const PHONE_VALIDATION = 'PHONE_VALIDATION'
export const CART_DETAILS = 'CART_DETAILS'
