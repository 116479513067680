import React, { useEffect, useState } from 'react'
import styles from '../Theme/Styles/styles.css'
import {
  Provider,
  Grid,
  View,
  Text,
  Divider,
  Content,
  defaultTheme,
  darkTheme,
  Flex,
  ToggleButton,
  TooltipTrigger,
  Tooltip,
} from '@adobe/react-spectrum'
import { cloneDeep } from "lodash";
import ACTable from './ACTable/ACTable'
import ACFilter from './ACFilter/ACFilter'
import { getAllConvHistory, getConversationId } from '../api'
import moment from 'moment';
import { AllConversationsIcon, ConversationViewIcon, ListViewIcon, FirstPageIcon, PrevPageIcon, NextPageIcon, LastPageIcon } from '../Theme/Icons';
import axios from "axios";
import { generateUniqueId } from '../util';
import localization from './lang/localization';

type propsData = {
  contextData: any
  callBackEvent: (eventType: string, eventData: object) => void
}

let source: any = null;
let mounted = false;

const App = (props: propsData) => {
  try {
    const [isApiCall, setApiCall] = useState(false);
    const { agentId, apiEndPoint, token, apiKey, acData } = props.contextData;
    const { paginate, dateTime, filterBy, listWithPaginate, isChangeList, sortDescriptor, isInit, allConvData } = acData;
    const lang = props.contextData.lang || 'en';

    useEffect(() => {
      mounted = true;
      if (agentId && !isApiCall && !acData.isInit) {
        setApiCall(true);
        getAllConversationData({ agentId })
      }
      document.body.classList.add('all-conversation-page');
      return (() => {
        document.body.classList.remove('all-conversation-page');
        source && source.cancel();
        mounted = false;
      })
    }, [agentId]);

    useEffect(() => {
      if (agentId && !isApiCall && acData.isInit && !acData.isDateChange) {
        setApiCall(true);
        getAllConversationData({ agentId })
      };
    }, [acData?.dateTime?.endDate]);

    useEffect(() => {
      if (acData?.allConvData?.length > 0) {
        const { paginate } = acData;
        paginate.totalItems = acData.allConvData.length;
        paginate.totalPages = Math.ceil(acData.allConvData.length / paginate.limit);
        paginate.lastPage = Math.ceil(acData.allConvData.length / paginate.limit);
        acData.paginate = paginate;
        props.callBackEvent('upsert', acData);
      }
      handleList(0);
    }, [acData?.allConvData]);

    useEffect(() => {
      handleList(acData?.paginate?.offset);
    }, [acData?.paginate?.offset]);

    const getAllConversationData = async (reqData: any) => {
      try {
        const { dateTime, filterBy } = acData;
        reqData = cloneDeep(reqData);
        source = axios.CancelToken.source();
        reqData.apiEndPoint = apiEndPoint;
        reqData.headers = {
          'Authorization': token,
          'x-api-key': apiKey
        }
        reqData.startDate = moment(dateTime.startDate, 'YYYY-MM-DD hh:mm a').toISOString();
        reqData.endDate = moment(dateTime.endDate, 'YYYY-MM-DD hh:mm a').toISOString();
        reqData.source = source;
        reqData.searchKeyword = '';
        if (filterBy?.isFilter && filterBy?.searchTranscripts) {
          acData.isAppliedFilter = true;
          reqData.searchKeyword = filterBy.searchTranscripts;
        }
        const res: any = await getAllConvHistory(reqData);
        if (mounted) {
          if (res?.data?.data?.Items) {
            let respList: any = (res.data.data.Items || []);
            if((filterBy.csat && filterBy.csat.length > 0)||(filterBy.channel && filterBy.channel.length > 0)||(filterBy.status && filterBy.status.length > 0))
            {
              acData.isAppliedFilter = true;
            }
            if (filterBy.csat && filterBy.csat.length > 0) {
              respList = respList.filter((item: any) => {
                return item.csat && filterBy.csat.indexOf(item.csat.toString()) > -1;
              });
            }
            if (filterBy.channel && filterBy.channel.length > 0) {
              respList = respList.filter((item: any) => {
                return item.channel && filterBy.channel.indexOf(item.channel.toString()) > -1;
              });
            }
            if (filterBy.status && filterBy.status.length > 0) {
              respList = respList.filter((item: any) => {
                const status = item.duration === undefined ? localization[lang].ALL_CONVERSATIONS.NOT_CONNECTED : localization[lang].ALL_CONVERSATIONS.CONNECTED;
                return status === filterBy.status
              });
            }
            respList = respList.filter((item: any) => {
              return item.disconnectReason;
            });
            respList = respList.map((i: any) => {
              if (i.customerFirstName || i.customerLastName) {
                i.customerFullName = `${i.customerFirstName || ''} ${i.customerLastName || ''}`
              } else {
                i.customerFullName = 'Visitor'
              }
              return i
            });
            acData.allConvData = respList;
            acData.isInit = true;
            acData.isChangeList = generateUniqueId();
            acData.isNewApiCall = acData.isAppliedFilter === true;
            props.callBackEvent('upsert', acData);
            setApiCall(false);
          } else {
            failApiHandleData(false);
          }
        }
      } catch (e) {
        failApiHandleData(false);
        props.callBackEvent('EXCEPTION', {
          module: 'oc-all-conversation-module-client',
          message: e
        })
      }
    }

    const failApiHandleData = (isConvIdData: boolean) => {
      acData.allConvData = [];
      if (isConvIdData) {
        acData.listWithPaginate = [];
      }
      acData.isChangeList = generateUniqueId();
      props.callBackEvent('upsert', acData);
      setApiCall(false);
    }

    const getConversationIdData = async (conversationId: string) => {
      try {
        source = axios.CancelToken.source();
        const headers = {
          'Authorization': token,
          'x-api-key': apiKey
        }
        if (!conversationId) {
          return;
        }
        setApiCall(true);
        const res: any = await getConversationId({ apiEndPoint, conversationId, source, headers });
        if (mounted) {
          if (res?.data?.data?.Items) {
            let resResult = res.data.data.Items || [];
            resResult = resResult.filter((item: any) => {
              return item.disconnectReason;
            });
            resResult = resResult.map((i: any) => {
              if (i.customerFirstName || i.customerLastName) {
                i.customerFullName = `${i.customerFirstName || ''} ${i.customerLastName || ''}`
              } else {
                i.customerFullName = 'Visitor'
              }
              return i
            });
            acData.allConvData = resResult;
            acData.paginate.offset = 0;
            acData.listWithPaginate = [];
            acData.isChangeList = generateUniqueId();
            acData.isAppliedFilter = true;
            acData.isNewApiCall = true;
            props.callBackEvent('upsert', acData);
            setApiCall(false);
          } else {
            failApiHandleData(true);
          }
        }
      } catch (e) {
        failApiHandleData(true);
        props.callBackEvent('EXCEPTION', {
          module: 'oc-all-conversation-module-client',
          message: e
        })
      }
    }

    const handleCallbackEvent = (eventType: string, eventData: any) => {
      const { paginate, allConvData, filterBy } = acData;
      if (eventType === 'sortDescriptor') {
        acData.sortDescriptor = eventData;
        props.callBackEvent('upsert', acData);
      } else if (eventType === 'PAGINATE_FIRST_PAGE') {
        paginate.offset = 0;
        paginate.active = eventType;
        acData.paginate = paginate;
        props.callBackEvent('upsert', acData);
      } else if (eventType === 'PAGINATE_PREV_PAGE') {
        paginate.offset = paginate.offset - 1;
        paginate.active = eventType;
        acData.paginate = paginate;
        props.callBackEvent('upsert', acData);
      } else if (eventType === 'PAGINATE_NEXT_PAGE') {
        paginate.offset = paginate.offset + 1;
        paginate.active = eventType;
        acData.paginate = paginate;
        props.callBackEvent('upsert', acData);
      } else if (eventType === 'PAGINATE_LAST_PAGE') {
        paginate.offset = paginate.totalPages - 1;
        paginate.active = eventType;
        acData.paginate = paginate;
        props.callBackEvent('upsert', acData);
      } else if (eventType === 'listView' || eventType === 'conversationView') {
        props.callBackEvent(eventType, { allConvData, currentAllConv: eventData });
      } else if (eventType === 'upsertFilterBy') {
        filterBy[eventData.key] = eventData.val;
        acData.filterBy = filterBy;
        props.callBackEvent('upsert', acData);
      } else if (eventType === 'clearFilterBy') {
        filterBy.csat = [];
        filterBy.isSearchByConversationId = true;
        filterBy.searchConversationId = '';
        filterBy.searchTranscripts = '';
        filterBy.status = '';
        filterBy.channel = ''
        acData.filterBy = filterBy;
        if (acData.isDateChange) {
          const defaultStartDate = new Date();
          defaultStartDate.setDate(defaultStartDate.getDate() - 6);
          acData.dateTime.startDate = defaultStartDate;
          acData.dateTime.endDate = new Date();
          props.callBackEvent('upsert', acData);
          getAllConversationData({ agentId });
        } else {
          props.callBackEvent('upsert', acData);
        }
      } else if (eventType === 'FilterByDate') {
        if (eventData?.callapi && !isApiCall) {
          setApiCall(true);
          acData.allConvData = [];
          acData.listWithPaginate = [];
          acData.isDateChange = true;
          acData.isAppliedFilter = true;
          acData.paginate = {
            offset: 0,
            limit: 20,
            totalItems: 0,
            firstPage: 0,
            totalPages: 0,
            lastPage: 0,
            active: ''
          };
          props.callBackEvent('upsert', acData);
          getAllConversationData({ agentId });
        } else if (!eventData.callapi) {
          acData.dateTime.startDate = eventData.start;
          acData.dateTime.endDate = eventData.end;
          acData.isDateChange = true;
          props.callBackEvent('upsert', acData);
        }
      } else if (eventType === 'FilterBy') {
        if (eventData?.reset && !isApiCall) {
          setApiCall(true);
          acData.filterBy = {
            csat: [],
            isSearchByConversationId: true,
            searchConversationId: '',
            searchTranscripts: '',
            isFilter: false,
            status: '',
            channel: ''
          };
          acData.allConvData = [];
          acData.listWithPaginate = [];
          acData.isChangeList = generateUniqueId();
          acData.paginate = {
            offset: 0,
            limit: 20,
            totalItems: 0,
            firstPage: 0,
            totalPages: 0,
            lastPage: 0,
            active: ''
          };
          if (acData.isDateChange) {
            const defaultStartDate = new Date();
            defaultStartDate.setDate(defaultStartDate.getDate() - 6);
            acData.dateTime.startDate = defaultStartDate;
            acData.dateTime.endDate = new Date();
          }
          props.callBackEvent('upsert', acData);
          getAllConversationData({ agentId });
        } else {
          if (eventData.isSearchByConversationId && !isApiCall) {
            getConversationIdData(eventData.searchConversationId.trim());
          } else if (!eventData.isSearchByConversationId) {
            setApiCall(true);
            acData.allConvData = [];
            acData.listWithPaginate = [];
            acData.isChangeList = generateUniqueId();
            acData.paginate = {
              offset: 0,
              limit: 20,
              totalItems: 0,
              firstPage: 0,
              totalPages: 0,
              lastPage: 0,
              active: ''
            };
            props.callBackEvent('upsert', acData);
            getAllConversationData({ agentId });
          }
        }
      }
      else if (eventType === 'phone-in-transcript') {
        props.callBackEvent('phone-in-transcript', eventData);
      }
    }

    const handleList = (offset: number) => {
      if (acData?.allConvData?.length) {
        const { allConvData, paginate } = acData;
        const list: any = cloneDeep(allConvData);
        acData.listWithPaginate = list.slice(offset * paginate.limit, paginate.limit + offset * paginate.limit);
        acData.isChangeList = generateUniqueId();
        props.callBackEvent('upsert', acData);
      }
    }

    const start = paginate.totalItems > 0 ? (paginate.offset * paginate.limit) + 1 : 0;
    let end = (paginate.limit + paginate.offset * paginate.limit);
    if (end > paginate.totalItems) {
      end = paginate.totalItems;
    }
    return (
      <Provider
        theme={props.contextData.darkMode ? darkTheme : defaultTheme}
        colorScheme='light'
        UNSAFE_className={`
          ${styles['ac-container']} 
          ${props.contextData.darkMode && styles['ac-container-dark']} 
          ${!acData.isListView && styles['ac-container-con-view']} 
        `}>
        <Content height={80} minHeight={80}>
          <div className={styles['ac-header-container']}>
            <Flex direction="row" UNSAFE_className={styles['ac-header-container-sec']}>
              <View UNSAFE_className={`${styles['nav-header']} ${styles['nav-header-left']}`}>
                <li className={styles['nav-icon']}>
                  <div className={styles['header-icon']}>
                    <AllConversationsIcon />
                  </div>
                </li>
                <li className={styles['nav-title']}>
                  <Text>{localization[lang].ALL_CONVERSATIONS.PREVIOUS_CONVERSATIONS}</Text>
                </li>
              </View>
              <View UNSAFE_className={styles['nav-header-right']}>
                {
                  acData.isListView &&
                  <div className={styles['pagination-container']}>
                    <TooltipTrigger>
                      <ToggleButton
                        isDisabled={paginate.offset <= 0 || paginate.totalItems === 0 || paginate.totalPages <= 1 || !acData.isListView || isApiCall}
                        UNSAFE_className={styles['round-button']}
                        onPress={() => handleCallbackEvent('PAGINATE_FIRST_PAGE', {})}
                        isSelected={paginate.active === 'PAGINATE_FIRST_PAGE'}
                      >
                        <FirstPageIcon />
                      </ToggleButton>
                      <Tooltip UNSAFE_className={styles['ac-tooltip']}>{localization[lang].ALL_CONVERSATIONS.FIRST_PAGE}</Tooltip>
                    </TooltipTrigger>
                    <TooltipTrigger>
                      <ToggleButton
                        isDisabled={paginate.totalPages <= 1 || paginate.offset <= 0 || !acData.isListView || isApiCall}
                        UNSAFE_className={styles['round-button']}
                        onPress={() => handleCallbackEvent('PAGINATE_PREV_PAGE', {})}
                        isSelected={paginate.active === 'PAGINATE_PREV_PAGE'}
                      >
                        <PrevPageIcon />
                      </ToggleButton>
                      <Tooltip UNSAFE_className={styles['ac-tooltip']}>{localization[lang].ALL_CONVERSATIONS.PREVIOUS_PAGE}</Tooltip>
                    </TooltipTrigger>
                    <Text UNSAFE_className={styles['pagination-text']}>
                      {start}-{end} {localization[lang].ALL_CONVERSATIONS.OF} {paginate.totalItems} {localization[lang].ALL_CONVERSATIONS.CONVERSATIONS}
                    </Text>
                    <TooltipTrigger>
                      <ToggleButton
                        isDisabled={paginate.totalPages <= 0 || paginate.offset === (paginate.lastPage - 1) || !acData.isListView || isApiCall}
                        UNSAFE_className={styles['round-button']}
                        onPress={() => handleCallbackEvent('PAGINATE_NEXT_PAGE', {})}
                        isSelected={paginate.active === 'PAGINATE_NEXT_PAGE'}
                      >
                        <NextPageIcon />
                      </ToggleButton>
                      <Tooltip UNSAFE_className={styles['ac-tooltip']}>{localization[lang].ALL_CONVERSATIONS.NEXT_PAGE}</Tooltip>
                    </TooltipTrigger>
                    <TooltipTrigger>
                      <ToggleButton
                        isDisabled={paginate.totalPages <= 0 || paginate.offset === (paginate.lastPage - 1) || !acData.isListView || isApiCall}
                        UNSAFE_className={styles['round-button']}
                        onPress={() => handleCallbackEvent('PAGINATE_LAST_PAGE', {})}
                        isSelected={paginate.active === 'PAGINATE_LAST_PAGE'}
                      >
                        <LastPageIcon />
                      </ToggleButton>
                      <Tooltip UNSAFE_className={styles['ac-tooltip']}>{localization[lang].ALL_CONVERSATIONS.LAST_PAGE}</Tooltip>
                    </TooltipTrigger>
                  </div>
                }
                <Flex UNSAFE_className={styles['all-con-view-sec']} direction='row'>
                  <div className={styles['conversation-view']}>
                    <TooltipTrigger>
                      <ToggleButton
                        UNSAFE_className={styles['all-con-conversationview-btn']}
                        isEmphasized
                        onPress={() => handleCallbackEvent('conversationView', null)}
                        isSelected={!acData.isListView}
                        isDisabled={isApiCall}>
                        <ConversationViewIcon />
                      </ToggleButton>
                      <Tooltip UNSAFE_className={styles['ac-tooltip']}>{localization[lang].ALL_CONVERSATIONS.WIDGET_VIEW}</Tooltip>
                    </TooltipTrigger>
                  </div>
                  <div className={styles['list-vew']}>
                    <TooltipTrigger>
                      <ToggleButton
                        UNSAFE_className={styles['all-con-listview-btn']}
                        isEmphasized
                        onPress={() => handleCallbackEvent('listView', null)}
                        isSelected={acData.isListView}
                        isDisabled={isApiCall}>
                        <ListViewIcon />
                      </ToggleButton>
                      <Tooltip UNSAFE_className={styles['ac-tooltip']}>{localization[lang].ALL_CONVERSATIONS.LIST_VIEW}</Tooltip>
                    </TooltipTrigger>
                  </div>
                </Flex>
              </View>
            </Flex>
            <Divider size='M' />
          </div>
        </Content>
        {!acData.isListView && <Divider size='M' />}
        {
          acData.isListView &&
          <Grid
            areas={['header', 'content']}
            rows={['auto']}
            gap='7px'
            flex='true'
            flexGrow={1}
            alignItems='stretch'
            marginTop={20}
            marginBottom={20}
            UNSAFE_className={styles['ac-main-grid-container']}
          >
            <View gridArea='header'>
              <ACFilter
                gridData={listWithPaginate}
                dateFilter={{ ...dateTime }}
                callBackEvent={handleCallbackEvent}
                paginate={paginate}
                darkTheme={props.contextData.darkMode}
                isApiCall={isApiCall}
                filterBy={filterBy}
                lang={lang}
                isInit={isInit}
              />
            </View>
            <View gridArea='content'>
              <ACTable
                allConvData={allConvData}
                gridData={listWithPaginate}
                isChangeList={isChangeList}
                sortDescriptor={sortDescriptor}
                callBackEvent={handleCallbackEvent}
                paginate={paginate}
                darkTheme={props.contextData.darkMode}
                isApiCall={isApiCall}
                lang={lang}
              />
            </View>
          </Grid>
        }
      </Provider>
    )
  } catch (error) {
    props.callBackEvent('EXCEPTION', {
      module: 'AllConversation_Module',
      message: error.message
    })
    return <h3>Failed to load module</h3>
  }
}

export default App
