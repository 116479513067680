import {
  Button,
  Flex,
  Item,
  Picker,
  ProgressCircle,
  TextField
} from '@adobe/react-spectrum'
import React from 'react'
import { connect } from 'react-redux'
import { createCaseApi } from '../api/casesApi'
import localization from '../language/localization'
import {
  setActiveCase,
  setCaseCreatedFlag,
  setCaseList,
  setCreateCaseParams
} from '../store/actions/caseAction'
import { toggleNotification } from '../store/actions/uiActions'
import { DarkBackIcon, LightBackIcon } from '../Theme/Icons/icons'
import darkTheme from '../Theme/Styles/main/dark.css'
import lightTheme from '../Theme/Styles/main/light.css'
import { getSessionStorage, setSessionStorage } from '../utils/sessionStorage'
import {
  getCodeForTimeZone,
  getItemCode,
  getLocaleAttribute,
  getMappedCaseTypeFromIssueType
} from '../utils/utils'

interface IState {
  caseTitle: string
  caseCategory: any
  channelCode: number
  priorityCode: number
  impactCode: number
  caseCreatedFlag: boolean
  createCaseResponse: string
  status: string
}

interface ICreateCase {
  title: string
  channelCode: number
  priorityCode: number
  impactCode: number
  typeCode: number
  timezone: number
  language: string
  productCode: string
  email: string
}

interface IProps {
  darkMode: boolean
  language: any
  setCaseListDispatch: (data: any) => void
  handleViewChange: (flag: boolean) => void
  setActiveCaseDispatch: (payload: any) => void
  toggleNotification: (flag: boolean, message: string) => void
  setCaseCreatedFlagDispatch: (flag: boolean) => void
  setCreateCaseParamsDispatch: (data: any) => void
  ocData: any
  contextData: any
  caseDeeplinkURL: string
  createCaseParams: any
}

class CreateCase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      caseTitle: '',
      caseCategory: '',
      // Channel Code - CHAT
      channelCode: 4,
      priorityCode: 4,
      // Impact Code- SMALL
      impactCode: 3,
      caseCreatedFlag: false,
      createCaseResponse: '',
      status: 'Active'
    }
  }

  componentDidMount() {
    setSessionStorage(
      this.props.ocData.conversationId,
      'currentPage',
      'createCase'
    )
    const data = getSessionStorage(this.props.ocData.conversationId)
    const casetitle = data.caseTitle
      ? data.caseTitle
      : this.props.createCaseParams.caseTitle
    const casecategory = data.caseCategory
      ? data.caseCategory
      : this.props.createCaseParams.caseCategory
    this.setState({ caseTitle: casetitle, caseCategory: casecategory })
    this.props.setCreateCaseParamsDispatch({
      ...this.props.createCaseParams,
      caseTitle: casetitle,
      caseCategory: casecategory
    })
  }

  /**
   * @logic MILO API requirements
   * Title of the Case
   * ChannelCode: 4 for CHAT & 1 for Phone
   * PriorityCode: 4 for low
   * Impact Code: 3 for small
   * TypeCode for Product: 2, Admin: 1
   * Timezone defaults to en_US
   * @returns title, channelCode, priorityCode, typeCode, Timezone, language, productCode, email
   * 
   */
  prepareCaseData(): ICreateCase {
    const {
      enterpriseContextData: {
        issueType = '',
        timezone = '',
        language = 'en',
        region = 'US',
        customerEmail = '',
        originForCurrentConversation = '',
        productCode: productCodeFromJC = ''
      } = {}
    } = this.props.contextData
    const { caseTitle, priorityCode, impactCode } = this.state
    let { channelCode } = this.state
    if (originForCurrentConversation === 'inbound-phone' || originForCurrentConversation === 'outbound-phone') {
      channelCode = 1;
    }
    const typeCode =
      getMappedCaseTypeFromIssueType(issueType) === 'PRODUCT' ? 2 : 1
    const locale = getLocaleAttribute(language, region)
    const timezoneCode = getCodeForTimeZone(timezone)
    const productCode = getItemCode(issueType, productCodeFromJC)
    return {
      title: caseTitle,
      channelCode,
      priorityCode,
      impactCode,
      typeCode,
      timezone: timezoneCode,
      language: locale,
      productCode,
      email: customerEmail
    }
  }

  createCase = async (localizeString: any) => {
    this.setState({ caseCreatedFlag: true })
    const createCaseData: ICreateCase = this.prepareCaseData()
    const { orgId, rengaId, conversationId, agentName } = this.props.ocData
    const DYNAMICS_CASE_DEEPLINK_URL = this.props.caseDeeplinkURL
    if (orgId && rengaId) {
      try {
        const createCaseResponse = await createCaseApi(
          conversationId,
          rengaId,
          orgId,
          createCaseData,
          agentName
        )
        setSessionStorage(conversationId, 'currentPage', 'caseList')
        if (
          createCaseResponse &&
          createCaseResponse.data &&
          createCaseResponse.data.json
        ) {
          const { guid } = createCaseResponse.data.json
          const newCaseId = createCaseResponse.data.json.caseId
            ? createCaseResponse.data.json.caseId
            : ' '
          this.setState({ caseCreatedFlag: false })
          this.setState({ createCaseResponse: newCaseId })
          this.props.setActiveCaseDispatch(createCaseResponse.data.json)
          this.props.toggleNotification(
            true,
            localizeString.case_linked_notification
          )
          this.props.setCaseCreatedFlagDispatch(true)
          window.open(`${DYNAMICS_CASE_DEEPLINK_URL}${guid}`, '_blank')
          setTimeout(() => {
            this.props.toggleNotification(false, '')
          }, 5000)
          this.props.handleViewChange(true)
        }
      } catch (err) {
        console.error('ecm: create case error', err)
        this.setState({ caseCreatedFlag: false })
      }
    }
  }

  getCaseTypeLabel(localizeString: any) {
    const {
      enterpriseContextData: { issueType = '' } = {}
    } = this.props.contextData
    const caseType = getMappedCaseTypeFromIssueType(issueType)
    switch (caseType.toLowerCase()) {
      case 'admin':
        return localizeString.administration
      case 'product':
        return localizeString.product
      default:
        return false
    }
  }

  handleCaseTitleChange = (e: any) => {
    this.setState({ caseTitle: e })
    setSessionStorage(this.props.ocData.conversationId, 'caseTitle', e)
    this.props.setCreateCaseParamsDispatch({
      ...this.props.createCaseParams,
      caseTitle: e
    })
  }

  handleCaseCategoryChange = (e: any) => {
    this.setState({ caseCategory: e })
    setSessionStorage(this.props.ocData.conversationId, 'caseCategory', e)
    this.props.setCreateCaseParamsDispatch({
      ...this.props.createCaseParams,
      caseCategory: e
    })
  }

  render() {
    const { darkMode, language } = this.props
    const localizeString = localization[language]
    const styles = darkMode ? darkTheme : lightTheme
    const customerName = this.props.ocData.customerName
    return (
      <React.Fragment>
        <div className={styles['create-case-top-bar']}>
          {this.props.darkMode ? <DarkBackIcon /> : <LightBackIcon />}
          <div
            className={styles['back-title']}
            onClick={() => {
              this.props.handleViewChange(true)
            }}
          >
            {localizeString.back}
          </div>
        </div>
        <div className={styles['case-details']}>
          <div className={styles['create-case-customer-name']}>
            <div>{customerName}</div>
            <div className={styles['create-case-info']}>
              {localizeString.case_information}
            </div>
          </div>
          <div className={styles['create-case-title']}>
            <TextField
              UNSAFE_style={{ width: '98%' }}
              placeholder={localizeString.type_here}
              label={localizeString.case_title_create_case}
              onChange={this.handleCaseTitleChange}
              value={this.state.caseTitle}
            />
          </div>
          <div className={styles['create-case-category-type']}>
            <Picker
              UNSAFE_className={styles['field-label']}
              UNSAFE_style={{ width: '50%' }}
              label={localizeString.case_category}
              onSelectionChange={this.handleCaseCategoryChange}
              isDisabled={this.state.caseTitle === ''}
              placeholder={localizeString.select}
              selectedKey={this.state.caseCategory}
            >
              <Item key='Incident'>Incident</Item>
            </Picker>
            <Flex direction='column' gap='size-100' marginTop={20}>
              <div className={styles['case-type-label']}>
                {localizeString.case_type}
              </div>
              <div className={styles['create-case-type']}>
                {this.getCaseTypeLabel(localizeString)}
              </div>
            </Flex>
          </div>
          <div className={styles['wrapper-back-create-button']}>
            <Button
              UNSAFE_className={styles['create-button']}
              isDisabled={
                this.state.caseTitle === '' ||
                this.state.caseCategory === '' ||
                this.state.caseCreatedFlag
              }
              type='button'
              variant='primary'
              onPress={() => this.createCase(localizeString)}
              data-testid='create-case-button'
            >
              {localizeString.create_case_button}
            </Button>
          </div>
          {this.state.caseCreatedFlag === true ? (
            <div className={styles['create-case-loader-wrapper']}>
              <ProgressCircle
                UNSAFE_className={styles['create-case-loader']}
                aria-label='Creating Case...'
                isIndeterminate
              />
              <div className={styles['creating-case-label']}>
                {localizeString.creating_case_loader_msg}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={styles['create-case-footer']} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  language: state.uiState.language,
  darkMode: state.uiState.darkMode,
  ocData: state.ocState.activeConversationData,
  activeCase: state.caseState.activeCase,
  contextData: state.ocState.contextData,
  caseDeeplinkURL: state.uiState.caseDeeplinkURL,
  createCaseParams: state.caseState.createCaseParams
})

const mapDispatchToProps = (dispatch: any) => ({
  setCaseListDispatch: function (data: any) {
    dispatch(setCaseList(data))
  },
  setActiveCaseDispatch: function (payload: any) {
    dispatch(setActiveCase(payload))
  },
  setCaseCreatedFlagDispatch: function (flag: boolean) {
    dispatch(setCaseCreatedFlag(flag))
  },
  toggleNotification: function (flag: boolean, message: string) {
    dispatch(toggleNotification(flag, message))
  },
  setCreateCaseParamsDispatch: function (data: any) {
    dispatch(setCreateCaseParams(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCase)
