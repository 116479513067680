import React, { useState, useEffect } from 'react'
import styles from './ACTable.css'
import {
  TableView,
  TableHeader,
  TableBody,
  Row,
  Cell,
  Column
} from '@react-spectrum/table';
import { Flex, ProgressCircle, Link, useAsyncList } from '@adobe/react-spectrum'
import { CallIcon, ChatIcon } from '../../Theme/Icons';
import moment from 'moment';
import { generateUniqueId, timeParser } from '../../util';
import { useCollator } from '@react-aria/i18n';
import localization from '../lang/localization';

type propsData = {
  allConvData: [] | any
  gridData: [] | any
  callBackEvent: (eventType: string, eventData: object) => void
  paginate: any
  darkTheme: boolean
  isApiCall: boolean
  lang: any
  isChangeList: number
  sortDescriptor: any
}

const ACTable = (props: propsData) => {
  const [renderKey, setRenderKey] = useState<number>(0);
  const collator = useCollator({ numeric: true });
  const list = useAsyncList({
    async load() {
      return {
        items: getGridData()
      };
    },
    async sort({ sortDescriptor }: any) {
      props.callBackEvent('sortDescriptor', sortDescriptor);
      return { items: getGridData(sortDescriptor) };
    },
  });

  useEffect(() => {
    if (props?.sortDescriptor?.column) {
      list.sortDescriptor = props.sortDescriptor;
      list.sort(props.sortDescriptor);
    }
    list.reload();
    setRenderKey(generateUniqueId());
  }, [props?.isChangeList, props?.lang]);

  const getGridData = (sortDescriptor?: any) => {
    const { offset, limit } = props.paginate;
    sortDescriptor = sortDescriptor || props.sortDescriptor;
    let gridData: any = [];
    props.allConvData.sort((a: any, b: any) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      let cmp = collator.compare(first, second);
      if (sortDescriptor.direction === 'descending') {
        cmp *= -1;
      }
      return cmp;
    });
    gridData = props.allConvData;
    gridData = gridData.slice(offset * limit, limit + offset * limit);
    return gridData;
  }

  const columns = [
    { name: localization[props.lang].ALL_CONVERSATIONS.CHANNEL, uid: 'channel', allowsSorting: false },
    { name: localization[props.lang].ALL_CONVERSATIONS.QUEUE, uid: 'queue', allowsSorting: true },
    { name: localization[props.lang].ALL_CONVERSATIONS.CONSUMER_NAME, uid: 'customerFullName', allowsSorting: true },
    { name: localization[props.lang].ALL_CONVERSATIONS.PHONE_NUMBER, uid: 'phoneNumber', allowsSorting: false },
    { name: localization[props.lang].ALL_CONVERSATIONS.STATUS, uid: 'status', allowsSorting: false },
    { name: localization[props.lang].ALL_CONVERSATIONS.INITIATION_METHOD, uid: 'initiationMethod', allowsSorting: true },
    { name: localization[props.lang].ALL_CONVERSATIONS.DISCONNECT_REASON, uid: 'disconnectReason', allowsSorting: true },
    { name: localization[props.lang].ALL_CONVERSATIONS.START_TIME, uid: 'conversationStartDate', allowsSorting: true },
    { name: localization[props.lang].ALL_CONVERSATIONS.LAST_MESSAGE_TIME, uid: 'updateDate', allowsSorting: true },
    { name: localization[props.lang].ALL_CONVERSATIONS.DURATION, uid: 'duration', allowsSorting: true }
  ]

  const renderDisconnectReason = (item: any) => {
    const disconnectReason: any = {
      "TRANSFER": localization[props.lang].ALL_CONVERSATIONS.TRANSFERRED,
      "AGENT_DISCONNECT": localization[props.lang].ALL_CONVERSATIONS.AGENT,
      "CUSTOMER_DISCONNECT": localization[props.lang].ALL_CONVERSATIONS.CUSTOMER,
      "IDLE": localization[props.lang].ALL_CONVERSATIONS.IDLE,
      "OVERDUE": localization[props.lang].ALL_CONVERSATIONS.OVERDUE,
      "CONTACT_FLOW_DISCONNECT": localization[props.lang].ALL_CONVERSATIONS.SYSTEM,
      "THIRD_PARTY_DISCONNECT": localization[props.lang].ALL_CONVERSATIONS.AGENT,
      "TELECOM_PROBLEM": localization[props.lang].ALL_CONVERSATIONS.NETWORK_DISCONNECT,
      "VoiceTransferClose": localization[props.lang].ALL_CONVERSATIONS.SYSTEM
    };
    return (
        item.disconnectReason && <span className={styles.text}> {disconnectReason[item.disconnectReason] ? disconnectReason[item.disconnectReason] : item.disconnectReason}</span>
    )
  }

  const renderStatus = (item: any) =>{
    return (
      <span>
        {item.duration === undefined || item.duration <= 0 ? localization[props.lang].ALL_CONVERSATIONS.NOT_CONNECTED: localization[props.lang].ALL_CONVERSATIONS.CONNECTED}
      </span>
    )
  }

  const renderChannel = (item: any) => {
    return (
      item.channel === 'CHAT' ? <ChatIcon color={props.darkTheme ? '#b3b3b3' : '#6e6e6e'} className={`${props.darkTheme ? styles['ac-closed-staus-icon-dark'] : styles['ac-closed-staus-icon']}`} />
      : <CallIcon color={props.darkTheme ? '#b3b3b3' : '#6e6e6e'} className={`${props.darkTheme ? styles['ac-closed-staus-icon'] : styles['ac-closed-staus-icon-dark']}`} />
    )
  }

  const renderQueue = (item: any, columnKey: string) => {
    let transferCount = 0;
    try {
      const transferData = JSON.parse(item.contactAttributes) || {};
      transferCount = transferData.transferCount || 0;
    } catch (e) { }
    return (
      <span>
        {item[columnKey]}
        {transferCount > 0 && <span className={styles['ac-transfer-count']}>+{transferCount}</span>}
      </span>
    )
  }

  const getCutomerNameTranslation = (name: string) => {
    if (name?.toLowerCase() === 'visitor') {
      return localization[props.lang].ALL_CONVERSATIONS.VISITOR
    }
    return name;
  }

  const renderInitiationMethod = (item:any) =>{
    const initiationMethod: any = {
      "API": localization[props.lang].ALL_CONVERSATIONS.API,
      "CALLBACK": localization[props.lang].ALL_CONVERSATIONS.CALLBACK,
      "DISCONNECT": localization[props.lang].ALL_CONVERSATIONS.DISCONNECT,
      "EXTERNAL_OUTBOUND": localization[props.lang].ALL_CONVERSATIONS.EXTERNAL_OUTBOUND,
      "INBOUND": localization[props.lang].ALL_CONVERSATIONS.INBOUND,
      "MONITOR": localization[props.lang].ALL_CONVERSATIONS.MONITOR,
      "OUTBOUND": localization[props.lang].ALL_CONVERSATIONS.OUTBOUND,
      "QUEUE_TRANSFER": localization[props.lang].ALL_CONVERSATIONS.QUEUE_TRANSFER,
      "TRANSFER": localization[props.lang].ALL_CONVERSATIONS.TRANSFER
    };
    return (
        item.initiationMethod && <span className={styles.text}> {initiationMethod[item.initiationMethod] ? initiationMethod[item.initiationMethod] : item.initiationMethod}</span>
    )
  }

  const getPhoneNumber = (item: any) => {
    let phone = '';
    phone = item.channel === 'VOICE' ? item.customerEndpoint : ''
    return phone;
  }

  const getCell = (columnKey: any, item: any) => {
    if (columnKey === 'customerFullName') {
      return (<Cell> <div><Link UNSAFE_className={styles.allcon_mod_consumer_name_text} onPress={() => props.callBackEvent('conversationView', item)}>{getCutomerNameTranslation(item[columnKey])}</Link> </div></Cell>)
    }
    if (columnKey === 'response_time') {
      return (<Cell> <div className={styles.allcon_mod_response_time_text}>{item[columnKey]} </div></Cell>)
    }
    if (columnKey === 'queue') {
      return (<Cell> {renderQueue(item, columnKey)}</Cell>)
    }
    if (columnKey === 'duration') {
      return (<Cell> {timeParser(item[columnKey])}</Cell>)
    }
    if (columnKey === 'conversationStartDate' || columnKey === 'updateDate') {
      return (<Cell> {moment(item[columnKey]).format('DD MMM YYYY - hh:mm a') === 'Invalid date' ? '' : moment(item[columnKey]).format('DD MMM YYYY - hh:mm a')}</Cell>)
    }
    if (columnKey === 'disconnectReason') {
      return (<Cell> {renderDisconnectReason(item)}</Cell>)
    }
    if (columnKey === 'status') {
      return (<Cell> {renderStatus(item)}</Cell>)
    }
    if(columnKey === 'channel')
    {
      return (<Cell> {renderChannel(item)}</Cell>)
    }
    if(columnKey === 'phoneNumber')
    {
      return (<Cell> <div><Link UNSAFE_className={styles.allcon_mod_phone_number_text} onPress={() => props.callBackEvent('phone-in-transcript', item.customerEndpoint)}>{getPhoneNumber(item)}</Link> </div></Cell>)
    }
    if(columnKey === 'initiationMethod')
    {
      return (<Cell> {renderInitiationMethod(item)}</Cell>)
    }
    return (<Cell>{item[columnKey]}</Cell>)
  }

  return (
    <Flex width="100%" UNSAFE_className={`${styles['ac-table-container']} ${props.darkTheme && styles['ac-table-container-dark']}`}>
      {
        props.isApiCall &&
        <div className={styles['ac-loader-api']}>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </div>
      }
      <TableView
        key={renderKey}
        aria-label='All conversations table'
        width="100%"
        sortDescriptor={list && list.sortDescriptor}
        onSortChange={list && list.sort}
        renderEmptyState={() => <div>{localization[props.lang].ALL_CONVERSATIONS.NO_DATA}</div>}>
        <TableHeader columns={columns}>
          {(column) => <Column key={column.uid} allowsSorting={column.allowsSorting}>{column.name}</Column>}
        </TableHeader>
        <TableBody items={(list && list.items) ? list.items : []}>
          {(item: any) =>
            <Row key={generateUniqueId()}>
              {(columnKey) => getCell(columnKey, item)}
            </Row>
          }
        </TableBody>
      </TableView>
    </Flex>
  )
}

export default ACTable
