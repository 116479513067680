import getClient from '../utils/client'

/*
  LinkedCustomer: 
  rengaId: string
    orgId: string
    firstName: string
    lastName: string
    orgGuid: string
    c360Guid: string
    customerEmail: string
*/
export async function loadCustomer(
  conversationId: string,
  linkedCustomer: any
) {
  return getClient(true).post(`/api/v1/customer/load`, {
    conversationId: conversationId,
    linkedCustomer
  })
}

export async function validateCustomer(
  conversationId: string,
  rengaId: string,
  orgId: string
) {
  return getClient(true).post(`/api/v1/customer/validate`, {
    conversationId,
    rengaId,
    orgId
  })
}

export async function readConversationDetailsFromDB(conversationId: string) {
  return getClient(true).get(
    `/api/v1/db/fetch?conversationId=${conversationId}`
  )
}

export async function searchCustomer(params: any) {
  return getClient(true).post('/api/v1/customer/search', {
    ...params
  })
}


export async function changeCustomer(
  conversationId: string,
  customerDetails: {
    rengaId: string
    orgId: string
    firstName: string
    lastName: string
    orgGuid: string
    c360Guid: string
    customerEmail: string
    customerPhone: string
  }
) {
  return getClient(true).put(`/api/v1/customer/change`, {
    conversationId: conversationId,
    linkedCustomer: {
      rengaId: customerDetails.rengaId,
      orgId: customerDetails.orgId,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      c360Guid: customerDetails.c360Guid,
      contactGuid: '',
      fraudStatus: '',
      orgGuid: customerDetails.orgGuid,
      email: customerDetails.customerEmail,
      phone: customerDetails.customerPhone
    }
  })
}

