import React from "react";
import { Button, Text, Divider, Content, Dialog, Header, Heading, Footer, ActionButton } from "@adobe/react-spectrum"
import styles from '../Theme/Styles/styles.css'
import { End, Alert } from "../Theme/Icons/DialpadIcons";
import btnStyles from "../Theme/Styles/telephonyBtns.styles.css"
import localization from "../lang/localization";


type propsData = {
    lang: string
    handleClose: (clear: boolean) => void
}

const CallButtonsPopup = (props: propsData) => {
    const { lang, handleClose } = props;
    const endCall = (e: any) => {
        const clear = e.target.id === 'end' || false;
        handleClose(clear)
    }

    return (
        <Dialog width={480} minHeight={229} maxHeight={511} UNSAFE_className={styles['action-menu-popup']}>
            <Heading><ActionButton UNSAFE_className={btnStyles['telephony-end']} right="10px" bottom="5px">
                <End />
            </ActionButton>{localization[lang].TELEPHONY.END_CALL}</Heading>
            <Header><Alert /></Header>
            <Divider />
            <Content>
                <Text>{localization[lang].TELEPHONY.DISCONNECT}</Text>
            </Content>
            <Footer justifySelf="end">
                <Button id="clear" variant="secondary" onPress={endCall}>
                    {localization[lang].ACTION_MODAL.CANCEL}
                </Button>
                <Button id="end" variant="cta" onPress={endCall}>
                    {localization[lang].TELEPHONY.END_CALL}
                </Button>
            </Footer>
        </Dialog>
    )
}

export default CallButtonsPopup;