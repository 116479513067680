import React from 'react';
import { useSelector } from 'react-redux';
import localization from '../language/localization'
import darkTheme from '../Theme/Styles/main/dark.css';
import lightTheme from '../Theme/Styles/main/light.css';
const Zendesk = (props : any) => {
  const {
    darkMode,
    language
  } = useSelector((state: any) => state.uiState);
  const localizeString: any = language
    ? localization[language]
    : localization.en
  const styles = darkMode ? darkTheme : lightTheme;
  return (
   
    <React.Fragment>
      <div className={styles['ecm-zendesk-wrapper']}>
        <div className={styles['ecm-zendesk-title']}>Zendesk Page</div>
        <div className={styles['ecm-zendesk-message']}>
          {localizeString['zendesk_message']}
          </div>
          <a
            className={styles['ecm-zendesk-hyperlink']}
            target='_blank'
            href={props?.url}
            onClick={(e) => e.stopPropagation()}
          >
            {localizeString['zendesk_hypertext']}
          </a>        
      </div>
    </React.Fragment>
  );
};
export default Zendesk;
