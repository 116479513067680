import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Chevron from '../Theme/Icons/Chevron';
import '../Theme/Styles/Accordion.css';
import styles from '../Theme/Styles/styles.module.css';
import SCIPCustomerInfo from './scip/CustomerSearch';
import themes from '../Theme/Themes';
import localization from '../Language/localization';
import CustomerInteractionSummary from './scip/CustomerInteractionSummary';
import { ActionButton, AlertDialog, DialogContainer, Item, Link, Picker, TextField } from '@adobe/react-spectrum';
import Confirm from '../Theme/Icons/Confirm';
import Cancel from '../Theme/Icons/Cancel';
import SupportPlan from '../Theme/Icons/SupportPlan';
import { updateOfferPitch, updateOfferRejectionReason, updateOfferResponseStatus } from '../services/analytics';
import { OFFER_PITCH_MESSAGE, OFFER_REJECTION_REASON, OFFER_REJECTION_REASON_CCT_PRO } from '../Store/constants/valueAddAction';
import { INBOUND_PHONE, OUTBOUND_PHONE, CHAT_TO_PHONE, BOT_TO_PHONE } from '../Store/constants/origin';
import ConversationSummary from './ConversationSummary';
import { CartDetails } from './CartDetails'

declare const window: any;
window.value_add_action = {};

const saasl_analytics = window.value_add_action;
let entitlementList: string[] = ['no'];
const ASPLIST = ['Business', 'Enterprise', 'Elite'];
const phoneOrigin = [INBOUND_PHONE, OUTBOUND_PHONE, BOT_TO_PHONE];

interface Props {
  key: string;
  title: string;
  callBackEvent: (eventType: string, eventData: any) => void;
  setReload: (reload: any) => void;
  feedback?: any;
  agentPath: any;
  cipValues: any;
  isCIP: boolean;
  reload: any;
  chatStatus: any;
  callOrigin: string;
  transferInProgressId: string;
  conversationSummary: any;
  currentConvId: string;
  urls: {
    HENDRIX_DOMAIN: string;
    CM_DOMAIN: string;
    CIP_DOMAIN: string;
    CCP_API_END_POINT: string;
  };
}

function Accordion({ title, cipValues, isCIP, urls, reload, chatStatus, callOrigin, transferInProgressId, callBackEvent, conversationSummary, agentPath, setReload , feedback, currentConvId}: Props) {
  const content: any = useRef('0px');
  const [setActive, setActiveState] = useState('active');
  const [setHeight, setHeightState] = useState(`${content.current.scrollHeight}px`);
  const [setRotate, setRotateState] = useState('accordion-icon rotate');
  const [inputCustomerOfferResponse, setInputCustomerOfferResponse] = useState(false);
  const [inputAgentRelayPitchInMessage, setInputAgentRelayPitchInMessage] = useState(false);
  const [customerRejectedSection, setCustomerRejectedSection] = useState(false);
  const [offerPitch, setOfferPitch] = useState([]);
  const [showInputAcknowledgment, setShowInputAcknowledgment] = useState(false);
  const [displayPitchMessage, setDisplayPitchMessage] = useState(false);
  const [cctProOffer, setCCTProOffer] = useState(false);
  const [rejectReasonOptions, setRejectReasonOptions] = useState([]);
  const [showCustomReasonReject, setShowCustomReasonReject] = useState(false);
  const [customReasonReject, setCustomReasonReject] = useState('');
  const [customerAskedForOfferPopup, setCustomerAskedForOfferPopup] = useState(false);
  const [offerPitchConfirmationPopup, setOfferPitchConfirmationPopup] = useState(false);
  const [offerData, setOfferData] = useState({value: '', deeplink: '', cctProOffer: false, contactOrigin: ''});

  const contextualdata: any = useSelector((state: any) => state.customerInfo);
  const { currentContactId }: any = useSelector((state: any) => state.customerInfo);
  const iFrameHeightCIS: any = useSelector((state: any) => state.customerInfo.iFrameHeightCIS);
  const isConversationActive: boolean = useSelector((state: any) => state.customerInfo.isConverstationActive);
  const cipData = useSelector((state: any) => state.customerInfo.CIP_data);
  const scipData = useSelector((state: any) => state.customerInfo.SCIP_data);
  const jcAuthData = useSelector((state: any) => state.customerInfo.jcAuthData);

  const contactOrigin = cipData[1]?.Origin[0] || scipData[2].Origin[0];
  entitlementList = contextualdata?.customerOfferList[0]?.entitlement?.length
    ? contextualdata?.customerOfferList[0]?.entitlement
    : jcAuthData?.entitlementsList && jcAuthData?.entitlementsList.split(';')?.length > 0
    ? jcAuthData?.entitlementsList.split(';')
    : ['no'];
  const entitlementString: string = entitlementList.join(', ');
  const vaaMeta: any[] = contextualdata?.customerOfferList[0]?.meta || [];
  const channelType = jcAuthData?.channel;

  useEffect(() => {
    adjustAccodionHeight();
  }, [content?.current?.scrollHeight]);

  useEffect(() => {
    if (title === 'Quote / Order Details') adjustAccodionHeight();
  }, [contextualdata?.SCIP_data[1]?.Order, contextualdata?.SCIP_data[1]?.Quote]);

  useEffect(() => {
    if (title === 'Customer Interaction Summary') adjustAccodionHeight();
  }, [iFrameHeightCIS]);

  useEffect(() => {
    if (title === 'Customer Info') adjustAccodionHeight();
  }, [contextualdata?.searchCustomer, contextualdata?.isSearchCustomerLoading]);

  useEffect(() => {
    if (title === 'Personal Info') adjustAccodionHeight();
  }, [inputAgentRelayPitchInMessage, showInputAcknowledgment, contextualdata?.CIP_data[0]]);

  const { darkModeBG, darkModeBorder, darkModeTitle, darkModeFieldValues, darkModea, darkModePitchMessage, darkModeRejectionSection, darkModeCustomerResponseMessage } = themes(
    useSelector((state: any) => state.customerInfo.darkMode),
  );

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'active' ? 'accordion-icon' : 'accordion-icon rotate');
  };
  const adjustAccodionHeight = () => {
    setActiveState('active');
    setHeightState(`${content.current.scrollHeight}px`);
    setRotateState('accordion-icon rotate');
  };

  // Get the customer language-region code
  const getLanguageRegionCode = (customerLanguage, customerRegion) => {
    const language = customerLanguage.toLowerCase();
    const region = customerRegion.toUpperCase();
    
    // Currently for Portuguese language, only Brazil region is supported in localisation
    if (language === 'pt') return `${language}-BR`;
    if (language === 'zh') return `${language}-${region}`;
    
    return language;
  };


  //Field key for deeplink/hyperlink
  const deepLink = ['Email Address', 'Renga ID', 'Website', 'Order', 'Quote', 'Value Add Action', 'offerEligibility'];
  const language = useSelector((state: any) => state.customerInfo.language);
  const urlcontextualdata = isCIP ? contextualdata.CIP_data : contextualdata.SCIP_data;
  const currentQueue = urlcontextualdata?.[isCIP ? 1 : 2]?.['Queue Path']?.at(-1).trim();
  const ID = urlcontextualdata?.[isCIP ? 2 : 3]?.['Phone ID']?.length ? urlcontextualdata[isCIP ? 2 : 3]['Phone ID'] : urlcontextualdata?.[isCIP ? 2 : 3]?.['Chat ID'];
  const rengaID = urlcontextualdata[0]['Renga ID'];
  const saaslId = ID + rengaID;
  const {language: customerLang = 'en', region: customerRegion = '', languageCode: customerLangCode = 'en'} = jcAuthData;

  const customerLanguage = customerLang || customerLangCode;
  const customerLanguageRegionCode = useMemo(() => getLanguageRegionCode(customerLanguage, customerRegion), [customerLanguage, customerRegion]);
  

  console.log("Contact ID for offer", currentContactId)
  // To avoid TypeError
  if (!saasl_analytics.hasOwnProperty(saaslId)) {
    saasl_analytics[saaslId] = { offerName: '', offerStatus: [], deeplink: '' };
  }

  useEffect(() => {
    setRejectReasonOptions(cctProOffer ? OFFER_REJECTION_REASON_CCT_PRO[language] : OFFER_REJECTION_REASON[language]);
  }, [language, cctProOffer]);

  useEffect(() => {
    // To remove the UI banners when switched between conversation
    setShowInputAcknowledgment(false);
    setInputCustomerOfferResponse(false);
    setDisplayPitchMessage(false);
    setCustomerRejectedSection(false);
    setInputAgentRelayPitchInMessage(false);
    setOfferPitch([]);
    setCustomerAskedForOfferPopup(false);
    setOfferPitchConfirmationPopup(false);

    // Get the flow of the offer UI using window object (Cache storage)
    const { offerStatus = [] } = saasl_analytics[saaslId];
    if (offerStatus.length) {
      if (offerStatus.includes('waiting-customer-response')) {
        setInputCustomerOfferResponse(true);
      }
      if (offerStatus.includes('offer-pitch-message')) {
        setDisplayPitchMessage(true);
      }
      if (offerStatus.includes('rejection-section')) {
        setCustomerRejectedSection(true);
      }
      if (offerStatus.includes('waiting-agent-relay-response')) {
        setInputAgentRelayPitchInMessage(true);
      }
      if (offerStatus.includes('customer-asked-for-offer')) {
        setCustomerAskedForOfferPopup(true);
      }
    }
  }, [currentContactId, saaslId]);

  useEffect(() => {
    setOfferPitch([]);
    setShowInputAcknowledgment(false);
  }, [reload]);

  const parseCustomerId = (idToCheck: string) => {
    let tempCustomerIds = [];
    const isTwilio = idToCheck.search(/twilio/i);
    let newCustomerId = 'Not available';
    if (idToCheck && isTwilio === -1) {
      tempCustomerIds = idToCheck.split(',');
      if (tempCustomerIds.length === 1) {
        newCustomerId = idToCheck;
      } else {
        tempCustomerIds.forEach(eachTempId => {
          if (eachTempId.includes('Renga ID:')) {
            newCustomerId = eachTempId.substring(eachTempId.lastIndexOf(':') + 1).trim();
          }
        });
      }
    }
    return newCustomerId;
  };

  const getDeeplink = (key: string, value: string): string => {
    try {
      if (!(key && value && urls)) return '#';
      switch (key) {
        case 'Email Address':
          if (rengaID[0] && (rengaID[0].match(/adobeid/gi) || rengaID[0].match(/@/gi))) {
            const parsedCustomerId = parseCustomerId(rengaID[0]);
            return `${urls.HENDRIX_DOMAIN}/app/customer?id=${parsedCustomerId}&tab=subscriptions&chat-queue-id=${currentQueue}&chat-session-id=${ID}`;
          }
          return `${urls.HENDRIX_DOMAIN}/app/search?email=${urlcontextualdata[0]['Email Address']}&chat-queue-id=${currentQueue}&chat-session-id=${ID}`;
        case 'Renga ID':
          if (value && (value.match(/adobeid/gi) || value.match(/@/gi)))
            return `${urls.HENDRIX_DOMAIN}/app/customer?id=${rengaID}&tab=subscriptions&chat-queue-id=${currentQueue}&chat-session-id=${ID}`;
          return '#';
        case 'Order':
          if (value.includes('No Order found') || value.includes('オーダーはありません')) return '#';
          return `${urls.HENDRIX_DOMAIN}/app/order?id=${value}`;
        case 'Quote':
          if (value.includes('No Quote found') || value.includes('見積はありません')) return '#';
          return `${urls.HENDRIX_DOMAIN}/app/quote?id=${value}`;
        case 'Website':
          return value;
        case 'Value Add Action':
          const hxParam = vaaMeta ? vaaMeta.find(element => element?.k === value) : null;
          return hxParam ? `${urls.HENDRIX_DOMAIN}/app/search?guid=${rengaID}&chat-queue-id=${currentQueue}&chat-session-id=${ID}&context=${hxParam?.v}` : '#';
        default:
          return '#';
      }
    } catch (err) {
      console.log('Error in getDeeplink', err);
      return '#';
    }
  };

  const agentPitchedOfferInChat = async (offerName: string, deeplink: any, iscctPro: any = false) => {
    setOfferPitchConfirmationPopup(false);
    setDisplayPitchMessage(false);
    setCustomerRejectedSection(false);
    setShowInputAcknowledgment(false);
    setCCTProOffer(iscctPro);
    setRejectReasonOptions(iscctPro ? OFFER_REJECTION_REASON_CCT_PRO[language] : OFFER_REJECTION_REASON[language]);

    saasl_analytics[saaslId].offerStatus = [];
    saasl_analytics[saaslId].offerStatus.push('waiting-customer-response');

    saasl_analytics[saaslId].deeplink = deeplink;

    const message = offerName === 'Single-app-to-All-Apps' ? OFFER_PITCH_MESSAGE[customerLanguageRegionCode][offerName].replace('####', entitlementString) : OFFER_PITCH_MESSAGE[customerLanguageRegionCode][offerName];
    const translateMsg = offerName === 'Single-app-to-All-Apps' ? OFFER_PITCH_MESSAGE[language][offerName].replace('####', entitlementString) : OFFER_PITCH_MESSAGE[language][offerName];
    callBackEvent('OFFER_PITCH_MESSAGE', { message, translateMsg });

    saasl_analytics[saaslId].offerStatus.push('waiting-customer-response');
    setInputCustomerOfferResponse(true);

    const requestBody = {
      conversation_id: currentContactId,
      customer_id: rengaID[0],
      offer_pitched: [offerName],
      offer_pitch_timestamp: new Date(),
    };

    updateOfferPitch(urls.CIP_DOMAIN, requestBody);

    saasl_analytics[saaslId].offerName = offerName;
    setOfferPitch(prevArray => [...prevArray, offerName]);
  };

  const customerAcceptedOffer = () => {
    setDisplayPitchMessage(false);
    setInputCustomerOfferResponse(false);
    setInputAgentRelayPitchInMessage(false);
    setCustomerRejectedSection(false);

    const requestBody = {
      conversation_id: currentContactId,
      offer_accepted: [saasl_analytics[saaslId]?.offerName],
    };

    updateOfferResponseStatus(urls.CIP_DOMAIN, requestBody);

    if (saasl_analytics[saaslId].deeplink !== '#') {
      window.open(saasl_analytics[saaslId].deeplink, '_blank');
      setReload(key => key + 1);
    }
    setShowInputAcknowledgment(true);
    saasl_analytics[saaslId] = { offerName: '', offerStatus: [], deeplink: '' };
  };

  const customerRejectedOffer = () => {
    setDisplayPitchMessage(false);
    setInputCustomerOfferResponse(false);
    setInputAgentRelayPitchInMessage(false);

    saasl_analytics[saaslId].offerStatus = ['rejection-section'];
    setCustomerRejectedSection(true);

    const requestBody = {
      conversation_id: currentContactId,
      offer_rejected: [saasl_analytics[saaslId]?.offerName],
    };

    updateOfferResponseStatus(urls.CIP_DOMAIN, requestBody);
  };

  const displayTextMessage = (text: string) => (
    <div className={`${styles['accordion-value-add-action-customer-response-message']} ${darkModeCustomerResponseMessage}`}>
      <div className={`${styles['accordion-value-add-action-customer-response-message-child-message-offer-executed']} ${darkModePitchMessage}`}>{text}</div>
    </div>
  );

  const displayOfferPitchMessage = (offerName: string) => {
    let offerPitchMessage = OFFER_PITCH_MESSAGE[language][offerName];

    if (offerName === 'Single-app-to-All-Apps') {
      offerPitchMessage = offerPitchMessage.replace('####', entitlementString);
    }

    return (
      <div className={styles['accordion-value-add-offer-pitch-message-section']}>
        <div className={`${styles['accordion-value-add-action-pitch-message']} ${darkModePitchMessage}`}>"{offerPitchMessage}"</div>
      </div>
    );
  };

  const setCustomerReasonSelected = (reasonIndex: any) => {
    if (reasonIndex == rejectReasonOptions.length) {
      console.log('setCustomerReasonSelected');
      console.log('reasonIndex', reasonIndex);
      console.log('rejectReasonOptions.length', rejectReasonOptions.length);
      setShowCustomReasonReject(true);
      setCustomReasonReject('');
    } else {
      const reason = cctProOffer ? OFFER_REJECTION_REASON_CCT_PRO['en'][reasonIndex - 1].reason : OFFER_REJECTION_REASON['en'][reasonIndex - 1].reason;

      rejectionReasonAccepted(reason);
    }
  };
  const rejectionReasonAccepted = (reason: string) => {
    setShowCustomReasonReject(false);
    setCustomerRejectedSection(false);
    setShowInputAcknowledgment(true);
    setCustomReasonReject('');

    const requestBody = {
      conversation_id: currentContactId,
      offer_rejection_reason: [reason],
    };
    updateOfferRejectionReason(urls.CIP_DOMAIN, requestBody);
    saasl_analytics[saaslId] = { offerName: '', offerStatus: [], deeplink: '' };
  };

  const agentPitchedOfferInVoice = () => {
    
    setDisplayPitchMessage(false);
    setCustomerRejectedSection(false);
    setShowInputAcknowledgment(false);
    setInputAgentRelayPitchInMessage(false);

    saasl_analytics[saaslId].offerStatus = [];
    saasl_analytics[saaslId].offerStatus.push('waiting-customer-response');
    setInputCustomerOfferResponse(true);

    const requestBody = {
      conversation_id: currentContactId,
      customer_id: rengaID[0],
      offer_pitched: [saasl_analytics[saaslId]?.offerName],
      offer_pitch_timestamp: new Date(),
    };

    updateOfferPitch(urls.CIP_DOMAIN, requestBody);
    setOfferPitch(prevArray => [...prevArray, saasl_analytics[saaslId]?.offerName]);
  };

  const agentDidNotRelay = () => {
    setDisplayPitchMessage(false);
    setInputCustomerOfferResponse(false);
    setInputAgentRelayPitchInMessage(false);
    saasl_analytics[saaslId] = { offerName: '', offerStatus: [], deeplink: '' };
  };

  const showCustomerAskedForOfferPopup = (value: string, deeplink: any, cctProOffer: any = false, contactOrigin: string) => {
    saasl_analytics[saaslId].offerName = value;
    saasl_analytics[saaslId].deeplink = deeplink;
    saasl_analytics[saaslId].offerStatus.push('customer-asked-for-offer');
    
    setCustomerAskedForOfferPopup(true);
    setOfferData({...offerData, value, deeplink, cctProOffer, contactOrigin})

  };

  const customerAskedForOffer = () => {
    setCustomerAskedForOfferPopup(false);
    setInputCustomerOfferResponse(true);
    setCCTProOffer(offerData?.cctProOffer);
    setRejectReasonOptions(offerData?.cctProOffer ? OFFER_REJECTION_REASON_CCT_PRO[language] : OFFER_REJECTION_REASON[language]);
    
    saasl_analytics[saaslId].offerStatus = [];
    saasl_analytics[saaslId].offerStatus.push('waiting-customer-response');

    const requestBody = {
      conversation_id: currentContactId,
      customer_id: rengaID[0],
      offer_pitched: [offerData?.value],
      offer_pitch_timestamp: new Date(),
    };

    updateOfferPitch(urls.CIP_DOMAIN, requestBody);
    setOfferPitch(prevArray => [...prevArray, offerData?.value]);

  }

  const customerDidNotAskForOffer = () => {
    setCustomerAskedForOfferPopup(false);
    const { cctProOffer } = offerData;
    const originOutbound = phoneOrigin?.includes(callOrigin) || (callOrigin === CHAT_TO_PHONE && !channelType);

    // For CHAT or Chat-to-Phone(Chat = Active), display the offer confirmation popup
    if(!originOutbound && chatStatus && chatStatus !== 'Closed' && transferInProgressId !== currentConvId) setOfferPitchConfirmationPopup(true);
    // For any other scenario, display the offer pitch message in UI assuming it to be a VOICE conversation
    else {
      setDisplayPitchMessage(false);
      setCustomerRejectedSection(false);
      setShowInputAcknowledgment(false);
      setCCTProOffer(cctProOffer);
      setRejectReasonOptions(cctProOffer ? OFFER_REJECTION_REASON_CCT_PRO[language] : OFFER_REJECTION_REASON[language]);

      saasl_analytics[saaslId].offerStatus.push('offer-pitch-message');
      setDisplayPitchMessage(true);

      saasl_analytics[saaslId].offerStatus.push('waiting-agent-relay-response');
      setInputAgentRelayPitchInMessage(true);
    }
  }

  return (
    <div className={styles['accordion-section'] + darkModeBorder}>
      <button className={`${styles['accordion']} ${styles[setActive]} ${darkModeBG}`} onClick={toggleAccordion}>
        <Chevron className={setRotate} width={17.93} fill={'#6E6E6E'} />

        <p className={styles['accordion-title'] + darkModeTitle}>{localization[language][title]}</p>
      </button>
      <div id={'accordion-content'} ref={content} style={{ maxHeight: `${setHeight}` }} className={styles['accordion-content'] + darkModeBG}>
        {title === 'Contextual Info' && (
          <CartDetails/>
        )}
        {title === 'Current Conversation Summary' ? (
          <ConversationSummary conversationSummary={conversationSummary} agentPath={agentPath} callBackEvent={callBackEvent} feedbackGotten={feedback !== undefined} currentConvId={currentConvId}/>
        ) : title === 'Customer Info' ? (
          <SCIPCustomerInfo urls={urls} callBackEvent={callBackEvent} contactOrigin={contactOrigin}/>
        ) : title === 'Customer Interaction Summary' ? (
          <CustomerInteractionSummary urls={urls} />
        ) : (
          Object.entries(cipValues).map(([key, values]: any) =>
            key !== 'Title' && values?.length > 0 ? (
              <div key={key} className={styles['accordion-content-fields']}>
                <span
                  className={`${styles[isCIP ? 'accordion-content-fields-keys-cip' : 'accordion-content-fields-keys-scip']} ${
                    styles[key === 'CustomerOne Sub-Issue' ? (isCIP ? 'accordion-content-fields-keys-cip-sub-issue' : 'accordion-content-fields-keys-scip-sub-issue') : '']
                  }`}>
                  {localization[language][key]}
                </span>
                {deepLink.includes(key)
                  ? values.map((value: any, index: number) => {
                      const deeplink = getDeeplink(key, value);
                      const isdeeplink: boolean = deeplink !== '#';
                      return (
                        <div key={index}>
                          {(key === 'Value Add Action' || key === 'offerEligibility') &&
                          !offerPitch.includes(value) &&
                          !displayPitchMessage &&
                          !customerRejectedSection &&
                          !inputCustomerOfferResponse ? (
                            isConversationActive ? (
                              <Link UNSAFE_className={styles['accordion-content-fields-values-isnota']} data-attr-key={key} onPress={() => showCustomerAskedForOfferPopup(value, deeplink, value === 'CCT-Pro', contactOrigin)}>
                                  {value !== '---' ? value.replaceAll('-', ' ') : value}
                              </Link>
                            ) : isdeeplink ? (
                              <Link UNSAFE_className={styles['accordion-content-fields-values-isnota']} onPress={() => window.open(deeplink, '_blank')}>
                                {value !== '---' ? value.replaceAll('-', ' ') : value}
                              </Link>
                            ) : (
                              <span key={index} className={styles['accordion-content-fields-values-isnota'] + darkModeBG + darkModeFieldValues}>
                                {value !== '---' ? value.replaceAll('-', ' ') : value}
                              </span>
                            )
                          ) : (
                            !(key === 'Value Add Action' || key === 'offerEligibility') &&
                            (isdeeplink ? (
                              <Link UNSAFE_className={styles['accordion-content-fields-values-isnota']} data-attr-key={key}>
                                <a href={deeplink} target="_blank" className={styles['accordion-content-fields-values-isnota'] + darkModea} rel="noreferrer">
                                  {value}
                                </a>
                              </Link>
                            ) : (
                              <span key={index} className={styles['accordion-content-fields-values-isnota'] + darkModeBG + darkModeFieldValues}>
                                {value !== '---' ? value.replaceAll('-', ' ') : value}
                              </span>
                            ))
                          )}
                        </div>
                      );
                    })
                  : values.map((value: any, index: number) =>
                      key === 'Queue Path' && index + 1 === values.length ? (
                        <span key={index} className={styles['accordion-content-fields-queue-path'] + darkModeBG + darkModeFieldValues}>
                          {localization[language][value] ? localization[language][value] : value}
                        </span>
                      ) : key === 'Highest Support Plan' ? (
                        <span key={index} className={styles['accordion-content-fields-values-isnota'] + darkModeBG + darkModeFieldValues}>
                          <React.Fragment>
                            <SupportPlan /> {value.highestASP}
                          </React.Fragment>{' '}
                          <br />
                          {value.aspRegion && `In Region Support(${value.aspRegion})`}
                        </span>
                      ) : key === 'Phone' ? (
                        <button
                          onClick={() => callBackEvent('CIP_PHONE_NUMBER_CLICK', value)}
                          key={index}
                          className={styles['accordion-content-fields-values-phone'] + darkModeBG + darkModea}>
                          {value}
                        </button>
                      ) : (
                        <span key={index} className={styles['accordion-content-fields-values-isnota'] + darkModeBG + darkModeFieldValues}>
                          {localization[language][value] ? localization[language][value] : value}
                        </span>
                      ),
                    )}
              </div>
            ) : null,
          )
        )}
      </div>
      {title === 'Personal Info' && (
        <div>
          {displayPitchMessage && displayOfferPitchMessage(saasl_analytics[saaslId]?.offerName)}

          {inputCustomerOfferResponse && (
            <div className={`${styles['accordion-value-add-action-customer-response-message']} ${darkModeCustomerResponseMessage}`}>
              <div className={`${styles['accordion-value-add-action-customer-response-message-child-message']} ${darkModeCustomerResponseMessage}}`}>
                {localization[language]['did-customer-accept-offer'] + ` (${saasl_analytics[saaslId].offerName.replaceAll('-', ' ')})?`}
              </div>
              <div className={styles['accordion-value-add-action-customer-response-message-child-buttons']}>
                <ActionButton UNSAFE_style={{ backgroundColor: '#fff', marginRight: '5px' }} aria-label="Icon only" onPress={customerAcceptedOffer}>
                  <Confirm />
                </ActionButton>
                <ActionButton UNSAFE_style={{ backgroundColor: '#fff', marginRight: '5px' }} aria-label="Icon only" onPress={customerRejectedOffer}>
                  <Cancel />
                </ActionButton>
              </div>
            </div>
          )}

          {inputAgentRelayPitchInMessage && (
            <div className={`${styles['accordion-value-add-action-customer-response-message']} ${darkModeCustomerResponseMessage}`}>
              <div className={`${styles['accordion-value-add-action-customer-response-message-child-message']} ${darkModeCustomerResponseMessage}}`}>
                {localization[language]['relay-offer-to-customer'] + ` (${saasl_analytics[saaslId].offerName.replaceAll('-', ' ')})?`}
              </div>
              <div className={styles['accordion-value-add-action-customer-response-message-child-buttons']}>
                <ActionButton UNSAFE_style={{ backgroundColor: '#fff', marginRight: '5px' }} aria-label="Icon only" onPress={agentPitchedOfferInVoice}>
                  <Confirm />
                </ActionButton>
                <ActionButton UNSAFE_style={{ backgroundColor: '#fff', marginRight: '5px' }} aria-label="Icon only" onPress={agentDidNotRelay}>
                  <Cancel />
                </ActionButton>
              </div>
            </div>
          )}

          {showInputAcknowledgment && displayTextMessage(localization[language]['thank-you'])}

          {customerRejectedSection && (
            <div className={`${styles['accordion-value-add-action-customer-response-message']} ${darkModeRejectionSection}`}>
              <Picker
                UNSAFE_className={styles['accordion-value-add-action-picker']}
                label={localization[language]['offer-rejection-reason']}
                placeholder=""
                items={rejectReasonOptions}
                onSelectionChange={selected => setCustomerReasonSelected(selected)}>
                {(item: any) => <Item key={item.id}>{item.reason}</Item>}
              </Picker>
              {showCustomReasonReject && (
                <div className={styles['accordion-customer-reject-reson']}>
                  <TextField value={customReasonReject} UNSAFE_style={{ width: '100%' }} onChange={setCustomReasonReject} />
                  <ActionButton
                    UNSAFE_style={{ backgroundColor: '#fff', marginRight: '2px' }}
                    isDisabled={customReasonReject.length === 0}
                    aria-label="Icon only"
                    onPress={() => rejectionReasonAccepted(customReasonReject)}>
                    <Confirm />
                  </ActionButton>
                </div>
              )}
            </div>
          )}

          {customerAskedForOfferPopup && (
            <div className={`${styles['accordion-value-add-action-customer-response-message']} ${darkModeCustomerResponseMessage}`}>
              <div className={`${styles['accordion-value-add-action-customer-response-message-child-message']} ${darkModeCustomerResponseMessage}}`}>
                {localization[language]['customer-asked-for-offer'] + ` (${saasl_analytics[saaslId].offerName.replaceAll('-', ' ')})?`}
              </div>
              <div className={styles['accordion-value-add-action-customer-response-message-child-buttons']}>
                <ActionButton UNSAFE_style={{ backgroundColor: '#fff', marginRight: '5px' }} aria-label="Icon only" onPress={customerAskedForOffer}>
                  <Confirm />
                </ActionButton>
                <ActionButton UNSAFE_style={{ backgroundColor: '#fff', marginRight: '5px' }} aria-label="Icon only" onPress={customerDidNotAskForOffer}>
                  <Cancel />
                </ActionButton>
              </div>
            </div>
          )}

          <DialogContainer onDismiss={() => setOfferPitchConfirmationPopup(false)}>
            {offerPitchConfirmationPopup &&
              <AlertDialog
                variant="confirmation"
                title={localization[language]['customer-offer']}
                primaryActionLabel="Continue"
                cancelLabel="Cancel"
                onPrimaryAction={() => agentPitchedOfferInChat(offerData?.value, offerData?.deeplink, offerData?.value === 'CCT-Pro')}
              >
                {localization[language]['offer-pitch-confirmation'] + ` (${offerData?.value.replaceAll('-', ' ')})?`}
              </AlertDialog>
            }
          </DialogContainer>

        </div>
      )}
    </div>
  );
}

export default Accordion;
