import actionTypes from '../actionTypes'

const {
  SET_LOAD_CUSTOMER_DATA,
  CLEAR_CUSTOMER_STATE,
  SET_CUSTOMER_GUID
} = actionTypes

const initialState = {
  contextData: {},
  loadCustomerData: {},
  c360Guid: ''
}

interface Action {
  type: string
  value: any
}

const customerReducer = (
  state: object = initialState,
  action: Action = { type: '', value: '' }
) => {
  switch (action.type) {
    case SET_LOAD_CUSTOMER_DATA: {
      return {
        ...state,
        loadCustomerData: action.value
      }
    }
    case CLEAR_CUSTOMER_STATE: {
      return initialState
    }
    case SET_CUSTOMER_GUID: {
      return {
        ...state,
        c360Guid: action.value
      }
    }
    default:
      return state
  }
}

export default customerReducer
