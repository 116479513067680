const getAuthHeaders = () => {
  if (localStorage.getItem('customPanelAccessToken')) {
    return {
      'x-api-key': 'oac-custom-panel',
      Authorization: JSON.parse(localStorage.getItem('customPanelAccessToken') || ''),
    };
  }
};

export { getAuthHeaders };
