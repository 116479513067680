import React, { useEffect } from 'react'
import styles from '../Themes/Styles/styles.module.css'
import localization from '../Language/localization'
import AACard from './AACard'
import { initDunamis, trackPinnedRecentQueryAndCopyEvents } from '../Analytics'
import { Query } from './AdobeAnswersFrame'

interface Props {
  deleteQuery: (
    assistId: string,
    queryId: string,
    isPinnedQuery: boolean
  ) => void,
  pinUnpinQuery: (
    assistId: string,
    queryId: string,
    isPinned: boolean
  ) => void
  conversationId: string
  pinned: boolean
  queries: Query[]
  setSelectedQuery: (
    conversationId: string,
    queryId: string
  ) => void
  selectedQueryId: string | null
  isLoading: boolean
  language: string
  numberOfPinnedQueries: number
  //disabledNewQuestion: (enabled: boolean) => void,
  darkMode: boolean
}
const AACardList = (props: Props) => {
  const {
    deleteQuery,
    pinUnpinQuery,
    pinned,
    queries,
    language,
    numberOfPinnedQueries,
    darkMode,
    conversationId,
    selectedQueryId,
    setSelectedQuery,
    isLoading
  } = props
  useEffect(() => { 
    initDunamis() 
  }, []);

  const switchCurrentQuery = (assistId: string, id: string): void => {
    trackPinnedRecentQueryAndCopyEvents(assistId, "query", (pinned ? "pinned" : "recent"), undefined);
    setSelectedQuery(conversationId, id)
  }

  return (
    <div className={`${styles['aa_module_container_right_content_querylist']}`}>
      <div>{pinned ? localization[language]['Pinned queries'] : localization[language]['Recent queries']}</div>
      {queries?.map((query) => (
        <AACard
          numberOfPinnedQueries={numberOfPinnedQueries}
          query={query}
          deleteQuery={deleteQuery}
          pinUnpinQuery={pinUnpinQuery}
          switchQuery={!isLoading ? switchCurrentQuery : null}
          key={query.id}
          current={selectedQueryId === query.id}
          actionsDisabled={isLoading}
          pinned={pinned}
          darkMode={darkMode}
          language={language}
        />
      ))}
    </div>
  )
}
export default AACardList
