import React, { useState } from 'react'
import styles from '../Theme/Styles/styles.module.css'
import localization from '../Language/localization'
import { NOT_HELPFUL_REASON } from '../Language/localization'
import themes from '../Theme/Themes'
import ThumbsUp from '../Theme/Icons/ThumbsUp'
import ThumbsDown from '../Theme/Icons/ThumbsDown'
import ThumbsUpDark from '../Theme/Icons/ThumbsUpDark'
import ThumbsDownDark from '../Theme/Icons/ThumbsDownDark'
import { useSelector } from 'react-redux'
import { Button, ButtonGroup, Content, Dialog, DialogTrigger, Divider, Heading, Item, Link, Picker, TextArea } from '@adobe/react-spectrum'

declare const window: any;

interface iProps {
  currentConvId: string;
  callBackEvent: (eventType: string, eventData: any) => void;
  feedbackGotten: any;
}


function ConversationSummaryFeedback({callBackEvent, feedbackGotten, currentConvId}: iProps ) {
  console.log('feedBackGotten in feedBack component', feedbackGotten)
  const language = useSelector((state: any) => state.customerInfo.language)
  const { darkMode, darkModeConversationSummaryFeedback } = themes(useSelector((state: any) => state.customerInfo.darkMode))

  const thumbsUp = darkMode ? <ThumbsUpDark /> : <ThumbsUp />
  const thumbsDown = darkMode ? <ThumbsDownDark /> : <ThumbsDown />

  const [notUsefulReason, setNotUsefulReason] = useState<string>('')
  const [inputText, setInputText] = useState<string>('')

  const onPressThumbsUp = () => {
    console.log('CIP: Conversation Summary: Thumbs Up was pressed');
    window.updateCTRForConversationSummaryAnalytics(currentConvId, true, true, true)
    callBackEvent('CONVERSATION_SUMMARY_FEEDBACK', true)
  }
  const onPressThumbsDown = () => {
    console.log('CIP: Conversation Summary: Thumbs Down was pressed')
    window.updateCTRForConversationSummaryAnalytics(currentConvId, true, true, false);
    callBackEvent('CONVERSATION_SUMMARY_FEEDBACK', false)
  }

  const setCustomerReasonSelected = (reasonIndex: any) => {
    const reason = NOT_HELPFUL_REASON[language][reasonIndex - 1].reason
    setNotUsefulReason(reason)
  }

  const sendFeedBack = (close) => {
    close()
    alert(`Feedback ${notUsefulReason}, ${inputText} was sent!`)
  }

  const onMessageChange = (e: any): void => {
    setInputText(e)
  }

  return (
    feedbackGotten ? 
    <div className={`${styles['accordion-value-conversation-summary-feedback']} ${darkModeConversationSummaryFeedback}`}>
      {localization[language]['thank-you-feedback']}
    </div>
    : 
    <div className={`${styles['accordion-value-conversation-summary-feedback']} ${darkModeConversationSummaryFeedback}`}>
      <div>{localization[language]['Is this helpful?']}</div>
      <Link onPress={() => onPressThumbsUp()}>
        <div className={`${styles['conversation-summary-thumbsup']}`}> {thumbsUp} </div>
      </Link>
      <Link onPress={() => onPressThumbsDown()}>
        <div className={`${styles['conversation-summary-thumbsup']}`}> {thumbsDown} </div>
      </Link>
      {/* <DialogTrigger>

        {(close) => (
          <Dialog>
            <Heading>{localization[language]['Feedback']}</Heading>
            <Divider />
            <Content UNSAFE_className=''>
              <Picker
                UNSAFE_style={{ display: 'flex', flexDirection: 'column' }}
                width={'100%'}
                label={localization[language]['Reason']}
                placeholder=''
                items={NOT_HELPFUL_REASON[language]}
                onSelectionChange={(selected) => setCustomerReasonSelected(selected)}
              >
                {(item: any) => <Item key={item.id}>{item.reason}</Item>}
              </Picker>
              <div className={`${styles['conversation-summary-feedback-text-area']}`}>
                <TextArea width={'100%'} label={localization[language]['Why was this not helpful?']} onChange={onMessageChange} value={inputText} />
              </div>
            </Content>
            <ButtonGroup>
              <Button variant='secondary' onPress={close}>
                {localization[language]['Cancel']}
              </Button>
              <Button variant='cta' onPress={() => sendFeedBack(close)}>
                {localization[language]['Send']}
              </Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogTrigger> */}
    </div>
  )
}

export default ConversationSummaryFeedback