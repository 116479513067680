import axios from 'axios'
import store from '../store'
import {getAuthHeadersForConsoleService} from '../utils/getAuthHeaders';


function getClient() {
  const data: any = store.getState()
  const baseURL = data.uiState.agentConsoleServiceBaseUrl
  return axios.create({
    baseURL: baseURL,
    timeout: 90000,
    headers: getAuthHeadersForConsoleService(),
  })
}

export default getClient
