import styles from '../styles/Styles.module.css'

const themes = (darkMode: boolean) => ({
  darkMode,
  darkModeBG: darkMode ? ` ${styles['ru-panel-dark']}` : '',
  darkModeBorder: darkMode ? ` ${styles['ru-panel-dark-border']}` : '',
  darkModeTitle: darkMode ? ` ${styles['ru-title-dark']}` : '',
  darkModeExpandView: darkMode ? `${styles['ru-expand-view-dark']}` : '',
  darkModeSupTeleAcceptBox: darkMode ? `${styles['ru-suptel-accept-box-dark']}` : '',
  darkModeSupTelOfferBox: darkMode ? `${styles['ru-suptel-offer-box-dark']}` : '',
  darkModeNoConsent: darkMode ? `${styles['ru-no-consent-dark']}` : ''
})

export default themes
