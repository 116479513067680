import React from 'react'

export const InfoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='18'
      viewBox='0 0 18 18'
      width='18'
    >
      <defs>
        <style>
          {`
        .ecm-info-icon {
          fill: #6E6E6E;
        }
      `}
        </style>
      </defs>
      <title>S Info 18 N</title>
      <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
      <path
        className='ecm-info-icon'
        d='M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM8.85,3.15a1.359,1.359,0,0,1,1.43109,1.28286q.00352.06452.00091.12914A1.332,1.332,0,0,1,8.85,5.9935a1.3525,1.3525,0,0,1-1.432-1.432A1.3585,1.3585,0,0,1,8.72033,3.14907Q8.78516,3.14643,8.85,3.15ZM11,13.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H8V9H7.5A.5.5,0,0,1,7,8.5v-1A.5.5,0,0,1,7.5,7h2a.5.5,0,0,1,.5.5V12h.5a.5.5,0,0,1,.5.5Z'
      />
    </svg>
  )
}
