import actionTypes from '../actionTypes'

const initialState = {
  isCaseListPage: true,
  darkMode: false,
  language: 'en',
  baseServiceUrl: '',
  agentConsoleServiceBaseUrl : '',
  customerDeeplinkURL: '',
  caseDeeplinkURL: '',
  toggleNotification: false,
  notificationMessage: '',
  readOnlyMode: true,
  showChangeCustomer: false,
  showSearchCustomer: false,
}

const {
  CASE_LIST_PAGE_FLAG,
  SET_LANGUAGE,
  TOGGLE_DARK_MODE,
  SET_SERVICE_BASE_URL,
  SET_AGENT_CONSOLE_SERVICE_BASE_URL,
  SET_CUSTOMER_DEEPLINK_URL,
  SET_CASE_DEEPLINK_URL,
  CLEAR_UI_STATE,
  TOGGLE_NOTIFICATION,
  READ_ONLY_MODE,
  SET_CHANGE_CUSTOMER,
  SET_SEARCH_CUSTOMER
} = actionTypes

interface Action {
  type: string
  value: boolean
  payload?: any
}

const uiReducer = (
  state: object = initialState,
  action: Action = { type: '', value: false }
) => {
  switch (action.type) {
    case CASE_LIST_PAGE_FLAG: {
      return {
        ...state,
        isCaseListPage: action.value
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: action.value
      }
    }
    case TOGGLE_DARK_MODE: {
      return {
        ...state,
        darkMode: action.value
      }
    }
    case SET_SERVICE_BASE_URL: {
      return {
        ...state,
        baseServiceUrl: action.value
      }
    }
    case SET_AGENT_CONSOLE_SERVICE_BASE_URL: {
      return {
        ...state,
        agentConsoleServiceBaseUrl: action.value
      }
    }
    case SET_CASE_DEEPLINK_URL: {
      return {
        ...state,
        caseDeeplinkURL: action.value
      }
    }
    case SET_CUSTOMER_DEEPLINK_URL: {
      return {
        ...state,
        customerDeeplinkURL: action.value
      }
    }

    case CLEAR_UI_STATE: {
      return {
        ...state,
        isCaseListPage: true,
        darkMode: false,
        language: 'en'
      }
    }

    case TOGGLE_NOTIFICATION: {
      return {
        ...state,
        toggleNotification: action.value,
        notificationMessage: action.payload
      }
    }

    case READ_ONLY_MODE: {
      return {
        ...state,
        readOnlyMode: action.value
      }
    }

    case SET_CHANGE_CUSTOMER: {
      return {
        ...state,
        showChangeCustomer: action.value
      }
    }

    case SET_SEARCH_CUSTOMER: {
      return {
        ...state,
        showSearchCustomer: action.value
      }
    }

    default:
      return state
  }
}

export default uiReducer
