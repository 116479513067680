import store from "../Store/Store";
declare const window: any;

export function initDunamis() {
  window.dunamis_analytics = window.dunamis_analytics || {};
}

export function renderAnswerEvent(currentAnswer, query) {
  // dunamis analytics event - render search results
  if (typeof window.dunamis_analytics.sendGenAIAdobeAnswersEvents === 'function' && currentAnswer?.answer?.length > 0) {
    window.dunamis_analytics.sendGenAIAdobeAnswersEvents({
      subCategory: 'Search',
      type: 'render',
      subType: 'result',
      "ui.search_keyword": query || undefined, // search_keyword - shouldn't be answer
      "content.name": currentAnswer?.assistId
    })
  }
}

export function querySearchEvent(question, assistId, isSuggestedQuery = false) {
  // dunamis analytics event - click event for search - good
  if (typeof window.dunamis_analytics.sendGenAIAdobeAnswersEvents === 'function') {
    if (isSuggestedQuery) {
      window.dunamis_analytics.sendGenAIAdobeAnswersEvents({
        subType: 'search',
        "ui.search_keyword": question,
        "content.name": assistId,
        "content.category": "autoquery"
      })
    } else {
      window.dunamis_analytics.sendGenAIAdobeAnswersEvents({
        subType: 'search',
        "ui.search_keyword": question,
        "content.name": assistId
      })
    }
  }
}

export function regenerateAnswerEvent(assistId) {
  console.log(assistId);
}

export function trackAnswerFeedbackEvent(assistId, type, subType, value, category, feedbackReason, feedback_comments) {
  // dunamis analytics event - Feedback - thumbs up or down
  if (typeof window.dunamis_analytics.sendGenAIAdobeAnswersEvents === 'function') {
    window.dunamis_analytics.sendGenAIAdobeAnswersEvents({
      subCategory: 'Feedback',
      "content.name": assistId,
      "content.category": category || undefined,
      "content.status": feedbackReason || undefined,
      "custom.feedback_comments": feedback_comments || undefined,
      subType: subType,
      type,
      value,
    })
  }
}

export function trackPinnedRecentQueryAndCopyEvents(assistId, subType, value, uiType) {
  // - good
  if (typeof window.dunamis_analytics.sendGenAIAdobeAnswersEvents === 'function') {
    window.dunamis_analytics.sendGenAIAdobeAnswersEvents({
      subCategory: 'Search',
      type: "click",
      "content.name": assistId,
      "ui.view_type": uiType || undefined,
      subType,
      value
    })
  }
}

export function storeTheAdobeAnswers(conversationId, currentQuery) {
  const { assistId, answers, answer } = currentQuery; // sometimes query contains answers as an array or answer as object
  const assistIdForAnalytics = (Array.isArray(answers) && typeof answers[0] === 'object') ? answers[0].assistId : assistId;
  const answerForAnalytics = (Array.isArray(answers) && typeof answers[0] === 'object') ? answers[0].text : answer;

  // logic to store assistId w.r.t conversationId for Adobe Answers requirement.
  window.dunamis_analytics = window.dunamis_analytics || {};
  window.dunamis_analytics.adobeanswers = window.dunamis_analytics.adobeanswers || {};
  window.dunamis_analytics.adobeanswers[conversationId] = { answer: answerForAnalytics, assistId: assistIdForAnalytics }
}
