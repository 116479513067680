declare const window: any;

export function initDunamis() {
    window.dunamis_analytics = window.dunamis_analytics || {};
}

/**
 * 
 * @param widgetName - Case Management/ Sales CIP
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param searchEmailPhoneText - email/phone/caseId - this cannot be captured to adhere the GDPR rules - this is customer email. instead can we capture the rengaId
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * 
 * Case Management/Sales CIP- id search
 * BR column
 */
export function customerSearchEvent(widgetName: String, vaoWorkflowCategory: String) {
    if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
        window.dunamis_analytics.sendValueAddedOffersEvents({
            subCategory: widgetName,
            subType: 'search',
            type: 'click',
            "content.category": vaoWorkflowCategory,
        })
    }
}

/**
 * 
 * @param widgetName - Case Management/ Sales CIP
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param searchEmailPhoneText - email/phone/caseId - this cannot be captured to adhere the GDPR rules - this is customer email. instead can we capture the rengaId
 * @param searchResultCount 
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * 
 * Case Management/Sales CIP- display search result
 * BS column
 */
export function customerSearchResultRenderEvent(widgetName: String, vaoWorkflowCategory: String, searchResultCount: Number) {
    if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
        window.dunamis_analytics.sendValueAddedOffersEvents({
            subCategory: widgetName,
            subType: 'search-results',
            type: 'render',
            "content.category": vaoWorkflowCategory,
            "event.count": searchResultCount,
        })
    }
}

/**
 * 
 * @param widgetName - Case Management/ Sales CIP
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param searchEmailPhoneText - email/phone/caseId - this cannot be captured to adhere the GDPR rules - this is customer email. instead can we capture the rengaId 
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * 
 * Case Management/Sales CIP-click customer
 * BT column
 */
export function customerAccountSelectionEvent(widgetName: String, vaoWorkflowCategory: String, rengaId: String) {
    if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
        window.dunamis_analytics.sendValueAddedOffersEvents({
            subCategory: widgetName,
            subType: 'customer',
            type: 'click',
            "content.category": vaoWorkflowCategory,
            "event.visitor_guid": rengaId
        })
    }
}