import { Link } from '@adobe/react-spectrum';
import localization from '../../lang/localization';
import styles from '../../Theme/Styles/styles.css';
import React from 'react';

interface Summary {
    main_issue: string;
    agentPath: string;
    conversation_points: any;
    links: any;
}

interface Props {
    data: Summary;
    darkMode: boolean;
    language: string;
}

const ConversationSummary = ({data, darkMode, language}: Props) => {
    const { main_issue = "", agentPath = "", conversation_points = "", links = "" } = data;
    return (
        <div>
            <div className={styles['ch-summary-accordian-conversation-with']}>
                <span className={darkMode ? styles['ch-summary-conversation-with-title-dark']: styles['ch-summary-conversation-with-title']}>{localization[language].CONVERSATION_WITH}</span>
                <div className={darkMode ? styles['ch-summary-accordian-values-dark'] : styles['ch-summary-accordian-values']}>{agentPath}</div>
                </div>
            <div className={darkMode ? styles['ch-summary-accordian-fields-dark'] : styles['ch-summary-accordian-fields']}>
                <div className={darkMode ? styles['ch-summary-main-issue-title-dark']: styles['ch-summary-main-issue-title']}>{localization[language].MAIN_CUSTOMER_ISSUE}</div>
                <div className={darkMode ? styles['ch-summary-accordian-values-dark']: styles['ch-summary-accordian-values']}>{main_issue ?? '- No data -'}</div>
            </div>
            <div className={darkMode ? styles['ch-summary-accordian-fields-dark']: styles['ch-summary-accordian-fields']}>
                <div className={darkMode ? styles['ch-summary-main-issue-title-dark'] :styles['ch-summary-main-issue-title']}>{localization[language].CONVERSATION_POINTS}</div>
                <div className={darkMode ? styles['ch-summary-summary-conversation-points-dark'] :styles['ch-summary-summary-conversation-points']}>
                <ul>
                        {conversation_points && conversation_points.length > 0
                        ? conversation_points?.filter((point: any) => (point.trim().length)).map((point: string, index: any) => (
                            <li className={darkMode ? styles['ch-summary-accordian-values-dark']: styles['ch-summary-accordian-values']} key={index}>
                                {point ?? '- No data -'}
                            </li>
                            ))
                        : '- No data -'}
                    </ul>
                </div>
            </div>
            <div className={styles['ch-accordian-summary-links-section']}>
                <div className={darkMode ? styles['ch-summary-main-issue-title-dark'] : styles['ch-summary-main-issue-title']}>{localization[language].LINKS_PROVIDED}</div>
                {links && links.length > 0
                ? links?.map((link: string, index: number) => {
                    const redirectLink = link.slice(0, 4).toLowerCase() === 'http' || link.slice(0, 2) === '//' ? link : `//${link}`;
                    return (
                        <Link UNSAFE_className={styles['ch-accordian-summary-links']} key={index}>
                        <a href={redirectLink} target="_blank" className={styles['ch-accordian-link-values']} rel="noreferrer">
                            {link}
                        </a>
                        </Link>
                    );
                    })
                : '- No data -'}
            </div>
        </div>
    );
}

export default ConversationSummary;