const localization = {
    en: {
        "ALL_CONVERSATIONS": {
            "ALL_CONVERSATIONS": "All Conversations",
            "PREVIOUS_CONVERSATIONS": "Previous Conversations",
            "VISITOR": "Visitor",
            "CONV_ID": "Conversation ID",
            "TRANSCRIPTS": "Transcripts",
            "SEARCH_TRANSCRIPTS": "Search Transcripts",
            "SEARCH_CONV_ID": "Search by Conversation ID",
            "ENTER_SEARCH_TERMS": "Enter search terms",
            "ENTER_CONV_ID": "Enter Conversation ID",
            "AGENT_SURVEY": "Agent Survey Attributes",
            "CSAT": "CSAT",
            "SELECT": "Select",
            "RESET": "Reset",
            "FILTER": "Filter",
            "CURRENT_FILTER": "Currently filtered by",
            "RESET_FILTERS": "Reset filters",
            "NOW": "Now",
            "TRANSFERRED": "Transferred",
            "AGENT": "Agent",
            "CUSTOMER": "Customer",
            "NO_DATA": "No Data Available",
            "STATUS": "STATUS",
            "CONSUMER_NAME": "CONSUMER NAME",
            "CHANNEL": "CHANNEL",
            "QUEUE": "QUEUE",
            "START_TIME": "START TIME",
            "LAST_MESSAGE_TIME": "END TIME",
            "DURATION": "DURATION",
            "FILTER_BY": "Filter By",
            "END_TIME": "End Time",
            "CONVERSATIONS": "conversations",
            "FIRST_PAGE": "First Page",
            "PREVIOUS_PAGE": "Previous Page",
            "NEXT_PAGE": "Next Page",
            "LAST_PAGE": "Last Page",
            "WIDGET_VIEW": "Widget View",
            "LIST_VIEW": "List View",
            "OF": "of",
            "IDLE": "Idle",
            "OVERDUE": "Overdue",
            "SYSTEM": "System",
            "NETWORK_DISCONNECT":"Network Disconnect",
            "PHONE_NUMBER": "PHONE NUMBER",
            "INITIATION_METHOD":"INITIATION METHOD",
            "DISCONNECT_REASON":"DISCONNECT REASON",
            "CONNECTED": "Connected",
            "NOT_CONNECTED": "Not Connected",
            "API": "API",
            "CALLBACK": "Callback",
            "DISCONNECT": "Disconnect",
            "EXTERNAL_OUTBOUND": "External Outbound",
            "INBOUND": "Inbound",
            "MONITOR": "Monitor",
            "OUTBOUND": "Outbound",
            "QUEUE_TRANSFER": "Queue transfer",
            "TRANSFER": "Transfer"
        }
    },
    ja: {
        "ALL_CONVERSATIONS": {
            "ALL_CONVERSATIONS": "すべての会話",
            "PREVIOUS_CONVERSATIONS": "以前の会話",
            "VISITOR": "訪問者",
            "CONV_ID": "会話ID",
            "TRANSCRIPTS": "トランスクリプト",
            "SEARCH_TRANSCRIPTS": "トランスクリプトの検索",
            "SEARCH_CONV_ID": "会話IDで検索",
            "ENTER_SEARCH_TERMS": "検索項目を入力",
            "ENTER_CONV_ID": "会話IDを入力",
            "AGENT_SURVEY": "エージェントサーベイ属性",
            "CSAT": "CSAT",
            "SELECT": "選択",
            "RESET": "リセット",
            "FILTER": "フィルター",
            "CURRENT_FILTER": "現在のフィルター条件",
            "RESET_FILTERS": "フィルターをリセット",
            "NOW": "今",
            "TRANSFERRED": "転送されました",
            "AGENT": "担当者",
            "CUSTOMER": "お客様",
            "NO_DATA": "使用可能なデータがありません",
            "STATUS": "ステータス",
            "CONSUMER_NAME": "お客様の名前",
            "CHANNEL": "チャネル",
            "QUEUE": "キュー",
            "START_TIME": "開始時間",
            "LAST_MESSAGE_TIME": "終了時間",
            "DURATION": "期間",
            "FILTER_BY": "フィルター条件",
            "END_TIME": "終了時間",
            "CONVERSATIONS": "会話",
            "FIRST_PAGE": "最初のページ",
            "PREVIOUS_PAGE": "前のページ",
            "NEXT_PAGE": "次のページ",
            "LAST_PAGE": "最後のページ",
            "WIDGET_VIEW": "ウィジェットビュー",
            "LIST_VIEW": "リストビュー",
            "OF": "/",
            "IDLE": "非アクティブ", 
            "OVERDUE": "期限切れ",
            "SYSTEM": "システム",
            "NETWORK_DISCONNECT":"ネットワーク切断",
            "PHONE_NUMBER": "電話番号",
            "INITIATION_METHOD":"初期化メソッド",
            "DISCONNECT_REASON":"切断の理由",
            "CONNECTED": "接続済み",
            "NOT_CONNECTED": "接続されていません",
            "API": "API",
            "CALLBACK": "コールバック",
            "DISCONNECT": "切断",
            "EXTERNAL_OUTBOUND": "外部アウトバウンド",
            "INBOUND": "受信",
            "MONITOR": "モニタリング",
            "OUTBOUND": "アウトバウンド",
            "QUEUE_TRANSFER": "キューの転送",
            "TRANSFER": "転送"
        }
    },
    fr: {
        "ALL_CONVERSATIONS": {
            "AGENT": "Agent",
            "AGENT_SURVEY": "Attributs du questionnaire de l’agent",
            "ALL_CONVERSATIONS": "Toutes les conversations",            
            "CHANNEL": "CANAL",
            "CONSUMER_NAME": "NOM DU CONSOMMATEUR",
            "CONV_ID": "Identifiant de conversation",
            "CONVERSATIONS": "conversations",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "Actuellement filtré par",
            "CUSTOMER": "Client",
            "DURATION": "DURÉE",
            "END_TIME": "Heure de fin",
            "LAST_MESSAGE_TIME": "Heure de fin",
            "ENTER_CONV_ID": "Saisir l’identifiant de la conversation",
            "ENTER_SEARCH_TERMS": "Saisir les termes de recherche",
            "FILTER": "Filtrer",
            "FILTER_BY": "Filtrer par",
            "FIRST_PAGE": "Première page",
            "LAST_PAGE": "Dernière page",
            "LIST_VIEW": "Répertorier l’affichage",            
            "NEXT_PAGE": "Page suivante",
            "NO_DATA": "Aucune donnée disponible",
            "NOW": "Maintenant",
            "OF": "de",
            "PREVIOUS_CONVERSATIONS": "Conversations précédentes",
            "PREVIOUS_PAGE": "Page précédente",
            "QUEUE": "FILE D’ATTENTE",
            "RESET": "Réinitialiser",            
            "RESET_FILTERS": "Réinitialiser les filtres",
            "SEARCH_CONV_ID": "Rechercher par ID de conversation",
            "SEARCH_TRANSCRIPTS": "Rechercher des transcriptions",
            "SELECT": "Sélectionner",
            "START_TIME": "HEURE DE DÉBUT",
            "STATUS": "STATUT",
            "TRANSCRIPTS": "Transcriptions",
            "TRANSFERRED": "Transféré",
            "VISITOR": "Visiteur",
            "WIDGET_VIEW": "Vue de widget",
            "IDLE": "Inactif",      
            "OVERDUE": "En retard",      
            "SYSTEM": "Système" ,
            "NETWORK_DISCONNECT":"Déconnexion du Réseau  ",
            "PHONE_NUMBER": "GÉRER LE DOSSIER",
            "INITIATION_METHOD":"MÉTHODE D'INITIATION",
            "DISCONNECT_REASON":"RAISON DE LA DÉCONNEXION",
            "CONNECTED": "Connecté",
            "NOT_CONNECTED": "Non connecté",
            "API": "API",
            "CALLBACK": "Rappel",
            "DISCONNECT": "Déconnecter",
            "EXTERNAL_OUTBOUND": "Sortie externe",
            "INBOUND": "Entrant",
            "MONITOR": "Surveiller",
            "OUTBOUND": "Sortant",
            "QUEUE_TRANSFER": "Transfert vers une file d'attente",
            "TRANSFER": "Transférer"      
        }
    },
    de: {
        "ALL_CONVERSATIONS": {
            "AGENT": "Agent",
            "AGENT_SURVEY": "Umfrageattribute des Agenten",
            "ALL_CONVERSATIONS": "Alle Unterhaltungen",            
            "CHANNEL": "KANAL",
            "CONSUMER_NAME": "NAME DES VERBRAUCHERS",
            "CONV_ID": "Unterhaltungs-ID",
            "CONVERSATIONS": "Unterhaltungen",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "Derzeit gefiltert nach",
            "CUSTOMER": "Kunden",
            "DURATION": "DAUER",
            "END_TIME": "Endzeit",
            "LAST_MESSAGE_TIME": "Endzeit",
            "ENTER_CONV_ID": "Unterhaltungs-ID eingeben",
            "ENTER_SEARCH_TERMS": "Suchbegriffe eingeben",
            "FILTER": "Filter",
            "FILTER_BY": "Filtern nach",
            "FIRST_PAGE": "Erste Seite",
            "LAST_PAGE": "Letzte Seite",
            "LIST_VIEW": "Listenansicht",            
            "NEXT_PAGE": "Nächste Seite",
            "NO_DATA": "Keine Daten verfügbar",
            "NOW": "Jetzt",
            "OF": "von",
            "PREVIOUS_CONVERSATIONS": "Frühere Unterhaltungen",
            "PREVIOUS_PAGE": "Vorherige Seite",
            "QUEUE": "WARTESCHLANGE",
            "RESET": "Zurücksetzen",            
            "RESET_FILTERS": "Filter zurücksetzen",
            "SEARCH_CONV_ID": "Suche nach Unterhaltungs-ID",
            "SEARCH_TRANSCRIPTS": "Transkripte suchen",
            "SELECT": "Auswählen",
            "START_TIME": "STARTZEIT",
            "STATUS": "STATUS", 
            "TRANSCRIPTS": "Transkripte",
            "TRANSFERRED": "Übertragen",
            "VISITOR": "Besucher",
            "WIDGET_VIEW": "Widget-Ansicht",
            "IDLE": "Inaktiv",      
            "OVERDUE": "Überfällig",      
            "SYSTEM": "System"  ,
            "NETWORK_DISCONNECT":"Netzwerkunterbrechung",
            "PHONE_NUMBER": "Fall verwalten",
            "INITIATION_METHOD":"Initiierungsmethode",
            "DISCONNECT_REASON":"Grund für die Trennung der Verbindung",
            "CONNECTED": "Verbunden",
            "NOT_CONNECTED": "Nicht verbunden",
            "API": "API",
            "CALLBACK": "Rückruf",
            "DISCONNECT": "Verbindung trennen",
            "EXTERNAL_OUTBOUND": "Extern ausgehend",
            "INBOUND": "Eingehend",
            "MONITOR": "Überwachen",
            "OUTBOUND": "Ausgehend",
            "QUEUE_TRANSFER": "An Warteschlange weiterleiten",
            "TRANSFER": "Weiterleiten"        
        }
    },
    es: {
        "ALL_CONVERSATIONS": {
            "AGENT": "Agente",
            "AGENT_SURVEY": "Atributo de encuesta del agente",
            "ALL_CONVERSATIONS": "Todas las conversaciones",            
            "CHANNEL": "CANAL",
            "CONSUMER_NAME": "NOMBRE DEL CONSUMIDOR",
            "CONV_ID": "ID de conversación",
            "CONVERSATIONS": "conversaciones",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "Actualmente filtrado por",
            "CUSTOMER": "Cliente",
            "DURATION": "DURACIÓN",
            "END_TIME": "Hora de finalización",
            "LAST_MESSAGE_TIME": "Hora de finalización",
            "ENTER_CONV_ID": "Introducir ID de conversación",
            "ENTER_SEARCH_TERMS": "Introducir términos de búsqueda",
            "FILTER": "Filtro",
            "FILTER_BY": "Filtrar por",
            "FIRST_PAGE": "Primera página",
            "LAST_PAGE": "Última página",
            "LIST_VIEW": "Vista de lista",            
            "NEXT_PAGE": "Página siguiente",
            "NO_DATA": "No hay datos disponibles",
            "NOW": "Ahora",
            "OF": "de",
            "PREVIOUS_CONVERSATIONS": "Conversaciones anteriores",
            "PREVIOUS_PAGE": "Página anterior",
            "QUEUE": "COLA",
            "RESET": "Restablecer",            
            "RESET_FILTERS": "Restablecer filtros",
            "SEARCH_CONV_ID": "Buscar por ID de conversación",
            "SEARCH_TRANSCRIPTS": "Buscar transcripciones",
            "SELECT": "Seleccionar",
            "START_TIME": "HORA DE INICIO",
            "STATUS": "ESTADO", 
            "TRANSCRIPTS": "Transcripciones",
            "TRANSFERRED": "Se ha transferido",
            "VISITOR": "Visitante",
            "WIDGET_VIEW": "Vista de widget",
            "IDLE": "Inactivo",      
            "OVERDUE": "Atrasado",      
            "SYSTEM": "Sistema" ,
            "NETWORK_DISCONNECT":"Desconexión de la red",
            "PHONE_NUMBER": "Administrar caso",
            "INITIATION_METHOD":"Método de iniciación",
            "DISCONNECT_REASON":"Motivo de la desconexión",
            "CONNECTED": "Conectado",
            "NOT_CONNECTED": "No conectado",
            "API": "API",
            "CALLBACK": "Devolución de llamada",
            "DISCONNECT": "Desconectar",
            "EXTERNAL_OUTBOUND": "Salida externa",
            "INBOUND": "Entrante",
            "MONITOR": "Monitorizar",
            "OUTBOUND": "Saliente",
            "QUEUE_TRANSFER": "Transferencia de cola",
            "TRANSFER": "Transferir"      
        }
    },
    it: {
        "ALL_CONVERSATIONS": {
            "AGENT": "Agente",
            "AGENT_SURVEY": "Attributi del sondaggio sugli agenti",
            "ALL_CONVERSATIONS": "Tutte le conversazioni",            
            "CHANNEL": "CANALE",
            "CONSUMER_NAME": "NOME DEL CONSUMATORE",
            "CONV_ID": "ID conversazione",
            "CONVERSATIONS": "conversazioni",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "Attualmente filtrate per",
            "CUSTOMER": "Cliente",
            "DURATION": "DURATA",
            "END_TIME": "Ora di fine",
            "LAST_MESSAGE_TIME": "Ora di fine",
            "ENTER_CONV_ID": "Immetti ID conversazione",
            "ENTER_SEARCH_TERMS": "Immetti criteri di ricerca",
            "FILTER": "Filtro",
            "FILTER_BY": "Filtra per",
            "FIRST_PAGE": "Prima pagina",
            "LAST_PAGE": "Ultima pagina",
            "LIST_VIEW": "Visualizzazione elenco",            
            "NEXT_PAGE": "Pagina successiva",
            "NO_DATA": "Nessun dato disponibile",
            "NOW": "Adesso",
            "OF": "di",
            "PREVIOUS_CONVERSATIONS": "Conversazioni precedenti",
            "PREVIOUS_PAGE": "Pagina precedente",
            "QUEUE": "CODA",
            "RESET": "Reimposta",            
            "RESET_FILTERS": "Reimposta filtri",
            "SEARCH_CONV_ID": "Cerca tramite ID conversazione",
            "SEARCH_TRANSCRIPTS": "Ricerca trascrizioni",
            "SELECT": "Seleziona",
            "START_TIME": "ORA DI INIZIO",
            "STATUS": "STATO", 
            "TRANSCRIPTS": "Trascrizioni",
            "TRANSFERRED": "Trasferito",
            "VISITOR": "Visitatore",
            "WIDGET_VIEW": "Visualizzazione widget",
            "IDLE": "Inattivo",      
            "OVERDUE": "In ritardo",      
            "SYSTEM": "Sistema"  ,
            "NETWORK_DISCONNECT":"Rete disconnessa",
            "PHONE_NUMBER": "Gestisci caso",
            "INITIATION_METHOD":"Metodo di avvio",
            "DISCONNECT_REASON":"Motivo della disconnessione",
            "CONNECTED": "Connesso",
            "NOT_CONNECTED": "Non connesso",
            "API": "API",
            "CALLBACK": "Richiama",
            "DISCONNECT": "Disconnetti",
            "EXTERNAL_OUTBOUND": "In uscita esterna",
            "INBOUND": "In entrata",
            "MONITOR": "Monitoraggio",
            "OUTBOUND": "In uscita",
            "QUEUE_TRANSFER": "Trasferimento coda",
            "TRANSFER": "Trasferisci"     
        }
    },
    'pt-BR': {
        "ALL_CONVERSATIONS": {
            "AGENT": "Agente",
            "AGENT_SURVEY": "Atributos de Pesquisa do Agente",
            "ALL_CONVERSATIONS": "Todas as Conversas",            
            "CHANNEL": "CANAL",
            "CONSUMER_NAME": "NOME DO CONSUMIDOR",
            "CONV_ID": "ID da Conversa",
            "CONVERSATIONS": "conversas",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "Atualmente filtrado por",
            "CUSTOMER": "Cliente",
            "DURATION": "DURAÇÃO",
            "END_TIME": "Hora do Fim",
            "LAST_MESSAGE_TIME": "Hora do Fim",
            "ENTER_CONV_ID": "Informar ID da Conversa",
            "ENTER_SEARCH_TERMS": "Informar critérios de busca",
            "FILTER": "Filtrar",
            "FILTER_BY": "Filtrar por",
            "FIRST_PAGE": "Primeira Página",
            "LAST_PAGE": "Última Página",
            "LIST_VIEW": "visualizar lista",            
            "NEXT_PAGE": "Próxima Página",
            "NO_DATA": "Dados não disponíveis",
            "NOW": "Agora",
            "OF": "de",
            "PREVIOUS_CONVERSATIONS": "Conversas Anteriores",
            "PREVIOUS_PAGE": "Página Anterior",
            "QUEUE": "FILA",
            "RESET": "Redefinir",            
            "RESET_FILTERS": "Redefinir filtros",
            "SEARCH_CONV_ID": "Pesquisar por ID de Conversa",
            "SEARCH_TRANSCRIPTS": "Pesquisar Transcrições",
            "SELECT": "Selecionar",
            "START_TIME": "HORA DE INÍCIO",
            "STATUS": "STATUS", 
            "TRANSCRIPTS": "Transcrições",
            "TRANSFERRED": "Transferido",
            "VISITOR": "Visitante",
            "WIDGET_VIEW": "Visualização de Dispositivo",
            "IDLE": "Inativo",      
            "OVERDUE": "Vencido",      
            "SYSTEM": "Sistema",
            "NETWORK_DISCONNECT":"Desconexão de Rede",
            "PHONE_NUMBER": "Gerenciar Caso",
            "INITIATION_METHOD":"Método de iniciação",
            "DISCONNECT_REASON":"Motivo da desconexão",
            "CONNECTED": "Conectado",
            "NOT_CONNECTED": "Não conectado",
            "API": "API",
            "CALLBACK": "Retorno de chamada",
            "DISCONNECT": "Desconectar",
            "EXTERNAL_OUTBOUND": "Saída externa",
            "INBOUND": "Entrada",
            "MONITOR": "Monitorar",
            "OUTBOUND": "Saída",
            "QUEUE_TRANSFER": "Transferência da fila",
            "TRANSFER": "Transferir"     
        }
    },
    ko: {
        "ALL_CONVERSATIONS": {
            "AGENT": "상담사",
            "AGENT_SURVEY": "상담사 설문 조사 속성",
            "ALL_CONVERSATIONS": "모든 대화",            
            "CHANNEL": "채널",
            "CONSUMER_NAME": "고객 이름",
            "CONV_ID": "대화 ID",
            "CONVERSATIONS": "대화",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "현재 필터링 기준:",
            "CUSTOMER": "고객",
            "DURATION": "기간",
            "END_TIME": "종료 시간",
            "LAST_MESSAGE_TIME": "종료 시간",
            "ENTER_CONV_ID": "대화 ID 입력",
            "ENTER_SEARCH_TERMS": "검색어 입력",
            "FILTER": "필터링",
            "FILTER_BY": "필터링 기준",
            "FIRST_PAGE": "첫 페이지",
            "LAST_PAGE": "마지막 페이지",
            "LIST_VIEW": "목록 보기",            
            "NEXT_PAGE": "다음 페이지",
            "NO_DATA": "사용 가능한 데이터 없음",
            "NOW": "지금",
            "OF": "/",
            "PREVIOUS_CONVERSATIONS": "이전 대화",
            "PREVIOUS_PAGE": "이전 페이지",
            "QUEUE": "대기열",
            "RESET": "재설정",            
            "RESET_FILTERS": "필터 재설정",
            "SEARCH_CONV_ID": "대화 ID로 검색",
            "SEARCH_TRANSCRIPTS": "기록 검색",
            "SELECT": "선택",
            "START_TIME": "시작 시간",
            "STATUS": "상태", 
            "TRANSCRIPTS": "기록",
            "TRANSFERRED": "전송됨",
            "VISITOR": "방문자",
            "WIDGET_VIEW": "위젯 보기",
            "IDLE": "유휴 상태",     
            "OVERDUE": "지연",
            "SYSTEM": "시스템",
            "NETWORK_DISCONNECT":"네트워크 연결 끊김",
            "PHONE_NUMBER": "사례 관리",
            "INITIATION_METHOD":"시작 방법",
            "DISCONNECT_REASON":"연결 해제 이유",
            "CONNECTED": "연결됨",
            "NOT_CONNECTED": "연결되지 않음",
            "API": "API",
            "CALLBACK": "콜백",
            "DISCONNECT": "연결 해제",
            "EXTERNAL_OUTBOUND": "외부 아웃바운드",
            "INBOUND": "인바운드",
            "MONITOR": "모니터링",
            "OUTBOUND": "아웃바운드",
            "QUEUE_TRANSFER": "대기열 전송",
            "TRANSFER": "전송"    
        }
    },
    'zh-TW': {
        "ALL_CONVERSATIONS": {
            "AGENT": "客服專員",
            "AGENT_SURVEY": "客服專員調查屬性",
            "ALL_CONVERSATIONS": "所有對話",            
            "CHANNEL": "通道",
            "CONSUMER_NAME": "消費者名稱",
            "CONV_ID": "對話 ID",
            "CONVERSATIONS": "對話",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "目前過濾條件：",
            "CUSTOMER": "客戶",
            "DURATION": "時長",
            "END_TIME": "結束時間",
            "LAST_MESSAGE_TIME": "結束時間",
            "ENTER_CONV_ID": "輸入對話 ID",
            "ENTER_SEARCH_TERMS": "輸入搜尋詞彙",
            "FILTER": "篩選",
            "FILTER_BY": "篩選條件",
            "FIRST_PAGE": "第一頁",
            "LAST_PAGE": "上一頁",
            "LIST_VIEW": "清單檢視",            
            "NEXT_PAGE": "下一頁",
            "NO_DATA": "無可用数据",
            "NOW": "現在",
            "OF": "/",
            "PREVIOUS_CONVERSATIONS": "上個對話",
            "PREVIOUS_PAGE": "上一頁",
            "QUEUE": "佇列",
            "RESET": "重設",            
            "RESET_FILTERS": "重設篩選器",
            "SEARCH_CONV_ID": "依對話 ID 搜尋",
            "SEARCH_TRANSCRIPTS": "搜尋文字記錄",
            "SELECT": "選取",
            "START_TIME": "開始時間",
            "STATUS": "狀態", 
            "TRANSCRIPTS": "文字記錄",
            "TRANSFERRED": "已轉移",
            "VISITOR": "訪客",
            "WIDGET_VIEW": "小工具檢視",
            "IDLE": "閒置",      
            "OVERDUE": "逾期",      
            "SYSTEM": "系統",
            "NETWORK_DISCONNECT":"網絡連接已斷開",
            "PHONE_NUMBER": "管理個案",
            "INITIATION_METHOD":"啟動方法",
            "DISCONNECT_REASON":"中斷連線原因",
            "CONNECTED": "已連線",
            "NOT_CONNECTED": "未連線",
            "API": "API",
            "CALLBACK": "回電",
            "DISCONNECT": "中斷連線",
            "EXTERNAL_OUTBOUND": "外部外撥",
            "INBOUND": "來電",
            "MONITOR": "監控",
            "OUTBOUND": "外撥",
            "QUEUE_TRANSFER": "佇列轉接",
            "TRANSFER": "轉接"     
        }
    },
    'zh-CN': {
        "ALL_CONVERSATIONS": {
            "AGENT": "客服专员",
            "AGENT_SURVEY": "客服专员调查属性",
            "ALL_CONVERSATIONS": "全部对话",            
            "CHANNEL": "渠道",
            "CONSUMER_NAME": "消费者姓名",
            "CONV_ID": "对话 ID",
            "CONVERSATIONS": "对话",
            "CSAT": "CSAT",
            "CURRENT_FILTER": "当前筛选条件",
            "CUSTOMER": "客户",
            "DURATION": "持续时间",
            "END_TIME": "结束时间",
            "LAST_MESSAGE_TIME": "结束时间",
            "ENTER_CONV_ID": "输入对话 ID",
            "ENTER_SEARCH_TERMS": "输入搜索词",
            "FILTER": "筛选",
            "FILTER_BY": "筛选条件",
            "FIRST_PAGE": "首页",
            "LAST_PAGE": "末页",
            "LIST_VIEW": "列表视图",            
            "NEXT_PAGE": "下一页",
            "NO_DATA": "无可用数据",
            "NOW": "现在",
            "OF": "/",
            "PREVIOUS_CONVERSATIONS": "先前对话",
            "PREVIOUS_PAGE": "上一页",
            "QUEUE": "队列",
            "RESET": "重置",            
            "RESET_FILTERS": "重置筛选器",
            "SEARCH_CONV_ID": "按对话 ID 搜索",
            "SEARCH_TRANSCRIPTS": "搜索聊天记录",
            "SELECT": "选择",
            "START_TIME": "开始时间",
            "STATUS": "状态", 
            "TRANSCRIPTS": "聊天记录",
            "TRANSFERRED": "已转接",
            "VISITOR": "访客",
            "WIDGET_VIEW": "小组件视图",
            "IDLE": "闲置",     
            "OVERDUE": "超时",     
            "SYSTEM": "系统",
            "NETWORK_DISCONNECT":"网络连接已断开",
            "PHONE_NUMBER": "管理案例",
            "INITIATION_METHOD":"启动方法",
            "DISCONNECT_REASON":"断开连接的原因",
            "CONNECTED": "已连接",
            "NOT_CONNECTED": "未连接",
            "API": "API",
            "CALLBACK": "回拨",
            "DISCONNECT": "断开连接",
            "EXTERNAL_OUTBOUND": "外部出站",
            "INBOUND": "入站",
            "MONITOR": "监控",
            "OUTBOUND": "出站",
            "QUEUE_TRANSFER": "队列转接",
            "TRANSFER": "转接"     
        }
    },
}
export default localization;