import axios from 'axios';
import { useEffect } from 'react';
import { translatePDC } from '../../util/pdc';
import { getAuthHeaders } from '../../util';
import languageMapper from '../languageMapper.json';

const Mousetrap = require("mousetrap");

const usePdcHotkeys = (queueName: string, language: string, conversationId: any, callbackMessage: any, sourceLang: string, targetLang: string, pdcUrl: string, translateAPI: string, geofluencyEnabled: boolean) => {

    const setHotkeysForPDC = (data: any) => {
        data.forEach((element: any) => {
            const { winHotKey = '', macHotKey = '', message: pdcMessage = '' } = element;
            Mousetrap.bind(winHotKey, () => { callbackMessage(pdcMessage) })
            Mousetrap.bind(macHotKey, () => { callbackMessage(pdcMessage) })
        });
    }

    const getPdcHotkeyData = async () => {
        try {
            const apiLanguage = languageMapper[language] ? languageMapper[language] : '';
            const res = await axios.get(`${pdcUrl}?language=${apiLanguage}&queueId=${queueName}`, {
                headers: getAuthHeaders() as any,
            });
            return res?.data?.json || [];
        } catch (error) {
            console.error("Error fetching PDC hotkey data:", error);
            return []; 
        }
    }


    useEffect(() => {
        const fetchPDCHotkeys = async () => {
            Mousetrap.reset();
            const cacheKey = `pdc-hotkeys-${queueName}`;
            const geoFluencyCacheKey = `geofluency-pdc-hotkeys-${queueName}-${language}`;
            const cachedData = sessionStorage.getItem(`pdc-hotkeys-${queueName}`)


            if (cachedData) {
                if (geofluencyEnabled) {
                    const geofluencyCachedData = sessionStorage.getItem(geoFluencyCacheKey);
                    if (geofluencyCachedData) {
                        setHotkeysForPDC(JSON.parse(geofluencyCachedData));
                    } else {
                        const pdcData = await getPdcHotkeyData();
                        const translatedGeoData = await translatePDC(translateAPI, pdcData, sourceLang, targetLang);
                        sessionStorage.setItem(geoFluencyCacheKey, JSON.stringify(translatedGeoData));
                        setHotkeysForPDC(translatedGeoData);
                    }
                } else {
                    setHotkeysForPDC(JSON.parse(cachedData));
                }
            }
            else {
                const pdcData = await getPdcHotkeyData();
                sessionStorage.setItem(cacheKey, JSON.stringify(pdcData));
                setHotkeysForPDC(pdcData);
            }
            
        }
        if (queueName) fetchPDCHotkeys();
    }, [conversationId, queueName, geofluencyEnabled, ]);
}

export default usePdcHotkeys;

