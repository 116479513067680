import axios from 'axios'
import { useState } from 'react'

/**
 * Hook to update the recent items in the db
 */
const useAddRecentItemsHook = (): {
  isLoading: boolean,
  submit: (
    AAURL: string,
    answer: string,
    assistId: string,
    description: string,
    ldap: string,
    sessionId: string,
    question: string,
    linkSources: Array<any>

  ) => Promise<{
    recentItems: Array<any>,
    queryAdded: any
  }>
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const submit = (
    AAURL: string,
    answer: string,
    assistId: string,
    description: string,
    ldap: string,
    sessionId: string,
    question: string,
    linkSources: Array<any>
  ): Promise<{
    recentItems: Array<any>,
    queryAdded: any
  }> => {
    setIsLoading(true)

    const queryPath = '/adobe-answers/recent-items/'
    return axios.post(
      `${AAURL}${queryPath}${ldap}`,
      { answer, assistId, description, sessionId, question , linkSources },
      {
        headers: {
          'x-api-key': 'oac-custom-panel',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
        }
      }
    )
      .then((response) => {
        const { recentItems = [] } = response?.data

        return {
          recentItems,
          queryAdded: recentItems[recentItems.length - 1] 
        }
      })
      .catch((err) => {
        console.log('Error adding recent Item: ', err?.message ?? 'Internal Error')
        throw err
      })
  }

  return {
    isLoading,
    submit
  }
}

export default useAddRecentItemsHook
