import { combineReducers } from 'redux'
import RUReducer from './ru-reducer'

const rootReducer = combineReducers({
  RUInfo: RUReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
