import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './reducers'

const middlewares = [thunk, logger]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

/*  Syntax:
    const store = createStore(
        () => {}, // reducers
        [] // middlewares thunk, loggers
    )
 */

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))

export default store