declare const window: any;

/**
 * 
 * @param conversationId 
 * @param value 
 * 
 * Case Management/ ECM Management /Sales CIP- id search
 */
export function updateCustomerSearchClick(conversationId: string, value: string) {
    let updatedItem = {};

    if(typeof window.updateCTRItem !== 'function') return;

    if (typeof window.getCTRItem === 'function') {
        const existingItem = window.getCTRItem(conversationId);

        if (existingItem?.customerSearchClick?.includes('Yes')) return;

        updatedItem = Object.assign({}, existingItem, { customerSearchClick: `${conversationId}: ${value}` });
    } else {
        updatedItem = { customerSearchClick: `${conversationId}: ${value}` };
    }

    return window.updateCTRItem(conversationId, updatedItem);
}
