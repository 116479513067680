import axios from 'axios'
import { useState } from 'react'

const useGetQueriesHook = (): {
  errors: string | undefined,
  getQueries: (
    AAUrl: string,
    ldap: string
  ) => Promise<{
    pinnedQueries: Array<any>,
    recentQueries: Array<any>
  }>,
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>();

  const getQueries = (
    AAUrl: string,
    ldap: string
  ): Promise<{
    pinnedQueries: Array<any>,
    recentQueries: Array<any>
  }> => {
    setIsLoading(true);
    const path = `/adobe-answers/recent-items/${ldap}`
    return axios.get(`${AAUrl}${path}`, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
      .then((response) => {
        console.log('Get agent queries response', response.data)
        setIsLoading(false)
        const recentQueries = response?.data?.recentItems
          ?.sort((a, b) => {
            if (a.timestamp > b.timestamp) return -1
            if (a.timestamp < b.timestamp) return 1
            return 0
          })
          .filter((query: any) => !!query.assistId && !!query.answer && !query.error)

        const pinnedQueries = response?.data?.pinnedItems
          ?.sort((a, b) => {
            if (a.timestamp > b.timestamp) return -1
            if (a.timestamp < b.timestamp) return 1
            return 0
          })
          .filter((query: any) => !!query.assistId && !!query.answer && !query.error)

        return {
          pinnedQueries,
          recentQueries
        }
      })
      .catch((err) => {
        console.log('Error fetching Adobe Answers queries: ', err?.message ?? 'Internal Error')
        setIsLoading(false)
        setErrors(`Error fetching Adobe Answers queries: ${err?.message ?? 'Internal Error'}`)
        return {
          pinnedQueries: [],
          recentQueries: []
        }
      })
  }

  return {
    errors,
    getQueries,
    isLoading
  }
}

export default useGetQueriesHook
