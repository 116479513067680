import axios from 'axios'
import { useState } from 'react'

/**
 * Hook for getting a sessionId from the LEO service
 */
const useGetLeoSessionHook = (): {
  getSession: (
    url: string,
    conversationId: string
  ) => Promise<string>,
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getSession = (
    AAUrl: string,
    conversationId: string
  ): Promise<string> => {
    setIsLoading(true)
    const sessionPath = '/adobe-answers/session'
    return axios.get(`${AAUrl}${sessionPath}`, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      },
      params: {
        conversationId
      }
    })
      .then((response) => {
        setIsLoading(false)
        const sessionId = response?.data?.sessionId
        if (!sessionId) {
          throw new Error('No sessionId returned.')
        }

        return sessionId
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('Error getting sessionId from Leo. ', err?.message ?? 'Internal Error')
        throw err
      })
  }

  return {
    getSession,
    isLoading
  }
}

export default useGetLeoSessionHook
