import axios from "axios"
import { useState } from 'react'
import { Query } from "../Components/AdobeAnswersFrame"

/**
 * Hook for submitting feedback to LEO
 */
const useSubmitLeoFeedbackHook = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const submit = (
    query: Query,
    conversationId: string,
    genAIURL: string,
    selectedFlags: any[],
    commentInputText: string,
    feedbackType: string
  ): Promise<any> => {
    setIsSubmitting(true)
    const updateLeoFeedbackPath = '/adobe-answers/feedback'

    const data = {
      assistId: query?.assistId,
      feedback: {
        options: selectedFlags,
        comment: commentInputText,
        expectedSourceUrls: [],
        type: feedbackType
      },
      conversationId: conversationId
    }
  
    return axios.put(`${genAIURL}${updateLeoFeedbackPath}`, data, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
    .catch((err) => {
      console.log('Error submitting LEO feedback: ', err?.message ?? 'Internal Error')
      throw err
    })
    .finally(() => {
      setIsSubmitting(false)
    })
  }

  return {
    isSubmitting,
    submit
  }
}

export default useSubmitLeoFeedbackHook
