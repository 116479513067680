import React, { useState, memo, useEffect } from 'react'
import {
    ActionButton,
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Heading,
    Form,
    TextArea,
} from '@adobe/react-spectrum';
import AddIcon from '@spectrum-icons/workflow/Add';
import { Picker, Item } from '@adobe/react-spectrum'
import { Tooltip, TooltipTrigger } from '@adobe/react-spectrum'
const ALLOWED_LENGTH_FOR_PDC_CUSTOM_PDC  = 20;
const CustomDialogContainer = ({ list, customPdcLength, apiCallbackHandler, localizeStrings }) => {
    const [category, setCategory] = useState('');
    const [message, setMessage] = useState('');
    const [validationPassed, updateValidation] = useState(false)
    const clearStates = () => {
        setCategory('');
        setMessage('');
        updateValidation(false);
    };
    useEffect(() => {
        updateValidation(category && message)
    }, [category, message]);
    return (
        customPdcLength >= ALLOWED_LENGTH_FOR_PDC_CUSTOM_PDC ?
            <TooltipTrigger delay={0}>
                <ActionButton UNSAFE_style={{ border: 'none', height: '12px', width: '12px', background: 'transparent', cursor: 'pointer' }} >
                    <AddIcon />
                </ActionButton>
                <Tooltip>{localizeStrings['maximum_custom']}</Tooltip>
            </TooltipTrigger>
            :
            <DialogTrigger placement='bottom'>
                <ActionButton UNSAFE_style={{ border: 'none', height: '12px', width: '12px', background: 'transparent', cursor: 'pointer' }} >
                    <AddIcon />
                </ActionButton>
                {
                    (close) => (
                        <Dialog placement='bottom'>
                            <Heading>{localizeStrings['add_custom_title']}</Heading>
                            <Divider />
                            <Content>
                                <Form width={'100%'}>
                                    <Picker label={localizeStrings['category_text']} placeholder='' onSelectionChange={setCategory}>
                                        {list.map(li => <Item key={li}>{li}</Item>)}
                                    </Picker>
                                    <TextArea onChange={setMessage} label={localizeStrings['content_text']} />
                                </Form>
                            </Content>
                            <ButtonGroup>
                                <Button variant="secondary" UNSAFE_style={{ cursor: 'pointer' }} onPress={() => { clearStates(); close() }}>{localizeStrings['cancel_text']}</Button>
                                <Button UNSAFE_style={validationPassed ? { background: '#0469E3', cursor: 'pointer', border: 'none', color: 'white' } : ''} variant="accent" isDisabled={!(validationPassed)} onPress={() => {
                                    apiCallbackHandler({ title: category, message })
                                    clearStates()
                                    close()
                                }}>{localizeStrings['add_text']}</Button>
                            </ButtonGroup>
                        </Dialog>
                    )
                }
            </DialogTrigger >

    )
}

const MemoizedCustomDialogContainer = memo(CustomDialogContainer)
export default MemoizedCustomDialogContainer