import axios from 'axios'
import store from '../store'
import {getAuthHeaders} from '../utils/getAuthHeaders';

let cancelToken: any = {}
let source: any = {}

export const createCancelToken = () => {
  console.log('ECM - Creating the Cancel Token')
  cancelToken = axios.CancelToken
  source = cancelToken.source()
}

export const deleteCancelToken = () => {
  console.log('ECM - Cancelling the API calls')
  source.cancel('ECM API calls cancelled on umnmount')
}

function getClient(cancelRequest?: boolean) {
  const data: any = store.getState()
  const baseURL = data.uiState.baseServiceUrl
  return axios.create({
    baseURL: baseURL,
    timeout: 90000,
    headers: getAuthHeaders(),
    ...(cancelRequest && { cancelToken: source.token })
  })
}

export default getClient
