import { ActionButton } from '@adobe/react-spectrum'
import React from 'react'
import { useSelector } from 'react-redux'

import localization from '../language/localization'
import { Refresh } from '../Theme/Icons/icons'
import darkTheme from '../Theme/Styles/main/dark.css'
import lightTheme from '../Theme/Styles/main/light.css'

const CaseHeader = (props: any) => {
  const darkMode = useSelector((state: any) => state.uiState.darkMode)
  const language = useSelector((state: any) => state.uiState.language)
  const localizeString = localization[language]
  const styles = darkMode ? darkTheme : lightTheme
  return (
    <div className={styles['ecm-case-header']}>
      <div className={styles['ecm-header']}>
        <div className={styles['ecm-widget-header']}>
          <div className={styles['widget-icon']}>
            {localizeString.ecm_icon_button}
          </div>
          <span>{localizeString.ecm_title}</span>
        </div>
        <ActionButton
          UNSAFE_className={styles['refresh-button']}
          isQuiet
          onPress={() => props.handleRefresh()}
        >
          <Refresh />
        </ActionButton>
      </div>
    </div>
  )
}

export default CaseHeader
