/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

.no-results {
  display: block;
  /*
    Renamed from padding-y to padding-height to fix docs issue where fallback var replaced this value
    (due to old spectrum-css postcss-custom-properties-custom-mapping plugin).
  */
  padding-top: var(--spectrum-selectlist-option-padding-height);
  padding-inline-start: var(--spectrum-selectlist-option-padding);
  font-size: var(--spectrum-selectlist-option-text-size);
  font-weight: var(--spectrum-selectlist-option-text-font-weight);
  font-style: italic;
}

.mobile-combobox {
  outline: none;
}

.mobile-input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-value {
  vertical-align: middle;
}

.tray-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: none;
}

.tray-textfield {
  margin: var(--spectrum-global-dimension-size-150);
  margin-bottom: var(--spectrum-global-dimension-size-50);
  flex-shrink: 0;
  width: initial !important;

  &.has-label {
    margin-top: var(--spectrum-global-dimension-size-50);
  }

  .tray-textfield-input {
    padding-inline-start: var(--spectrum-textfield-padding-x);
  }
}

.tray-listbox {
  width: 100%;
  flex: 1;
}
