import axios from 'axios';
import { apiClient } from './axiosSettings'
import getAuthHeaders from  './getAuthHeaders'

export async function getTranslationData(reqData: any) {
  return apiClient.post(`${reqData.endpoint}/api/translate/chat`, reqData.body, { headers: getAuthHeaders() });
}

export const getTranslationForPDC = (translateURL: string, source: string = 'en', target: string = 'es', texts: Array<any>) => {
  return axios.post(translateURL,
    {
      "sourceLanguage": source, "targetLanguage": target, "texts": texts
    },
    {
      headers: getAuthHeaders(),
    }
  )
}