import React, { useState } from 'react'
import styles from '../Themes/Styles/styles.module.css'
import { Pin } from '../Themes/Icons'
import { Link, Tooltip, TooltipTrigger } from '@adobe/react-spectrum'
import { Query } from './AdobeAnswersFrame'
import themes from '../Themes/Themes'
import AAQueryButtons from './AAQueryButtons'

interface Props {
  deleteQuery: (
    assistId: string,
    queryId: string,
    isPinnedQuery: boolean
  ) => void,
  pinUnpinQuery: (
    assistId: string,
    queryId: string,
    isPinned: boolean
  ) => void,
  query: Query
  switchQuery: ((
    assistId: string,
    id: string
  ) => void) | null
  current: boolean
  actionsDisabled: boolean
  numberOfPinnedQueries: number
  language: string
  pinned: boolean
  darkMode: boolean
}

const AACard = (props: Props) => {
  const {
    query: { description = '', timestamp = '', id = '', assistId = '', answers, pinOperation = undefined } = {},
    current = false,
    deleteQuery,
    pinUnpinQuery,
    switchQuery,
    actionsDisabled,
    numberOfPinnedQueries,
    pinned,
    darkMode,
    language
  } = props
  // I see the query object contains answer and sometimes answers as an array
  const assistIdForAnalytics = (Array.isArray(answers) && typeof answers[0] === 'object') ? answers[0].assistId : assistId;
  const [showButtons, setShowButtons] = useState<boolean>(false)

  const { darkModeAIMessageContent, darkModeTitle } = themes(darkMode)
  const date = new Date(timestamp)
  const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() % 2000}`
  
  return (
    <div
      className={`${styles['aa_card']} ${current && styles['aa_card_current']}`}
      onClick={() => switchQuery?.(assistIdForAnalytics, id)}
      onMouseOver={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <div className={`${styles['aa_card_content']} ${showButtons && styles['aa_card_content_hover']}`}>
        <div className={`${styles['aa_card_description']} ${darkModeAIMessageContent}`}>
        <TooltipTrigger delay={0}>
            <Link 
              UNSAFE_style={{ marginTop: '10px', width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
              UNSAFE_className={`${styles['aa_card_data_link']} ${darkModeTitle}`}>
                {description}
            </Link>
            <Tooltip>{description}</Tooltip>
          </TooltipTrigger>
          {pinned && <Pin className={'aa_card_description_pin'} />}
        </div>
        <div className={`${styles['aa_card_data']} ${darkModeAIMessageContent}`}>{dateString}</div>
      </div>
      {showButtons && (
        <AAQueryButtons
          assistIdForAnalytics={assistIdForAnalytics}
          id={id}
          deleteQuery={deleteQuery}
          pinUnpinQuery={pinUnpinQuery}
          actionsDisabled={actionsDisabled}
          numberOfPinnedQueries={numberOfPinnedQueries}
          pinned={pinned}
          language={language}
          pinOperation={pinOperation}
        />
      )}
    </div>
  )
}
export default AACard
