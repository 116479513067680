import getClient from '../utils/client';

export default async function searchCustomer(
  conversationId: string,
  email: string,
  phone: string,
  caseId: string
): Promise<any> {
  return getClient(true)
    .post(`/api/v1/customer/search`, {
      conversationId,
      email,
      phone,
      caseId
    })
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, searchCustomerResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, searchCustomerResponse : Exception Occured', e);
      return {};
    });
}
