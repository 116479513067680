import React from "react";

export const ActiveConvIcon = (props: any) => (
    <svg id="icon_-_ongoing_conversations_filter" data-name="icon - ongoing conversations filter" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.className}>
        <g id="Smock_Chat_18_N">
            <rect id="Canvas" width="18" height="18" opacity="0" className={props.className} />
            <path id="Path_104" data-name="Path 104" d="M9.5,7a.5.5,0,0,1,.5.5v6a.5.5,0,0,1-.5.5H4.793a.5.5,0,0,0-.353.146L3,15.585V14.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5v-6A.5.5,0,0,1,1.5,7Zm-8-1A1.5,1.5,0,0,0,0,7.5v6A1.5,1.5,0,0,0,1.5,15H2v2.4a.25.25,0,0,0,.427.177L5,15H9.5A1.5,1.5,0,0,0,11,13.5v-6A1.5,1.5,0,0,0,9.5,6Z" className={props.className} />
            <path id="Path_105" data-name="Path 105" d="M12,7.3A2.3,2.3,0,0,0,9.7,5H6V2.5A1.5,1.5,0,0,1,7.5,1h9A1.5,1.5,0,0,1,18,2.5v6A1.5,1.5,0,0,1,16.5,10H15v2.4a.25.25,0,0,1-.427.177L12,10Z" className={props.className} />
        </g>
    </svg>
);

export const ClosedConvIcon = (props: any) => (
    <svg className={props.className} id="icon_-_conversation_closed" data-name="icon - conversation closed" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="Group_161501" data-name="Group 161501" transform="translate(12014 16831)">
            <rect id="Rectangle_150784" data-name="Rectangle 150784" width="18" height="18" transform="translate(-12014 -16831)" fill="rgba(255,0,0,0)" />
            <g id="Group_77" data-name="Group 77" transform="translate(-12939.998 -17358.633)">
                <g id="Group_161559" data-name="Group 161559" transform="translate(932.42 534.055)">
                    <line id="Line_293" data-name="Line 293" y2="7.293" transform="translate(5.157) rotate(45)" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="1.4" />
                    <line id="Line_294" data-name="Line 294" x2="7.293" transform="translate(0) rotate(45)" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="1.4" />
                </g>
            </g>
            <path id="noun-success-4213622" d="M77,0a7,7,0,1,1-7,7A7,7,0,0,1,77,0Zm0,1.4A5.6,5.6,0,1,0,82.6,7,5.6,5.6,0,0,0,77,1.4Z" transform="translate(857.998 529.633)" fill={props.color} />
            <path id="noun-success-4213622" d="M77,0a7,7,0,1,1-7,7A7,7,0,0,1,77,0Zm0,1.4A5.6,5.6,0,1,0,82.6,7,5.6,5.6,0,0,0,77,1.4Z" transform="translate(-12082 -16829)" fill={props.color} />
        </g>
    </svg>

);


export const CallIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.995" height="13.995" viewBox="0 0 13.995 13.995" className={props.className}>
        <path id="Icon_metro-phone" className={props.className} data-name="Icon metro-phone" d="M12.1,11.653c-.866.866-.866,1.733-1.733,1.733s-1.733-.866-2.6-1.733-1.733-1.733-1.733-2.6S6.9,8.188,7.769,7.321,6.036,3.856,5.17,3.856s-2.6,2.6-2.6,2.6c0,1.733,1.78,5.245,3.465,6.931s5.2,3.465,6.931,3.465c0,0,2.6-1.733,2.6-2.6s-2.6-3.465-3.465-2.6Z" transform="translate(-2.071 -3.356)" fill="none" stroke="#4b4b4b" strokeWidth="1"/>
    </svg>
)

export const AllConversationsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <defs>
            <style>{`.all-con-mainicon-a,.all-con-mainicon-c,.all-con-mainicon-d{fill:#fff;}.all-con-mainicon-a{stroke:#fff;}.all-con-mainicon-b{fill:rgba(255,255,255,0);}.all-con-mainicon-c{opacity:0;}.all-con-mainicon-e{clip-path:url(#all-con-mainicon-a)}.all-con-mainicon-f{fill:#1473e6;};}`}</style>
            <clipPath id="all-con-mainicon-a"><path className="a" d="M9.5,9V2H0V0H12V9Z" transform="translate(-14232 -16931)" /></clipPath>
        </defs>
        <circle className="all-con-mainicon-f" cx="15" cy="15" r="15" />
        <rect className="all-con-mainicon-c" width="18" height="18" transform="translate(6 6)" />
        <path className="all-con-mainicon-d" d="M9.457,7a.5.5,0,0,1,.5.5v5.973a.5.5,0,0,1-.5.5H4.771a.5.5,0,0,0-.352.146L2.986,15.542V14.462a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5V7.493a.5.5,0,0,1,.5-.5ZM1.493,6A1.493,1.493,0,0,0,0,7.493v5.973a1.493,1.493,0,0,0,1.493,1.493h.5v2.386a.249.249,0,0,0,.425.176l2.561-2.561h4.48a1.493,1.493,0,0,0,1.493-1.493V7.493A1.493,1.493,0,0,0,9.457,6Z" transform="translate(6.524 6.215)" />
        <g className="all-con-mainicon-e" transform="translate(14241 16940.191)">
            <path className="all-con-mainicon-d" d="M9.457,7a.5.5,0,0,1,.5.5v5.973a.5.5,0,0,1-.5.5H4.771a.5.5,0,0,0-.352.146L2.986,15.542V14.462a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5V7.493a.5.5,0,0,1,.5-.5ZM1.493,6A1.493,1.493,0,0,0,0,7.493v5.973a1.493,1.493,0,0,0,1.493,1.493h.5v2.386a.249.249,0,0,0,.425.176l2.561-2.561h4.48a1.493,1.493,0,0,0,1.493-1.493V7.493A1.493,1.493,0,0,0,9.457,6Z" transform="translate(-14231.476 -16936.975)" />
        </g>
        <g className="all-con-mainicon-e" transform="translate(14244 16937.191)">
            <path className="all-con-mainicon-d" d="M9.457,7a.5.5,0,0,1,.5.5v5.973a.5.5,0,0,1-.5.5H4.771a.5.5,0,0,0-.352.146L2.986,15.542V14.462a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5V7.493a.5.5,0,0,1,.5-.5ZM1.493,6A1.493,1.493,0,0,0,0,7.493v5.973a1.493,1.493,0,0,0,1.493,1.493h.5v2.386a.249.249,0,0,0,.425.176l2.561-2.561h4.48a1.493,1.493,0,0,0,1.493-1.493V7.493A1.493,1.493,0,0,0,9.457,6Z" transform="translate(-14231.476 -16936.975)" />
        </g>
    </svg>
);

export const FilterIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18" className={props.className}>
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
        <path className={props.clasName} d="M15.473,1H1.527a.5.5,0,0,0-.3935.8085L7,9.2945V16.95a.496.496,0,0,0,.84.412l1.9905-2.0765A.60949.60949,0,0,0,10,14.864V9.2945l5.8665-7.486A.5.5,0,0,0,15.473,1Z" />
    </svg>
)

export const ChevronDownIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18" className={props.className}>
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
        <path className={props.className} d="M4,7.01a1,1,0,0,1,1.7055-.7055l3.289,3.286,3.289-3.286a1,1,0,0,1,1.437,1.3865l-.0245.0245L9.7,11.7075a1,1,0,0,1-1.4125,0L4.293,7.716A.9945.9945,0,0,1,4,7.01Z" />
    </svg>
)

export const ConversationViewIcon = () => (
    <svg id="button_-_conversation_view" data-name="button - conversation view" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Ellipse_9716" data-name="Ellipse 9716" fill="none" stroke="#6e6e6e" strokeWidth="2">
            <circle cx="15" cy="15" r="15" stroke="none" />
            <circle cx="15" cy="15" r="14" fill="none" />
        </g>
        <rect id="Rectangle_150900" data-name="Rectangle 150900" width="5" height="11" transform="translate(12 9)" fill="#6e6e6e" />
        <rect id="Rectangle_150905" data-name="Rectangle 150905" width="4" height="2" transform="translate(7 9)" fill="#6e6e6e" />
        <rect id="Rectangle_150906" data-name="Rectangle 150906" width="4" height="2" transform="translate(7 12)" fill="#6e6e6e" />
        <rect id="Rectangle_150907" data-name="Rectangle 150907" width="4" height="2" transform="translate(7 15)" fill="#6e6e6e" />
        <rect id="Rectangle_150908" data-name="Rectangle 150908" width="4" height="2" transform="translate(7 18)" fill="#6e6e6e" />
        <rect id="Rectangle_150904" data-name="Rectangle 150904" width="5" height="11" transform="translate(18 9)" fill="#6e6e6e" />
    </svg>
)

export const ListViewIcon = () => (
    <svg id="button_-_list_view" data-name="button - list view" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Ellipse_9714" data-name="Ellipse 9714" fill="none" stroke="#6e6e6e" strokeWidth="2">
            <circle cx="15" cy="15" r="15" stroke="none" />
            <circle cx="15" cy="15" r="14" fill="none" />
        </g>
        <rect id="Rectangle_150897" data-name="Rectangle 150897" width="14" height="2" transform="translate(8 10)" fill="#707070" />
        <rect id="Rectangle_150898" data-name="Rectangle 150898" width="14" height="2" transform="translate(8 14)" fill="#707070" />
        <rect id="Rectangle_150899" data-name="Rectangle 150899" width="14" height="2" transform="translate(8 18)" fill="#707070" />
    </svg>
)

export const FirstPageIcon = () => (
    <svg id="button_-_pagination_first" data-name="button - pagination first" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Ellipse_34" data-name="Ellipse 34" fill="none" stroke="#6e6e6e" strokeWidth="2">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
        </g>
        <path id="Path_34" data-name="Path 34" d="M22.845,21.271l-4.232-4.232,4.232-4.232L21.539,11.5,16,17.039l5.539,5.539Z" transform="translate(-11.615 -4.961)" fill="#6e6e6e" />
        <path id="Path_35" data-name="Path 35" d="M22.845,21.271l-4.232-4.232,4.232-4.232L21.539,11.5,16,17.039l5.539,5.539Z" transform="translate(-4.77 -4.961)" fill="#6e6e6e" />
    </svg>
)

export const PrevPageIcon = () => (
    <svg id="button_-_pagination_prev" data-name="button - pagination prev" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Ellipse_35" data-name="Ellipse 35" fill="none" stroke="#6e6e6e" strokeWidth="2">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
        </g>
        <path id="Path_36" data-name="Path 36" d="M22.845,21.271l-4.232-4.232,4.232-4.232L21.539,11.5,16,17.039l5.539,5.539Z" transform="translate(-7.422 -4.961)" fill="#6e6e6e" />
    </svg>
)

export const NextPageIcon = () => (
    <svg id="button_-_pagination_next" data-name="button - pagination next" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Ellipse_37" data-name="Ellipse 37" fill="none" stroke="#6e6e6e" strokeWidth="2">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
        </g>
        <path id="Path_37" data-name="Path 37" d="M22.845,21.271l-4.232-4.232,4.232-4.232L21.539,11.5,16,17.039l5.539,5.539Z" transform="translate(31.23 29.115) rotate(180)" fill="#6e6e6e" />
    </svg>
)

export const LastPageIcon = () => (
    <svg id="button_-_pagination_last" data-name="button - pagination last" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Ellipse_36" data-name="Ellipse 36" fill="none" stroke="#6e6e6e" strokeWidth="2">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
        </g>
        <path id="Path_39" data-name="Path 39" d="M22.845,21.271l-4.232-4.232,4.232-4.232L21.539,11.5,16,17.039l5.539,5.539Z" transform="translate(35.422 29.115) rotate(180)" fill="#6e6e6e" />
        <path id="Path_38" data-name="Path 38" d="M22.845,21.271l-4.232-4.232,4.232-4.232L21.539,11.5,16,17.039l5.539,5.539Z" transform="translate(28.578 29.115) rotate(180)" fill="#6e6e6e" />
    </svg>
)

export const FirstTimeCustomerIcon = (props: any) => (
    <svg id="icon_-_first_time_customer" data-name="icon - first time customer" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.className}>
        <rect id="Rectangle_150931" data-name="Rectangle 150931" width="18" height="18" fill="rgba(255,255,255,0)" />
        <g id="noun-award-954130" transform="translate(-5.574 -5.863)">
            <g id="Group_161737" data-name="Group 161737" transform="translate(6.575 7)">
                <path id="Path_84285" data-name="Path 84285" d="M14.6,959.362a5.526,5.526,0,1,0,5.526,5.526A5.526,5.526,0,0,0,14.6,959.362Zm0,2.317.991,1.994L17.81,964l-1.6,1.549.379,2.195L14.6,966.7l-1.983,1.036L13,965.545,11.393,964l2.217-.323Zm-5.052,7.153-2.974,4.294,2.674-.535.713,2.5,2.657-4.15A5.884,5.884,0,0,1,9.549,968.832Zm10.088,0a5.884,5.884,0,0,1-3.069,2.105l2.657,4.15.713-2.5,2.674.535Z" transform="translate(-6.575 -959.362)" className={props.className} />
            </g>
        </g>
    </svg>
)

export const OverDueIcon = (props: any) => (
    <svg id="icon_-_overdue_filter" data-name="icon - overdue filter" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.className}>
        <g id="Group_161499" data-name="Group 161499" transform="translate(12064 16831)">
            <rect id="Rectangle_150782" data-name="Rectangle 150782" width="18" height="18" transform="translate(-12064 -16831)" fill="rgba(255,255,255,0)" />
            <path id="Path_84172" data-name="Path 84172" d="M933.945,543.646V543.3l1.595-1.6v-4.119a4.55,4.55,0,0,1,3.352-4.732l.236-.063V532a.878.878,0,0,1,1.755-.059c0,.02,0,.04,0,.059v.787l.236.063a4.539,4.539,0,0,1,3.353,4.709V541.7l1.6,1.6v.347ZM940,533.679c-.094,0-.187.007-.281.016a3.593,3.593,0,0,0-3.22,3.922v4.076h7.016v-4.1c.007-.063.014-.155.016-.248a3.592,3.592,0,0,0-3.518-3.659H940Zm.054,12.421a1.5,1.5,0,0,0,1.5-1.5h-3A1.5,1.5,0,0,0,940.054,546.1Z" transform="translate(-12995.006 -17360.689)" className={props.className} />
        </g>
    </svg>
)

export const CalenderIcon = (props: any) => (
    <svg id="icon_-_calendar" data-name="icon - calendar" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.className}>
        <g id="Group_12" data-name="Group 12">
            <g id="Group_11" data-name="Group 11">
                <path id="Path_20" data-name="Path 20" d="M22.442,7.385H21.231V6.692a.692.692,0,1,0-1.385,0v.692H10.154V6.692a.692.692,0,0,0-1.385,0v.692H7.558A1.56,1.56,0,0,0,6,8.942v13.5A1.56,1.56,0,0,0,7.558,24H22.442A1.56,1.56,0,0,0,24,22.442V8.942A1.56,1.56,0,0,0,22.442,7.385ZM7.558,8.769H8.769v.692a.692.692,0,0,0,1.385,0V8.769h9.692v.692a.692.692,0,1,0,1.385,0V8.769h1.212a.173.173,0,0,1,.173.173v1.9H7.385v-1.9A.173.173,0,0,1,7.558,8.769ZM22.442,22.615H7.558a.173.173,0,0,1-.173-.173V12.231H22.615V22.442A.174.174,0,0,1,22.442,22.615Z" transform="translate(-6 -6)" className={props.className} />
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="0.692" cy="0.692" r="0.692" transform="translate(5.538 7.615)" className={props.className} />
                <circle id="Ellipse_11" data-name="Ellipse 11" cx="0.692" cy="0.692" r="0.692" transform="translate(8.308 7.615)" className={props.className} />
                <circle id="Ellipse_12" data-name="Ellipse 12" cx="0.692" cy="0.692" r="0.692" transform="translate(11.077 7.615)" className={props.className} />
                <circle id="Ellipse_13" data-name="Ellipse 13" cx="0.692" cy="0.692" r="0.692" transform="translate(13.846 7.615)" className={props.className} />
                <circle id="Ellipse_14" data-name="Ellipse 14" cx="0.692" cy="0.692" r="0.692" transform="translate(2.769 10.385)" className={props.className} />
                <circle id="Ellipse_15" data-name="Ellipse 15" cx="0.692" cy="0.692" r="0.692" transform="translate(5.538 10.385)" className={props.className} />
                <circle id="Ellipse_16" data-name="Ellipse 16" cx="0.692" cy="0.692" r="0.692" transform="translate(8.308 10.385)" className={props.className} />
                <circle id="Ellipse_17" data-name="Ellipse 17" cx="0.692" cy="0.692" r="0.692" transform="translate(11.077 10.385)" className={props.className} />
                <circle id="Ellipse_18" data-name="Ellipse 18" cx="0.692" cy="0.692" r="0.692" transform="translate(13.846 10.385)" className={props.className} />
                <circle id="Ellipse_19" data-name="Ellipse 19" cx="0.692" cy="0.692" r="0.692" transform="translate(2.769 13.154)" className={props.className} />
                <circle id="Ellipse_20" data-name="Ellipse 20" cx="0.692" cy="0.692" r="0.692" transform="translate(5.538 13.154)" className={props.className} />
                <circle id="Ellipse_21" data-name="Ellipse 21" cx="0.692" cy="0.692" r="0.692" transform="translate(8.308 13.154)" className={props.className} />
            </g>
        </g>
    </svg>
)

export const ChatIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.705" viewBox="0 0 12 12.705" className={props.className}>
  <path id="Path_84541" className={props.className} data-name="Path 84541" d="M10.364,7.091a.545.545,0,0,1,.545.545v6.545a.545.545,0,0,1-.545.545H5.229a.545.545,0,0,0-.386.16l-1.57,1.57V15.273a.545.545,0,0,0-.545-.545H1.636a.545.545,0,0,1-.545-.545V7.636a.545.545,0,0,1,.545-.545ZM1.636,6A1.636,1.636,0,0,0,0,7.636v6.545a1.636,1.636,0,0,0,1.636,1.636h.545v2.614a.273.273,0,0,0,.466.193l2.807-2.807h4.909A1.636,1.636,0,0,0,12,14.182V7.636A1.636,1.636,0,0,0,10.364,6Z" transform="translate(0 -6)" fill="#4b4b4b"/>
</svg>
)