import React, { useEffect } from "react";
import styles from "../Theme/Styles/transferCallPopup.styles.css"
import { Content, Dialog } from "@adobe/react-spectrum"
import TransferCall from "./TransferCall";

const TransferCallModal = (props: any) => {
    const { lang, endpoint, headers, transferFun, transferQueueData, darkMode, currentConversation, rtq, close, closePopup, saveTransferSelections, callBackEvent,
        quickConnectConfiguration, allActiveTransferData, routingProfileQueues } = props;
    const { ocPlatformData: { chatInfo: { currentContactId = '' } = {} } = {} } = currentConversation || {};

    useEffect(() => {
        const cleanUp = () => {
            closePopup(close, false);
        }
        return cleanUp
    }, [currentContactId]);

    return (
        <Dialog width={405} height={520} left={10} UNSAFE_className={`${styles['transfer-call-popover']} ${darkMode ? styles['tcp-dark-background'] : 'tcp-light-background'}`}>
            <Content UNSAFE_className={styles['toolbar-tranfer-content']}>
                <TransferCall
                    darkMode={darkMode}
                    lang={lang}
                    endpoint={endpoint}
                    headers={headers}
                    transferFun={transferFun}
                    transferQueueData={transferQueueData}
                    currentConversation={currentConversation}
                    rtq={rtq}
                    closePopup={closePopup}
                    close={close}
                    saveTransferSelections={saveTransferSelections}
                    isDialpadTransfer=''
                    callBackEvent={callBackEvent}
                    quickConnectConfiguration={quickConnectConfiguration}
                    allActiveTransferData={allActiveTransferData}
                    routingProfileQueues={routingProfileQueues}
                />
            </Content>
        </Dialog>
    )
}

export default TransferCallModal;
