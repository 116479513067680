import React from 'react';

function SupportPlan() {
  return (
    <svg id="icon_-_first_time_customer" data-name="icon - first time customer" xmlns="http://www.w3.org/2000/svg" width="11.96" height="11.73" viewBox="0 0 18 18">
      <rect id="Rectangle_150931" data-name="Rectangle 150931" width="18" height="18" fill="rgba(255,255,255,0)" />
      <g id="noun-award-954130" transform="translate(-5.574 -5.863)">
        <g id="Group_161737" data-name="Group 161737" transform="translate(6.575 7)">
          <path
            id="Path_84285"
            data-name="Path 84285"
            d="M14.6,959.362a5.526,5.526,0,1,0,5.526,5.526A5.526,5.526,0,0,0,14.6,959.362Zm0,2.317.991,1.994L17.81,964l-1.6,1.549.379,2.195L14.6,966.7l-1.983,1.036L13,965.545,11.393,964l2.217-.323Zm-5.052,7.153-2.974,4.294,2.674-.535.713,2.5,2.657-4.15A5.884,5.884,0,0,1,9.549,968.832Zm10.088,0a5.884,5.884,0,0,1-3.069,2.105l2.657,4.15.713-2.5,2.674.535Z"
            transform="translate(-6.575 -959.362)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
}

export default SupportPlan;
