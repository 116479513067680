function getAuthHeaders() {
    if (localStorage.getItem('customPanelAccessToken')) {
        return {
            'x-api-key': 'oac-custom-panel',
            Authorization: JSON.parse(localStorage.getItem('customPanelAccessToken') || ''),
        };
    }
    return;
}
function getAuthHeadersForConsoleService() {
    if (localStorage.getItem('ims-token')) {
        return {
            'x-api-key': 'oac-connect-service',
            Authorization: JSON.parse(localStorage.getItem('ims-token') || ''),
        };
    }
    return;
}
export { getAuthHeaders, getAuthHeadersForConsoleService };
