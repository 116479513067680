import axios, { AxiosResponse } from 'axios'
import { UPDATE_CONSENT_STATUS, REJECT_REASON, UPDATE_CONV_DATA, SET_LOADING_STATUS, SET_REJECTED_REASONS, UPDATE_OFFER_STATUS, SET_MULTIPARTY_ASSIGNED_AGENT, SET_FETCH_OFFERS_ERROR } from '../constants/ru-constants'

export const setConsentStatus = (data: any) => {
  return async (dispatch) => {
    const { convId, customerId, consentStatus, url } = data;

    try {
      if (!customerId.match(/@/gi)) return;

      dispatch({ type: UPDATE_CONSENT_STATUS, payload: { convId, consentStatus, customerId } })
      
      const updateParams = {
        consentStatus,
        contactId: convId
      }

      await axios.put(`${url}/api/v1/offers/pores/${customerId}`, { updateParams }, {
        headers: {
          'x-api-key': 'oac-custom-panel',
          Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
        }
      });

    } catch (error) {
      console.log('Failed to update consent status in db: ', error)
    }
  }
}

export const setRejectReason = (rejectReason: string) => (dispatch: any) => {
  try {
    dispatch({ type: REJECT_REASON, payload: rejectReason })
  } catch (error) { }
}

export const fetchCustomerOffers = (convId, customerId, url, queueName) => {
  return async (dispatch) => {
    if(!customerId.match(/@/gi)){
      dispatch({ type: UPDATE_CONV_DATA, payload: { convId, customerId, data: {} } })
      return
    }
    dispatch({ type: SET_LOADING_STATUS, payload: { convId, loading: true, customerId } });
    dispatch({ type: SET_FETCH_OFFERS_ERROR, payload: { convId, value: false } })

    try {
      const response: AxiosResponse = await axios.get(`${url}/api/v1/offers/pores?customerId=${customerId}&contactId=${convId}&queue=${queueName}`, {
        headers: {
          'x-api-key': 'oac-custom-panel',
          Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
        }
      });
      // For development Purpose
      // const dummyResponse =  JSON.stringify(sampleCardResponse);
      // const response = {
      //   data: JSON.parse(dummyResponse)
      // }
      if (response.status !== 200) dispatch({ type: SET_FETCH_OFFERS_ERROR, payload: { convId, value: true } })

      dispatch({ type: UPDATE_CONV_DATA, payload: { convId, customerId, data: response.data || {} } })
      dispatch({ type: SET_LOADING_STATUS, payload: { convId, loading: false, customerId } })
    } catch (error) {
      dispatch({ type: SET_FETCH_OFFERS_ERROR, payload: { convId, value: true } })
      dispatch({ type: UPDATE_CONV_DATA, payload: { convId, customerId, data: {} } })
      dispatch({ type: SET_LOADING_STATUS, payload: { convId, loading: false, customerId } })
      console.log('Failed to load Customer Offers: ', error)
    }
  }
}

export const fetchConsentMessages: any = (url) => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await axios.get(`${url}/api/v1/offers/custom-data`, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
    const responseData: any = response.data

    // const response = {
    //   "data": sampleConsentMessages
    // };
    // const responseData: any = response.data

    dispatch({ type: SET_REJECTED_REASONS, payload: responseData })
  } catch (error) {
    console.log('Failed to load ConsentMessages from custom-data API: ', error)
  }
}

export const updateOfferStatus = (data: any) => async (dispatch: any) => {
  try {
    const { convId, customerId, url, index, offerId, offerStatus, rejectReason } = data;
    const lastUpdatedTimestamp = new Date().getTime();

    if (!customerId.match(/@/gi)) return;

    dispatch({ type: UPDATE_OFFER_STATUS, payload: { status: offerStatus, convId, index, rejectReason, lastUpdatedTimestamp, customerId } })

    const updateParams = {
      contactId: convId,
      customerId,
      offers: [{ offerId, offerStatus, rejectReason, lastUpdatedTimestamp }],
      consentStatus: 'accepted'
    }

    await axios.put(`${url}/api/v1/offers/pores/${customerId}`, { updateParams }, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
    
  } catch (error) {
    console.log('updateOfferStatus Error: ', error)
  }
}

export const setMutlipartyAssignedAgent = (assignedAgent: boolean) => (dispatch: any) => {
  try {
    dispatch({ type: SET_MULTIPARTY_ASSIGNED_AGENT, payload: assignedAgent })
  } catch (error) { }
}

