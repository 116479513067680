import { getCaseDetails } from '../../api/casesApi'
import actionTypes from '../actionTypes'
const {
  SET_CASE_LIST,
  SET_ACTIVE_CASE,
  CASE_LIST_PAGE_FLAG,
  CLEAR_CASE_STATE,
  CASE_CREATED,
  SET_CREATE_CASE_PARAMS
} = actionTypes

type getCasePayload = {
  conversationId: string
  orgId: string
  rengaId: string
  status: string
}
export const getCasesAPI = (
  payload: getCasePayload,
  localizeString: any,
  toggleLoader?: any
) => {
  return (dispatch: any) => {
    console.log('ECM: Triggered get cases call')
    console.table(payload)
    if (toggleLoader) {
      toggleLoader(true, localizeString.getting_cases_loader_msg)
    }
    getCaseDetails(
      payload.orgId,
      payload.rengaId,
      payload.status,
      payload.conversationId
    )
      .then((result) => {
        const { success, json: caseList = [] } = result.data
        if (success) {
          dispatch({ type: SET_CASE_LIST, value: caseList })
          dispatch({ type: CASE_LIST_PAGE_FLAG, value: true })
        } else {
          dispatch({ type: CASE_LIST_PAGE_FLAG, value: false })
        }
      })
      .catch((err) => {
        console.error('ecm: get cases error', err)
      })
      .finally(() => {
        if (typeof toggleLoader !== 'undefined') {
          toggleLoader(false, '')
        }
      })
  }
}
export const setCaseList = (caseList: Array<Object>) => {
  return {
    type: SET_CASE_LIST,
    value: caseList
  }
}

export const setActiveCase = (activeCase: any) => {
  return {
    type: SET_ACTIVE_CASE,
    value: activeCase
  }
}

export const setCreateCaseParams = (params: any) => {
  return {
    type: SET_CREATE_CASE_PARAMS,
    value: params
  }
}

export const clearCaseState = (payload: any) => {
  return {
    type: CLEAR_CASE_STATE,
    value: payload
  }
}

export const setCaseCreatedFlag = (flag: boolean) => {
  return {
    type: CASE_CREATED,
    value: flag
  }
}
