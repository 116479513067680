export const setSessionStorage = (
  conversationId: string,
  property: string,
  value: any
) => {
  try {
    if (!conversationId) {
      return false
    }
    const availableData = sessionStorage.getItem('ECM_SESSION_STORAGE') || '{}'
    const existingData = JSON.parse(availableData)
    const currentConvData = existingData[conversationId] || null
    if (currentConvData === null) {
      // NEW INSERTION
      const newData = {}
      newData[property] = value
      existingData[conversationId] = newData
    } else {
      // UPDATE OPERATION
      existingData[conversationId][property] = value
    }
    const updatedData = JSON.stringify(existingData)
    sessionStorage.setItem('ECM_SESSION_STORAGE', updatedData)
    return true
  } catch {
    console.error(' Not able to update session storage ')
    return false
  }
}
export const getSessionStorage = (conversationId: string) => {
  try {
    if (!conversationId) {
      return false
    }
    const availableData = sessionStorage.getItem('ECM_SESSION_STORAGE') || '{}'
    const existingData = JSON.parse(availableData)
    return existingData[conversationId] || {}
  } catch {
    console.error(' Not able to fetch session storage ')
    return null
  }
}

/* istanbul ignore next */
export const clearSessionStorage = () => {
  try {
    sessionStorage.removeItem('ECM_SESSION_STORAGE')
    return true
  } catch {
    console.error(' Not able to clear session storage ')
    return false
  }
}

/* istanbul ignore next */
export const removeConvDataFromSessionStorage = (conversationId: string) => {
  try {
    const availableData = sessionStorage.getItem('ECM_SESSION_STORAGE') || '{}'
    const existingData = JSON.parse(availableData)
    const currentConvData = existingData[conversationId] || null
    if (currentConvData !== null) {
      delete existingData[conversationId]
      const updatedData = JSON.stringify(existingData)
      sessionStorage.setItem('ECM_SESSION_STORAGE', updatedData)
      return true
    }
    return true
  } catch {
    console.error(' Not able to clear session storage by conversation Id ')
    return false
  }
}
