export const OFFER_PITCH_MESSAGE = {
  en: {
    'Single-app-to-All-Apps': `As I can see you're currently using #### plan, you're being offered an All-Apps plan which includes over 20 Creative Cloud apps and services. That can help you to make amazing designs, videos, marketing materials, and more along with a 100 GB cloud storage at a huge, discounted price. Would you like to hear about the details of this offer? It will just take one or two minutes.`,
    'Paid-Up-Front-Offer':
      'I notice you are on the monthly payment plan. If you switch to paid up front, you would be eligible for a discount. Would you like to know how much you could save?',
    'No-Entitlement-to-Subscription':
      'I would like to inform you that you are eligible for a special discount on a subscription plan. All of our latest software and updates are now available through subscription only. Along with customer support, you can also access free tutorials on new features & tools right within the applications. Would you like to know more about this offer?',
    'PSx-Mobile-Engagement':
      'Did you know that as part of your current subscription, you already have access to the Photoshop Express mobile app? The app lets you easily edit and retouch pictures, assemble collages, and combine photos on-the-go. https://www.adobe.com/products/photoshop-express.html.  I can send you additional information via email for you to explore.',
    'Acrobat-Mobile-Engagement':
      'Did you know that as part of your current subscription, you already have access to the Acrobat mobile app? The app lets you easily view, sign and edit PDFs on-the-go. https://www.adobe.com/acrobat/mobile/acrobat-reader.html.  I can send you additional information via email for you to explore.',
    'Perpetual-to-Subscription':
      'I would like to inform you that you are eligible for a special discount on a subscription plan. All of our latest software and updates are now available through subscription only. Along with customer support, you can also access free tutorials on new features & tools right within the applications. Would you like to know more about this offer?',
    'CCT-Pro': 'We are glad to inform you that your account is eligible for a free upgrade to CCT Pro. There will be no additional cost associated with this upgrade.',
    'Adobe-Express-Engagement':
      'Have you tried out Express? It’s a great application that you already have access to as part of your subscription to Creative Cloud. If you’d like I can send you a link to it. There is some functionality like FireFly, the Adobe Generative AI product, that will let you turn a text description into a photo.',
  },
  ja: {
    'Single-app-to-All-Apps': `現在Adobe製品をご利用のお客様限定で、ご契約中のプランをコンプリートプランへ変更いただくと、特別にお申し込みから一年間20％～最大で49%の割引となるキャンペーンを行っております。コンプリートプランでは 100 GB の大容量クラウドストレージと20種類以上の製品がご利用いただけます。最新技術満載のアプリケーションで話題の生成AIを楽しんだり、ハイクオリティなデザインや動画、マーケティング資料などの制作、高度なPDF編集が可能でございます。詳しくご案内してもよろしいでしょうか？`,
    'Paid-Up-Front-Offer':
      'ただいま、お客様のお支払い方法は【年間プラン 月々払い】でございますが、お支払方法を年間一括の前払いに変更いただくと割引になるキャンペーンを行っております。詳しくご案内してもよろしいでしょうか？',
    'No-Entitlement-to-Subscription': `お客様は、最新のアプリケーションが利用できるサブスクリプションプランへ特別価格でお申込み可能でございます。カスタマーサポートや新機能のチュートリアルもご利用いただけますので、詳しくご案内してもよろしいでしょうか？`,
    'PSx-Mobile-Engagement':
      '現在ご利用のサブスクリプションにて、  Photoshop Express モバイルアプリにアクセスできることをご存知でしょうか？このアプリを使用すると、外出先でも写真の編集やレタッチ、コラージュの作成、写真の管理などを簡単に行うことができます。https://www.adobe.com/jp/products/photoshop-express.html。ご活用いただけるよう、追加情報をメールでお送りします。',
    'Acrobat-Mobile-Engagement':
      '現在ご利用のサブスクリプションにて、 Acrobat モバイルアプリにアクセスできることをご存知でしょうか？このアプリを使用すると、外出先でも簡単に PDF を表示、署名、編集ができます。https://www.adobe.com/jp/acrobat/mobile/acrobat-reader.html。ご活用いただけるよう、追加情報をメールでお送りします。',
    'Perpetual-to-Subscription':
      'お客様は、最新のアプリケーションが利用できるサブスクリプションプランへ特別価格でお申込み可能でございます。カスタマーサポートや新機能のチュートリアルもご利用いただけますので、詳しくご案内してもよろしいでしょうか？',
    'CCT-Pro': '突然ではございますが、CCT Pro への無料アップグレードの対象者であることが確認できましたのでお知らせいたします。このアップグレードに伴う追加費用は一切ございません。',
    'Adobe-Express-Engagement':
      '最後におすすめのサービスについて紹介させていただきます。「Adobe Express」はご存じでしょうか？現在ご契約中のプランでご利用が可能なサービスです。説明文を入力すると、想像した画像をAIが自動生成する「Adobe Firefly」やデザインテンプレートなど、様々な機能をご用意しております。よろしければアクセス用のリンクをお送りいたしますがいかがでしょうか？',
  },
  fr: {
    'Single-app-to-All-Apps': `Je constate que la formule que vous utilisez actuellement est ####; nous vous proposons une formule Toutes les applications qui inclut plus de 20 logiciels et services Creative Cloud à un tarif avantageux. Cela peut vous aider à créer des designs extraordinaires, des vidéos, des projets marketing et encore plus, tout en bénéficiant d'un espace de stockage cloud de 100 Go. Souhaitez-vous connaître les détails de cette offre ? Cela prendra juste une ou deux minutes.`,
    'Paid-Up-Front-Offer': `Je remarque que votre formule est actuellement payée mensuellement. Si vous passez au paiement anticipé, vous pourrez bénéficier d'une réduction. Souhaiteriez-vous plus d'informations concernant l'offre et combien vous pourriez économiser?`,
    'No-Entitlement-to-Subscription': `Je voudrais vous informer que vous êtes éligible pour une offre promotionnelle sur un abonnement Adobe.  Toutes les mises à jour et les logiciels les plus récents sont désormais disponibles uniquement sur abonnement. Vous pouvez accéder à des tutoriels gratuits sur les nouvelles fonctionnalités et les nouveaux outils directement dans les applications et aussi bénéficier du support clients. Souhaitez-vous en savoir plus sur cette offre ?`,
    'PSx-Mobile-Engagement': `Saviez-vous que l’accès à l'application mobile Photoshop Express est déjà inclus dans votre formule actuelle? L'application vous permet de modifier et de retoucher facilement des photos, d'assembler des collages et de combiner des photos à tout moment. https://www.adobe.com/fr/products/photoshop-express.html. Je peux vous envoyer des informations supplémentaires par e-mail si vous le souhaitez.`,
    'Acrobat-Mobile-Engagement': `Saviez-vous que l’accès à l'application mobile Acrobat est déjà inclus dans votre formule actuelle? L'application vous permet de visualiser, de signer et de modifier facilement des PDF où que vous soyez. https://www.adobe.com/fr/acrobat/mobile/acrobat-reader.html. Je peux vous envoyer des informations supplémentaires par e-mail si vous le souhaitez.`,
    'Perpetual-to-Subscription': `Je voudrais vous informer que vous êtes éligible pour une offre promotionnelle sur un abonnement Adobe.  Toutes les mises à jour et les logiciels les plus récents sont désormais disponibles uniquement sur abonnement. Vous pouvez accéder à des tutoriels gratuits sur les nouvelles fonctionnalités et les nouveaux outils directement dans les applications et aussi bénéficier du support clients. Souhaitez-vous en savoir plus sur cette offre ?`,
    'CCT-Pro':
      "Nous avons le plaisir de vous informer que votre compte est éligible pour une mise à niveau gratuite vers CCT Pro. Il n'y aura aucun coût supplémentaire associé à cette mise à niveau.",
    'Adobe-Express-Engagement':
      "Avez-vous essayé Express ? C'est une excellente application à laquelle vous avez déjà accès dans le cadre de votre abonnement Creative Cloud. Si vous le souhaitez, je peux vous envoyer un lien vers cette application. Il y a des fonctionnalités comme FireFly, le produit de l'IA Générative Adobe, qui vous permet de transformer une description textuelle en une photo.",
  },
  de: {
    'Single-app-to-All-Apps': `Wie ich sehe, nutzen Sie derzeit das ####-Abo. Gerne kann ich Ihnen das All-Apps-Abo anbieten, welches über 20 Creative Cloud-Apps und Dienste umfasst. Das kann Ihnen helfen, tolle Designs, Videos, Marketingmaterialien und mehr zu erstellen. Sie erhalten außerdem einen 100GB-Cloud-Speicher und das alles zu einem reduzierten Preis. Möchten Sie mehr über die Einzelheiten dieses Angebots erfahren? `,
    'Paid-Up-Front-Offer':
      'Ich habe festgestellt, dass Sie den monatlichen Zahlungsplan haben. Wenn Sie zur Vorauszahlung wechseln, haben Sie Anspruch auf einen Rabatt. Möchten Sie wissen, wie viel Sie sparen könnten?',
    'No-Entitlement-to-Subscription':
      'Ich möchte Sie darüber informieren, dass Sie Anspruch auf einen Sonderrabatt für ein Abonnement haben. Alle unsere neuesten Software und Updates sind jetzt nur im Abonnement erhältlich. Neben dem Kundensupport können Sie direkt in den Anwendungen auch auf kostenlose Tutorials zu neuen Funktionen und Tools zugreifen. Möchten Sie mehr über dieses Angebot erfahren?',
    'PSx-Mobile-Engagement':
      'Wussten Sie, dass Sie im Rahmen Ihres aktuellen Abonnements bereits Zugriff auf die mobile Photoshop Express-App haben? Mit der App können Sie unterwegs ganz einfach Bilder bearbeiten und retuschieren, Collagen zusammenstellen und Fotos kombinieren. https://www.adobe.com/products/photoshop-express.html. Ich kann Ihnen zusätzliche Informationen per E-Mail senden, damit Sie sie erkunden können.',
    'Acrobat-Mobile-Engagement':
      'Wussten Sie, dass Sie im Rahmen Ihres aktuellen Abonnements bereits Zugriff auf die mobile Acrobat-App haben? Mit der App können Sie PDFs ganz einfach unterwegs anzeigen, signieren und bearbeiten. https://www.adobe.com/acrobat/mobile/acrobat-reader.html. Ich kann Ihnen zusätzliche Informationen per E-Mail senden, damit Sie sie erkunden können.',
    'Perpetual-to-Subscription':
      'Ich möchte Sie darüber informieren, dass Sie zu einem Sonderrabatt für ein Abo berechtigt sind. Alle unsere neuesten Softwareprodukte und Updates sind jetzt nur noch im Abo erhältlich. Außerdem können Sie neben dem Kundensupport auch auf kostenlose Tutorials zu neuen Funktionen und Tools direkt in den Anwendungen zugreifen. Möchten Sie mehr über dieses Angebot erfahren?',
    'CCT-Pro':
      'Wir freuen uns, Ihnen mitteilen zu können, dass Ihr Konto für ein kostenloses Upgrade auf CCT Pro berechtigt ist. Mit diesem Upgrade sind keine zusätzlichen Kosten verbunden',
    'Adobe-Express-Engagement':
      'Haben Sie Express bereits ausprobiert? Es ist eine nützliche Anwendung, auf die Sie im Rahmen Ihres Creative Cloud-Abonnements bereits Zugriff haben. Wenn Sie möchten, kann ich Ihnen einen Link dazu schicken. Es gibt einige Funktionen wie FireFly, das Generative AI-Produkt von Adobe, mit dem Sie eine Textbeschreibung in ein Foto umwandeln können.',
  },
  es: {
    'Single-app-to-All-Apps': `Como veo que actualmente utiliza el plan ####, le comento que tenemos un plan que incluye más de 20 aplicaciones y los servicios proprios de Creative Cloud. Eso puede ayudarle a complementar su trabajo, y realizar diseños increíbles, vídeos, materiales de marketing y mucho más, junto con un almacenamiento en la nube de 100 GB. ¿Le gustaría que tanto puede ahorrar con esta oferta?`,
    'Paid-Up-Front-Offer':
      'Me he dado cuenta de que tienes el plan de pago mensual. Si cambias a pagar por adelantado, tendrás derecho a un descuento. ¿Le gustaría saber cuánto podría ahorrarse?',
    'No-Entitlement-to-Subscription':
      'Me gustaría informarle de que puede beneficiarse de un descuento especial en un plan de suscripción. Todos nuestros programas y actualizaciones más recientes están ahora disponibles únicamente mediante suscripción. Además del servicio de atención al cliente, también puede acceder a tutoriales gratuitos sobre nuevas funciones y herramientas directamente desde las aplicaciones. ¿Le gustaria tener mas información sobre esta oferta?',
    'PSx-Mobile-Engagement': `¿Sabía que como usted ya tiene la suscripción de Photoshop también cuenta con el acceso a la aplicación móvil de Photoshop Express? La aplicación le permite editar y retocar imágenes fácilmente, montar collages y combinar fotos sobre la marcha. https://www.adobe.com/es/products/photoshop-express.html  ¿Quiere que le envíe la información a su correo electrónico?Puedo enviarle información adicional por correo electrónico para que la consulte.`,
    'Acrobat-Mobile-Engagement':
      '¿Sabía que como usted ya tiene la suscripción de Acrobat también cuenta con el acceso a la aplicación móvil de Acrobat? La aplicación le permite ver, firmar y editar fácilmente archivos PDF sobre la marcha. https://www.adobe.com/es/acrobat/mobile/acrobat-reader.html  ¿Quiere que le envíe la información a su correo electrónico?',
    'Perpetual-to-Subscription':
      'Me gustaría informarle de que puede beneficiarse de un descuento especial en un plan de suscripción. Todos nuestros programas y actualizaciones más recientes están ahora disponibles únicamente mediante suscripción. Además del servicio de atención al cliente, también puede acceder a tutoriales gratuitos sobre nuevas funciones y herramientas directamente desde las aplicaciones. ¿Desea saber más sobre esta oferta?',
    'CCT-Pro': 'Nos complace informarle de que su cuenta puede optar a una actualización gratuita a CCT Pro. Esta actualización no tendrá ningún coste adicional.',
    'Adobe-Express-Engagement':
      '¿Ha probado Express? Es una aplicación genial a la que ya tiene acceso como parte de su suscripción a Creative Cloud. Si lo desea, puedo enviarle un enlace a la misma. Tiene algunas funciones como Firefly, el producto Generative AI de Adobe, que le permitirá convertir un texto descriptivo en una foto.',
  },
  it: {
    'Single-app-to-All-Apps': `Dalla verifica effettuata vedo che al momento stai utilizzando il piano ####, ti viene offerto un piano All-Apps che include oltre 20 app e servizi Creative Cloud. Questo può aiutarla a realizzare fantastici design, video, materiali di marketing e altro ancora compreso lo spazio di archiviazione cloud da 100 GB a un prezzo scontatissimo. Posso illustrarle i dettagli di questa offerta in brevissimo tempo.Potrebbe interessarle? `,
    'Paid-Up-Front-Offer':
      'Ho notato che ha un piano di pagamento mensile. Cambiando fatturazione da pagamento mensile a pagamento anticipato unica fatturazione annuale, posso applicarle uno scontodiritto a uno sconto. Se é daccordo possiamo procedere per verificare quanto potrebbe risparmiare? ',
    'No-Entitlement-to-Subscription': `Desidero informarvi che potete beneficiare di uno sconto speciale per un piano di abbonamento. Tutti i nostri ultimi software e aggiornamenti sono ora disponibili solo in abbonamento. Oltre all'assistenza clienti, è possibile accedere a tutorial gratuiti su nuove funzioni e strumenti direttamente dalle applicazioni. Volete saperne di più su questa offerta?`,
    'PSx-Mobile-Engagement': `Sapeva giá dell’utilizzo del programma su dispositivi Mobile,avendo giá attivo un abbonamento Photoshop Express, ha possibilitá di utilizzare il programma attraverso l’accesso all'app mobile Photoshop Express? L'app le consente di modificare e ritoccare facilmente le immagini, assemblare collage e combinare foto. https://www.adobe.com/products/photoshop-express.html. Posso inviarle ulteriori informazioni via e-mail per consentirle di esplorarle tutte le nuove funzionalitá`,
    'Acrobat-Mobile-Engagement': `Sapeva che, come parte del suo attuale abbonamento, ha già accesso all'app mobile Acrobat? L'app le consente di visualizzare, firmare e modificare facilmente i PDF in qualsiasi momento. https://www.adobe.com/acrobat/mobile/acrobat-reader.html. Posso inviarle ulteriori informazioni via e-mail se lo desidera`,
    'Perpetual-to-Subscription': `Vorrei informarla che ha diritto a uno sconto speciale su un piano di abbonamento. Tutti i nostri software e aggiornamenti più recenti sono ora disponibili solo tramite abbonamento. Oltre all'assistenza clienti, sono compresi tutorial gratuiti e nuove funzionalità direttamente dalle applicazioni. Vuole saperne di più su questa offerta?`,
    'CCT-Pro': "Siamo lieti di informarla che il suo account può essere aggiornato gratuitamente a CCT Pro. L'aggiornamento non comporta alcun costo aggiuntivo.",
    'Adobe-Express-Engagement':
      "Avete provato Express? È un'ottima applicazione a cui avete già accesso come parte del vostro abbonamento a Creative Cloud. Se volete, posso inviarvi un link. Ci sono alcune funzionalità come FireFly, il prodotto di Adobe Generative AI, che vi permetterà di trasformare una descrizione testuale in una foto.",
  },
  'pt-BR': {
    'Single-app-to-All-Apps': `Como eu posso ver, você está usando o plano ####. Mas temos o plano All-Apps a um preço promocional especial que inclui mais de 20 aplicativos e serviços da Creative Cloud. Isso pode ajudá-lo a criar designs, vídeos, materiais de marketing incríveis e muito mais, além de um armazenamento em nuvem de 100 GB . Você gostaria de saber mais sobre os detalhes desta oferta?`,
    'Paid-Up-Front-Offer':
      'Percebi que você está no plano de pagamento mensal. Se você mudar para o pagamento antecipado, terá direito a um desconto. Você gostaria de saber quanto você poderia economizar?',
    'No-Entitlement-to-Subscription':
      'Gostaria de informar que você tem direito a um desconto especial em um plano de assinatura. Todos os nossos softwares e atualizações mais recentes agora estão disponíveis somente por meio de assinatura. Além do suporte ao cliente, você também pode acessar tutoriais gratuitos sobre novos recursos e ferramentas diretamente nos aplicativos. Você gostaria de saber mais sobre esta oferta?',
    'PSx-Mobile-Engagement':
      'Você sabia que, como parte da sua assinatura atual, você já tem acesso ao aplicativo móvel Photoshop Express? O aplicativo permite que você edite e retoque fotos com facilidade, monte colagens e combine fotos em qualquer lugar. https://www.adobe.com/products/photoshop-express.html. Você gostaria de saber mais sobre  as vantagens  deste Produto? (So that can be compliant with AHT and RFT)',
    'Acrobat-Mobile-Engagement':
      'Você sabia que, como parte da sua assinatura atual, você já tem acesso ao aplicativo móvel Acrobat? O aplicativo permite que você visualize, assine e edite PDFs facilmente em qualquer lugar. https://www.adobe.com/acrobat/mobile/acrobat-reader.html.  Você gostaria de saber mais sobre  as vantagens  deste Produto? (So that can be compliant with AHT and RFT)',
    'Perpetual-to-Subscription':
      'Gostaria de informar que você tem direito a um desconto especial em um plano de assinatura. Todos os nossos softwares e atualizações mais recentes agora estão disponíveis somente por meio de assinatura. Além do suporte ao cliente, você também pode acessar tutoriais gratuitos sobre novos recursos e ferramentas diretamente nos aplicativos. Você gostaria de saber mais sobre esta oferta?',
    'CCT-Pro':
      'Temos o prazer de informar que a sua conta está qualificada para uma atualização gratuita para a subscrição CCT Pro. Não haverá nenhum custo adicional associado com esta atualização de pacote',
    'Adobe-Express-Engagement':
      'Você já experimentou o Express? É um ótimo aplicativo ao qual você já tem acesso como parte de sua assinatura da Creative Cloud. Se quiser posso te enviar o link dele. Existem algumas funcionalidades como FireFly, o produto Adobe Generative IA, que permite transformar uma descrição de texto em uma foto.',
  },
  'zh-TW': {
    'Single-app-to-All-Apps': `我看到您目前正在使用 #### 方案，系統會為您提供全部應用程式計劃，其中包含 20 多個 Creative Cloud 應用程式和服務。這可以幫助您以優惠的價格製作出色的設計，視頻，營銷材料等以及 100 GB 的雲存儲空間。您想了解此優惠的詳細信息嗎？只需要一兩分鐘。`,
    'Paid-Up-Front-Offer': '我查看到您目前正在使用每月付款計劃。如果您选择转换到年度預付计划，您將有資格獲得年度优惠折扣。您想进一步了解这个优惠方案吗？',
    'No-Entitlement-to-Subscription':
      '我想告知您，您具有資格獲得訂閱計劃的特殊优惠折扣。我們所有最新的軟件和更新現在只能通過訂閱獲得。除了客戶支持之外，您還可以直接在應用程序中訪問有關新功能和工具的免費教程。您想了解更多有關此優惠的信息嗎？',
    'PSx-Mobile-Engagement':
      '您是否了解，作為目前訂閱的一部分，您已經可以存取 Photoshop Express 行動應用程式？該應用程序可讓您隨時隨地輕鬆編輯和修飾圖片，組合拼貼以及合併照片。https://www.adobe.com/products/photoshop-express.html。我可以通過電子郵件向您發送更多信息供您探索。',
    'Acrobat-Mobile-Engagement':
      '您是否了解，作為目前訂閱的一部分，您已經可以存取 Acrobat 行動應用程式？該應用程序使您可以在旅途中輕鬆查看，簽名和編輯 PDF。https://www.adobe.com/acrobat/mobile/acrobat-reader.html。我可以通過電子郵件向您發送更多信息供您探索。',
    'Perpetual-to-Subscription':
      '由於您是我們的優質客戶，您目前具有獲得訂閱產品計劃的特殊折扣資格。我們所有最新的軟件和更新現在只能通過訂閱產品獲得。除了享受客戶支持之外，您還可以直接在應用程序中訪問有關新功能和工具的免費教程。您想了解更多有關此優惠的信息嗎？',
    'CCT-Pro': '我们很高兴地通知您，您的帐户可以免费升级到 CCT Pro  。 此次升级不会产生任 何额外费用',
    'Adobe-Express-Engagement':
      '您嘗試過 Express 嗎？ 這是一款出色的應用程序，您可以透過 Creative Cloud 訂閱來存取它。 如果您願意，我可以向您發送連結。 有一些功能，例如 Adob​​e Generative AI 產品 FireFly，可讓您將文字描述轉換為照片。',
  },
  'zh-HK': {
    'Single-app-to-All-Apps': `我看到您目前正在使用 #### 方案，系統會為您提供全部應用程式計劃，其中包含 20 多個 Creative Cloud 應用程式和服務。這可以幫助您以優惠的價格製作出色的設計，視頻，營銷材料等以及 100 GB 的雲存儲空間。您想了解此優惠的詳細信息嗎？只需要一兩分鐘。`,
    'Paid-Up-Front-Offer': '我查看到您目前正在使用每月付款計劃。如果您选择转换到年度預付计划，您將有資格獲得年度优惠折扣。您想进一步了解这个优惠方案吗？',
    'No-Entitlement-to-Subscription':
      '我想告知您，您具有資格獲得訂閱計劃的特殊优惠折扣。我們所有最新的軟件和更新現在只能通過訂閱獲得。除了客戶支持之外，您還可以直接在應用程序中訪問有關新功能和工具的免費教程。您想了解更多有關此優惠的信息嗎？',
    'PSx-Mobile-Engagement':
      '您是否了解，作為目前訂閱的一部分，您已經可以存取 Photoshop Express 行動應用程式？該應用程序可讓您隨時隨地輕鬆編輯和修飾圖片，組合拼貼以及合併照片。https://www.adobe.com/products/photoshop-express.html。我可以通過電子郵件向您發送更多信息供您探索。',
    'Acrobat-Mobile-Engagement':
      '您是否了解，作為目前訂閱的一部分，您已經可以存取 Acrobat 行動應用程式？該應用程序使您可以在旅途中輕鬆查看，簽名和編輯 PDF。https://www.adobe.com/acrobat/mobile/acrobat-reader.html。我可以通過電子郵件向您發送更多信息供您探索。',
    'Perpetual-to-Subscription':
      '由於您是我們的優質客戶，您目前具有獲得訂閱產品計劃的特殊折扣資格。我們所有最新的軟件和更新現在只能通過訂閱產品獲得。除了享受客戶支持之外，您還可以直接在應用程序中訪問有關新功能和工具的免費教程。您想了解更多有關此優惠的信息嗎？',
    'CCT-Pro': '我们很高兴地通知您，您的帐户可以免费升级到 CCT Pro  。 此次升级不会产生任 何额外费用',
    'Adobe-Express-Engagement':
      '您嘗試過 Express 嗎？ 這是一款出色的應用程序，您可以透過 Creative Cloud 訂閱來存取它。 如果您願意，我可以向您發送連結。 有一些功能，例如 Adob​​e Generative AI 產品 FireFly，可讓您將文字描述轉換為照片。',
  },
  'zh-CN': {
    'Single-app-to-All-Apps': `我发现，你目前正在使用 #### 套餐，你将获得一个全应用程序套餐，其中包括 20 多个 Creative Cloud 应用程序和服务。这可以帮助您以优惠的价格制作精彩的设计、视频、营销材料等，以及 100 GB 的云存储空间。您想听听此优惠的细节吗？只需要一两分钟。`,
    'Paid-Up-Front-Offer': '我注意到你正在使用月度付款计划。如果您切换到预付款，您将有资格获得折扣。你想知道你能节省多少钱吗？',
    'No-Entitlement-to-Subscription':
      '我想告诉您，您有资格获得订阅计划的特别折扣。我们所有最新的软件和更新现在只能通过订阅获得。除了客户支持外，您还可以在应用程序中访问有关新功能和工具的免费教程。您想进一步了解此优惠吗？',
    'PSx-Mobile-Engagement':
      '你知道吗，作为你当前订阅的一部分，你已经可以使用 Photoshop Express 移动应用程序了？该应用程序可让您随时随地轻松编辑和修饰图片、组装拼贴画以及组合照片。https://www.adobe.com/products/photoshop-express.html。我可以通过电子邮件向您发送更多信息，供您探索。',
    'Acrobat-Mobile-Engagement':
      '您知道吗，作为当前订阅的一部分，您已经可以访问 Acrobat 移动应用程序了？该应用程序可以让你随时随地轻松查看、签署和编辑 PDF。https://www.adobe.com/acrobat/mobile/acrobat-reader.html。我可以通过电子邮件向您发送更多信息，供您探索。',
    'Perpetual-to-Subscription':
      '我想告诉您，您有资格获得订阅计划的特别折扣。我们所有最新的软件和更新现在只能通过订阅获得。除了客户支持外，您还可以在应用程序中访问有关新功能和工具的免费教程。您想进一步了解此优惠吗？',
    'CCT-Pro': '我們很高興地通知您，您的帳戶可以免費 升級到 CCT Pro  。 此次升級不會產生任 何額外費用',
    'Adobe-Express-Engagement': '',
  },
  ko: {
    'Single-app-to-All-Apps': `고객님께서는 20개 이상의 Creative Cloud 앱과 100GB 클라우드 스토리지를 사용하실 수 있는 Creative Cloud 모든 앱 플랜을 대폭 할인된 금액으로 이용하실 수 있습니다. 이 할인에 대해 관심이 있으신가요? 약 1-2분간 시간을 내어 주신다면 고객님의 혜택에 대하여 자세히 안내드리겠습니다.`,
    'Paid-Up-Front-Offer':
      '고객님께서는 오늘 월결제 플랜에서 연간 선지불 플랜으로 변경하실 경우, 대폭 할인된 금액으로 플랜을 이용하실 수 있습니다. 혹시 이 혜택에 대하여 관심이 있으실까요?',
    'No-Entitlement-to-Subscription':
      '고객님께서는 Adobe의 최신 소프트웨어 및 업데이트를 이용하실 수 있는 구독 플랜을 특별 할인가에 이용하실 수 있습니다. 구독을 통해 고객 지원 서비스와 더불어 무료 학습자료와 다양한 최신 기능들도 사용하실 수 있습니다. 혹시 이 혜택에 대하여 관심이 있으실까요?',
    'PSx-Mobile-Engagement':
      '현재 이용중이신 플랜으로 Photoshop Express 모바일 앱을 이용하실 수 있다는 사실을 알고 계셨나요? 이 앱을 통해 이동 중에도 손쉽게 사진을 편집, 결합 및 보정하고, 콜라주를 조합할 수 있습니다.  https://www.adobe.com/kr/products/photoshop-express.html 더 자세히 알아보시고자 한다면 이메일을 통해 고객님께 추가 정보를 보내드려도 괜찮으실까요?',
    'Acrobat-Mobile-Engagement':
      '현재 이용중이신 플랜으로 Acrobat 모바일 플랜을 이용하실 수 있다는 사실을 알고 계셨나요?  https://www.adobe.com/kr/acrobat/mobile/acrobat-reader.html Acrobat 모바일 앱을 통해 이동 중에도 PDF를 손쉽게 보고, 서명하고, 편집할 수 있습니다. 더 자세히 알아보시고자 한다면 이메일을 통해 고객님께 추가 정보를 보내드려도 괜찮으실까요?',
    'Perpetual-to-Subscription':
      '고객님께서는 Adobe의 최신 소프트웨어 및 업데이트를 이용하실 수 있는 구독 플랜을 특별 할인가에 이용하실 수 있습니다. 구독을 통해 고객 지원 서비스와 더불어 무료 학습자료와 다양한 최신 기능들도 사용하실 수 있습니다. 혹시 이 혜택에 대하여 관심이 있으실까요?',
    'CCT-Pro':
      'CCT Pro는 5명 이상의 사용자를 보유한 팀 및 기업에서 이용할 수 있습니다. CCT Pro로 업그레이드하시면 모든 앱 제품과 Adobe Stock 제품을 추가 금액 없이 바로 사용하실 수 있습니다.',
    'Adobe-Express-Engagement':
      'Adobe Express를 사용해 보셨나요? 이미 Creative Cloud를 구독 중이시라면 바로 사용하실 수 있는 앱입니다. 사용해 보시고 싶으시다면, 링크를 보내드리겠습니다. 특히, 텍스트 설명을 사진으로 바꿔주는 Adobe의 생성형 AI 제품인 FireFly와 같은 놀라운 기능을 체험하실 수 있습니다. 사용해 보시면 분명 매력을 느끼실 수 있을거예요.',
  },
};

export const OFFER_REJECTION_REASON = {
  en: [
    { id: 1, reason: 'Price related' },
    { id: 2, reason: 'Usage related' },
    { id: 3, reason: 'Require time to think/discuss' },
    { id: 4, reason: 'Dissatisfied with previous support/experience' },
    { id: 5, reason: 'Personal Reasons' },
  ],
  ja: [
    { id: 1, reason: '価格関連' },
    { id: 2, reason: '使用頻度関連' },
    { id: 3, reason: '検討する/相談する時間が必要' },
    { id: 4, reason: '以前のサポート/経験に不満' },
    { id: 5, reason: '個人的な理由' },
  ],
  fr: [
    { id: 1, reason: 'Lié au prix' },
    { id: 2, reason: `Lié à l'utilisation` },
    { id: 3, reason: 'Nécessite du temps pour réfléchir/discuter' },
    { id: 4, reason: `Insatisfait du support ou de l'expérience précédents` },
    { id: 5, reason: 'Raisons personnelles' },
  ],
  de: [
    { id: 1, reason: 'Preisbezogen' },
    { id: 2, reason: 'Nutzungsbezogen' },
    { id: 3, reason: 'Brauche Zeit zum Nachdenken/Diskutieren' },
    { id: 4, reason: 'Unzufrieden mit früherer Hilfe/Erfahrung' },
    { id: 5, reason: 'Persönliche Gründe' },
  ],
  es: [
    { id: 1, reason: 'Relacionado con el precio' },
    { id: 2, reason: 'Relacionado con el uso' },
    { id: 3, reason: 'Requieren tiempo para pensar/discutir' },
    { id: 4, reason: 'Insatisfecho con la asistencia/experiencia anterior' },
    { id: 5, reason: 'Razones personales' },
  ],
  it: [
    { id: 1, reason: 'Relativo al prezzo' },
    { id: 2, reason: `Relativo all'uso` },
    { id: 3, reason: 'Richiede tempo per pensare/discutere' },
    { id: 4, reason: 'Insoddisfatto del supporto/esperienza precedente' },
    { id: 5, reason: 'Motivi personali' },
  ],
  'pt-BR': [
    { id: 1, reason: 'Relacionado ao preço' },
    { id: 2, reason: 'Relacionado ao uso' },
    { id: 3, reason: 'Requer tempo para pensar/discutir' },
    { id: 4, reason: 'Insatisfeito com o suporte/experiência anterior' },
    { id: 5, reason: 'Razões pessoais' },
  ],
  'zh-TW': [
    { id: 1, reason: '價格相關' },
    { id: 2, reason: '使用相關' },
    { id: 3, reason: '需要時間思考/討論' },
    { id: 4, reason: '對以前的支持/經驗不滿意' },
    { id: 5, reason: '個人理由' },
  ],
  'zh-CN': [
    { id: 1, reason: '价格相关' },
    { id: 2, reason: '与用法相关' },
    { id: 3, reason: '需要时间思考/讨论' },
    { id: 4, reason: '对以前的支持/经验不满意' },
    { id: 5, reason: '个人原因' },
  ],
  ko: [
    { id: 1, reason: '가격 관련' },
    { id: 2, reason: '사용 관련' },
    { id: 3, reason: '생각/토론할 시간이 필요함' },
    { id: 4, reason: '이전 지원/경험에 만족하지 못함' },
    { id: 5, reason: '개인적인 사유' },
  ],
};
export const OFFER_REJECTION_REASON_CCT_PRO = {
  en: [
    { id: 1, reason: 'Decline due to price increase on renewal' },
    { id: 2, reason: 'Need time to think/discuss internally' },
    { id: 3, reason: 'Do not need any offer' },
    { id: 4, reason: "Don't want to give stocks access to the users" },
    { id: 5, reason: 'No requirement/Do not use Stock' },
    { id: 6, reason: 'Do not want to make any Changes to Account' },
    { id: 7, reason: 'Others (give box to add reason)' },
  ],
  ja: [
    { id: 1, reason: 'リニューアル時の価格上昇による減少' },
    { id: 2, reason: '社内で考えたり話し合ったりする時間が必要' },
    { id: 3, reason: 'オファーは必要ありません' },
    { id: 4, reason: '株にユーザーへのアクセスを与えたくない' },
    { id: 5, reason: '要件なし/ストックは使用しないでください' },
    { id: 6, reason: 'アカウントに変更を加えたくない' },
    { id: 7, reason: 'その他（理由を追加するにはボックスを選択）' },
  ],
  fr: [
    { id: 1, reason: 'Baisse due à la hausse des prix sur Renewald' },
    { id: 2, reason: 'Besoin de temps pour réfléchir/discuter en interne' },
    { id: 3, reason: "Vous n'avez besoin d'aucune offre" },
    { id: 4, reason: 'Je ne veux pas donner accès aux stocks aux utilisateurs' },
    { id: 5, reason: 'Aucune exigence/Ne pas utiliser le stock' },
    { id: 6, reason: 'Je ne souhaite apporter aucune modification au compte' },
    { id: 7, reason: 'Autres (inscrivez une case pour ajouter une raison)' },
  ],
  de: [
    { id: 1, reason: 'Rückgang aufgrund des Preisanstiegs bei Renewald' },
    { id: 2, reason: 'Brauche Zeit zum internen Denken/Diskutieren' },
    { id: 3, reason: 'Brauche kein Angebot' },
    { id: 4, reason: 'Ich möchte den Benutzern keinen Zugriff auf Aktien gewähren' },
    { id: 5, reason: 'Keine Anforderung/Stock nicht verwenden' },
    { id: 6, reason: 'Ich möchte keine Änderungen am Konto vornehmen' },
    { id: 7, reason: 'Andere (geben Sie ein Feld an, um einen Grund hinzuzufügen)' },
  ],
  es: [
    { id: 1, reason: 'Disminución debido al aumento de precios en Renewald' },
    { id: 2, reason: 'Necesito tiempo para pensar/discutir internamente' },
    { id: 3, reason: 'No necesito ninguna oferta' },
    { id: 4, reason: 'No quiero dar acceso a las acciones a los usuarios' },
    { id: 5, reason: 'Sin requerimiento/No utilice Stock' },
    { id: 6, reason: 'No quiero realizar ningún cambio en la cuenta' },
    { id: 7, reason: 'Otros (dé la casilla para añadir el motivo)' },
  ],
  it: [
    { id: 1, reason: "Calo dovuto all'aumento dei prezzi su renewald" },
    { id: 2, reason: 'Hai bisogno di tempo per pensare/discutere internamente' },
    { id: 3, reason: 'Non ho bisogno di alcuna offerta' },
    { id: 4, reason: 'Non voglio dare accesso alle azioni agli utenti' },
    { id: 5, reason: 'Nessun requisito/Non utilizzare Stock' },
    { id: 6, reason: "Non desideri apportare modifiche all'account" },
    { id: 7, reason: 'Altri (inserisci una casella per aggiungere il motivo)' },
  ],
  'pt-BR': [
    { id: 1, reason: 'Descida devido ao aumento dos preços na renovação' },
    { id: 2, reason: 'Precisa de tempo para pensar/discutir internamente' },
    { id: 3, reason: 'Não precisa de nenhuma oferta' },
    { id: 4, reason: 'Não quer dar acesso aos stocks aos utilizadores' },
    { id: 5, reason: 'Sem requisito/Não utilizar Stock' },
    { id: 6, reason: 'Não deseja fazer alterações na conta' },
    { id: 7, reason: 'Outros (dar caixa para acrescentar razão)' },
  ],
  'zh-TW': [
    { id: 1, reason: '由於更新瓦爾德價格上漲而下降' },
    { id: 2, reason: '需要時間在內部思考/討論' },
    { id: 3, reason: '不需要任何優惠' },
    { id: 4, reason: '不想將股票存取權授予使用者' },
    { id: 5, reason: '無需/不使用庫存' },
    { id: 6, reason: '不想對帳戶進行任何變更' },
    { id: 7, reason: '其他（給框添加理由）' },
  ],
  'zh-CN': [
    { id: 1, reason: '由于续订后价格上涨而下跌' },
    { id: 2, reason: '需要时间在内部思考/讨论' },
    { id: 3, reason: '不需要任何报价' },
    { id: 4, reason: '不想让用户访问股票s' },
    { id: 5, reason: '没有要求/不要使用库存' },
    { id: 6, reason: '不想对账户进行任何更改' },
    { id: 7, reason: '其他（给出方框以添加理由）' },
  ],
  ko: [
    { id: 1, reason: '리뉴얼에 따른 가격 인상으로 인한 하락' },
    { id: 2, reason: '내부적으로 생각하고 토론할 시간이 필요함' },
    { id: 3, reason: '어떤 제안도 필요 없습니다' },
    { id: 4, reason: '사용자에게 주식 액세스 권한을 부여하고 싶지 않음' },
    { id: 5, reason: '요구 사항 없음/재고 사용 금지' },
    { id: 6, reason: '계정을 변경하고 싶지 않음' },
    { id: 7, reason: '기타 (이유를 추가하기 위해 상자 제공) ' },
  ],
};
