import { apiClient } from './axiosSettings'
import getAuthHeaders from './getAuthHeaders'

export async function fetchAgentAuxData(params: any) {
  const { agentId, agentConsoleServiceApiEndPoint, agentTimezone } = params
  const queryParams = new URLSearchParams({
    agentId: agentId,
    timezone: agentTimezone
  });
  try {
    const result = await apiClient.get(`${agentConsoleServiceApiEndPoint}/api/v1/agent/aux-report`, {
      params: queryParams,
      headers: getAuthHeaders()
    })
    return result?.data || {}
  } catch (e) {
    console.log('Error in fetching agent aux data - ', e)
    return {}
  }
}