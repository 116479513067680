declare const window: any

export function initDunamis() {
  window.dunamis_analytics = window.dunamis_analytics || {}
}

/**
 * 
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType - send-consent/ customer-has-given-consent | yes/no
 * @param value - customer-consent | ''
 * 
 *  yes/no click for customer consent (send in event.subtype as yes instead of yes I asked for telephony workflow and differentiate the events using event.subcategory)
    Send the value as 'customer-consent' for the click for customer consent. refer the BH column. And for BF columns the value will be empty
    BF and BH columns
 */

export function consentOverlayEvent(vaoWorkflowCategory, btnType, value) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      'ui.view_type': 'recommended-upgrade',
      value
    })
  }
}

/**
 *

 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType  - send/cancel
 * BG column
 */
export function consentDialogEvent(vaoWorkflowCategory, btnType) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      'ui.view_type': 'customer-consent-modal'
    })
  }

}

/**
 * 
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * @param value - refreshed (incase offer was updated based on customer id update, then only send this. If not, don't send)
 * 
 *  refreshed (incase offer was updated based on customer id update, then only send this. If not, don't send)
    Refer BI column
 */
export function renderOfferEvent(vaoWorkflowCategory, offerId, offerName, campaignName, value) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: 'offer',
      type: 'render',
      'ui.view_type': 'recommended-upgrade',
      'content.name': offerId,
      'content.type': offerName || campaignName,
      value
    })
  }

}

/**
 * 
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType - show-details/ hide-details
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * 
    show offer details 
    Refer BJ column
 */
export function showOfferEvent(vaoWorkflowCategory, btnType, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      type: 'click',
      'ui.view_type': 'recommended-upgrade',
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }

}

/**
 * 
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 *  send offer details 
    Refer BK column
 */
export function sendOfferEvent(vaoWorkflowCategory, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: 'send-offer',
      type: 'click',
      'ui.view_type': 'recommended-upgrade',
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }
}

/**
 *
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType - send/cancel
 * @param offerId
 * @param offerName
 * @param campaignName
 *

 * send/cancel offer in send offer modal
 * Refer BM Column
 */
export function sendOrCancelOfferDialogEvent(vaoWorkflowCategory, btnType, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      type: 'click',
      'ui.view_type': 'send-offer-modal',
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }

}

/**
 * 
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * @param value - offer-id/why-offer-benefits-customer
 * 
 *  copy offer details 
    Refer BL column
 */
export function copyOfferDetailsEvent(vaoWorkflowCategory, offerId, offerName, campaignName, value) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: 'copy',
      type: 'click',
      'ui.view_type': 'recommended-upgrade',
      'content.name': offerId,
      'content.type': offerName || campaignName,
      value
    })
  }

}

/**
 * 
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType - yes/no
 * @param offerId 
 * @param offerName 
 * @param campaignName 
 * @param value - accepted-offer/rejected-offer/ did-you-mkae-the-offer
 * 
 * yes/no if customer accepted the offer or did you make the offer
    Refer BN column
 */
export function offerAcceptOrRejectEvent(vaoWorkflowCategory, btnType, offerId, offerName, campaignName, value) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      type: 'click',
      'ui.view_type': 'recommended-upgrade',
      'content.name': offerId,
      'content.type': offerName || campaignName,
      value
    })
  }
}

/**
 *
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType - submit/change-reason
 * @param offerId
 * @param offerName
 * @param campaignName
 * @param rejectionReason
 *

 * submit click or change reason click for rejecting offer
 * BO Column
 */
export function offerRejectReasonEvent(vaoWorkflowCategory, btnType, offerId, offerName, campaignName, rejectionReason) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      type: 'click',
      'ui.view_type': 'rejection-reason-modal',
      'content.name': offerId,
      'content.type': offerName || campaignName,
      'content.status': rejectionReason,
      value: 'rejected-offer'
    })
  }
}

/**
 *
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param offerId
 * @param offerName
 * @param campaignName
 *

 * open hendrix click
 * BP column
 */
export function openHendrixBtnEvent(vaoWorkflowCategory, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: 'open-hendrix',
      type: 'click',
      'ui.view_type': 'recommended-upgrade', // check with dunamis this is having different value
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }
}

/**
 *
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param btnType - paste-in-chat/cancel
 * @param offerId
 * @param offerName
 * @param campaignName
 *

 * paste-in-chat click
 * BQ column
 */

export function offerPasteInChatEvent(vaoWorkflowCategory, btnType, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: vaoWorkflowCategory,
      subType: btnType,
      type: 'click',
      'ui.view_type': 'copy-details', // check with dunamis this is having different value
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }
}

/**
 *
 * @param widgetName - Case Management/ Sales CIP
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param searchEmailPhoneText - email/phone/caseId - this cannot be captured to adhere the GDPR rules - this is customer email. instead can we capture the rengaId
 * @param offerId
 * @param offerName
 * @param campaignName
 *
 * Case Management/Sales CIP- id search
 * BR column
 */
export function customerSearchEvent(widgetName, vaoWorkflowCategory, searchEmailPhoneText, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: widgetName,
      subType: 'search',
      type: 'click',
      'content.category': vaoWorkflowCategory,
      'ui.search_keyword': searchEmailPhoneText, // check with dunamis this is having different value
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }
}

/**
 *
 * @param widgetName - Case Management/ Sales CIP
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param searchEmailPhoneText - email/phone/caseId - this cannot be captured to adhere the GDPR rules - this is customer email. instead can we capture the rengaId
 * @param searchResultCount
 * @param offerId
 * @param offerName
 * @param campaignName
 *
 * Case Management/Sales CIP- display search result
 * BS column
 */
export function customerSearchResultRenderEvent(widgetName, vaoWorkflowCategory, searchEmailPhoneText, searchResultCount, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: widgetName,
      subType: 'search-results',
      type: 'render',
      'content.category': vaoWorkflowCategory,
      'event.count': searchResultCount,
      'ui.search_keyword': searchEmailPhoneText, // check with dunamis this is having different value
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }
}

/**
 *
 * @param widgetName - Case Management/ Sales CIP
 * @param vaoWorkflowCategory - Support Chat/ Support Telephony/ Sales Chat/ Sales Telephony
 * @param searchEmailPhoneText - email/phone/caseId - this cannot be captured to adhere the GDPR rules - this is customer email. instead can we capture the rengaId
 * @param offerId
 * @param offerName
 * @param campaignName
 *
 * Case Management/Sales CIP-click customer
 * BT column
 */
export function customerAccountSelectionEvent(widgetName, vaoWorkflowCategory, searchEmailPhoneText, offerId, offerName, campaignName) {
  if (typeof window.dunamis_analytics.sendValueAddedOffersEvents === 'function') {
    window.dunamis_analytics.sendValueAddedOffersEvents({
      subCategory: widgetName,
      subType: 'customer',
      type: 'click',
      'content.category': vaoWorkflowCategory,
      'ui.search_keyword': searchEmailPhoneText, // check with dunamis this is having different value
      'content.name': offerId,
      'content.type': offerName || campaignName
    })
  }

}
