import { Toast } from '@react/react-spectrum/Toast'
import React from 'react'
import { CheckMarkIcon } from '../Theme/Icons/icons'
import styles from '../Theme/Styles/notification/notification.css'

interface IProps {
  message: string
}
const Notification = (props: IProps) => {
  const { message } = props
  return (
    <div className={styles['ecm-notification-component']}>
      <Toast className={styles['ecm-notification-wrapper']} timeout={3000}>
        <div className={styles['ecm-notification-content']}>
          <div className={styles['ecm-notification-icon']}>
            <CheckMarkIcon />
          </div>
          <div className={styles['ecm-notification-text']}>
            <span className={styles['ecm-notification-title']}>{message}</span>
          </div>
        </div>
      </Toast>
    </div>
  )
}

export default Notification
