import getClient from '../utils/client';

export default async function getCreateCasePayload(payload: any): Promise<any> {
  return getClient(true)
    .post(`/api/v1/cases/casepayload`, payload)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, CreateCasePayload :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, CreateCasePayload : Exception Occured', e);
      return false;
    })
    .finally(() => {
      try {
        setTimeout(() => {
          (window as any).enable_CCM = false;
          (window as any).enable_CIP = false;
          (window as any).enable_SCIP = false;
        });
      } catch (err) {
        console.error('CCM Analytics Error Occurred');
      }
    });
}
