import React from 'react';
import AdobeAnswersFrame from './Components/AdobeAnswersFrame';
import { ErrorBoundary } from 'react-error-boundary';

interface propsData {
  conversationSummary?: any,
  conversationId: string,
  agentDetails: any,
  contextData?: any;
  callBackEvent: (event: string, eventData: any) => void;
  darkMode: boolean;
  language: string;
  urls: {
    GENAI_MIDDLEWARE_URL: string,
    FG_ENDPOINT_AAFeedback: string
  };
}

const App = (props: propsData) => {
  const { conversationSummary, conversationId, agentDetails, contextData, darkMode, language, urls, callBackEvent } = props;
  return (
    <ErrorBoundary fallback={<div>Failed to load module</div>}>
      <AdobeAnswersFrame
        conversationSummary={conversationSummary}
        conversationId={conversationId}
        agentDetails={agentDetails}
        contextData={contextData}
        darkMode={darkMode}
        language={language}
        urls={urls}
        callBackEvent={callBackEvent}
      />
    </ErrorBoundary>
  );
};

export default App;