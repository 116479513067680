import styles from './Styles/styles.module.css';



const themes = (darkMode: boolean) => ({
  darkMode,
  darkModeBG: darkMode ? ` ${styles['aa-panel-dark']}` : '',
  darkModeBorder: darkMode ? ` ${styles['aa-panel-dark-border']}` : '',
  darkModeTitle: darkMode ? ` ${styles['aa-title-dark']}` : '',
  darkModeInfo: darkMode ? ` ${styles['aa-info-dark']}` : '',
  darkModeBorderLeft: darkMode ? ` ${styles['aa-dark-border-left']}` : '',
  darkModeTextAreaWrapper: darkMode ? ` ${styles['aa-dark-textarea-wrapper']}` : '',
  darkModea: darkMode ? ` ${styles['aa-dark']}` : '',
  darkModeAnswerFooter: darkMode ? ` ${styles['answer-footer-dark']}` : '',
  darkModeStartTyping: darkMode ? ` ${styles['aa-dark-start-typing']}` : '',
  darkModeAIMessage: darkMode ? ` ${styles['aa-dark-ai-message']}` : '',
  darkModeAgentAvatar: darkMode ? ` ${styles['aa-dark-agent-avatar']}` : '',
  darkModeAIMessageContent: darkMode ? ` ${styles['aa_dark_mode_ai_message_content']}` : '',
  darkModeAgentFeedback: darkMode ? ` ${styles['agent-feedback-dark']}` : '',
  darkModeOverlay: darkMode? ` ${styles['aa-overlay_dark']}` : '',
  darkModeMarkdown: darkMode? ` ${styles['aa-markdown_dark']}` : '',
  darkModeActionButton: darkMode? ` ${styles['aa-dark-action-button']}` : '',
  darkModeLeftHeader: darkMode? ` ${styles['aa-dark-action-button']}` : '',
  darkModeSuggestionTitle: darkMode? ` ${styles['aa-dark-suggestion-title']}` : '',
  darkModeSuggestion: darkMode? ` ${styles['aa-dark-suggestion']}` : '',
});

export default themes;
