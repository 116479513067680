export const LANGUAGE = 'LANGUAGE'
export const DART_THEME = 'DART_THEME'
export const ANSWER_LOADING = 'ANSWER_LOADING'
export const ANSWER_RESULT = 'ANSWER_RESULT'
export const GET_ANSWER = 'GET_ANSWER'
export const UPDATE_CURRENT_QUERY = 'UPDATE_CURRENT_QUERY'
export const NEW_QUERY = 'NEW_QUERY'
export const SWITCH_QUERY = 'SWITCH_QUERY'
export const DELETE_QUERY = 'DELETE_QUERY'
export const PIN_QUERY = 'PIN_QUERY'
export const COPY_PASTE_MESSAGE = 'COPY_PASTE_MESSAGE'
export const CLEAN_CURRENT_QUERY = 'CLEAN_CURRENT_QUERY'
export const UPDATE_ANSWER_INDEX = 'UPDATE_ANSWER_INDEX'
export const UPDATE_FEEDBACK_REASONS = 'UPDATE_FEEDBACK_REASONS'
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK'
export const UPDATE_QUERIES = 'UPDATE_QUERIES'
export const AGENT_LDAP='AGENT_LDAP'
export const ERROR='ERROR'
export const FEEDBACK_VALUES = 'FEEDBACK_VALUES'
export const FEATURE_FLAG_AA_Q = 'aaqcsffprmscrt'
