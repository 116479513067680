import actionTypes from '../actionTypes'

const {
  SET_CASE_LIST,
  SET_ACTIVE_CASE,
  CLEAR_CASE_STATE,
  CASE_CREATED,
  SET_CREATE_CASE_PARAMS
} = actionTypes

const initialState = {
  caseList: [],
  activeCase: {},
  caseCreated: false,
  createCaseParams: { caseTitle: '', caseCategory: '' }
}

interface Action {
  type: string
  value: boolean
}

const caseReducer = (
  state: object = initialState,
  action: Action = { type: '', value: false }
) => {
  switch (action.type) {
    case SET_CASE_LIST: {
      return {
        ...state,
        caseList: action.value
      }
    }

    case CLEAR_CASE_STATE: {
      return initialState
    }

    case SET_ACTIVE_CASE: {
      return {
        ...state,
        activeCase: action.value
      }
    }

    case CASE_CREATED: {
      return {
        ...state,
        caseCreated: action.value
      }
    }

    case SET_CREATE_CASE_PARAMS: {
      return {
        ...state,
        createCaseParams: action.value
      }
    }

    default:
      return state
  }
}

export default caseReducer
