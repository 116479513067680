export const clearOCData = (payload: any) => {
  console.log('Triggered clearOCData with Payload', payload);
  return { type: 'CLEAR_OC_DATA', payload };
};

export const setOCSecrets = (payload: any) => {
  console.log('Triggered setOCSecrets with Payload', payload);
  return { type: 'SET_OC_SECRETS', payload };
};

export const setConversationStatus = (payload: any) => {
  console.log('Triggered setConversationStatus with Payload', payload);
  return { type: 'SET_CONVERSATION_STATUS', payload };
};

export const setReturnCustomerFlag = (payload: boolean) => {
  console.log('Triggered setReturnCustomerFlag with Payload', payload);
  return { type: 'SET_RETURN_CUSTOMER_FLAG', payload };
};

export const setTransferCount = (payload: any) => {
  console.log('Triggered setTransferCount with Payload', payload);
  return { type: 'SET_TRANSFER_COUNT', payload };
};

export const setTransferStatus = (payload: boolean) => {
  console.log('Triggered setTransferStatus with Payload', payload);
  return { type: 'SET_TRANSFER_STATUS', payload };
};

export const setAllowedAgentFlag = (payload: boolean) => {
  console.log('Triggered setAllowedAgentFlag with Payload', payload);
  return { type: 'SET_ALLOWED_AGENT_FLAG', payload };
};

export const setMultipleAccountLinkedFlag = (payload: string) => {
  console.log('Triggered setIsMultipleAccountLinked with Payload', payload);
  return { type: 'SET_MULTIPLE_ACCOUNT_LINKED', payload };
};

export const setOrigin = (payload: string) => {
  console.log('Triggered setOrigin with Payload', payload);
  return { type: 'SET_ORIGIN', payload };
};

export const setlinkedConversationId = (payload: string) => {
  console.log('Triggered setlinkedConversationId with Payload', payload);
  return { type: 'SET_LINKED_CONVERSATION_ID', payload };
};

type setOCDataType = {
  isZendesk: string;
  conversationId: string;
  isConversationStillOpen: boolean;
  originalSkillName: string;
  currentSkillName: string;
  assignedAgentLDAP: string;
  viewingAgentLDAP: string;
};

export const setOCData = (payload: setOCDataType) => {
  console.log('Triggered setOCData with Payload', payload);
  return { type: 'SET_OC_DATA', payload };
};

type contextData = {
  isUnauth: boolean;
  authCustomer:
    | {}
    | {
        rengaId: string;
        contactGuid: string;
        firstName: string;
        lastName: string;
        email: string;
        fraudStatus: string;
      };
  language: 'en' | 'ja';
  darkMode: boolean;
  returnCustomer:
    | {}
    | {
        isReturnCustomer: boolean;
        previouslyLinkedCaseId: string;
      };
  conversationId: string;
  isConversationStillOpen: boolean;
  originalSkillName: string;
  currentSkillName: string;
  assignedAgentLDAP: string;
  viewingAgentLDAP: string;
  isZendeskSkill: boolean;
  caseRelatedInfos: {};
};

export const setCurrentContext = (payload: contextData) => {
  console.log('Triggered setCurrentContext with Payload', payload);
  return { type: 'SET_CURRENT_CONTEXT', payload };
};
