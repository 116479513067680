import React from 'react'
import styles from '../Styles/styles.css'
export const Logo = () => (
  <svg width={30} height={30} viewBox='0 0 30 30'>
    <defs>
      <style>{'.lo-a{fill:transparent;}.lo-b{fill:#fff;}'}</style>
    </defs>
    <g transform='translate(3.5 3.881)'>
      <g transform='translate(-0.5 -0.881)'>
        <rect
          className='lo-a'
          width={30}
          height={30}
          transform='translate(-3 -3)'
        />
        <path
          className='lo-b'
          d='M13.218,3.5h5.46V17.357Z'
          transform='translate(1.167 1.523)'
        />
        <path
          className='lo-b'
          d='M7.967,3.5H2.5V17.357Z'
          transform='translate(1.739 1.523)'
        />
        <path
          className='lo-b'
          d='M10.642,9.023l3.48,8.227h-2.28L10.8,14.63H8.252Z'
          transform='translate(1.438 1.396)'
        />
      </g>
    </g>
  </svg>
)

export const ChatWorkspace = () => (
  <svg width={30} height={30} viewBox='0 0 30 30'>
    <path d='M15 0A15 15 0 110 15 15 15 0 0115 0z' fill='transparent' />
    <path
      className={styles.fillC}
      d='M21.06 8.515a.758.758 0 01.758.758v9.091a.758.758 0 01-.758.758h-7.132a.757.757 0 00-.536.222l-2.181 2.18v-1.645a.758.758 0 00-.758-.758H8.939a.758.758 0 01-.758-.758v-9.09a.758.758 0 01.758-.758zM8.939 7a2.273 2.273 0 00-2.273 2.273v9.091a2.273 2.273 0 002.273 2.273h.757v3.631a.379.379 0 00.647.267l3.9-3.9h6.818a2.273 2.273 0 002.273-2.273V9.273A2.273 2.273 0 0021.06 7z'
    />
  </svg>
)

export const AllConversation = () => (
  <svg width='30' height='30' viewBox='0 0 30 30'>
    <defs>
      <style>
        {
          '.ac-a,.ac-c,.ac-d{fill:#b3b3b3;}.ac-a{stroke:#707070;}.ac-b{fill:rgba(75,75,75,0);}.ac-c{opacity:0;}.ac-e{clip-path:url(#a);}'
        }
      </style>
      <clipPath id='a'>
        <path
          className='ac-a'
          d='M9.5,9V2H0V0H12V9Z'
          transform='translate(-14232 -16931)'
        />
      </clipPath>
    </defs>
    <circle className='ac-b' cx='15' cy='15' r='15' />
    <rect className='ac-c' width='18' height='18' transform='translate(6 6)' />
    <path
      className={styles.fillC}
      d='M9.457,7a.5.5,0,0,1,.5.5v5.973a.5.5,0,0,1-.5.5H4.771a.5.5,0,0,0-.352.146L2.986,15.542V14.462a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5V7.493a.5.5,0,0,1,.5-.5ZM1.493,6A1.493,1.493,0,0,0,0,7.493v5.973a1.493,1.493,0,0,0,1.493,1.493h.5v2.386a.249.249,0,0,0,.425.176l2.561-2.561h4.48a1.493,1.493,0,0,0,1.493-1.493V7.493A1.493,1.493,0,0,0,9.457,6Z'
      transform='translate(6.524 6.215)'
    />
    <g className='ac-e' transform='translate(14241 16940.191)'>
      <path
        className={styles.fillC}
        d='M9.457,7a.5.5,0,0,1,.5.5v5.973a.5.5,0,0,1-.5.5H4.771a.5.5,0,0,0-.352.146L2.986,15.542V14.462a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5V7.493a.5.5,0,0,1,.5-.5ZM1.493,6A1.493,1.493,0,0,0,0,7.493v5.973a1.493,1.493,0,0,0,1.493,1.493h.5v2.386a.249.249,0,0,0,.425.176l2.561-2.561h4.48a1.493,1.493,0,0,0,1.493-1.493V7.493A1.493,1.493,0,0,0,9.457,6Z'
        transform='translate(-14231.476 -16936.975)'
      />
    </g>
    <g className='ac-e' transform='translate(14244 16937.191)'>
      <path
        className={styles.fillC}
        d='M9.457,7a.5.5,0,0,1,.5.5v5.973a.5.5,0,0,1-.5.5H4.771a.5.5,0,0,0-.352.146L2.986,15.542V14.462a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.5-.5V7.493a.5.5,0,0,1,.5-.5ZM1.493,6A1.493,1.493,0,0,0,0,7.493v5.973a1.493,1.493,0,0,0,1.493,1.493h.5v2.386a.249.249,0,0,0,.425.176l2.561-2.561h4.48a1.493,1.493,0,0,0,1.493-1.493V7.493A1.493,1.493,0,0,0,9.457,6Z'
        transform='translate(-14231.476 -16936.975)'
      />
    </g>
  </svg>
)
export const HelpIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={30}
    height={30}
    viewBox='0 0 30 30'
  >
    <path
      className={styles.fillC}
      d='M16.091 18.966a.917.917 0 01-.977 1.008.931.931 0 01-.978-1.008.978.978 0 011.955-.029v.029zM14.977 10a4.616 4.616 0 00-2.259.536c-.059.031-.059.092-.059.153v1.486a.075.075 0 00.119.061 3.692 3.692 0 011.872-.5c.906 0 1.263.383 1.263.934 0 .475-.283.8-.773 1.3-.713.73-1.14 1.191-1.14 1.912a1.709 1.709 0 00.357 1.057.244.244 0 00.188.061h1.293a.065.065 0 00.059-.107 1.651 1.651 0 01-.238-.843c0-.459.55-.964 1.13-1.531a2.737 2.737 0 00.951-2.113c0-1.348-.98-2.406-2.763-2.406zm8.523 5A8.5 8.5 0 1115 6.5a8.5 8.5 0 018.5 8.5zm-1.825 0A6.675 6.675 0 1015 21.675 6.675 6.675 0 0021.675 15z'
    />
  </svg>
)

export const LogIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
    
    <rect id="Canvas" fill={styles.fillC} opacity="0" width="18" height="18" /><path className={styles.fillC} d="M16.5,12h-1a.5.5,0,0,0-.5.5V15H3V12.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,0,16.5,12Z" />
    <path  className={styles.fillC} d="M8.8245,13.428a.25.25,0,0,0,.35,0L12.9,9.6655a.391.391,0,0,0,.1-.263.4.4,0,0,0-.4-.4H10V1.5A.5.5,0,0,0,9.5,1h-1a.5.5,0,0,0-.5.5V9H5.4a.4.4,0,0,0-.4.4.391.391,0,0,0,.1.263Z" />
  </svg>
)

export const CCPLogIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18" fill='#b3b3b3'>
    <rect id="Canvas" fill="blue" opacity="0" width="18" height="18" /><ellipse cx="9" cy="3.5" rx="8" ry="2.5" />
    <path d="M5.3885,12.5895a1.211,1.211,0,0,1-.314-.8C3.2305,11.478,1.509,10.942,1,10.135V14.5C1,15.8805,4.582,17,9,17c.1385,0,.2735-.0045.4105-.0065Z" />
    <path d="M16.5,6.5V9.3635A1.18,1.18,0,0,0,17,8.5V5.135A5.06144,5.06144,0,0,1,14.3855,6.5Z" />
    <path d="M6.315,10.5H8.5V7.13C5.953,7.059,1.8365,6.4625,1,5.135V8.5c0,.996,1.868,1.8535,4.565,2.255A1.21847,1.21847,0,0,1,6.315,10.5Z" />
    <path d="M17.573,12.427A.25.25,0,0,0,17.3965,12H15V8H10v4H7.6035a.25.25,0,0,0-.1765.427L12.5,18Z" />
  </svg>
)

export const DarkMode = () => (
  <svg width={18} height={18} viewBox='0 0 18 18'>
    <defs>
      <style>{'.dm-a{fill:none;}.dm-b{fill:#6e6e6e;}'}</style>
    </defs>
    <rect className='dm-a' width='18' height='18' />
    <path
      className='dm-b'
      d='M7,14a7,7,0,1,1,7-7A7.008,7.008,0,0,1,7,14ZM7,1V13A6,6,0,1,0,7,1Z'
      transform='translate(2 2)'
    />
  </svg>
)

export const Sound = () => (
  <svg width={18} height={18} viewBox='0 0 18 18'>
    <defs>
      <style>{'.so-a,.so-b{fill:#6e6e6e;}.so-a{opacity:0;}'}</style>
    </defs>
    <rect className='so-a' width='18' height='18' />
    <path
      className='so-b'
      d='M3.372,6.036H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.372a.714.714,0,0,1,.466.173L7.4,15.8a.363.363,0,0,0,.6-.288V2.5a.363.363,0,0,0-.6-.286L3.839,5.865a.72.72,0,0,1-.467.172Z'
    />
    <path
      className='so-b'
      d='M11.02,9a3.468,3.468,0,0,1-.7,2.1.49.49,0,0,0,.043.644l.008.008a.5.5,0,0,0,.743-.045A4.478,4.478,0,0,0,11.1,6.277a.5.5,0,0,0-.742-.044l-.007.008a.491.491,0,0,0-.042.646A3.472,3.472,0,0,1,11.02,9Z'
    />
    <path
      className='so-b'
      d='M14.02,9a6.469,6.469,0,0,1-1.558,4.218.486.486,0,0,0,.031.658l.007.007a.5.5,0,0,0,.707,0l.03-.033a7.49,7.49,0,0,0-.013-9.714.5.5,0,0,0-.7-.061l-.03.027-.007.008a.488.488,0,0,0-.033.66A6.468,6.468,0,0,1,14.02,9Z'
    />
  </svg>
)

export const Profile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 0 18 18" width={24}>
    <defs>
      <style>
        {`.profile-nav-a { fill: #b3b3b3;}`}
      </style>
    </defs>
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path className="profile-nav-a" d="M9,.5A8.5,8.5,0,1,0,17.5,9,8.5,8.5,0,0,0,9,.5Zm5.491,13.59161a5.41289,5.41289,0,0,0-3.11213-1.56415.65361.65361,0,0,1-.5655-.65569V10.9256a.65656.65656,0,0,1,.16645-.42218A4.99536,4.99536,0,0,0,12.12006,7.3855c0-2.36029-1.25416-3.67963-3.14337-3.67963s-3.179,1.36835-3.179,3.67963A5.05147,5.05147,0,0,0,6.9892,10.5047a.655.655,0,0,1,.16656.42206v.94165a.64978.64978,0,0,1-.57006.65539,5.43158,5.43158,0,0,0-3.11963,1.5205,7.49965,7.49965,0,1,1,11.025.04731Z" />
  </svg>
)


export const TranslateIcon = () => (
  <svg id="button_-_translate" data-name="button - translate" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_150939" data-name="Rectangle 150939" width="18.642" height="18.467" fill="#b3b3b3"/>
    </clipPath>
  </defs>
  <path className={styles.fillC} id="Path_84231" data-name="Path 84231" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" fill="#1473e6" opacity="0"/>
  <g id="Group_161790" data-name="Group 161790" transform="translate(5.68 5.768)">
    <path className={styles.fillC} id="Path_84289" data-name="Path 84289" d="M17.425,5H7.071V15.354H17.425Zm-1.294,9.06H8.365V6.294h7.765Z" transform="translate(-6.81 -4.815)" fill="#b3b3b3"/>
    <path className={styles.fillC} id="Path_84290" data-name="Path 84290" d="M61.385,62.677h2.846l.518,1.294h1.294L63.456,57.5H62.161l-2.588,6.471h1.294Zm1.423-3.559.906,2.265H61.9Z" transform="translate(-57.37 -55.374)" fill="#b3b3b3"/>
    <path className={styles.fillC} id="Path_84291" data-name="Path 84291" d="M220.949,216.294h5.177v7.765h-7.765v-5.177h-1.294v6.471H227.42V215h-6.471Z" transform="translate(-209.04 -207.05)" fill="#b3b3b3"/>
    <g id="Group_161789" data-name="Group 161789">
      <g id="Group_161788" data-name="Group 161788" clipPath="url(#clip-path)">
        <path className={styles.fillC} id="Path_84292" data-name="Path 84292" d="M272.155,267.5v1.294h-2.588v1.294h1.33a4.293,4.293,0,0,0,.864,2.193,5.743,5.743,0,0,1-2.194.4v1.294a6.3,6.3,0,0,0,3.236-.77,6.3,6.3,0,0,0,3.236.77v-1.294a5.743,5.743,0,0,1-2.194-.4,4.294,4.294,0,0,0,.865-2.193h1.33v-1.294H273.45V267.5Zm.647,4.046a2.664,2.664,0,0,1-.6-1.457h1.206a2.664,2.664,0,0,1-.6,1.457" transform="translate(-259.599 -257.608)" fill="#b3b3b3"/>
        <path className={styles.fillC} id="Path_84293" data-name="Path 84293" d="M2.693,361.441v-2.31l.833.835.131.131.131-.131.456-.456.131-.131-.131-.131-1.925-1.925-.131-.131-.131.131L.131,359.25,0,359.381l.131.131.456.456.131.131.131-.131.833-.835v3.321H5.9v-1.011H2.693Z" transform="translate(0 -343.986)" fill="#b3b3b3"/>
        <path className={styles.fillC} id="Path_84294" data-name="Path 84294" d="M350.336,2.942l-.456-.456-.131-.131-.131.131-.833.835V0h-4.22V1.011h3.208v2.31l-.833-.835-.131-.131-.131.131-.456.456-.131.131.131.131,1.925,1.925.131.131.131-.131L350.336,3.2l.131-.131Z" transform="translate(-331.825)" fill="#b3b3b3"/>
      </g>
    </g>
  </g>
</svg>
)
