import React, { memo } from 'react'
import {
    ActionButton,
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Heading,
    Text,
} from '@adobe/react-spectrum';
import DeleteOutline from '@spectrum-icons/workflow/DeleteOutline';
import Alert from '@spectrum-icons/workflow/Alert'

const DeleteCustomPdcTrigger = ({ apiCallbackHandler, pdcContent, localizeStrings }) => {
    return (
        <DialogTrigger>
            <ActionButton UNSAFE_style={{ border: 'none', height: '12px', width: '12px', background: 'transparent', cursor: 'pointer' }} >
                <DeleteOutline />
            </ActionButton>
            {
                (close) => (
                    <Dialog>
                        <Content>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Heading>{localizeStrings['delete_pdc_title']}</Heading>
                                <Alert UNSAFE_style={{ color: '#D7373F'}} />
                            </div>
                            <Divider UNSAFE_style={{ background: '#E1E1E1', height: '2px', marginBottom: '16px', marginTop: '16px'}} />
                            <Text>{localizeStrings['delete_pdc_confirmation']}</Text>
                        </Content>
                        <ButtonGroup>
                            <Button variant="secondary" UNSAFE_style={{ border: '2px solid #4B4B4B', cursor: 'pointer'}} onPress={close}>{localizeStrings['cancel_text']}</Button>
                            <Button variant=" negative" UNSAFE_style={{ background: '#C9252D', color: 'white', border: 'none', cursor: 'pointer'}} onPress={() => {
                                apiCallbackHandler(pdcContent)
                                close()
                            }}>{localizeStrings['delete_text']}</Button>
                        </ButtonGroup>
                    </Dialog>
                )
            }
        </DialogTrigger >

    )
}

const MemoizedDeleteCustomPdcTrigger = memo(DeleteCustomPdcTrigger)
export default MemoizedDeleteCustomPdcTrigger