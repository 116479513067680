import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
    callbackEvent: any;
}

const ChangeCustomer = (props: IProps) => {

    console.log("activeCustomer in Change Customer", useSelector(
        (state: any) => state.ocState.activeConversationData
    ))

    const { rengaId = '', firstName = '', lastName = '', customerEmail: email = '', conversationId = '', phone = '' } = useSelector(
        (state: any) => state.ocState.activeConversationData
    )
    useEffect(() => {
        console.log('ECM - Active customer ', rengaId, firstName, lastName, email, phone, conversationId);
        if (rengaId) {
            props.callbackEvent('activeCustomer', {
                rengaId,
                firstName,
                lastName,
                email,
                phone,
                contactId: conversationId,
            });
        } else {
            props.callbackEvent('activeCustomer', {
                rengaId: '',
                firstName: '',
                lastName: '',
                email: '',
                contactId: '',
                phone: ''
            });
        }
    }, [rengaId]);
    return <div />;
};

export default ChangeCustomer;
