import getClient from '../utils/client';

export default async function getCasesById(conversationId: string, rengaId: string): Promise<any> {
  return getClient(true)
    .get(`/api/v1/cases/fetch?conversationId=${conversationId}&rengaId=${rengaId}`)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, getCasesResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, getCasesResponse : Exception Occured', e);
      return {};
    });
}
