const UNAUTH = 'unauthenticated';
const NON_RETURN_CUSTOMER = 'none';
const ZENDESK_QUEUES = ['sign-leg-ind-en', 'sign-leg-ent-en'];
const CHAT_TO_PHONE = 'chat-to-phone';
const CHANNEL_MESSAGING = 'messaging';

function checkConversationOpen(closedConversation: boolean, closedPhoneCall: boolean) {
  return !(closedConversation && closedPhoneCall);
}

// If its chat to phone, then phone leg should be loaded with linkedConversationId and not phone's contactId
function checkChatToPhoneFlow(origin: string, channel: string, contactId: string, linkedConversationId: string) {
  const chatToPhoneNewLeg: boolean = origin === CHAT_TO_PHONE && channel !== CHANNEL_MESSAGING;
  if (chatToPhoneNewLeg && linkedConversationId)
    return linkedConversationId
  return contactId
}

function getAlterConvId(linkedConversationId: string, phoneConvId: string, contactId: string) {

  const ids: any = [];
  //push conversationId
  if (contactId) {
    ids.push(contactId)
  }
  //In case of CHAT-TO-Phone and it is transferred leg
  if (linkedConversationId && contactId !== linkedConversationId) {
    ids.push(linkedConversationId)
  }
  //Incase of chat and phone landed in same leg
  if (phoneConvId && !(ids.includes(phoneConvId))) {
    ids.push(phoneConvId)
  }
  return ids;
}
function checkZendeskSkill(currentSkill: string) {
  return ZENDESK_QUEUES.includes(currentSkill.toLowerCase())
}

// Check Unauthenticated flow for two features: Chat and Phone
function checkUnauthFlow(customerType: string, currentQueue: string, customerId: string, isMultipleAccountLinked: string, channel: string) {
  if (checkZendeskSkill(currentQueue)) return false;
  const chatUnauthFlow: any = customerType && customerType.toLowerCase() === UNAUTH && channel === CHANNEL_MESSAGING
  // If customerId does not have rengaId or isMultipleAccountLinked, then navigate to Search Customer Page
  const phoneUnauthFlow: any = !customerId?.includes('@') || isMultipleAccountLinked === 'true';
  return (chatUnauthFlow || phoneUnauthFlow);
}

function checkReturnCustomer(returningCustomerType: string) {
  if (
    returningCustomerType &&
    returningCustomerType.toLowerCase() !== NON_RETURN_CUSTOMER
  ) {
    return true;
  }
  return false;
}

function checkZendeskTransfer(originalSkill: string, currentSkill: string) {
  if (checkZendeskSkill(originalSkill) && !checkZendeskSkill(currentSkill))
    return true;
  else
    return false;
}

// Function that checks and return if the Agent is allowed to do actions on widgets
function checkAllowedAgent(agentLinkedToConversation: boolean, multipartyAssignedAgent: any, conversationOpenStatus: boolean, multipleAgentsConnected: any) {
  // For Open Conversation, check multipartyAssignedAgent only 
  if (conversationOpenStatus) {
    if (multipleAgentsConnected !== undefined && multipartyAssignedAgent !== undefined)
      return multipartyAssignedAgent;
    // Chat flow - When multipartyAssignedAgent is not populated, then set allowedAgent to true
    else
      return true;
  }
  // If conversation is closed then use legacy allowedAgent flag, i.e, only agents linked to that conversation.
  else
    return agentLinkedToConversation;
}

export const parseContextData = (contextData: any) => {
  // Destructuring the context data
  const {
    jcAuthData: {
      customerId = '',
      isMultipleAccountLinked = '',
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      phoneNumber = '',
      queueName = '',
      currentQueue = '',
      linkedConversationId = '',
      returningCustomerType = '',
      returningCustomerCaseId = '',
      chatTransferCount = '0',
      customerType = '',
      channel = '',
      issueType = '',
      issueTypeCode = '',
      subissueType = '',
      subissueTypeCode = '',
      entitlementsList = '',
      productCode = '',
      subProductName = '',
      subProductCode = '',
      botFlow = '',
    } = {},
    jcMidConvData: {
      HostContextInfo = '',
      botIntent = '',
      botSubintent = '',
      botProductCode = '',
      botProductName = '',
    } = {},
    ocPlatformData: {
      chatInfo: {
        contactId = '',
      } = {},
      callInfo: {
        assignedAgent = undefined,
        multipleAgentsConnected = undefined,
        initialContactId: phoneConvId = undefined,
      } = {},
      chattingAgentInfo: {
        agentLDAP: chattingAgentLDAP = ''
      } = {},
      agentInfo: {
        agentLDAP = ''
      } = {},
      allowedAgent = false
    } = {},
    ocAppData: {
      language = 'en',
      darkMode = false,
      env_secrets = {},
      closedConversation = true,
      closedPhoneCall = true,
      originForCurrentConversation: origin = ''
    } = {}
  } = contextData

  const conversationOpenStatus = checkConversationOpen(closedConversation, closedPhoneCall);

  return {
    authCustomer: {
      rengaId: customerId,
      contactGuid: '',
      firstName: firstName,
      lastName: lastName,
      email: email,
      fraudStatus: '',
      phone: phone ? phone : phoneNumber
    },
    originalSkillName: queueName,
    currentSkillName: currentQueue,
    returnCustomer: {
      isReturnCustomer: checkReturnCustomer(returningCustomerType),
      previouslyLinkedCaseId: returningCustomerCaseId
    },
    transferCount: chatTransferCount,
    isConversationStillOpen: conversationOpenStatus,
    conversationId: checkChatToPhoneFlow(origin, channel, contactId, linkedConversationId),
    alterChannelConversationId: getAlterConvId(linkedConversationId, phoneConvId, contactId),
    assignedAgentLDAP: chattingAgentLDAP,
    viewingAgentLDAP: agentLDAP,
    allowedAgent: checkAllowedAgent(allowedAgent, assignedAgent, conversationOpenStatus, multipleAgentsConnected),
    language,
    darkMode,
    env: env_secrets,
    caseRelatedInfos: {
      assignedAgentLdap: chattingAgentLDAP,
      conversationId: contactId,
      jcAuthData: {
        channel: origin,
        issueType,
        issueTypeCode,
        subissueType,
        subissueTypeCode,
        entitlementsList,
        productCode,
        subProductName,
        subProductCode,
        botFlow,
        queueName,
        customerType,
      },
      jcMidConvData: {
        HostContextInfo,
        botIntent,
        botSubintent,
        botProductCode,
        botProductName,
      }
    },
    isZendeskSkill: checkZendeskSkill(currentQueue),
    isZendeskTransfer: checkZendeskTransfer(queueName, currentQueue),
    isUnauth: checkUnauthFlow(customerType, currentQueue, customerId, isMultipleAccountLinked, channel),
    isMultipleAccountLinked: isMultipleAccountLinked,
    multipleAgentsConnected,
    origin,
  }
};