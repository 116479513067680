import {
  ActionButton,
  Button,
  ProgressCircle,
  Tooltip,
  TooltipTrigger
} from '@adobe/react-spectrum'
import { find, sortBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { linkCase, unlinkCase } from '../api/casesApi'
import localization from '../language/localization'
import { getCasesAPI, setActiveCase } from '../store/actions/caseAction'
import { toggleNotification } from '../store/actions/uiActions'
import { LinkNotSelected, LinkSelected } from '../Theme/Icons/icons'
import { InfoIcon } from '../Theme/Icons/infoIcon'
import commonStyles from '../Theme/Styles/common.css'
import darkTheme from '../Theme/Styles/main/dark.css'
import lightTheme from '../Theme/Styles/main/light.css'
import darkTable from '../Theme/Styles/table/darkTable.css'
import lightTable from '../Theme/Styles/table/lightTable.css'
import { getSessionStorage, setSessionStorage } from '../utils/sessionStorage'

interface Iprops {
  handleViewChange: (flag: boolean) => void
  handleSearchView: (flag: boolean) => void
}
const CaseList = (props: Iprops) => {
  const dispatch = useDispatch()
  const {
    darkMode,
    isCaseListPage: showTable,
    language,
    customerDeeplinkURL: DYNAMICS_CUSTOMER_DEEPLINK_URL,
    caseDeeplinkURL: DYNAMICS_CASE_DEEPLINK_URL,
    readOnlyMode
  } = useSelector((state: any) => state.uiState)
  const localizeString = localization[language]
  const { caseList, activeCase, caseCreated } = useSelector(
    (state: any) => state.caseState
  )
  const { caseId: activeCaseId = '' } = activeCase
  const { loadCustomerData, c360Guid: customerGuid = '' } = useSelector(
    (state: any) => state.customerState
  )

  // Change load customer to active
  const { agentName } = useSelector(
    (state: any) => state.ocState.activeConversationData
  )

  console.log(
    'activeCustomer',
    useSelector((state: any) => state.ocState.activeConversationData)
  )

  const { firstName, lastName, orgId, rengaId, conversationId, customerEmail } = useSelector(
    (state: any) => state.ocState.activeConversationData
  )
  const styles = darkMode ? darkTheme : lightTheme
  const tableStyle = darkMode ? darkTable : lightTable

  const [trigger, setTrigger] = useState(false)
  const [activeCaseTitle, setActiveCaseTitle] = useState('')
  const [loader, setLoader] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState('');
  const { linkedCustomer: { c360Guid = '' } = {} } = loadCustomerData
  // to handle customer deeplink for where Db doc doesnt exist
  const finalCustomerGuid = c360Guid || customerGuid
  const thead = [
    [localizeString.table_column_case_title, 'table-case-title-column'],
    [localizeString.table_column_last_modified, 'last-updated-column'],
    [localizeString.table_column_status_reason],
    [localizeString.table_column_case_id],
    ['', 'table-link-column']
  ]

  const toggleLoader = (flag: boolean, message: string) => {
    setLoader(flag);
    setLoaderMessage(message);
  }
  useEffect(() => {
    setSessionStorage(conversationId, 'currentPage', 'caseList')
    setSessionStorage(conversationId, 'enableSearchCustomer', false)
    setSessionStorage(conversationId, 'activeCustomerEmail', customerEmail)
  }, [])

  useEffect(() => {
    if (rengaId && orgId) {
      dispatch(getCasesAPI(
        { orgId, rengaId, status: 'Active', conversationId },
        localizeString,
        toggleLoader
      ))
    }
  }, [orgId, rengaId])

  useEffect(() => {
    const getCaseTitle = () => {
      const { title = '' } =
        find(caseList, {
          caseId: activeCaseId
        }) || {}
      setActiveCaseTitle(title)
    }
    if (activeCaseId && caseList.length) {
      getCaseTitle()
    }
  }, [activeCaseId, caseList])


  const linkCaseId = async (caseId: string, values: any) => {
    console.log('read only mode', readOnlyMode, readOnlyMode || caseCreated || !!activeCaseId)
    if (readOnlyMode) {
      return;
    } else if (!activeCaseId) {
      const { guid } = values
      dispatch(setActiveCase({ caseId, guid }))
      dispatch(
        toggleNotification(true, localizeString.case_linked_notification)
      )
      setTimeout(() => {
        dispatch(toggleNotification(false, ''))
      }, 1000)
      try {
        const linkCaseResponse = await linkCase(
          conversationId,
          guid,
          caseId,
          agentName,
          caseCreated
        )
        if (linkCaseResponse && linkCaseResponse.data) {
          setTrigger(false)
        }
      } catch (err) {
        console.error('ecm: link case error', err)
      }
    } else if (caseId !== activeCaseId) {
      setTrigger(true)
      setTimeout(() => setTrigger(false), 1000)
    }
  }

  const unlinkCaseId = async () => {
    console.log('read only mode', readOnlyMode)
    if (readOnlyMode) {
      return;
    } else if (activeCaseId) {
      try {
        dispatch(setActiveCase({}))
        dispatch(
          toggleNotification(true, localizeString.case_unlinked_notification)
        )
        setTimeout(() => {
          dispatch(toggleNotification(false, ''))
        }, 1000)
        const unlinkCaseResponse = await unlinkCase(conversationId)
        if (unlinkCaseResponse && unlinkCaseResponse.data) {
          setTrigger(false)
        }
      } catch (err) {
        console.error('ecm: unlink case error', err)
      }
    }
  }

  const cachedata = getSessionStorage(conversationId)
  console.log("showChangeCustomer", cachedata);

  const { enableChangeCustomer = false } = cachedata;
  const showChangeCustomer = !readOnlyMode && enableChangeCustomer

  const checkCaseLinked = (valueCaseId: any) => valueCaseId === activeCaseId
  console.log('customer deepliink', finalCustomerGuid)
  const CustomerTop = () => {
    const customerDeeplinkURL =
      DYNAMICS_CUSTOMER_DEEPLINK_URL + finalCustomerGuid
    return (
      <React.Fragment>
        <div className={styles['customer-name']}>
          <a
            className={commonStyles['ecm-deeplink']}
            rel='noopener noreferrer'
            target='_blank'
            href={customerDeeplinkURL}
          >
            {' '}
            {firstName + ' ' + lastName}
          </a>
          {showChangeCustomer && (
            <React.Fragment>
              <div>|</div>
              <div
                className={styles['change-customer']}
                onClick={() => props.handleSearchView(true)}>
                {localizeString.case_list_change_customer}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={styles['case-id-title']}>
          {localizeString.header_case}
          {activeCaseId
            ? activeCaseTitle + ' - ' + activeCaseId
            : localizeString.header_no_caseId}
        </div>
      </React.Fragment>
    )
  }

  const onFocusHandler = () => {
    setTrigger(true)
    setTimeout(() => setTrigger(false), 1000)
  }

  const renderLoader = (styles: any) => (
    <div className={styles['main-loader']}>
      <ProgressCircle aria-label='Loading…' isIndeterminate />
      <div className={styles['main-loader-label']}>
        {loaderMessage}
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <div className={styles['customer-top']}>
        <CustomerTop />
      </div>
      <div className={styles['case-details']}>
        <div className={styles['select-title']}>
          {localizeString.select_existing_case}
        </div>
        {loader ? renderLoader(styles) : <div className={styles['ecm-outer-table-wrapper']}>
          <div className={styles['new-case-button-wrapper']}>
            <Button
              UNSAFE_className={styles['new-case-button']}
              isDisabled={readOnlyMode || caseCreated || !!activeCaseId}
              type='button'
              onPress={() => props.handleViewChange(false)}
              variant='primary'
            >
              {localizeString.create_case_button}
            </Button>
          </div>
          {/* To do */}
          {showTable ?
            (
              caseList.length < 1 ? (
                // change styles
                <div className={styles['ecm-enterprise-no-cases-font']}>
                  <InfoIcon />
                  <div style={{ paddingLeft: '10px' }}>
                    {localizeString.no_open_cases_msg}
                  </div>
                </div>
              ) : (
                <div className={tableStyle['table-wrapper']}>
                  <table className={tableStyle['custom-table']}>
                    <thead className={tableStyle.thead}>
                      <tr>
                        {thead.map((head) => (
                          <th
                            scope='col'
                            className={
                              tableStyle[head[1]] ? tableStyle[head[1]] : ''
                            }
                            key={head[0]}
                          >
                            {head[0]}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className={tableStyle.tbody}>
                      {sortBy(caseList, function (o: any) {
                        return moment(o.lastModified)
                      })
                        .reverse()
                        .map((values: any, index) => (
                          <tr
                            key={index}
                            className={
                              checkCaseLinked(values.caseId)
                                ? styles['tr-selected']
                                : ''
                            }
                          >
                            {/* Define class for specific column if needed */}
                            <td
                              className={tableStyle['table-case-title']}
                              onClick={() => linkCaseId(values.caseId, values)}
                              title={values.title}
                            >
                              <a
                                className={commonStyles['ecm-deeplink']}
                                rel='noopener noreferrer'
                                target='_blank'
                                href={DYNAMICS_CASE_DEEPLINK_URL + values.guid}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {values.title}
                              </a>
                            </td>
                            <td
                              className={tableStyle['table-last-update']}
                              onClick={() => linkCaseId(values.caseId, values)}
                              title={moment(values.lastModified).format(
                                'YYYY-MM-DD hh:mm A'
                              )}
                            >
                              {moment(values.lastModified).format(
                                'YYYY-MM-DD hh:mm A'
                              )}
                            </td>
                            <td
                              className={tableStyle['table-status-reason-text']}
                              onClick={() => linkCaseId(values.caseId, values)}
                              title={values.statusReasonText}
                            >
                              {values.statusReasonText}
                            </td>
                            <td
                              className={tableStyle['table-caseId']}
                              onClick={() => linkCaseId(values.caseId, values)}
                              title={values.caseId}
                            >
                              {values.caseId}
                            </td>
                            <td className={tableStyle['table-link']}>
                              {checkCaseLinked(values.caseId) ? (
                                <div className={styles['link-icon-selected']}>
                                  <TooltipTrigger
                                    crossOffset={0}
                                    defaultOpen={false}
                                    isDisabled={false}
                                    shouldFlip
                                    offset={0}
                                    placement='bottom'
                                    isOpen={!readOnlyMode && (trigger || undefined)}
                                    delay={0}
                                  >
                                    <ActionButton
                                      isQuiet
                                      UNSAFE_className={
                                        styles['link-icon-selected-1']
                                      }
                                      onFocus={onFocusHandler}
                                      onPress={unlinkCaseId}
                                      data-testid='ecm-unlink-button'
                                    >
                                      <LinkSelected />
                                    </ActionButton>
                                    <Tooltip placement='bottom' isOpen={trigger}>
                                      {localizeString.unlink_case_tooltip || ''}
                                    </Tooltip>
                                  </TooltipTrigger>
                                </div>
                              ) : (
                                <div
                                  className={styles['link-icon']}
                                  onClick={() => linkCaseId(values.caseId, values)}
                                >
                                  <LinkNotSelected />
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )
            ) : (
              <ProgressCircle
                UNSAFE_className={styles['table-loader']}
                aria-label='Loading…'
                isIndeterminate
              />
            )}
        </div>
        }
      </div>
    </React.Fragment>
  )
}

export default CaseList