import { CLEAN_CURRENT_QUERY, SWITCH_QUERY } from "../Store/constants/AAConstants"
import store from '../Store/Store'

export const localStorageUpdate = (conversationId, queryId) => {
    let conversationQueries = {}
      try {
        conversationQueries = JSON.parse(localStorage.getItem('conversationQuery') ?? '') || {}
      } catch (e) {
        conversationQueries = {}
      }
      conversationQueries[conversationId] = queryId
      localStorage.setItem('conversationQuery', JSON.stringify(conversationQueries))
}

export const getFastFollowFlags = () => {
  try {
    const localData: any = localStorage.getItem('fastFollowFlags')
    return JSON.parse(localData) || {}
  } catch (e) {
    console.log('fastFollowFlags fail in oc-aa-module-client', e)
    return {}
  }
}

export const findQueryForConversation = (conversationId) => {
    let conversationQueryList = {}
    try{
      conversationQueryList = JSON.parse(localStorage.getItem('conversationQuery') ?? '')
    } catch (e){
      console.error('Error parsing local storage for ', conversationId )
      conversationQueryList = {}
    }
    const currentConversationQuery = conversationQueryList?.[conversationId]
    const { recentQueries,  pinnedQueries} = store.getState().AAInfo;
    let isCurrentConversationQueryExist = recentQueries.find((query) => query.id === currentConversationQuery)
    if(!isCurrentConversationQueryExist) isCurrentConversationQueryExist = pinnedQueries.find((query) => query.id === currentConversationQuery)
    if(currentConversationQuery && isCurrentConversationQueryExist) {
      store.dispatch({ type: SWITCH_QUERY, payload: currentConversationQuery })
      return true
    } else {
      store.dispatch({ type: CLEAN_CURRENT_QUERY })
      return false
    }
}

