import getClient from '../utils/client'

export async function getCaseDetails(
  orgId: string,
  rengaId: string,
  status: string,
  conversationId: string
) {
  return getClient(true).get(
    `/api/v1/cases/fetch?orgId=${orgId}&rengaId=${rengaId}&status=${status}&conversationId=${conversationId}`
  )
}

export async function createCaseApi(
  conversationId: string,
  rengaId: string,
  orgId: string,
  createCaseData: any,
  agentName: string
) {
  return getClient().post(`/api/v1/cases/createCase`, {
    conversationId: conversationId,
    rengaId: rengaId,
    orgId: orgId,
    ...createCaseData,
    agentName
  })
}

export async function linkCase(
  conversationId: string,
  guid: string,
  id: string,
  linkedByAgent: string,
  caseCreated: boolean
) {
  return getClient().put(`/api/v1/cases/link`, {
    conversationId: conversationId,
    linkedCaseData_DME: {
      guid: guid,
      id: id,
      linkedByAgent: linkedByAgent,
      caseCreated: caseCreated
    }
  })
}

export async function unlinkCase(conversationId: string) {
  return getClient().put(`/api/v1/cases/unlink`, {
    conversationId: conversationId
  })
}
