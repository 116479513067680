import axios from "axios"
import { useState } from 'react'
import { Query } from "../Components/AdobeAnswersFrame"

/**
 * Hook for deleting a query from the backend
 */
const useDeletePinUnpinQueryHook = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isPinningUnpinning, setIsPinningUnpinning] = useState<boolean>(false)

  const deleteQuery = (
    isPinned: boolean,
    ldap: string,
    queryId: string,
    genAIURL: string
  ): Promise<any> => {
    setIsDeleting(true)
    const deleteQueryPath = `/adobe-answers/${isPinned ? 'pinned-items' : 'recent-items'}/${ldap}/${queryId}`

    return axios.delete(`${genAIURL}${deleteQueryPath}`, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
    .then((response) => {
      setIsDeleting(false)
      return response
    })
    .catch((err) => {
      setIsDeleting(false)
      console.log('Error deleting query: ', err?.message ?? 'Internal Error')
      throw err
    })
  }

  const pinUnpinQuery = (
    genAIURL: string,
    isPinned: boolean,
    ldap: string,
    queryId: string
  ) => {
    setIsPinningUnpinning(true)
    const updateQueryPath = `/adobe-answers/${isPinned ? 'pinned-items' : 'recent-items'}/${ldap}/${isPinned ? 'unpin' : 'pin'}/${queryId}`

    return axios.patch(
      `${genAIURL}${updateQueryPath}`,
      {},
      {
        headers: {
          'x-api-key': 'oac-custom-panel',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
        }
      }
    )
    .then((response) => {
      setIsPinningUnpinning(false)
      return response
    })
    .catch((err) => {
      setIsPinningUnpinning(false)
      console.log('Error pinning query: ', err?.message ?? 'Internal Error')
      throw err
    })
  }

  const updateQuery = (
    query: Query,
    genAIURL: string,
    isPinned: boolean,
    ldap: string,
    queryId: string
  ): Promise<any> => {
    setIsUpdating(true)
    const updateQueryPath = `/adobe-answers/${isPinned ? 'pinned-items' : 'recent-items'}/${ldap}/${queryId}`

    return axios.patch(`${genAIURL}${updateQueryPath}`, query, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
    .then((response) => {
      setIsUpdating(false)
      return response
    })
    .catch((err) => {
      setIsUpdating(false)
      console.log('Error updating the query: ', err?.message ?? 'Internal Error')
      throw err
    })
  }

  return {
    deleteQuery,
    isDeleting,
    isPinningUnpinning,
    isUpdating,
    pinUnpinQuery,
    updateQuery
  }
}

export default useDeletePinUnpinQueryHook
