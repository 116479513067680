declare global {
    interface Window {
        msCrypto: any
        crypto: any
    }
}

export const BANNER_TIMER = 'BANNER_TIMER';

export const generateUniqueId = () => {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return array[0];
}

export const getAuthHeaders = () => {
    if (localStorage.getItem('customPanelAccessToken')) {
        return {
            'x-api-key': 'oac-custom-panel',
            Authorization: JSON.parse(localStorage.getItem('customPanelAccessToken') || ''),
        };
    }
    return {};
}

export const getBannerTimer = (contactId: string) => {
    if (sessionStorage.getItem(BANNER_TIMER)) {
        const bannerTimer = JSON.parse(sessionStorage.getItem(BANNER_TIMER) || '')
        return bannerTimer?.[contactId] || {};
    }
    return {}
}

export const setBannerTimer = (bannerTimer: any) => {
    sessionStorage.setItem(BANNER_TIMER, JSON.stringify(bannerTimer));
}

function parseJSONContent(content: any): any | null {
    try {
        return JSON.parse(content);
    } catch (error) {
        return null;
    }
}

export function parseContent(content: any): string {
    const parsedContent = parseJSONContent(content);
    if (parsedContent && typeof parsedContent === 'object' && parsedContent.message) {
        const messageContent = parseJSONContent(parsedContent.message);
        // One time JSON.parse when message is converted by CCP
        if (messageContent === null && typeof (parsedContent.message) === 'string') {
            return parsedContent.message
        }
        // 2 times JSON.parse when message is converted by JC
        if (messageContent?.genericAttachments) {
            return messageContent?.genericAttachments[0]?.title;
        } else if (parsedContent.message && content.search(/GptResponse/i) !== -1) {
            return parsedContent?.message;
        }
    }
    return content;
}