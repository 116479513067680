const languages: any = {
  en_US: 'English',
  en_ZA: 'English',
  de_AT: 'German',
  en_AU: 'English',
  en_BE: 'English',
  fr_BE: 'French',
  nl_BE: 'Dutch',
  bg_BG: 'Bulgarian',
  pt_BR: 'Brazilian Portuguese',
  en_CA: 'English',
  fr_CA: 'French',
  de_CH: 'German',
  fr_CH: 'French',
  it_CH: 'Italian',
  zh_CN: 'Chinese Simplified',
  en_CY: 'English',
  cs_CZ: 'Czech',
  de_DE: 'German',
  da_DK: 'Danish',
  et_EE: 'Estonian',
  es_ES: 'Spanish',
  fi_FI: 'Finnish',
  fr_FR: 'French',
  en_GR: 'English',
  en_HK: 'English',
  zh_HK: 'Chinese Traditional',
  hu_HU: 'Hungarian',
  en_IE: 'English',
  en_IL: 'English',
  he_IL: 'Hebrew',
  en_IN: 'English',
  it_IT: 'Italian',
  ja_JP: 'Japanese',
  ko_KR: 'Korean',
  es_LA: 'Spanish',
  lt_LT: 'Lithuanian',
  de_LU: 'German',
  en_LU: 'English',
  fr_LU: 'French',
  lv_LV: 'Latvian',
  ar_MENA: 'Arabic',
  en_MENA: 'English',
  en_MT: 'English',
  es_MX: 'Spanish',
  nl_NL: 'Dutch',
  nb_NO: 'Norwegian',
  en_NZ: 'English',
  pl_PL: 'Polish',
  pt_PT: 'Portuguese',
  ro_RO: 'Romanian',
  ru_RU: 'Russian',
  sv_SE: 'Swedish',
  en_SEA: 'English',
  sl_SI: 'Slovenian',
  sk_SK: 'Slovakian',
  tr_TR: 'Turkish',
  zh_TW: 'Chinese Traditional',
  uk_UA: 'Ukrainian',
  en_GB: 'English',
};
export default languages;
