// @ts-nocheck
// This file is not in use, remove the above comment if that changes
import axios from 'axios';
import {
  ANSWER_LOADING,
  LANGUAGE,
  DART_THEME,
  UPDATE_CURRENT_QUERY,
  NEW_QUERY,
  SWITCH_QUERY,
  DELETE_QUERY,
  PIN_QUERY,
  CLEAN_CURRENT_QUERY,
  UPDATE_ANSWER_INDEX,
  UPDATE_FEEDBACK_REASONS,
  UPDATE_FEEDBACK,
  UPDATE_QUERIES,
  AGENT_LDAP,
  ERROR,
  FEEDBACK_VALUES
} from '../constants/AAConstants' 
import {Query, State} from '../constants/models'

const AAReducer = (
  // NOSONAR
  state: State = {
    darkMode: false,
    language: 'en', 
    isAnswerLoading: false,
    currentQuery: {
      id: '',
      date: '',
      description: '',
      question: '',
      answers: [],
      index: -1,
      sessionId:'',
      timestamp: 0,
    },
    queries: [],
    currentQueryIndex: -1,
    recentQueries: [],
    pinnedQueries: [],
    ldap: '',
    FeedBackValues: {
      THUMBS_DOWN_RESONS: {en: []},
      INAPROPRIATE_FLAGS: {en: []}
    }
  },
  action: any,
) => {
  const currentQuery = {...state.currentQuery}
  const recentQueryList = [...state.recentQueries];
  const pinnedQueryList = [...state.pinnedQueries];

  switch (action.type) {
    case DART_THEME:
      return { ...state, darkMode: action.payload };

    case LANGUAGE:
      return { ...state, language: action.payload };

    case AGENT_LDAP:
      const {ldap, genAIURL} = action.payload
      return { ...state, ldap, genAIURL };

    case UPDATE_QUERIES: 
    const {recentQueries = [], pinnedQueries = []} = action.payload
    return { ...state, recentQueries, pinnedQueries};

    case ANSWER_LOADING: 
      return { ...state, isAnswerLoading: action.payload };

    case UPDATE_CURRENT_QUERY: 
    const {responseAI: {answer = '', description = '', assistId = '' } = {}, pinOperation = undefined } = action.payload
      if(!currentQuery.answers) currentQuery.answers = []
      if(answer && assistId){
        currentQuery.answers.push({text: answer, assistId, feedback: null})
        currentQuery.index = currentQuery.answers?.length -1 
      }
      if(description) {
        currentQuery.description = description
      }
      if(pinOperation === 'forbitten') {
        currentQuery.pinOperation=pinOperation
      }
      //updated data in recent/pin queries
      const recentQueryListCopy =  recentQueryList.map( (query: Query) => {
        if(query?.id === currentQuery.id) {
          return currentQuery
        }
        return query
      })
      const pinnedQueryListCopy =  pinnedQueryList.map( (query: Query) => {
        if(query?.id === currentQuery.id) {
          return currentQuery
        }
        return query
      })
      return { ...state, currentQuery, recentQueries: recentQueryListCopy, pinnedQueries: pinnedQueryListCopy }; 

    case NEW_QUERY:
      const { newQuery } = action.payload
      if(!newQuery) return {...state}

      !newQuery.error && recentQueryList?.unshift(newQuery)
      return{...state, currentQuery: newQuery, recentQueries: recentQueryList, currentQueryIndex: newQuery?.id };
    
    case SWITCH_QUERY:
      let foundQuery = recentQueryList.find((query: Query) => query.id === action.payload)
      if(!foundQuery) foundQuery = pinnedQueryList.find((query: Query) => query.id === action.payload)
      if(!foundQuery) return {...state}
      const answerSaved = foundQuery.answer
      const assisTdSaved = foundQuery.assistId
      const feedback = foundQuery.feedback
      if(foundQuery && !foundQuery.answers && answerSaved){
        foundQuery.answers= []
        foundQuery.answers.push({text: answerSaved, assistId: assisTdSaved, feedback})
        foundQuery.index = 0
        delete foundQuery.answer
        delete foundQuery.assistId
      }
      return{ ...state, currentQuery: foundQuery, currentQueryIndex: action.payload };

    case CLEAN_CURRENT_QUERY: 
      return{...state, currentQuery: {}, recentQueries: recentQueryList, pinnedQueries: pinnedQueryList, currentQueryIndex: -1 };
    
    case DELETE_QUERY: 
    const {pinnedQueries: updatedPinnedQueries, recentQueries: updatedRecentQueries, currentQuery: nextQuery, currentQueryIndex: newcurrentQueryId}  = action.payload
    if(nextQuery && newcurrentQueryId) {
      const answerSaved1 = nextQuery?.answer
      const assistIdSaved1 = nextQuery?.assistId
      const feedback1 = nextQuery?.feedback
      if(answerSaved1 && nextQuery && !nextQuery?.answers && assistIdSaved1){
        nextQuery.answers= []
        nextQuery.answers.push({text: answerSaved1, assistId: assistIdSaved1, feedback1})
        nextQuery.index = 0
        delete nextQuery.answer
        delete nextQuery.assistId
      }
      return  {...state, recentQueries: updatedRecentQueries, pinnedQueries: updatedPinnedQueries, currentQuery: nextQuery , currentQueryIndex: newcurrentQueryId}
    } else {
      return {...state, recentQueries: updatedRecentQueries, pinnedQueries: updatedPinnedQueries }
    }
  
    case PIN_QUERY: 
      const {recentQueries: updatedRecentQueries1, pinnedQueries: updatedPinnedQueries1} = action?.payload
      return {...state, recentQueries: updatedRecentQueries1, pinnedQueries: updatedPinnedQueries1};

    case UPDATE_ANSWER_INDEX:
      const { id: currentQueryId, index } = action.payload
      currentQuery.index = index
      const updatedRecentQueryList = recentQueryList.map(query => {
        if(query.id === currentQueryId) return { ...query, index }
        return query
      })
      const updatedPinnedQueryList = pinnedQueryList.map(query => {
        if(query.id === currentQueryId) return { ...query, index }
        return query
      })
      return{...state, recentQueries: updatedRecentQueryList, pinnedQueries: updatedPinnedQueryList, currentQuery};

    case UPDATE_FEEDBACK_REASONS:
      const {resons, inapropriateFlags} = action.payload
      return{...state, feedbackResons: resons, inapropriateFlags};

    case UPDATE_FEEDBACK:
      const {type, assistId: answerId} = action.payload
      const currentQueryId1 = currentQuery.id
      const answerCopy = currentQuery?.answers.find((answer) => answer.assistId === answerId)
      if(answerCopy && answerCopy?.assistId === answerId) answerCopy.feedback = type
      //in case if query was just opened and contains only 1 answer
      if(currentQuery && currentQuery?.answer && currentQuery?.assistId === answerId){
        currentQuery.feedback = type
      }

      const updatedRecentQueryList1 = recentQueryList.map(query => {
        if(query.id === currentQueryId1) return currentQuery
        return query
      })
      const updatedPinnedQueryList1 = pinnedQueryList.map(query => {
        if(query.id === currentQueryId1) return currentQuery
        return query
      })
      return{...state, recentQueries: updatedRecentQueryList1, pinnedQueries: updatedPinnedQueryList1, currentQuery};

    case ERROR:
      return{...state, AAError: action.payload}

    case FEEDBACK_VALUES: 
    return {...state, FeedBackValues: action.payload}

    default:
      return state;
  }
};

export default AAReducer;
