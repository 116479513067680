import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionButton } from "@adobe/react-spectrum";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import RuCard from "../card/card-component";
import RuCardExpandView from "../expanded-card/card-expand-view";
import { URLS, CardDetails } from "../../interfaces";
import { EXPANDED_VIEW, UPDATE_CONV_EXPAND_VIEW_STATUS, UPDATE_CONV_CURRENT_OFFER_INDEX } from "../../redux/constants/ru-constants";
import styles from "./carousel.module.css";
import { useTheme } from "../../themes/ThemeContext";
import { renderOfferEvent } from '../../Analytics/vao-danalytics';

interface CarouselProps {
  cards: [CardDetails];
  channel: string;
  urls: URLS;
  customerId: string;
  queueName: string;
  convId: string;
  currentConvId: string;
  darkMode: boolean;
  defaultImage: any;
  isSalesContact: boolean;
  isVoiceContact: boolean;
  vaoWorkflowCategory: string;
  collapseRUDetailsFromOAC: string;
  callBackEvent: (event: any, eventData: object) => void;
}

const Carousel: React.FC<CarouselProps> = ({
  cards,
  urls,
  channel,
  customerId,
  isSalesContact,
  isVoiceContact,
  queueName,
  convId,
  currentConvId,
  darkMode,
  vaoWorkflowCategory,
  defaultImage,
  callBackEvent,
  collapseRUDetailsFromOAC
}) => {
  const dispatch: any = useDispatch();
  const { RUInfo: { convData = [], multipartyAssignedAgent = false } = {} } = useSelector((state: any) => state);
  const convDataKey = convId + customerId;
  const items = convData?.[convDataKey] || {}
  const [showCardExpandedView, setShowCardExpandedView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const { theme } = useTheme();
  const slidesContainerDarkmodeStyle = darkMode ? `${styles.slides__outer__container__border__dark}` : `${styles.slides__outer__container__border}`;

  useEffect(() => {
    setShowCardExpandedView(items?.expandView || false);
    if (items?.currentOfferIndex > cards?.length - 1) {
      //in case if for new customer it was returned less card amount then previouse 
      dispatch({
        type: UPDATE_CONV_CURRENT_OFFER_INDEX,
        payload: { index: 0, convId, customerId }
      })
      setActiveIndex(0)
    }
    else setActiveIndex(items?.currentOfferIndex || 0);
    setShowDetails(items?.expandView || false);

    // show/hide the CCM widget when the expanded view is closed
    callBackEvent(EXPANDED_VIEW, { expanded: items?.expandView || false });
  }, [convId, customerId])

  useEffect(() => {
    // analytics - below condition checks to avoid falsy values before the contactType is defined
    if (customerId === "") return;
    if (vaoWorkflowCategory === "") return;
    // if (convData?.[convId]?.customerId === customerId) return;

    Array.isArray(cards) && cards.length > 0 && cards.map((card) => {
      const { offerId, offerText, title: campaignName } = card;

      renderOfferEvent(vaoWorkflowCategory, offerId, offerText[0], campaignName, '');
    })
  }, [customerId])

  /**
   * 
   * @param index 
   * This method is used to set the index of the card that is clicked in the carousel,
   * not during the activeIndex change on component mount
   */
  const setExpandedViewIndex = (index: number) => {
    dispatch({
      type: UPDATE_CONV_CURRENT_OFFER_INDEX,
      payload: { index, convId, customerId }
    })
    setShowCardExpandedView(false)
    dispatch({
      type: UPDATE_CONV_EXPAND_VIEW_STATUS,
      payload: { status: false, convId, customerId }
    })
    callBackEvent(EXPANDED_VIEW, { expanded: false })
  }

  function goToIndex(index) {
    setActiveIndex(index);
    setExpandedViewIndex(index)
    setShowDetails(false);
  }

  function nextSlide() {
    if(activeIndex === cards.length - 1) return;
    const newIndex = activeIndex === cards.length - 1 ? 0 : activeIndex + 1;

    setActiveIndex(newIndex);
    setExpandedViewIndex(newIndex)
    setShowDetails(false);
  }

  function prevSlide() {
    if(activeIndex === 0) return;
    const newIndex = activeIndex === 0 ? cards.length - 1 : activeIndex - 1;

    setActiveIndex(newIndex);
    setExpandedViewIndex(newIndex);
    setShowDetails(false);
  }

  function handleExpandView(showDetails) {
    setShowCardExpandedView(showDetails)

    dispatch({
      type: UPDATE_CONV_EXPAND_VIEW_STATUS,
      payload: { status: showDetails, convId, customerId }
    });

    // show/hide the CCM widget when the expanded view is closed
    callBackEvent(EXPANDED_VIEW, { expanded: showDetails });
  }

  return (
    <div
      style={{
        background: theme.background,
        color: theme.color,
        borderColor: theme["border-color"],
      }}
      className={`${styles.custom__carousel}`}
      id="custom-carousel"
    >
      <div
        className={`${styles.inner__carousel__container} ${showCardExpandedView ? styles.carousel__expanded__view : ""
          }`}
      >
        <ActionButton
          onPress={prevSlide}
          UNSAFE_className={
            activeIndex !== 0
              ? `${styles.prev__btn}`
              : `${styles.btn_disabled} ${styles.prev__btn}`
          }
          isDisabled={!multipartyAssignedAgent}
          data-attribute-name="custom-carousel-prev-slide"
        >
          <ChevronLeft UNSAFE_style={{ fill: `${darkMode ? 'white' : '#000000'}`}} />
        </ActionButton>
        <div className={activeIndex !== cards?.length - 1 ? (
            `${styles.slides__outer__container} ${slidesContainerDarkmodeStyle}`
          ) : (
            `${styles.slides__outer__container}`
          )}>
          <div
            className={`${styles.slides}`}
            style={{ transform: `translateX(-${activeIndex * 282}px)` }}
          >
            {cards?.map((item, index) => {
              return (
                <RuCard
                  key={index}
                  urls={urls}
                  index={index}
                  convId={convId}
                  channel={channel}
                  isVoiceContact={isVoiceContact}
                  cardDetails={item}
                  darkMode={darkMode}
                  queueName={queueName}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  customerId={customerId}
                  activeIndex={activeIndex}
                  callBackEvent={callBackEvent}
                  isSalesContact={isSalesContact}
                  handleExpandView={handleExpandView}
                  defaultImage={defaultImage}
                  vaoWorkflowCategory={vaoWorkflowCategory}
                  showCardExpandedView={showCardExpandedView}
                  collapseRUDetailsFromOAC={collapseRUDetailsFromOAC}
                />
              );
            })}
          </div>
        </div>
        <ActionButton
          onPress={nextSlide}
          UNSAFE_className={
            activeIndex !== cards?.length - 1
              ? `${styles.next__btn}`
              : `${styles.btn_disabled} ${styles.next__btn}`
          }
          isDisabled={!multipartyAssignedAgent}
          data-attribute-name="custom-carousel-next-slide"
        >
          <ChevronRight UNSAFE_style={{ fill: `${darkMode ? 'white' : '#000000'}`}} />
        </ActionButton>
      </div>
      {!showCardExpandedView && (
        <div className={`${styles.dots}`}>
          {cards.map((_, index) => (
            <div onClick={() => goToIndex(index)}>
              <div
                className={`${styles.dot} ${index === activeIndex ? (darkMode ? styles.active_dark : styles.active) : ''}`}
                  key={index}
                  style={{ visibility: !multipartyAssignedAgent ? 'hidden' : 'visible'}} />
              </div>
          ))}
        </div>
      )}
      {showCardExpandedView && (
        <RuCardExpandView
          activeIndex={activeIndex}
          customerId={customerId}
          channel={channel}
          queueName={queueName}
          convId={convId}
          currentConvId={currentConvId}
          urls={urls}
          isVoiceContact={isVoiceContact}
          darkMode={darkMode}
          callBackEvent={callBackEvent}
          isSalesContact={isSalesContact}
          vaoWorkflowCategory={vaoWorkflowCategory}
          cardDetails={cards[activeIndex]}
          showCardExpandedView={showCardExpandedView}
        />
      )}
    </div>
  );
};

export default Carousel;
