import React, { useState } from 'react';
import { Link, ActionButton, DialogTrigger, Dialog, Heading, Divider, Content, Text, ComboBox, ButtonGroup, Button, Item } from '@adobe/react-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import { updateOfferStatus } from '../../redux/actions/ru-actions';
import { offerRejectReasonEvent } from '../../Analytics/vao-danalytics';
import { URLS, CardDetails } from '../../interfaces';
import styles from './offer-rejection-component.module.css'
import { useTheme } from '../../themes/ThemeContext';

interface RejectOfferDialog {
    close: () => void
    urls: URLS
    index: number
    convId: string
    customerId: string
    cardDetails: CardDetails,
    vaoWorkflowCategory: string
}

interface OfferRejectReasonComponent {
    urls: URLS
    index: number
    convId: string
    customerId: string
    cardDetails: CardDetails,
    vaoWorkflowCategory: string
}

const RejectOfferDialog = ({ close, convId, index, vaoWorkflowCategory, customerId, urls, cardDetails }: RejectOfferDialog) => {
    const dispatch: any = useDispatch()
    const [selectedRejectionReason, setSelectedRejectionReason] = useState('Select reason(s)')
    const { RUInfo: { customData: { rejectReasons: { reasons: rejectReasons = [] } = {} } = {} } = {} } = useSelector((state: any) => state)
    const { offerId, offerText, title: campaignName } = cardDetails;
    const offerName = offerText[0];
    const comboBoxItems = rejectReasons?.map((reason: any, index: number) => <Item key={index}>{reason}</Item>) || []

    const isValidRejectionReason = (reason: string) => rejectReasons.some(value => value === reason);

    const handleSubmit = () => {
        dispatch(updateOfferStatus({ convId, customerId, url: urls.PERSONALIZATION_DOMAIN, index, offerId, offerStatus: 'rejected', rejectReason: selectedRejectionReason }))
        offerRejectReasonEvent(vaoWorkflowCategory, 'submit', offerId, offerName, campaignName, selectedRejectionReason);

        close()
    }

    const handleCancel = () => {
        close();
        offerRejectReasonEvent(vaoWorkflowCategory, 'cancel', offerId, offerName, campaignName, '');
    }

    return (
        <Dialog UNSAFE_className={`${styles.dialog_box}`}>
            <Heading>Rejection Reason</Heading>
            <Divider />
            <Content>
                <Text>Please select the reason why the customer did not accept the offer:</Text>
                <ComboBox label='Rejection-Reason' allowsCustomValue={false} inputValue={selectedRejectionReason} onInputChange={setSelectedRejectionReason} defaultItems={comboBoxItems}>
                    {comboBoxItems}
                </ComboBox>
            </Content>
            <ButtonGroup>
                <Button variant='secondary' onPress={handleCancel}>
                    Cancel
                </Button>
                <Button
                    variant='cta'
                    isDisabled={!isValidRejectionReason(selectedRejectionReason)}
                    onPress={handleSubmit}
                    autoFocus
                >
                    Submit
                </Button>
            </ButtonGroup>
        </Dialog>
    )
}

const OfferRejectReasonComponent = ({ urls, index, convId, vaoWorkflowCategory, customerId, cardDetails }: OfferRejectReasonComponent) => {
    const { offerId, offerText, title: campaignName, rejectReason } = cardDetails;
    const { RUInfo: { multipartyAssignedAgent = false } = {} } = useSelector((state: any) => state)
    const { theme } = useTheme();
    const offerName = offerText[0];

    const rejectReasonHandler = () => {
        offerRejectReasonEvent(vaoWorkflowCategory, 'change-reason', offerId, offerName, campaignName, rejectReason)
    }

    return (
        <div className={`${styles.offer__rejection__reason__container}`}>
            <div className={`${styles.rejection__content}`}>
                <Text UNSAFE_className={`${styles.rejection__content__heading}`}>Reason for not accepting the offer</Text>
                <DialogTrigger>
                    <ActionButton isQuiet>
                        <Link
                            UNSAFE_style={{color: theme['color']}}
                            UNSAFE_className={`${styles.rejection__content__link}`}
                            isHidden={!multipartyAssignedAgent}
                            onPress={rejectReasonHandler}
                        >
                            Change
                        </Link>
                    </ActionButton>
                    {(close) => (
                        <RejectOfferDialog
                            convId={convId}
                            index={index}
                            vaoWorkflowCategory={vaoWorkflowCategory}
                            customerId={customerId}
                            urls={urls}
                            cardDetails={cardDetails}
                            close={() => close()}
                        />
                    )
                    }
                </DialogTrigger>
            </div>
            <Text UNSAFE_className={`${styles.rejection__reason__content}`}>{rejectReason}</Text>
        </div>
    )
}

export {
    OfferRejectReasonComponent,
    RejectOfferDialog
}
