String.prototype.toCamelCase = function (this: string): string {
    // Split the string by spaces, hyphens, or underscores
    const words = this.split(/[\s-_]+/);

    // Convert each word to camel case
    const camelCaseWords = words.map((word) => {
        // Lowercase the entire word
        word = word.toLowerCase();

        // Capitalize the first letter of each word except the first one
        word = word.charAt(0).toUpperCase() + word.slice(1) + ' ';
        // if (index !== 0) {
        //     word = word.charAt(0).toUpperCase() + word.slice(1);
        // }

        return word;
    });

    // Join all the words back together
    return camelCaseWords.join('');
};

export {};
