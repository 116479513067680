import { changeCustomer, loadCustomer } from '../../api/customerApi'
import { updateContextAttribute } from '../../api/updateContextAttributeApi'
import actionTypes from '../actionTypes'
const {
  SET_LOAD_CUSTOMER_DATA,
  TOGGLE_NOTIFICATION,
  SET_ACTIVE_CASE,
  CASE_CREATED,
  CLEAR_CUSTOMER_STATE,
  SET_ACTIVE_CONVERSATION_DATA,
  SET_CUSTOMER_GUID
} = actionTypes

type loadCustomerPayload = {
  conversationId: string
  rengaId: string
  orgId: string
  firstName: string
  lastName: string
  orgGuid: string
  c360Guid: string
  customerEmail: string
  customerPhone: string
  arrayOfConv: Array<string>
}

const DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT = 1000;
const CASE_LINKED_NOTIFICATION_TIMEOUT = 2000;
const CLEAR_CASE_LINKED_NOTIFICATION_TIMEOUT = 4000;

export const setLoadCustomerData = (
  payload: loadCustomerPayload,
  localizeString: any,
  agentDetails: any,
  callBackEvent: (eventType: string, eventData: object) => void,
  callChangeCustomer?: boolean
) => {
  if (callChangeCustomer) {
    return (dispatch: any) => {
      console.log('ecm: Triggered Change customer call')
      changeCustomer(payload.conversationId, {
        rengaId: payload.rengaId,
        orgId: payload.orgId,
        firstName: payload.firstName,
        lastName: payload.lastName,
        orgGuid: payload.orgGuid,
        c360Guid: payload.c360Guid,
        customerEmail: payload.customerEmail,
        customerPhone: payload.customerPhone
      })
        .then((response) => {
          const { success, json } = response.data
          if (success && json) {
            const {
              linkedCaseData_DME: {
                id: caseId = '',
                guid = '',
                caseCreated = false
              } = {}
            } = json
            const {
              conversationId = "",
              linkedCustomer: {
                firstName = "",
                lastName = "",
                orgId = "",
                rengaId = "",
                email = "",
                phone = ""
              } = {}
            } = json && json.data && json.data.Attributes ? json.data.Attributes : {}

            const { agentEmail = "", agentName = "" } = agentDetails
            dispatch({ type: SET_ACTIVE_CASE, value: { caseId, guid } })
            dispatch({ type: CASE_CREATED, value: caseCreated })
            dispatch({ type: SET_LOAD_CUSTOMER_DATA, value: json })
            console.log("ecm: Updating the changed customer")
            dispatch({ type: SET_ACTIVE_CONVERSATION_DATA, value: { firstName, lastName, orgId, rengaId, customerEmail: email, phone, conversationId, agentEmail, agentName } })
            // for Notification
            dispatch({
              type: TOGGLE_NOTIFICATION,
              value: true,
              payload: localizeString.customer_selected_notification
            })
            setTimeout(dispatch, DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT, { type: TOGGLE_NOTIFICATION, value: false, payload: '' })
            let payloadForContextAttribute = {
              attributes: {
                customerId: payload.rengaId || '',
                firstName: payload.firstName || '',
                lastName: payload.lastName || '',
                email: payload.customerEmail || '',
                orgId: payload.orgId,
                changeCustomerFrom: "ECM",
                ValueAddAction : '',
                entitlementsList : ''
              },
              contactId: payload.arrayOfConv[0]
            };
            if (payload.rengaId) {
              updateContextAttributeAPI(payloadForContextAttribute)
              if (payload.arrayOfConv.length > 1 && payload.arrayOfConv[1]) {
                payloadForContextAttribute.contactId =  payload.arrayOfConv[1];
                updateContextAttributeAPI(payloadForContextAttribute)
              }
            }
          }
          callBackEvent('CHANGE_CUSTOMER_TOGGLE', {})
        })
        .catch((err) => {
          console.error('ecm: load customer error', err)
        })
        .finally(() => {
          try {
            setTimeout(() => {
              ; (window as any).enable_CCM = false
                ; (window as any).enable_ECM = false
                ; (window as any).enable_CIP = false
                ; (window as any).enable_SCIP = false
            }, 50)
          } catch (e) {
            console.error('CCM Analytics Error Occurred')
          }
        })
    }

  } else {
    return (dispatch: any) => {
      console.log('ecm: Triggered load customer call without callChangeCustomer')
      const linkedCustomer = {
        rengaId: payload.rengaId,
        orgId: payload.orgId,
        firstName: payload.firstName,
        lastName: payload.lastName,
        orgGuid: payload.orgGuid,
        c360Guid: payload.c360Guid,
        email: payload.customerEmail
      }
      loadCustomer(payload.conversationId, linkedCustomer)
        .then((response) => {
          const { success, json } = response.data
          if (success && json) {
            const {
              linkedCaseData_DME: {
                id: caseId = '',
                guid = '',
                caseCreated = false
              } = {}
            } = json
            dispatch({ type: SET_ACTIVE_CASE, value: { caseId, guid } })
            dispatch({ type: CASE_CREATED, value: caseCreated })
            dispatch({ type: SET_LOAD_CUSTOMER_DATA, value: json })
            // for Notification
            dispatch({
              type: TOGGLE_NOTIFICATION,
              value: true,
              payload: localizeString.customer_selected_notification
            })
            if (caseId) {
              setTimeout(dispatch, CASE_LINKED_NOTIFICATION_TIMEOUT, {
                type: TOGGLE_NOTIFICATION,
                value: true,
                payload: localizeString.case_linked_notification
              })
              setTimeout(dispatch, CLEAR_CASE_LINKED_NOTIFICATION_TIMEOUT, { type: TOGGLE_NOTIFICATION, value: false, payload: '' })
            } else {
              setTimeout(dispatch, DEFAULT_CLEAR_CUSTOMER_LINKED_NOTIFICATION_TIMEOUT, { type: TOGGLE_NOTIFICATION, value: false, payload: '' })
            }
            callBackEvent('CHANGE_CUSTOMER_TOGGLE', {})
          }

        })
        .catch((err) => {
          console.error('ecm: load customer error', err)
        })
        .finally(() => {
          try {
            setTimeout(() => {
              ; (window as any).enable_CCM = false
                ; (window as any).enable_ECM = false
                ; (window as any).enable_CIP = false
                ; (window as any).enable_SCIP = false
            }, 50)
          } catch (e) {
            console.error('ECM Analytics Error Occurred')
          }
        })
    }
  }
}

export const setCustomerDetailsFromDB = (payload: any) => {
  return {
    type: SET_LOAD_CUSTOMER_DATA,
    value: payload
  }
}

export const setPersistantCustomerDetails = (payload: any) => {
  return {
    type: SET_LOAD_CUSTOMER_DATA,
    value: payload
  }
}

export const setGuidForCustomerDeeplink = (payload: any) => {
  return {
    type: SET_CUSTOMER_GUID,
    value: payload
  }
}

export const clearCustomerState = (payload: any) => {
  console.log('ecm: Triggered clear data for customer state ', payload)
  return {
    type: CLEAR_CUSTOMER_STATE,
    value: payload
  }
}

function updateContextAttributeAPI(payloadForContextAttribute: any) {
  console.log("ECM _UPDATE_CONTEXT_ATTRIBUTE Payload : ", payloadForContextAttribute)
  updateContextAttribute(payloadForContextAttribute).then((res) => {
    console.log("ECM _UPDATE_CONTEXT_ATTRIBUTE res : ", res.data)
  }).catch((err) => {
    console.log("ECM _UPDATE_CONTEXT_ATTRIBUTE Err : ", err)
  })
}
