import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext();

const themes = {
  light: {
    background: '#ffffff',
    color: '#000000',
    'border-color': '#e1e1e1',
    border: '1px solid #e1e1e1',
    'inner-text-color': '#4b4b4b',
    'copy-icon': 'black',
    'box1-color': '#dcdcdc',
    'box2-color': '#f5f5f5'
  },
  dark: {
    background: '#2f2f2f',
    color: '#e3e3e3',
    'border-color': '#5a5a5a',
    border: '1px solid #5a5a5a',
    'inner-text-color': '#e3e3e3',
    'copy-icon': 'white',
    'box1-color': '#3e3e3e',
    'box2-color': '#4d4d4d'
  },
};

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('light');

  const toggleTheme = (darkMode) => {
    const theme = darkMode ? 'dark' : 'light'
    setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme: themes[theme], toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}