import getClient from '../utils/client';

type loadCustomerPayload = {
  conversationId: string;
  linkedCustomer: {
    rengaId: string;
    contactGuid: string;
    lastName: string;
    firstName: string;
    email: string;
    fraudStatus: string;
  };
};

export default async function loadCustomer(payload: loadCustomerPayload): Promise<any> {
  return getClient(true)
    .post(`/api/v1/customer/load`, payload)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, loadCustomerResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      if (e && e.message && e.message.includes('API Calls cancelled on unmount')) {
        console.log('oc-ccm-module, loadCustomerResponse - cancelled');
        return { cancelled: true };
      }
      console.log('oc-ccm-module, loadCustomerResponse : Exception Occured', e);
      return {};
    });
}
