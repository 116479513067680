import React, { useEffect, useRef, useState } from 'react'
import { Flex } from '@adobe/react-spectrum'
import styles from '../Theme/Styles/styles.css'
import localization from '../lang/localization'
import ActiveBanner from './ActiveBanner'
import { Clock } from '../Theme/Icons'
import { getBannerTimer, setBannerTimer } from '../util'

interface stateCallConnection {
    contactId: string;
    connectionId: string;
    isHold: boolean;
    state: string;
    endPoint: string;
}

type propsData = {
    darkMode: boolean
    lang: string
    callStatus: string
    currentContactId: string
    chatStatus: string
    chatId: string
    bannerInfo: {
        contactId: string;
        isAcw: boolean;
        customerCount: number;
        isRinging: boolean,
        queueNames: any,
        callConnections: stateCallConnection[];
    }
    callBackEvent: (eventType: string, eventData: object) => void
}

export const CALL_STATUS = { ACTIVE: 'Active', CLOSED: 'Closed' }
export const CLEAR_CONTACT = 'clear-contact';


const Banner = React.memo((props: propsData) => {
    const {
        darkMode = false, callStatus = '', currentContactId, callBackEvent, lang = 'en', chatStatus = '', chatId = '',
        bannerInfo: {
            isAcw = false,
            contactId = '',
            queueNames = {},
            callConnections = []
        } = {}
    } = props;
    const clockInterval = useRef<any>(null);
    const bannerTimer = getBannerTimer(contactId);
    const [timer, setTimer] = useState(0);
    const activeChatAndCallStatus = (chatStatus && chatStatus !== CALL_STATUS.CLOSED) || (callStatus && callStatus === CALL_STATUS.ACTIVE);

    useEffect(() => {
        const timerVal = bannerTimer?.acwTime ? (Math.round((new Date().getTime() - new Date(bannerTimer.acwTime).getTime()) / 1000) || 0) : 0;
        setTimer(timerVal);
        if (timerVal > 0) {
            handleClearInterval();
            clockInterval.current = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }
        return () => {
            handleClearInterval();
        }
    }, [currentContactId]);

    useEffect(() => {
        isAcw ? handleTimer() : resetClock()
    }, [isAcw])

    const handleTimer = () => {
        if (!activeChatAndCallStatus || clockInterval?.current) return;
        const newBannerTimer = { ...bannerTimer } || {};
        newBannerTimer.acwTime = new Date();
        setBannerTimer({ [contactId]: newBannerTimer });
        clockInterval.current = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
    }

    const resetClock = () => {
        if (!activeChatAndCallStatus) return;
        handleClearInterval();
        setTimer(0);
        const newBannerTimer = { ...bannerTimer } || {};
        newBannerTimer.acwTime = '';
        setBannerTimer({ [contactId]: newBannerTimer });
    }

    const handleClearContact = () => {
        callBackEvent(CLEAR_CONTACT, {});
        handleClearInterval();
    }

    const handleClearInterval = () => {
        if(clockInterval.current) {
            clearInterval(clockInterval.current);
            clockInterval.current = null;
        } 
    }

    const clockTimer = new Date(timer * 1000).toISOString().substring(11, 19) || '';
    const afterCallWork = () => {
        return (
            <div className={styles['ccp-banner-after-call']}>
                <div className={styles['left-sec']}>
                    <span className={styles['call-work-text']}>{localization[lang]?.TELEPHONY.ACW} </span>
                    <span className={styles['call-work-timer']}><Clock />{clockTimer} {localization[lang]?.TELEPHONY.TIME_ELAPSED}</span>
                </div>
                <div className={styles['right-sec']}> <span className={styles['clear-contact']} onClick={handleClearContact}>{localization[lang]?.TELEPHONY.CLEAR_CONTACT}</span> </div>
            </div>
        )
    }

    if ((!isAcw && contactId && chatId) && (!activeChatAndCallStatus || ((!chatId || !contactId) && !callConnections.length))) {
        return <div />
    }

    if (isAcw && contactId === currentContactId) {
        return (
            <div>
                <Flex UNSAFE_className={styles['ccp-banner']}>
                    {afterCallWork()}
                </Flex>
            </div>
        )
    }

    return (
        <div>
            <Flex UNSAFE_className={styles['ccp-banner']}>
                {
                    callConnections.length > 0 &&
                    callConnections.map((callConnection: stateCallConnection) => (
                        <ActiveBanner
                            key={callConnection.connectionId}
                            darkMode={darkMode}
                            lang={lang}
                            queueNames={queueNames}
                            callConnection={callConnection}
                            connectionCount={callConnections.length}
                            callBackEvent={callBackEvent}
                        />
                    ))
                }
            </Flex>
        </div>
    )
})

export default Banner;