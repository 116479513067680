const initialState: any = {
  getCaseApiResponse: {},
  activeCase: {
    guid: '',
    id: '',
    linkedByAgent: '',
    caseCreated: false,
  },
};

type actionType = {
  type: string;
  payload: any;
};

const caseReducer = (state: any = initialState, action: actionType = { type: '', payload: '' }) => {
  switch (action.type) {
    case 'GET_CASES_RESPONSE': {
      return {
        ...state,
        getCaseApiResponse: action.payload,
      };
    }
    case 'SET_ACTIVE_CASE': {
      return {
        ...state,
        activeCase: action.payload,
      };
    }
    case 'CLEAR_CASE_DATA': {
      return initialState;
    }
    default:
      return state;
  }
};

export default caseReducer;
