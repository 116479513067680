import axios from 'axios';
import {
  DART_THEME,
  UPDATE_CIP_DATA,
  UPDATE_SCIP_DATA,
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_LOADING,
  LANGUAGE,
  UPDATE_IFRAME_HEIGHT,
  SHOW_SEARCH_CUSTOMER_TABLE,
  CURRENT_CONTACT_ID,
  FRAUD_ALERT_STATUS,
  LOADER,
  ORDER_QUOTE,
  CUSTOMER_OFFER,
  USER_PERSISTED,
  SET_CIS_LOADER,
  CONVERSATION_STATUS,
  JCAUTHDATA,
  PRESISTCHATINFO,
  CIS_ALLOWED_AGENT,
  PHONE_VALIDATION,
  CART_DETAILS
} from '../constants/customerInfoConstants';
import countries from '../../services/countries';

export const loader = (isload: boolean) => async (dispatch: any) => {
  try {
    dispatch({ type: LOADER, payload: isload });
  } catch (error) { }
};
export const updateConversationStatus = (isCIP: boolean, isConverstationActive: boolean) => async (dispatch: any) => {
  try {
    console.log('SCIP updateConversationStatus', isCIP, isConverstationActive);
    dispatch({ type: CONVERSATION_STATUS, payload: isConverstationActive });
  } catch (error) {
    console.log('SCIP updateConversationStatus action error', error);
  }
};
export const setCISloader = (isload: boolean) => async (dispatch: any) => {
  try {
    dispatch({ type: SET_CIS_LOADER, payload: isload });
  } catch (error) { }
};
export const setDarkTheme = (darkMode: boolean) => async (dispatch: any) => {
  try {
    dispatch({ type: DART_THEME, payload: darkMode });
  } catch (error) { }
};
export const setLanguage = (language: string) => async (dispatch: any) => {
  try {
    dispatch({ type: LANGUAGE, payload: language });
  } catch (error) { }
};
export const showSearchCustomerTable = (showTable: boolean) => async (dispatch: any) => {
  try {
    console.log('SearchCustomerTable showTable', showTable);
    dispatch({ type: SHOW_SEARCH_CUSTOMER_TABLE, payload: showTable });
  } catch (error) { }
};
export const showfraudAlertStatus = (showFraudAlert: any) => async (dispatch: any) => {
  try {
    dispatch({ type: FRAUD_ALERT_STATUS, payload: showFraudAlert });
  } catch (error) { }
};
export const updateIframeHeight = (height: string) => async (dispatch: any) => {
  try {
    dispatch({ type: UPDATE_IFRAME_HEIGHT, payload: height });
  } catch (error) { }
};
export const updatejcAuthData = (values: any) => async (dispatch: any) => {
  try {
    dispatch({ type: JCAUTHDATA, payload: values });
  } catch (error) { }
};
export const updatePresistChatInfo = (values: any) => async (dispatch: any) => {
  try {
    console.log('SCIP updatePresistChatInfo', values);
    dispatch({ type: PRESISTCHATINFO, payload: values });
  } catch (error) { }
};
export const customerOffer = (isCIP: boolean, customerOfferRes: any) => async (dispatch: any) => {
  try {
    if (isCIP) return dispatch({ type: CUSTOMER_OFFER, payload: customerOfferRes });
  } catch (error) {
    console.log('SCIP cx offer action error', error);
  }
};
export const getOrderQuote = (isCIP: boolean, orderQuote: any) => async (dispatch: any) => {
  try {
    if (!isCIP) return dispatch({ type: ORDER_QUOTE, payload: orderQuote });
  } catch (error) {
    console.log('SCIP orderQuote action error', error);
  }
};

export const updateWidget = (CIP: boolean, contextData: any) => async (dispatch: any) => {
  try {
    console.log('CIP updateWidget action', CIP, contextData[0]['Renga ID']);
    if (CIP) return dispatch({ type: UPDATE_CIP_DATA, payload: contextData });

    return dispatch({ type: UPDATE_SCIP_DATA, payload: contextData });
  } catch (error) {
    console.log('CIP updateWidget action error', error);
  }
};
export const cisAllowAgent = (isallowed: boolean) => async (dispatch: any) => {
  try {
    console.log('SCIP CIS AllowAgent Action', isallowed);
    return dispatch({ type: CIS_ALLOWED_AGENT, payload: isallowed });
  } catch (error) {
    console.log('SCIP CIS AllowAgent Action error', error);
  }
};

export const updateCartDetails = (cartDetails: any) => async (dispatch: any) => {
  try {
    console.log('SCIP - Cart Details Action', cartDetails);
    return dispatch({ type: CART_DETAILS, payload: cartDetails });
  } catch (error) {
    console.log('SCIP - Cart Details Action error', error);
  }
};

export const updatecurrentContactId = (id: any) => async (dispatch: any) => {
  try {
    console.log('updatecurrentContactId', id);
    return dispatch({ type: CURRENT_CONTACT_ID, payload: id });
  } catch (error) { }
};

export const getcustomerInfo = (cipURL: any, conversationId: string, email: string, phone: string, presistChatInfo: any) => async (dispatch: any) => {
  if (email === '' && phone === '') return;
  try {
    dispatch({ type: SEARCH_CUSTOMER_LOADING, payload: true });
    const params = {
      conversationId,
      email,
      phone,
    };
    const response: any = await axios.post(`${cipURL}/api/v1/customer/search`, params, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
    });
    const customerAccounts = response?.data?.json ? response.data.json : ['notFound'];
    console.log('SCIP getcustomerInfo response', response, customerAccounts, params, presistChatInfo);
    dispatch({ type: SEARCH_CUSTOMER_LOADING, payload: false });
    dispatch({ type: SEARCH_CUSTOMER, payload: customerAccounts });
    presistChatInfo[conversationId] = { email, phone, searchCustomer: customerAccounts };
    return dispatch(updatePresistChatInfo(presistChatInfo));
  } catch (e) {
    const customerAccounts = ['notFound'];
    dispatch({ type: SEARCH_CUSTOMER, payload: customerAccounts });
    dispatch({ type: SEARCH_CUSTOMER_LOADING, payload: false });
    console.error(e);
  }
};

export const updatesearchCustomerResPI = (url: any, scipData: any, searchCustomerRes: any, conversationId: string, callBackEvent: any) => async (dispatch: any) => {
  try {
    if (searchCustomerRes.rengaId === scipData[0]['Renga ID'][0]) {
      console.log('SCIP updatesearchCustomerResPI same ranga', scipData[0]['Renga ID'][0], searchCustomerRes.rengaId);
      return dispatch(showSearchCustomerTable(false));
    }
    dispatch(loader(true));
    console.log('SCIP updatesearchCustomerResPI', scipData, searchCustomerRes, JSON.parse(localStorage.getItem('fastFollowFlags')));
    // get Highest Entitlement of customer
    let entitlementList;
    if (JSON.parse(localStorage.getItem('fastFollowFlags')).ENTITLEMENT) {
      const rengaID = searchCustomerRes.rengaId ? searchCustomerRes.rengaId : scipData[0]['Renga ID'];
      const entitlementResponse: any = await axios.get(`${url.CCP_API_END_POINT}/api/v1/customer/entitlement`, {
        params: { userId: rengaID },
        headers: { 'x-api-key': 'oac-connect-service', Authorization: localStorage.getItem('ims-token').replaceAll('"', '') ?? '' },
      });
      const highestEntitlement = entitlementResponse.data.entitlements;
      entitlementList = Array.isArray(highestEntitlement) ? highestEntitlement.map(entitlement => entitlement?.productName) : highestEntitlement?.productName ?? '';
      console.log('SCIP entitlementResponse', entitlementResponse.data, entitlementList);
    }

    const updatedPI = {
      Title: 'Personal Info',
      Name: searchCustomerRes.firstName ? [`${searchCustomerRes.firstName} ${searchCustomerRes.lastName}`] : [scipData[0].Name],
      'Email Address': searchCustomerRes.email ? [searchCustomerRes.email] : [scipData[0]['Email Address']],
      Country: searchCustomerRes.countryCode ? [countries[searchCustomerRes.countryCode]] : [scipData[0].Country],
      Language: scipData[0].Language,
      'Renga ID': searchCustomerRes.rengaId ? [searchCustomerRes.rengaId] : [scipData[0]['Renga ID']],
      Phone: searchCustomerRes.phone ? [searchCustomerRes.phone] : [scipData[0]['Phone']],
      'Active Subscription Plan': entitlementList ? [entitlementList] : scipData[0]['Active Subscription Plan'],
      'Value Add Action': scipData[0]['Value Add Action'],
    };

    scipData[0] = updatedPI;
    dispatch({ type: UPDATE_SCIP_DATA, payload: scipData });
    dispatch(showSearchCustomerTable(false));
    const params = {
      conversationId,
      linkedCustomer: {
        rengaId: searchCustomerRes.rengaId || '',
        contactGuid: '',
        phone: searchCustomerRes.phone || '',
        firstName: searchCustomerRes.firstName || '',
        lastName: searchCustomerRes.lastName || '',
        email: searchCustomerRes.email || '',
        fraudStatus: searchCustomerRes.temporaryStatus || '',
      },
      entitlement: entitlementList || '',
      customerOfferList: '',
      sourceWidget: 'SCIP',
    };
    console.log("SCIP DATA", scipData)
    const conversationDetails = scipData.find((ele) => {
      if (ele.Title === 'Conversation Info') {
        return ele;
      }
    })
    console.log("conversationDetails", conversationDetails)
    const conversationIdArray = [];
    if (conversationDetails['Chat ID'] && conversationDetails['Chat ID'][0]) {
      conversationIdArray.push(conversationDetails['Chat ID'][0])
    }
    if (conversationDetails['Phone ID'] && conversationDetails['Phone ID'][0]) {
      conversationIdArray.push(conversationDetails['Phone ID'][0])
    }
    const payloadForUpdateContextAttributes: any = {
      attributes: {
        customerId: searchCustomerRes.rengaId || '',
        firstName: searchCustomerRes.firstName || '',
        lastName: searchCustomerRes.lastName || '',
        email: searchCustomerRes.email || '',
        changeCustomerFrom: 'SCIP',
        ValueAddAction : '',
        offerEligibility: '',
        entitlementsList : ''
      },
      contactId: conversationIdArray[0],
    };
    dispatch({ type: USER_PERSISTED, payload: params });
    const response: any = await axios.put(`${url.CIP_DOMAIN}/api/v1/customer/change`, params, {
      headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
    });
    console.log('SCIP isUserPersisted put response', `${url.CIP_DOMAIN}/api/v1/customer/change`, response.data);
    callBackEvent('CHANGE_CUSTOMER_TOGGLE')
    dispatch(loader(false));
    if (searchCustomerRes.rengaId) {
      updateContextAttributeAPI(url,payloadForUpdateContextAttributes);
      if(conversationIdArray.length > 1 && conversationIdArray[1]){
        payloadForUpdateContextAttributes.contactId = conversationIdArray[1]
        updateContextAttributeAPI(url,payloadForUpdateContextAttributes);
      }
    }
  } catch (e) {
    console.log('SCIP updatesearchCustomerResPI error', e);
    dispatch(loader(false));
  }
};

async function  updateContextAttributeAPI(url,payloadForUpdateContextAttributes) {
  console.log('SCIP_payloadForUpdateContextAttributes', `${url.CCP_API_END_POINT}/api/updateContactAttributes`, 'payload', JSON.stringify(payloadForUpdateContextAttributes));
  const updateContextResponse: any = await axios.post(`${url.CCP_API_END_POINT}/api/updateContactAttributes`, payloadForUpdateContextAttributes, {
    headers: { 'x-api-key': 'oac-connect-service', Authorization: localStorage.getItem('ims-token').replaceAll('"', '') ?? '' },
  });
  console.log('SCIP_updateContextResponse', JSON.stringify(updateContextResponse.data));
}

export const updatePhoneValidation = (valid: any) => async (dispatch: any) => {
  try {
    return dispatch({ type: PHONE_VALIDATION, payload: valid });
  } catch (error) {
    console.log('updatePhoneValidation Error', error);
  }
};
