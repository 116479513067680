import React, { useEffect, useState } from 'react'
import { ActionButton, DialogContainer, Flex, Tooltip, TooltipTrigger } from '@adobe/react-spectrum'
import styles from "../Theme/Styles/telephonyBtns.styles.css"
import { Hold, End, Join, Mute, Swap, DialogIcon, Transfer } from '../Theme/Icons/DialpadIcons';
import localization from "../lang/localization";
import CallButtonsPopup from './EndCallPopup';

type propsData = {
    lang: string
    close: any
    callStatus: string
    isHold: boolean
    isMute: boolean
    isJoin: boolean
    isSwap: boolean
    isTransfer: boolean
    currentContactId: string
    callConnectionCount: number
    callBackEvent: (eventType: string, eventData: object) => void
    closePopup: (close: any, clear: boolean, transferType: string) => void
}

const DialpadButtons = React.memo((props: propsData) => {
    const { lang, close, callStatus, isHold, isMute, isJoin, isSwap, isTransfer, currentContactId, callConnectionCount, callBackEvent, closePopup } = props;
    const [action, setAction] = useState({
        end: {
            disabled: false
        },
        hold: {
            click: false,
            disabled: false
        },
        mute: {
            click: false,
            disabled: false
        },
        dialog: {
            click: false,
            disabled: false
        },
        join: {
            click: false,
            disabled: false
        },
        swap: {
            click: false,
            disabled: true
        },
        transfer: {
            click: false,
            disabled: false
        }
    });
    const [isOpen, setOpen] = useState(false);

    const onCallAction = (e: any) => {
        const event = e.target.id;
        if (event === 'mute' || event === 'hold') {
            const val = !action?.[event].click;
            callBackEvent(`${event}call`, { [event]: val });
        }
        if (event === 'transfer') {
            const nextTransferVal = !action.transfer.click;
            setAction((prevAction: any) => ({
                ...prevAction,
                transfer: {
                    ...prevAction.transfer,
                    click: nextTransferVal,
                    disabled: !callStatus || callStatus === 'Closed'
                }
            }));
            callBackEvent("TRANSFER", { transfer: true });
        }
        if (event === 'join') {
            callBackEvent("JOIN_CALL", {});
        }
        if (event === 'swap') {
            callBackEvent("SWAP_CALL", {});
        }
        if (event === 'dialog') {
            callBackEvent("DIALOG_CLICK", {});
        }
    }

    useEffect(() => {
        const status = (!callStatus || callStatus === 'Closed') || false;
        setAction((prevAction: any) => ({
            ...prevAction,
            end: {
                ...prevAction.end,
                disabled: callConnectionCount ? false : status
            },
            mute: {
                ...prevAction.mute,
                click: isMute || false,
                disabled: status
            },
            hold: {
                ...prevAction.hold,
                click: isHold || false,
                disabled: status
            },
            join: {
                ...prevAction.join,
                click: isJoin || false,
                disabled: status || isJoin
            },
            swap: {
                ...prevAction.swap,
                click: isSwap || false,
                disabled: status || !isTransfer || isJoin
            },
            transfer: {
                ...prevAction.transfer,
                click: isTransfer || false,
                disabled: status || isTransfer
            }
        }));
    }, [isHold, isMute, currentContactId, callStatus, isJoin, isSwap, isTransfer, callConnectionCount])

    const handleClose = (clear: boolean) => {
        if (clear) {
            closePopup(close, true, 'voice');
            callBackEvent("endcall", {})
        }
        setOpen(false);
    }

    return (
        <Flex UNSAFE_className={styles['call-action-continer']}>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                {
                    (callStatus === "Active" || callConnectionCount) ?
                        <div>
                            <TooltipTrigger>
                                <ActionButton UNSAFE_className={styles['telephony-end']} isDisabled={action.end.disabled} onPress={() => setOpen(true)}>
                                    <End />
                                </ActionButton>
                                <Tooltip>{localization[lang].TELEPHONY.END}</Tooltip>
                            </TooltipTrigger>
                            <DialogContainer isDismissable onDismiss={() => setOpen(false)}>
                                {isOpen &&
                                    <CallButtonsPopup handleClose={handleClose} lang={lang} />
                                }
                            </DialogContainer>
                        </div>
                        : <ActionButton UNSAFE_className={styles['telephony-end']} isDisabled={action.end.disabled} onPress={close}>
                            <End />
                        </ActionButton>
                }
            </Flex>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                <TooltipTrigger>
                    <ActionButton id='hold' UNSAFE_className={`${styles['telephony-hold']} ${action.hold.click && styles['telephony-hold-clicked']}`} isDisabled={action.hold.disabled}
                        onPress={onCallAction}>
                        <Hold />
                    </ActionButton>
                    <Tooltip>{localization[lang].TELEPHONY.HOLD}</Tooltip>
                </TooltipTrigger>
            </Flex>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                <TooltipTrigger>
                    <ActionButton id='mute' UNSAFE_className={`${styles['telephony-mute']} ${action.mute.click && styles['telephony-mute-clicked']}`} isDisabled={action.mute.disabled}
                        onPress={onCallAction}>
                        <Mute />
                    </ActionButton>
                    <Tooltip>{localization[lang].TELEPHONY.MUTE}</Tooltip>
                </TooltipTrigger>
                {/* <Text UNSAFE_className={styles['telephony-icon-text']}>{localization[lang].TELEPHONY.MUTE}</Text> */}
            </Flex>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                <TooltipTrigger>
                    <ActionButton id='dialog' UNSAFE_className={styles['telephony-dialog']} isDisabled={action.dialog.disabled}
                        onPress={onCallAction}>
                        <DialogIcon />
                    </ActionButton>
                    <Tooltip>{localization[lang].TELEPHONY.DIALPAD}</Tooltip>
                </TooltipTrigger>
            </Flex>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                <TooltipTrigger>
                    <ActionButton id='join' isDisabled={action.join.disabled} UNSAFE_className={styles['telephony-dialog']}
                        onPress={onCallAction}>
                        <Join />
                    </ActionButton>
                    <Tooltip>{localization[lang].TELEPHONY.JOIN}</Tooltip>
                </TooltipTrigger>
            </Flex>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                <TooltipTrigger>
                    <ActionButton id='swap' isDisabled={action.swap.disabled} UNSAFE_className={styles['telephony-dialog']}
                        onPress={onCallAction}>
                        <Swap />
                    </ActionButton>
                    <Tooltip>{localization[lang].TELEPHONY.SWAP}</Tooltip>
                </TooltipTrigger>
            </Flex>
            <Flex UNSAFE_className={styles['call-action-subcontiner']}>
                <TooltipTrigger>
                    <ActionButton id='transfer' isDisabled={action.transfer.disabled} UNSAFE_className={styles['telephony-dialog']}
                        onPress={onCallAction}>
                        <Transfer />
                    </ActionButton>
                    <Tooltip>{localization[lang].TRANSFER_CALL.TRANSFER}</Tooltip>
                </TooltipTrigger>
            </Flex>
        </Flex>
    )
})

export default DialpadButtons