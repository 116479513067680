import axios from 'axios';
import { URL_CONSTANTS } from '../constants/url';
import { getAuthHeaders } from './getAuthHeaders';

interface pdcContent {
  title: string,
  message: string
}

export const getPdcList = async (language: string, queueId: string, pdcUrl: any, agentLanguage: string) => {
  return axios.get(pdcUrl, {
    params: {
      language,
      queueId,
      agentLanguage
    },
    headers: getAuthHeaders(),
  });
};


export const getCategories = async (pdcUrl: string) => {
  const endPoint = pdcUrl + URL_CONSTANTS.CATEGORIES
  return axios.get(endPoint, {
    headers: getAuthHeaders(),
  });
};

export const getCustomPdcList = async (agentLdap: any, pdcUrl: string) => {
  const endPoint = pdcUrl + URL_CONSTANTS.GET_CUSTOM_PDC
  return axios.get(endPoint, {
    params: {
      agentLdap,
      pdcType: 'custom-pdc',
    },
    headers: getAuthHeaders(),
  });
};

export const createCustomPdcList = async (
  agentLdap: string,
  pdcType: string,
  pdcContent: pdcContent,
  pdcUrl: string
) => {
  const endPoint = pdcUrl + URL_CONSTANTS.CREATE_CUSTOM_PDC
  return axios.post(endPoint,
    {
      agentLdap,
      pdcType,
      pdcContent
    },
    {
      headers: getAuthHeaders()
    }
  )
}

export const removeCustomPdcList = async (
  agentLdap: string,
  pdcType: string,
  pdcContent: pdcContent,
  pdcUrl: string
) => {
  const endPoint = pdcUrl + URL_CONSTANTS.REMOVE_CUSTOM_PDC
  return axios.post(endPoint,
    {
      agentLdap,
      pdcType,
      pdcContent
    },
    {
      headers: getAuthHeaders()
    }
  )
}

export const getFavoritePdcList = async (agentLdap: string, pdcUrl: string) => {
  const endPoint = pdcUrl + URL_CONSTANTS.GET_FAVORITE_PDC
  return axios.get(endPoint, {
    params: {
      agentLdap,
      pdcType: 'favorite-pdc',
    },
    headers: getAuthHeaders(),
  });
};

export const createFavoritePdcList = async (
  agentLdap: string,
  pdcType: string,
  pdcContent: pdcContent,
  pdcUrl: string
) => {
  const endPoint = pdcUrl + URL_CONSTANTS.CREATE_FAVORITE_PDC
  return axios.post(endPoint,
    {
      agentLdap,
      pdcType,
      pdcContent
    },
    {
      headers: getAuthHeaders()
    }
  )
}

export const removeFavoritePdcList = async (
  agentLdap: string,
  pdcType: string,
  pdcContent: pdcContent,
  pdcUrl: string
) => {
  const endPoint = pdcUrl + URL_CONSTANTS.REMOVE_FAVORITE_PDC
  return axios.post(endPoint,
    {
      agentLdap,
      pdcType,
      pdcContent
    },
    {
      headers: getAuthHeaders()
    }
  )
}