import React from 'react'
import { useSelector } from 'react-redux'
import localization from '../language/localization'
import darkTheme from '../Theme/Styles/main/dark.css'
import lightTheme from '../Theme/Styles/main/light.css'
const EnterpriseCaseView = () => {
  const { darkMode, language } = useSelector((state: any) => state.uiState)
  const localizeString = localization[language]
  const styles = darkMode ? darkTheme : lightTheme
  return (
    <React.Fragment>
      <div className={styles['ecm-enterprise-case-view-wrapper']}>
        <div className={styles['ecm-enterprise-case-view-message']}>
          {localizeString.navigate_to_dynamics}
        </div>
      </div>
    </React.Fragment>
  )
}
export default EnterpriseCaseView
