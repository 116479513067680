import styles from './Styles/styles.module.css';

interface Props {
  darkMode: boolean;
}

const themes = (darkMode: Props) => ({
  darkMode,
  darkModeBG: darkMode ? ` ${styles['customer-info-panel-dark']}` : '',
  darkModeBorder: darkMode ? ` ${styles['customer-info-panel-dark-border']}` : '',
  darkModeTitle: darkMode ? ` ${styles['accordion-title-dark']}` : '',
  darkModea: darkMode ? ` ${styles['a-dark']}` : '',
  darkModeaAdobeBold: darkMode ? ` ${styles['adobe-clean-bold-dark']}` : '',
  darkModeaAdobeRegular: darkMode ? ` ${styles['adobe-clean-regular-dark']}` : '',
  darkModeaAdobeRegularOr: darkMode ? ` ${styles['adobe-clean-regular-or-dark']}` : '',
  darkModeFieldValues: darkMode ? ` ${styles['accordion-content-fields-values-dark']}` : '',
  darkModePitchMessage: darkMode ? `${styles['accordion-value-add-action-pitch-message-dark']}` : '',
  darkModeRejectionSection: darkMode ? `${styles['accordion-value-add-action-rejection-section-dark']}` : '',
  darkModeCustomerResponseMessage: darkMode ? `${styles['accordion-value-add-action-customer-response-message-dark']}` : '',
  darkModeConversationSummaryFeedback: darkMode ? `${styles['accordion-value-conversation-summary-feedback-dark']}` : '',
});

export default themes;
