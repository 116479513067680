import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { loader, updateCartDetails } from '../Store/actions/customerInfoActions';


const useCartDetail = (CIP_DOMAIN: string, HostContextInfo: string, mockOfferIdFlag: boolean, reload: number, contactId: string) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Fetches and decodes HostContextInfo from a base64 encoded string.
     * 
     * The function performs the following steps:
     * 1. Decodes the input string from base64.
     * 2. Converts the decoded bytes to a Uint8Array.
     * 3. Decodes the byte array to a UTF-8 string.
     * 4. Parses the resulting string as JSON and returns it.
     * 
     * If an error occurs during any of these steps, an error message is logged,
     * and an empty array is returned.
     * 
     * @param {string} HostContextInfo - The base64 encoded HostContextInfo.
     * @returns {Array<object>} - The parsed JSON array or an empty array on error.
     */
    const fetchDecodedHostContextInfo = (HostContextInfo: string) => {
        try {
            // Decode base64
            const decodedBytes = atob(HostContextInfo);

            // Convert to a Uint8Array using Array.from
            const byteArray = new Uint8Array(Array.from(decodedBytes).map(c => c.charCodeAt(0)));

            const decoder = new TextDecoder('utf-8');
            const decodedString = decoder.decode(byteArray);

            return JSON.parse(decodeURIComponent(decodedString));

        } catch(e) {
            console.error('SCIP/CIP - Error while decoding and parsing the HostContextInfo - ', e);
            return [];
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        const fetchCartDetails = async () => {
            setIsLoading(true);
            try {
                
                const decodedHostContextInfo = HostContextInfo ? fetchDecodedHostContextInfo(HostContextInfo) : [];
                
                const cartProductsList = decodedHostContextInfo[0]?.data?.products || [];

                // If floodgate flag is enabled then add mock offer ID to default OfferIdList, else assign empty list
                const defaultOfferIdList = mockOfferIdFlag ? Array(cartProductsList.length).fill('BCEF34A01635E66D2F48CDA985DF8FFF') : [];

                // If HostContextInfo has the offerId then take the offer id from it, else use the defaultOfferIdList
                const cartOfferIdList = decodedHostContextInfo[0]?.data?.offerId?.length ? decodedHostContextInfo[0].data.offerId : defaultOfferIdList;

                const currency = decodedHostContextInfo[0]?.data?.order?.currency || '';

                const offerIdStrings = cartOfferIdList.join(',');

                console.log('QE Validation - Cart details (decoded HostContextInfo) - ', decodedHostContextInfo)

                let cartOfferDetails = [];
                if (offerIdStrings) {
                    const accessToken = localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') || '';
                    const response: any = await axios.get(`${CIP_DOMAIN}/api/v1/aos/offers/details/`, {
                        params: { offerIds: offerIdStrings },
                        headers: {
                            'x-api-key': 'oac-custom-panel',
                            Authorization: accessToken,
                        },
                        cancelToken: source.token,
                    });
                    cartOfferDetails = response?.data?.json;
                }
                
                dispatch(updateCartDetails({ cartProductsList, cartOfferIdList, cartOfferDetails, currency }));
            } catch (error) {
                console.error('Error fetching offer details:', error);
                dispatch(updateCartDetails({ cartProductsList: [], cartOfferIdList: [], cartOfferDetails: [], currency: '' }));
            } finally {
                setIsLoading(false);
            }
        };

        if (HostContextInfo) {
            fetchCartDetails();
        } 
        else {
            dispatch(updateCartDetails({ cartProductsList: [], cartOfferIdList: [], cartOfferDetails: [], currency: '' }));
        } 

        return () => {
            source.cancel();
        };
    }, [HostContextInfo, mockOfferIdFlag, reload, contactId, dispatch]);

    useEffect(() => {
        if (isLoading) {
            dispatch(loader(true));
        }
        else {
            dispatch(loader(false));
        } 
    }, [isLoading, dispatch]);
};

export default useCartDetail;
