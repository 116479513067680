import axios from "axios"

import localization from '../Language/localization'


const NO_ANSWER_AVAILABLE_ERROR = 'NO_ANSWER_AVAILABLE_ERROR'

/**
 * Hook for submitting a query to LEO
 */
const useSubmitLeoQueryHook = (): {
  submit: (
    genAIURL: string,
    question: string,
    sessionId: string,
    conversationId: string,
    language: string
  ) => Promise<{
    answer: string,
    description: string,
    assistId: string,
    sessionId: string,
    linkSources?: Array<any>
  }>
} => {
  const submit = (
    genAIURL: string,
    question: string,
    sessionId: string,
    conversationId: string,
    language: string = 'en'
  ): Promise<{
    answer: string,
    description: string,
    assistId: string,
    sessionId: string,
    linkSources?: Array<any>
  }> => {
    const path = '/adobe-answers/assist'
    const postData = {
      query: question, //"adobe products with its descriptions",
      sessionId: sessionId,
      conversationId
    }
    
    return axios.post(`${genAIURL}${path}`, postData, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
      .then((response) => {
        console.log('LEO response', response)
        var answer = response?.data.providers?.[0]?.answer
        var description = response?.data.providers?.[0]?.title
        const linkSources = response?.data.providers?.[0]?.linkSources
        if (!answer || !description) throw new Error(NO_ANSWER_AVAILABLE_ERROR)

        return {
          answer,
          description,
          assistId: response?.data?.assistId,
          sessionId,
          linkSources
        }
      })
      .catch((err) => {
        console.error('Adobe Answers Error getting LEO response', err)
        if (err?.message === NO_ANSWER_AVAILABLE_ERROR) {
          throw new Error(localization[language]['No Answers Available'])
        } else {
          throw new Error(localization[language]['Bad response'])
        }
      })
  }

  return {
    submit
  }
}

export default useSubmitLeoQueryHook
