const localization: any = {
  en: {
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again',
    'Here some suggestion you can try' : 'Here are some suggestions you can try:'
  },
  ja: {
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  fr: {
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  de: {
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  es: {
    
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  it: {
    
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  'pt-BR': {
    
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  'zh-TW': {
    
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  'zh-CN': {
   
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
  ko: {
    
    'Is this helpful?': 'Is this helpful?',
    'New Query': 'New Query',
    'Pinned queries': 'Pinned queries',
    'Recent queries': 'Recent queries',
    'Adobe Answers': 'Adobe Answers',
    'Start typing': 'Start typing below for real time answers powered by Adobe',
    'Pin': 'Pin',
    'Unpin': 'Unpin',
    'Delete': 'Delete',
    'Tanks for feedback': 'Thank you for your feedback!',
    'Copy-paste': 'Click to copy and paste text into the message bar',
    'Delete query title' : 'Delete query?',
    'Delete query content' : 'This will delete ',
    'Cancel': 'Cancel',
    'Send': 'Send',
    'Feedback': 'Thank you for your feedback',
    'Add a note': 'Add a note (optional)',
    'What went wrong':'What went wrong? Select all that apply.',
    'Select all': 'Select all that apply (required)',
    'Submit': 'Submit feedback',
    'Why not helpfull': 'Why was this not helpful?',
    'Type an inquiry': 'Type an inquiry...',
    'Regenerate': 'Regenerate',
    'Correct links': 'Correct links',
    'Maximum of 8 pinned': 'Maximum of 8 pinned queries allowed at one time',
    'Send Question': 'Send question',
    'Copy to chat': 'Copy to Chat',
    'Bad response': 'Unable to return the response, Please try again',
    'No Answers Available': 'Unable to find an answer for your query. Please rephrase the query and try again',
    'Generating Response': 'Generating Response...',
    'Report results': 'Report results',
    'Pin operation forbitten': 'Unable to pin query. Please ensure the query is updated successfully before attempting to pin it again'
  },
};

export default localization;

//some defaults string values for feedback, in case if didn't get them from floodgate

export const THUMBS_DOWN_RESONS: any = {
  en: [
  { key: 'ANSWER_TOO_LONG',                 value: 'Answer is too long / wordy' },
  { key: 'ANSWER_MISSING_DETAILS',          value: 'Answer is missing details' },
  { key: 'IRRELEVANT_ANSWER',               value: 'Answer is irrelevant to the question' },
  { key: 'ANSWER_INCORRECT',                value: 'Answer is incorrect' },
  { key: 'MADE_UP_FACTS',                   value: 'Answer includes made-up facts' },
  { key: 'ANSWER_FOR_OLDER_VERSION',        value: 'Answer is for older version' },
  { key: 'NO_RESPONSE',                     value: 'Didn\'t get a response' },
  { key: 'INCORRECT_OR_IRRELEVANT_ANSWER',  value: 'Incorrect or irrelevant answer' },
  { key: 'OTHER',                           value: 'Other' },
]
}

export const INAPROPRIATE_FLAGS: any = {
  en: [
  { key:'HARMFUL',                  value: 'Harmful' },
  { key:'ILLEGAL',                  value: 'Illegal' },
  { key:'OFFENSIVE',                value: 'Offensive' },
  { key:'BIASED',                   value: 'Biased' },
  { key:'TRADEMARK_VIOLATION',      value: 'Trademark violation' },
  { key:'COPYRIGHT_VIOLATION',      value: 'Copyright violation' },
  { key:'NUDITY_OR_SEXUAL_CONTENT', value: 'Nudity or sexual content' },
  { key:'VIOLENCE_OR_GORE',         value: 'Violence or gore' },
  ]
}