export default {
	"/": {
		"type": "select",
		"label": "お問い合わせ内容",
		"label-alt": "Nature of Contact",
		"options": [
			{
				"name": "CS、TS、RET に関する問題のサポート",
				"name-alt": "Support for CS, TS, RET issues",
				"path": "/support"
			},
			{
				"name": "販売に関する質問（価格、プランなど）",
				"name-alt": "Sales query (Pricing, plans, etc)",
				"path": "/sales"
			},
			{
				"name": "リセラーからの問い合わせ",
				"name-alt": "Reseller has contacted",
				"path": "/reseller"
			}
		]
	},
	"/support": {
		"type": "select",
		"label": "お問い合わせのライセンス種別",
		"label-alt": "Customer calling for which license",
		"options": [
			{
				"name": "エンタープライズ (EVIP および ETLA)",
				"name-alt": "Enterprise (EVIP and ETLA)",
				"path": "/support/enterprise"
			},
			{
				"name": "個人",
				"name-alt": "Individual",
				"path": "/support/individual"
			},
			{
				"name": "永久個人版ライセンス",
				"name-alt": "Perpetual Individual License",
				"path": "/support/perpetual"
			},
			{
				"name": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"name-alt": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"path": "/support/ppbu"
			},
			{
				"name": "SAAS - Export PDF, PDF Pack",
				"name-alt": "SAAS - Export PDF, PDF Pack",
				"path": "/support/saas"
			},
			{
				"name": "個人版Stock",
				"name-alt": "Stock for Individual",
				"path": "/support/stock-individual"
			},
			// {
			// 	"name": "サブスクリプションを解約 - Save Opportunity",
			// 	"name-alt": "Cancel Subscription - Save opportunity",
			// 	"path": "/support/individual"
			// },
			{
				"name": "チーム版Stock",
				"name-alt": "Stock for Team",
				"path": "/support/stock-team"
			},
			{
				"name": "チーム版",
				"name-alt": "Team Subscription",
				"path": "/support/team"
			},
			{
				"name": "ボリュームライセンス-永久版AVLライセンス",
				"name-alt": "Volume License - AVL Perpetual",
				"path": "/support/volume"
			}
		]
	},
	"/support/enterprise": {
		"type": "select",
		"label": "エンタープライズのお客様プロフィール",
		"label-alt": "Profile of Enterprise Customer",
		"options": [
			{
				"name": "管理者",
				"name-alt": "Administrator",
				"path": "/support/enterprise/admin"
			},
			{
				"name": "ASP オンショア ",
				"name-alt": "ASP Onshore ",
				"path": "/support/enterprise/asp-onshore"
			},
			{
				"name": "ASP サービスデスク",
				"name-alt": "ASP Service Desk",
				"path": "/support/enterprise/asp-service-desk"
			},
			{
				"name": "ASP SME",
				"name-alt": "ASP SME ",
				"path": "/support/enterprise/asp-sme"
			},
			{
				"name": "管理者以外",
				"name-alt": "End User",
				"path": "/support/enterprise/end-user"
			}
		]
	},
	"/support/enterprise/admin": {
		"type": "select",
		"label": "問題の種類 - エンタープライズ版",
		"label-alt": "Issue Type - Enterprise License",
		"options": [
			{
				"name": "Acrobat Activation, Acrobat Deployment, Acrobat Download/Install",
				"name-alt": "Acrobat Activation, Acrobat Deployment, Acrobat Download/Install",
				"path": "/support/enterprise/admin/acrobat-activation"
			},
			{
				"name": "CC Activation, CC Download/Install, Library Sync Issue",
				"name-alt": "CC Activation, CC Download/Install, Library Sync Issue",
				"path": "/support/enterprise/admin/cc-activation"
			},
			{
				"name": "コンソールの問題、SSO設定、エキスパートセッション、製品の導入など",
				"name-alt": "Console Issue, SSO Setup, Expert Sessions, Deployment, etc",
				"path": "/support/enterprise/admin/console"
			},
			{
				"name": "ダウンロード、インストール",
				"name-alt": "Download, Install",
				"path": "/support/enterprise/admin/download"
			},
			{
				"name": "製品に関する質問",
				"name-alt": "Product Questions",
				"path": "/support/enterprise/admin/product"
			}
		]
	},
	"/support/enterprise/admin/acrobat-activation": {
		"type": "filter",
		"label": "製品に関する質問",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/enterprise/admin/acrobat-activation/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/enterprise/admin/acrobat-activation/voice"
			}
		]
	},
	"/support/enterprise/admin/acrobat-activation/chat": {
		"type": "results",
		"queue": "ENT-DC-SD-Ja"
	},
	"/support/enterprise/admin/acrobat-activation/voice": {
		"type": "results",
		"queue": "ENT-Ja-SD-Acro"
	},
	"/support/enterprise/admin/cc-activation": {
		"type": "results",
		"queue": "ENT-Admin-SD-Ja"
	},
	"/support/enterprise/admin/console": {
		"type": "results",
		"queue": "ENT-Admin-SD-Ja"
	},
	"/support/enterprise/admin/download": {
		"type": "filter",
		"label": "製品に関する質問",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/enterprise/admin/download/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/enterprise/admin/download/voice"
			}
		]
	},
	"/support/enterprise/admin/download/chat": {
		"type": "results",
		"queue": "ENT-Ja-SD-Inst"
	},
	"/support/enterprise/admin/download/voice": {
		"type": "results",
		"queue": "ENT-Admin-SD-Ja"
	},
	"/support/enterprise/admin/product": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/enterprise/admin/product/voice"
			},
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/enterprise/admin/product/chat"
			}
		]
	},
	"/support/enterprise/admin/product/voice": {
		"type": "select",
		"label": "製品-エンタープライズ",
		"label-alt": "Products - Enterprise",
		"options": [
			{
				"name": "Acrobat",
				"name-alt": "Acrobat",
				"path": "/support/enterprise/admin/product/voice/acrobat"
			},
			{
				"name": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"name-alt": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"path": "/support/enterprise/admin/product/voice/captivate"
			},
			{
				"name": "Connect",
				"name-alt": "Connect",
				"path": "/support/enterprise/admin/product/voice/connect"
			},
			{
				"name": "Digital Publishing Suite",
				"name-alt": "Digital Publishing Suite",
				"path": "/support/enterprise/admin/product/voice/dps"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/enterprise/admin/product/voice/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/enterprise/admin/product/voice/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"path": "/support/enterprise/admin/product/voice/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink, and Slide",
				"path": "/support/enterprise/admin/product/voice/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/support/enterprise/admin/product/voice/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"path": "/support/enterprise/admin/product/voice/premier"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/support/enterprise/admin/product/voice/sign"
			},
			{
				"name": "Stock",
				"name-alt": "Stock",
				"path": "/support/enterprise/admin/product/voice/stock"
			}
		]
	},
	"/support/enterprise/admin/product/voice/acrobat": {
		"type": "results",
		"queue": "ENT-Ja-SD-Acro"
	},
	"/support/enterprise/admin/product/voice/captivate": {
		"type": "results",
		"queue": "ENT-Ja-SD-Ppbu"
	},
	"/support/enterprise/admin/product/voice/connect": {
		"type": "results",
		"message": "Adobe Connect の音声サポートは提供されていないことをお客様に伝え、https://www.samuraiz.co.jp/adobeproduct/connect/about.html を紹介してください",
		"message-alt": "Instruct the customer that voice support for Adobe Connect is not provided and to navigate to https://www.samuraiz.co.jp/adobeproduct/connect/about.html"
	},
	"/support/enterprise/admin/product/voice/dps": {
		"type": "results",
		"queue": "ENT-Ja-SD-DPS"
	},
	"/support/enterprise/admin/product/voice/dreamweaver": {
		"type": "results",
		"queue": "ENT-Ja-SD-Web"
	},
	"/support/enterprise/admin/product/voice/firefly": {
		"type": "results",
		"queue": "ENT-Ja-SD-Firefly"
	},
	"/support/enterprise/admin/product/voice/illustrator": {
		"type": "results",
		"queue": "ENT-Ja-SD-Design"
	},
	"/support/enterprise/admin/product/voice/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/enterprise/admin/product/voice/photoshop": {
		"type": "results",
		"queue": "ENT-Ja-SD-Photo"
	},
	"/support/enterprise/admin/product/voice/premier": {
		"type": "results",
		"queue": "ENT-Ja-SD-Video"
	},
	"/support/enterprise/admin/product/voice/sign": {
		"type": "results",
		"queue": "ENT-Ja-SD-Acro"
	},
	"/support/enterprise/admin/product/voice/stock": {
		"type": "results",
		"queue": "ENT-Ja-SD-Stock"
	},
	"/support/enterprise/admin/product/chat": {
		"type": "select",
		"label": "製品-エンタープライズ版",
		"label-alt": "Products - Enterprise",
		"options": [
			{
				"name": "Acrobat",
				"name-alt": "Acrobat",
				"path": "/support/enterprise/admin/product/chat/acrobat"
			},
			{
				"name": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"name-alt": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"path": "/support/enterprise/admin/product/chat/captivate"
			},
			{
				"name": "Connect",
				"name-alt": "Connect",
				"path": "/support/enterprise/admin/product/chat/connect"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/enterprise/admin/product/chat/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/enterprise/admin/product/chat/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"path": "/support/enterprise/admin/product/chat/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink, and Slide",
				"path": "/support/enterprise/admin/product/chat/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/support/enterprise/admin/product/chat/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"path": "/support/enterprise/admin/product/chat/premier"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/support/enterprise/admin/product/chat/sign"
			},
			{
				"name": "Stock",
				"name-alt": "Stock",
				"path": "/support/enterprise/admin/product/chat/stock"
			}
		]
	},
	"/support/enterprise/admin/product/chat/acrobat": {
		"type": "results",
		"queue": "ENT-DC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/captivate": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/connect": {
		"type": "results",
		"queue": "ENT-DC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/dreamweaver": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/firefly": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/illustrator": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/enterprise/admin/product/chat/photoshop": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/premier": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/sign": {
		"type": "results",
		"queue": "ENT-Sign-SD-Ja"
	},
	"/support/enterprise/admin/product/chat/stock": {
		"type": "results",
		"queue": "ENT-CC-SD-Ja"
	},
	"/support/enterprise/asp-onshore": {
		"type": "results",
		"queue": "ENT-ASP-Ja-APAC-Onshore"
	},
	"/support/enterprise/asp-service-desk": {
		"type": "results",
		"queue": "ENT-ASP-Ja-SD"
	},
	"/support/enterprise/asp-sme": {
		"type": "results",
		"queue": "ENT-ASP-Ja-SME"
	},
	"/support/enterprise/end-user": {
		"type": "results",
		"message": "お客様に、それぞれのIT管理者またはアドビのアカウント管理者に連絡して、管理コンソールから当社に連絡するよう伝えます。",
		"message-alt": "Advise customer to speak to their respective IT admin or Adobe account administrator to contact us via the admin console"
	},
	"/support/individual": {
		"type": "select",
		"label": "問題の種類 - 個人版",
		"label-alt": "Issue Type - Individual Subscriptions",
		"options": [
			{
				"name": "アカウントに関する問い合わせ",
				"name-alt": "Account Issues",
				"path": "/support/individual/account"
			},
			{
				"name": "請求に関する問い合わせ",
				"name-alt": "Billing Issues",
				"path": "/support/individual/billing"
			},
			{
				"name": "TWPを含む請求のキャンセル - Save opportunity",
				"name-alt": "Cancellation for known charge including TWP Charged - Save opportunity",
				"path": "/support/individual/cancel"
			},
			{
				"name": "不明請求",
				"name-alt": "Unknown charge",
				"path": "/support/individual/charge"
			},
			{
				"name": "テクニカルサポート - ダウンロード、インストールに関する質問、アクティベーション、サブスクリプションが試用版になった、同期を含むCCDAに関する問い合わせ",
				"name-alt": "Technical Support -Download, Install questions,Activation, Subscription converted to trial, CCDA any issue including sync",
				"path": "/support/individual/download"
			},
			{
				"name": "テクニカルサポート - 起動の問題、製品上のファイル同期の問題、操作方法、技術ヘルプ",
				"name-alt": "TS-Launch Issues, File Sync Issues-product related, How to and technical help",
				"path": "/support/individual/launch"
			},
			{
				"name": "取り消された または ブラックリストに掲載された引き換えコード",
				"name-alt": "Revoked \\ Blacklisted Redemption Code",
				"path": "/support/individual/revoked"
			}
		]
	},
	"/support/individual/account": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/individual/billing": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/individual/cancel": {
		"type": "results",
		"queue": "RET-Ja-Save"
	},
	"/support/individual/charge": {
		"type": "results",
		"queue": "RET-Ja-Save"
	},
	"/support/individual/download": {
		"type": "select",
		"label": "製品-個人版",
		"label-alt": "Products- Individual Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC または Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/support/individual/download/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/individual/download/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/individual/download/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/support/individual/download/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/support/individual/download/muse"
			},
			{
				"name": "その他のクリエイティブクラウド製品",
				"name-alt": "Other creative cloud products",
				"path": "/support/individual/download/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/support/individual/download/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/individual/download/premier"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/support/individual/download/substance"
			}
		]
	},
	"/support/individual/download/acrobat": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/download/dreamweaver": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/download/firefly": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/download/illustrator": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/download/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/individual/download/other": {
		"type": "results",
		"message": "LD/SVに相談するか、ナレッジベースで検索してください",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/support/individual/download/photoshop": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/download/premier": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/download/substance": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/individual/launch": {
		"type": "select",
		"label": "製品-個人版",
		"label-alt": "Products- Individual Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/support/individual/launch/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/individual/launch/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/individual/launch/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/support/individual/launch/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink, and Slide",
				"path": "/support/individual/launch/muse"
			},
			{
				"name": "その他のクリエイティブクラウド製品",
				"name-alt": "Other creative cloud products",
				"path": "/support/individual/launch/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/support/individual/launch/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/individual/launch/premier"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/support/individual/launch/substance"
			}
		]
	},
	"/support/individual/launch/acrobat": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/individual/launch/dreamweaver": {
		"type": "results",
		"queue": "CCM-Ja-Web"
	},
	"/support/individual/launch/firefly": {
		"type": "results",
		"queue": "CCM-Ja-Firefly"
	},
	"/support/individual/launch/illustrator": {
		"type": "results",
		"queue": "CCM-Ja-Design"
	},
	"/support/individual/launch/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/individual/launch/other": {
		"type": "results",
		"message": "LD/SVに相談するか、ナレッジベースで検索してください",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/support/individual/launch/photoshop": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/individual/launch/premier": {
		"type": "results",
		"queue": "CCM-Ja-Video"
	},
	"/support/individual/launch/substance": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/individual/revoked": {
		"type": "results",
		"queue": "AG-Ja-CC"
	},
	"/support/perpetual": {
		"type": "select",
		"label": "問題の種類 - 永久版AVLライセンス",
		"label-alt": "Issue Type - Perpetual AVL License",
		"options": [
			{
				"name": "アカウント管理",
				"name-alt": "Account management",
				"path": "/support/perpetual/account"
			},
			{
				"name": "アクティベーション、初回起動の問題、ファイル同期の問題",
				"name-alt": "Activation, First Launch Issues, File Sync Issues",
				"path": "/support/perpetual/activation"
			},
			{
				"name": "ダウンロード、インストール",
				"name-alt": "Download, Install",
				"path": "/support/perpetual/download"
			},
			{
				"name": "返品と返金",
				"name-alt": "Return and Refund",
				"path": "/support/perpetual/return"
			},
			{
				"name": "シリアル番号が不明または紛失した",
				"name-alt": "Serial number lost or missing",
				"path": "/support/perpetual/serial-number-lost"
			},
			{
				"name": "シリアル番号が本物ではないというエラー",
				"name-alt": "Serial number not Genuine error",
				"path": "/support/perpetual/serial-number-not-genuine"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/support/perpetual/technical"
			}
		]
	},
	"/support/perpetual/account": {
		"type": "select",
		"label": "永久版個人向け製品",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/account/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/account/lightroom"
			},
			{
				"name": "記載されていないその他の永久版製品",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/account/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/account/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/account/premier"
			}
		]
	},
	"/support/perpetual/account/acrobat": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/account/acrobat/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/account/acrobat/voice"
			}
		]
	},
	"/support/perpetual/account/acrobat/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/account/acrobat/voice": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/perpetual/account/lightroom": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/account/other": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/account/photoshop": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/account/photoshop/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/account/photoshop/voice"
			}
		]
	},
	"/support/perpetual/account/photoshop/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/account/photoshop/voice": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/perpetual/account/premier": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/account/premier/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/account/premier/voice"
			}
		]
	},
	"/support/perpetual/account/premier/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/account/premier/voice": {
		"type": "results",
		"queue": "CCM-Ja-Video"
	},
	"/support/perpetual/activation": {
		"type": "select",
		"label": "永久版個人向け製品",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/activation/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/activation/lightroom"
			},
			{
				"name": "記載されていないその他の永久版製品",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/activation/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/activation/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/activation/premier"
			}
		]
	},
	"/support/perpetual/activation/acrobat": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/activation/acrobat/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/activation/acrobat/voice"
			}
		]
	},
	"/support/perpetual/activation/acrobat/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/activation/acrobat/voice": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/perpetual/activation/lightroom": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/activation/other": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/activation/photoshop": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/activation/photoshop/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/activation/photoshop/voice"
			}
		]
	},
	"/support/perpetual/activation/photoshop/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/activation/photoshop/voice": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/perpetual/activation/premier": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/activation/premier/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/activation/premier/voice"
			}
		]
	},
	"/support/perpetual/activation/premier/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/activation/premier/voice": {
		"type": "results",
		"queue": "CCM-Ja-Video"
	},
	"/support/perpetual/download": {
		"type": "select",
		"label": "永久版個人向け製品",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/download/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/download/lightroom"
			},
			{
				"name": "記載されていないその他の永久版製品",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/download/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/download/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/download/premier"
			}
		]
	},
	"/support/perpetual/download/acrobat": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/download/acrobat/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/download/acrobat/voice"
			}
		]
	},
	"/support/perpetual/download/acrobat/chat": {
		"type": "results",
		"queue": "PPTL-Ja-Inst"
	},
	"/support/perpetual/download/acrobat/voice": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/perpetual/download/lightroom": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/download/other": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/download/photoshop": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/download/photoshop/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/download/photoshop/voice"
			}
		]
	},
	"/support/perpetual/download/photoshop/chat": {
		"type": "results",
		"queue": "PPTL-Ja-Inst"
	},
	"/support/perpetual/download/photoshop/voice": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/perpetual/download/premier": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/download/premier/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/download/premier/voice"
			}
		]
	},
	"/support/perpetual/download/premier/chat": {
		"type": "results",
		"queue": "PPTL-Ja-Inst"
	},
	"/support/perpetual/download/premier/voice": {
		"type": "results",
		"queue": "CCM-Ja-Inst"
	},
	"/support/perpetual/return": {
		"type": "select",
		"label": "永久版個人向け製品",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/return/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/return/lightroom"
			},
			{
				"name": "記載されていないその他の永久版製品",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/return/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/return/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/return/premier"
			}
		]
	},
	"/support/perpetual/return/acrobat": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/return/acrobat/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/return/acrobat/voice"
			}
		]
	},
	"/support/perpetual/return/acrobat/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/return/acrobat/voice": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/perpetual/return/lightroom": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/return/other": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/return/photoshop": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/return/photoshop/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/return/photoshop/voice"
			}
		]
	},
	"/support/perpetual/return/photoshop/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/return/photoshop/voice": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/perpetual/return/premier": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/return/premier/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/return/premier/voice"
			}
		]
	},
	"/support/perpetual/return/premier/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/return/premier/voice": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/perpetual/serial-number-lost": {
		"type": "select",
		"label": "永久版個人向け製品",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/serial-number-lost/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/serial-number-lost/lightroom"
			},
			{
				"name": "記載されていないその他の永久版製品",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/serial-number-lost/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/serial-number-lost/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/serial-number-lost/premier"
			}
		]
	},
	"/support/perpetual/serial-number-lost/acrobat": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/serial-number-lost/acrobat/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/serial-number-lost/acrobat/voice"
			}
		]
	},
	"/support/perpetual/serial-number-lost/acrobat/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/serial-number-lost/acrobat/voice": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/perpetual/serial-number-lost/lightroom": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/serial-number-lost/other": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/serial-number-lost/photoshop": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/serial-number-lost/photoshop/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/serial-number-lost/photoshop/voice"
			}
		]
	},
	"/support/perpetual/serial-number-lost/photoshop/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/serial-number-lost/photoshop/voice": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/perpetual/serial-number-lost/premier": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/perpetual/serial-number-lost/premier/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/perpetual/serial-number-lost/premier/voice"
			}
		]
	},
	"/support/perpetual/serial-number-lost/premier/chat": {
		"type": "results",
		"queue": "PPTL-Ja-CS"
	},
	"/support/perpetual/serial-number-lost/premier/voice": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/perpetual/serial-number-not-genuine": {
		"type": "results",
		"queue": "AG-Ja-CC"
	},
	"/support/perpetual/technical": {
		"type": "select",
		"label": "永久版個人向け製品",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/technical/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/technical/lightroom"
			},
			{
				"name": "記載されていないその他の永久版製品",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/technical/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/technical/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/technical/premier"
			}
		]
	},
	"/support/perpetual/technical/acrobat": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/perpetual/technical/lightroom": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/technical/other": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/perpetual/technical/photoshop": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/perpetual/technical/premier": {
		"type": "results",
		"queue": "CCM-Ja-Video"
	},
	"/support/ppbu": {
		"type": "results",
		"queue": "CCM-Ja-Ppbu"
	},
	"/support/saas": {
		"type": "select",
		"label": "問題の種類 - SAAS",
		"label-alt": "Issue Type - SAAS",
		"options": [
			{
				"name": "アカウントに関する問い合わせ",
				"name-alt": "Account Issues",
				"path": "/support/saas/account"
			},
			{
				"name": "請求に関する問い合わせ",
				"name-alt": "Billing Issues",
				"path": "/support/saas/billing"
			},
			{
				"name": "サブスクリプションを解約 - Save Opportunity",
				"name-alt": "Cancel Subscription - Save opportunity",
				"path": "/support/saas/cancel"
			},
			{
				"name": "不明請求",
				"name-alt": "Unknown charge",
				"path": "/support/saas/charge"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/support/saas/technical"
			}
		]
	},
	"/support/saas/account": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/saas/billing": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/saas/cancel": {
		"type": "results",
		"queue": "RET-Ja-Save"
	},
	"/support/saas/charge": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/saas/technical": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/stock-individual": {
		"type": "select",
		"label": "問題の種類-Stock",
		"label-alt": "Issue Type - Stock",
		"options": [
			{
				"name": "アカウントに関する問い合わせ",
				"name-alt": "Account Issues",
				"path": "/support/stock-individual/account"
			},
			{
				"name": "請求に関する問い合わせ",
				"name-alt": "Billing Issues",
				"path": "/support/stock-individual/billing"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/support/stock-individual/technical"
			}
		]
	},
	"/support/stock-individual/account": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/stock-individual/billing": {
		"type": "results",
		"queue": "CCM-Ja-CS"
	},
	"/support/stock-individual/technical": {
		"type": "results",
		"queue": "CCM-Ja-Stock"
	},
	"/support/stock-team": {
		"type": "select",
		"label": "問題の種類-Stock",
		"label-alt": "Issue Type - Stock",
		"options": [
			{
				"name": "アカウントに関する問い合わせ",
				"name-alt": "Account Issues",
				"path": "/support/stock-team/account"
			},
			{
				"name": "請求に関する問い合わせ",
				"name-alt": "Billing Issues",
				"path": "/support/stock-team/billing"
			},
			{
				"name": "サブスクリプションを解約 - Save Opportunity",
				"name-alt": "Cancel Subscription - Save opportunity",
				"path": "/support/stock-team/cancel"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/support/stock-team/technical"
			}
		]
	},
	"/support/stock-team/account": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/stock-team/account/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/stock-team/account/voice"
			}
		]
	},
	"/support/stock-team/account/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/stock-team/account/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/stock-team/billing": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/stock-team/billing/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/stock-team/billing/voice"
			}
		]
	},
	"/support/stock-team/billing/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/stock-team/billing/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/stock-team/cancel": {
		"type": "results",
		"queue": "RET-Ja-CCT"
	},
	"/support/stock-team/technical": {
		"type": "results",
		"queue": "CCM-Ja-Stock"
	},
	"/support/team": {
		"type": "select",
		"label": "問題の種類-法人版サブスクリプション",
		"label-alt": "Issue Type - CCTeam Subscriptions",
		"options": [
			{
				"name": "アカウント管理",
				"name-alt": "Account management",
				"path": "/support/team/account"
			},
			{
				"name": "アクティベーション、初回起動の問題、ファイル同期の問題",
				"name-alt": "Activation, First Launch Issues, File Sync Issues",
				"path": "/support/team/activation"
			},
			{
				"name": "管理者の変更",
				"name-alt": "Changing the ADMIN",
				"path": "/support/team/admin"
			},
			{
				"name": "Adobe ID",
				"name-alt": "Adobe ID",
				"path": "/support/team/adobe-id"
			},
			{
				"name": "複雑な請求に関する質問-税金または付加価値税のシナリオなど",
				"name-alt": "Complicated Billing Questions- TAX\\VAT scenarios, etc",
				"path": "/support/team/billing"
			},
			{
				"name": "解約、ライセンスの削減、ライセンスの移行",
				"name-alt": "Cancellation \\ Reduction \\ Migration",
				"path": "/support/team/cancel"
			},
			{
				"name": "CCT VIP",
				"name-alt": "CCT VIP",
				"path": "/support/team/cct-vip"
			},
			{
				"name": "料金、プランの変更、支払い方法の変更、クレジットカードの更新に関する質問",
				"name-alt": "Question on charges, change of plan, payment mode change, credit card update",
				"path": "/support/team/charges"
			},
			{
				"name": "Deployment",
				"name-alt": "Deployment",
				"path": "/support/team/deployment"
			},
			{
				"name": "ダウンロード、インストール",
				"name-alt": "Download, Install",
				"path": "/support/team/download"
			},
			{
				"name": "ライセンスの割り当てまたは割り当て解除",
				"name-alt": "Assign/Unassign licenses",
				"path": "/support/team/licenses"
			},
			{
				"name": "組織名の変更",
				"name-alt": "Change of team name",
				"path": "/support/team/name"
			},
			{
				"name": "支払いの問題、支払いの問題、その他の簡単な請求に関する質問",
				"name-alt": "Payment issue, payment failure\\issues, other simple Billing questions",
				"path": "/support/team/payment"
			},
			{
				"name": "リセラーの変更リクエスト",
				"name-alt": "Reseller change request",
				"path": "/support/team/reseller"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/support/team/technical"
			}
		]
	},
	"/support/team/account": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/account/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/account/voice"
			}
		]
	},
	"/support/team/account/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/account/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/activation": {
		"type": "results",
		"queue": "CCT-Ja-Inst"
	},
	"/support/team/admin": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/adobe-id": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/adobe-id/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/adobe-id/voice"
			}
		]
	},
	"/support/team/adobe-id/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/adobe-id/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/billing": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/billing/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/billing/voice"
			}
		]
	},
	"/support/team/billing/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/billing/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/cancel": {
		"type": "results",
		"queue": "RET-Ja-CCT"
	},
	"/support/team/cct-vip": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/cct-vip/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/cct-vip/voice"
			}
		]
	},
	"/support/team/cct-vip/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/cct-vip/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/charges": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/charges/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/charges/voice"
			}
		]
	},
	"/support/team/charges/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/charges/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/deployment": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/download": {
		"type": "results",
		"queue": "CCT-Ja-Inst"
	},
	"/support/team/licenses": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/name": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/name/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/name/voice"
			}
		]
	},
	"/support/team/name/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/name/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/payment": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/payment/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/payment/voice"
			}
		]
	},
	"/support/team/payment/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/payment/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/reseller": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/reseller/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/reseller/voice"
			}
		]
	},
	"/support/team/reseller/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/team/reseller/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/team/technical": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/team/technical/voice"
			},
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/team/technical/chat"
			}
		]
	},
	"/support/team/technical/voice": {
		"type": "select",
		"label": "製品-法人版サブスクリプション",
		"label-alt": "Products - CCT Team Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC または Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/support/team/technical/voice/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/team/technical/voice/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/team/technical/voice/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/support/team/technical/voice/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink, and Slide",
				"path": "/support/team/technical/voice/muse"
			},
			{
				"name": "その他のクリエイティブクラウド製品",
				"name-alt": "Other creative cloud products",
				"path": "/support/team/technical/voice/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/support/team/technical/voice/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/team/technical/voice/premier"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/support/team/technical/voice/sign"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/support/team/technical/voice/substance"
			}
		]
	},
	"/support/team/technical/voice/acrobat": {
		"type": "results",
		"queue": "CCT-Ja-Acro"
	},
	"/support/team/technical/voice/dreamweaver": {
		"type": "results",
		"queue": "CCT-Ja-Web"
	},
	"/support/team/technical/voice/firefly": {
		"type": "results",
		"queue": "CCT-Ja-Photo"
	},
	"/support/team/technical/voice/illustrator": {
		"type": "results",
		"queue": "CCT-Ja-Design"
	},
	"/support/team/technical/voice/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/team/technical/voice/other": {
		"type": "results",
		"message": "LD/SVに相談するか、ナレッジベースで検索してください",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/support/team/technical/voice/photoshop": {
		"type": "results",
		"queue": "CCT-Ja-Photo"
	},
	"/support/team/technical/voice/premier": {
		"type": "results",
		"queue": "CCT-Ja-Video"
	},
	"/support/team/technical/voice/sign": {
		"type": "results",
		"queue": "CCT-Ja-Acro"
	},
	"/support/team/technical/voice/substance": {
		"type": "results",
		"queue": "CCT-Ja-Photo"
	},
	"/support/team/technical/chat": {
		"type": "select",
		"label": "製品-法人版サブスクリプション",
		"label-alt": "Products - CCT Team Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/support/team/technical/chat/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/team/technical/chat/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/team/technical/chat/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/support/team/technical/chat/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink, and Slide",
				"path": "/support/team/technical/chat/muse"
			},
			{
				"name": "その他のクリエイティブクラウド製品",
				"name-alt": "Other creative cloud products",
				"path": "/support/team/technical/chat/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/support/team/technical/chat/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/team/technical/chat/premier"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/support/team/technical/chat/sign"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/support/team/technical/chat/substance"
			}
		]
	},
	"/support/team/technical/chat/acrobat": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/team/technical/chat/dreamweaver": {
		"type": "results",
		"queue": "CCM-Ja-Web"
	},
	"/support/team/technical/chat/firefly": {
		"type": "results",
		"queue": "CCM-Ja-Firefly"
	},
	"/support/team/technical/chat/illustrator": {
		"type": "results",
		"queue": "CCM-Ja-Design"
	},
	"/support/team/technical/chat/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/team/technical/chat/other": {
		"type": "results",
		"message": "LD/SVに相談するか、ナレッジベースで検索してください",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/support/team/technical/chat/photoshop": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/team/technical/chat/premier": {
		"type": "results",
		"queue": "CCM-Ja-Video"
	},
	"/support/team/technical/chat/sign": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/team/technical/chat/substance": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/volume": {
		"type": "select",
		"label": "問題の種類-永久版AVLライセンス",
		"label-alt": "Issue Type - Perpetual AVL License",
		"options": [
			{
				"name": "アカウント管理",
				"name-alt": "Account management",
				"path": "/support/volume/account"
			},
			{
				"name": "アクティベーション、初回起動の問題、ファイル同期の問題",
				"name-alt": "Activation, First Launch Issues, File Sync Issues",
				"path": "/support/volume/activation"
			},
			{
				"name": "ダウンロード、インストール",
				"name-alt": "Download, Install",
				"path": "/support/volume/download"
			},
			{
				"name": "返品と返金",
				"name-alt": "Return and Refund",
				"path": "/support/volume/return"
			},
			{
				"name": "シリアル番号が不明または紛失した",
				"name-alt": "Serial number lost or missing",
				"path": "/support/volume/serial-number-lost"
			},
			{
				"name": "シリアル番号が通らない",
				"name-alt": "Serial number not working",
				"path": "/support/volume/serial-number-not-working"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/support/volume/technical"
			}
		]
	},
	"/support/volume/account": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/volume/account/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/volume/account/voice"
			}
		]
	},
	"/support/volume/account/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/volume/account/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/volume/activation": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/volume/activation/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/volume/activation/voice"
			}
		]
	},
	"/support/volume/activation/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/volume/activation/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/volume/download": {
		"type": "results",
		"queue": "CCT-Ja-Inst"
	},
	"/support/volume/return": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/volume/return/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/volume/return/voice"
			}
		]
	},
	"/support/volume/return/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/volume/return/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/volume/serial-number-lost": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/volume/serial-number-lost/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/volume/serial-number-lost/voice"
			}
		]
	},
	"/support/volume/serial-number-lost/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/volume/serial-number-lost/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/volume/serial-number-not-working": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/volume/serial-number-not-working/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/volume/serial-number-not-working/voice"
			}
		]
	},
	"/support/volume/serial-number-not-working/chat": {
		"type": "results",
		"queue": "CCT-Ja-CS"
	},
	"/support/volume/serial-number-not-working/voice": {
		"type": "results",
		"queue": "CCT-Ja-Admin"
	},
	"/support/volume/technical": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/support/volume/technical/voice"
			},
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/support/volume/technical/chat"
			}
		]
	},
	"/support/volume/technical/voice": {
		"type": "select",
		"label": "製品-永久版AVL-ボリュームライセンス",
		"label-alt": "Products - Perpetual AVL- Volume License",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"path": "/support/volume/technical/voice/acrobat"
			},
			{
				"name": "Dreamweaver, Animate",
				"name-alt": "Dreamweaver, Animate",
				"path": "/support/volume/technical/voice/dreamweaver"
			},
			{
				"name": "Photoshop Elements or Premier Elements 20, 21, 22",
				"name-alt": "Photoshop Elements or Premier Elements 20, 21, 22",
				"path": "/support/volume/technical/voice/elements"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/volume/technical/voice/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture",
				"name-alt": "Illustrator, Indesign, Incopy, Capture",
				"path": "/support/volume/technical/voice/illustrator"
			},
			{
				"name": "Muse",
				"name-alt": "Muse",
				"path": "/support/volume/technical/voice/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/support/volume/technical/voice/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/volume/technical/voice/premier"
			}
		]
	},
	"/support/volume/technical/voice/acrobat": {
		"type": "results",
		"queue": "CCT-Ja-Acro"
	},
	"/support/volume/technical/voice/dreamweaver": {
		"type": "results",
		"queue": "CCT-Ja-Web"
	},
	"/support/volume/technical/voice/elements": {
		"type": "results",
		"queue": "CCT-Ja-Photo"
	},
	"/support/volume/technical/voice/firefly": {
		"type": "results",
		"queue": "CCT-Ja-Firefly"
	},
	"/support/volume/technical/voice/illustrator": {
		"type": "results",
		"queue": "CCT-Ja-Design"
	},
	"/support/volume/technical/voice/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/volume/technical/voice/photoshop": {
		"type": "results",
		"queue": "CCT-Ja-Photo"
	},
	"/support/volume/technical/voice/premier": {
		"type": "results",
		"queue": "CCT-Ja-Video"
	},
	"/support/volume/technical/chat": {
		"type": "select",
		"label": "製品-永久版AVL-ボリュームライセンス",
		"label-alt": "Products - Perpetual AVL- Volume License",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"path": "/support/volume/technical/chat/acrobat"
			},
			{
				"name": "Dreamweaver, Animate",
				"name-alt": "Dreamweaver, Animate",
				"path": "/support/volume/technical/chat/dreamweaver"
			},
			{
				"name": "Photoshop Elements or Premier Elements 20, 21, 22",
				"name-alt": "Photoshop Elements or Premier Elements 20, 21, 22",
				"path": "/support/volume/technical/chat/elements"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/volume/technical/chat/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture",
				"name-alt": "Illustrator, Indesign, Incopy, Capture",
				"path": "/support/volume/technical/chat/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse",
				"path": "/support/volume/technical/chat/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/support/volume/technical/chat/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/volume/technical/chat/premier"
			}
		]
	},
	"/support/volume/technical/chat/acrobat": {
		"type": "results",
		"queue": "CCM-Ja-Acro"
	},
	"/support/volume/technical/chat/dreamweaver": {
		"type": "results",
		"queue": "CCM-Ja-Web"
	},
	"/support/volume/technical/chat/elements": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/volume/technical/chat/firefly": {
		"type": "results",
		"queue": "CCM-Ja-Firefly"
	},
	"/support/volume/technical/chat/illustrator": {
		"type": "results",
		"queue": "CCM-Ja-Design"
	},
	"/support/volume/technical/chat/muse": {
		"type": "results",
		"message": "製品がEOLであることを伝え、コミュニティフォーラムへ誘導してください。\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">フォーラムリンク</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on community forums.\n<a target=\"_blank\" href=\"https://community.adobe.com/t5/jp/ct-p/jp?profile.language=ja\">Forum Link</a>"
	},
	"/support/volume/technical/chat/photoshop": {
		"type": "results",
		"queue": "CCM-Ja-Photo"
	},
	"/support/volume/technical/chat/premier": {
		"type": "results",
		"queue": "CCM-Ja-Video"
	},
	"/sales": {
		"type": "select",
		"label": "お問い合わせのライセンス種別",
		"label-alt": "Customer calling for which license?",
		"options": [
			{
				"name": "個人版",
				"name-alt": "Individual",
				"path": "/sales/individual"
			},
			{
				"name": "法人版",
				"name-alt": "Team Subscription",
				"path": "/sales/team"
			}
		]
	},
	"/sales/individual": {
		"type": "results",
		"queue": "SLS-CCI-Ja"
	},
	"/sales/team": {
		"type": "results",
		"queue": "SLS-CCT-Ja"
	},
	"/reseller": {
		"type": "filter",
		"label": "チャネル",
		"label-alt": "Channel",
		"options": [
			{
				"name": "チャット",
				"name-alt": "Chat",
				"path": "/reseller/chat"
			},
			{
				"name": "ボイス",
				"name-alt": "Voice",
				"path": "/reseller/voice"
			}
		]
	},
	"/reseller/chat": {
		"type": "results",
		"message": "0120-914-117にお電話いただくようお客様に伝えます ",
		"message-alt": "Instruct the customer to call 0120-914-117 "
	},
	"/reseller/voice": {
		"type": "select",
		"label": "問題の種類-リセラー",
		"label-alt": "Issue Type - Reseller",
		"options": [
			{
				"name": "アカウントに関する問い合わせ、またはテクニカル以外の問い合わせ",
				"name-alt": "Account or Non-Technical Issues",
				"path": "/reseller/voice/non-technical"
			},
			{
				"name": "技術的または製品に関するヘルプ",
				"name-alt": "Technical or product help",
				"path": "/reseller/voice/technical"
			}
		]
	},
	"/reseller/voice/non-technical": {
		"type": "results",
		"queue": "DNL-CPH-Ja-CCT"
	},
	"/reseller/voice/technical": {
		"type": "select",
		"label": "技術的な問題の種類-リセラー",
		"label-alt": "Technical Issue Type - Reseller",
		"options": [
			{
				"name": "ダウンロード、インストール（すべての製品）",
				"name-alt": "Download, Install (All Products)",
				"path": "/reseller/voice/technical/download"
			},
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/reseller/voice/technical/acrobat"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/reseller/voice/technical/photoshop"
			},
			{
				"name": "Stock",
				"name-alt": "Stock",
				"path": "/reseller/voice/technical/stock"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/reseller/voice/technical/premier"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/reseller/voice/technical/dreamweaver"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/reseller/voice/technical/illustrator"
			}
		]
	},
	"/reseller/voice/technical/download": {
		"type": "results",
		"queue": "CPH-Ja-Inst"
	},
	"/reseller/voice/technical/acrobat": {
		"type": "results",
		"queue": "CPH-Ja-Acro"
	},
	"/reseller/voice/technical/photoshop": {
		"type": "results",
		"queue": "CPH-Ja-Photo"
	},
	"/reseller/voice/technical/stock": {
		"type": "results",
		"queue": "CPH-Ja-Stock"
	},
	"/reseller/voice/technical/premier": {
		"type": "results",
		"queue": "CPH-Ja-Video"
	},
	"/reseller/voice/technical/dreamweaver": {
		"type": "results",
		"queue": "CPH-Ja-Web"
	},
	"/reseller/voice/technical/illustrator": {
		"type": "results",
		"queue": "CPH-Ja-Design"
	}
}