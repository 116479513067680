import React, { useEffect, useState } from 'react'
import styles from '../Themes/Styles/styles.module.css'
import localization from '../Language/localization'
import themes from '../Themes/Themes'
import { ThumbsUp, ThumbsDown, ThumbsUpDark, ThumbsDownDark, Flag, FlagDark, ThumbsUpDisabled, ThumbsDownDisabled, FlagDisabled } from '../Themes/Icons'
import { Button, ButtonGroup, Checkbox, CheckboxGroup, Content, Dialog, DialogTrigger, Divider, Flex, Heading, Link, TextArea } from '@adobe/react-spectrum'
import { initDunamis, trackAnswerFeedbackEvent } from '../Analytics'
import { Query } from './AdobeAnswersFrame'
//import {THUMBS_DOWN_RESONS, INAPROPRIATE_FLAGS } from '../Language/localization'

const thumbsUp = (darkMode) => (darkMode ? <ThumbsUpDark /> : <ThumbsUp />)
const thumbsDown = (darkMode) => (darkMode ? <ThumbsDownDark /> : <ThumbsDown />)
const abuseFlag = (darkMode) => (darkMode ? <FlagDark /> : <Flag />)

const ProvidedFeedbackIcon = ({ type, darkMode }) => {
  const { darkModeAgentFeedback } = themes(darkMode)
  return (
    <div className={`${styles['agent-feedback']} ${darkModeAgentFeedback}`}>
      {type === 'THUMBS_UP' ? <ThumbsUpDisabled/> : type === 'THUMBS_DOWN' ? <ThumbsDownDisabled/> : type === 'FLAG_INAPPROPRIATE' && <FlagDisabled />}
    </div>
  )
}

type Props = {
  query: Query,
  darkMode: boolean,
  feedbackOptions?: {
    inappropriateFlagOptions: {[key: string]: any},
    thumbsDownFeedbackOptions: {[key: string]: any}
  }
  language: string
  sendFeedback: (
    comment: string,
    feedbackType: string,
    options: any[]
  ) => void
}

function AgentFeedback({
  query,
  darkMode,
  feedbackOptions,
  language,
  sendFeedback
}: Props) {
  const { darkModeAgentFeedback, darkModeAIMessage } = themes(darkMode)
  const [inputText, setInputText] = useState<string>('')
  let [selected, setSelected] = useState<any[]>([])
  let [flagsSelected, setFlagsSelected] = useState<any[]>([])
  const [feedBackWasSent, setFeedBackWasSent] = useState<boolean>(false)
  const chatMaxLength = 999999999

  useEffect(() => {
    initDunamis();
  }, []);

  useEffect(() => {
    setFeedBackWasSent(false)
  }, [query.id])

  const onSubmitFeedback = (
    type: string,
    close?: () => void
  ): void => {
    sendFeedback(inputText, type, type === 'FLAG_INAPPROPRIATE' ? flagsSelected : selected)
    setInputText('')
    setSelected([])
    setFlagsSelected([])
    setFeedBackWasSent(true)
    close && close()
  }

  const handleThumpsDownClick = () => {
    trackAnswerFeedbackEvent(query?.assistId , "click", "negative",  'negative', "", "", "");
  }

  const handleReportClick = () => {
    trackAnswerFeedbackEvent(query?.assistId , "click", "report",  'report', "", "", "");
  }

  const closeDialog = (close) => {
    close && close()
    setInputText('')
    setFlagsSelected([])
    setSelected([])
  }

  const onMessageChange = (e: any): void => {
    setInputText(e)
  }

  return (
    <div>
      <div >
      {/* <div className={`${styles['answer-footer']} ${darkModeAnswerFooter}`}> */}
        {/* <div className={`${styles['regenerate-button']} ${darkModeAgentFeedback}`}>
          <Button
            onPress={regenerate}
            UNSAFE_style={{fontSize: 'smaller'}}
            isDisabled={!regenerationDisabled || isAnswerLoading}
            variant='secondary'
            height='24px'
            minHeight='unset'
            minWidth='unset'
          >
            {localization[language]['Regenerate']}
          </Button>
        </div> */}
        {!feedBackWasSent && !query?.feedback && (
          <div className={`${styles['agent-feedback']} ${darkModeAgentFeedback}`}>
            <div className={`${styles['agent-feedback-question']} ${darkModeAgentFeedback}`}>{localization[language]['Is this helpful?']}</div>
            <Link onPress={() => onSubmitFeedback('THUMBS_UP', undefined)}>
              <div className={`${styles['feedback-thumbsup']}`}> {thumbsUp(darkMode)} </div>
            </Link>
            <DialogTrigger>
              <Link onPress={handleThumpsDownClick}>
                <div className={`${styles['feedback-thumbsup']}`}> {thumbsDown(darkMode)} </div>
              </Link>
              {(close) => (
                <Dialog size='M'>
                  <Heading>{localization[language]['Feedback']}</Heading>
                  <Divider />
                  <Content UNSAFE_className=''>
                    <Flex gap='size-100'>
                      <CheckboxGroup label={localization[language]['What went wrong']} value={selected} onChange={setSelected}>
                        {feedbackOptions?.thumbsDownFeedbackOptions?.['en']?.map((reason) => (
                          <Checkbox key={reason.key} value={reason.key} >{reason.value}</Checkbox>
                        ))}
                      </CheckboxGroup>
                    </Flex>
                    <div className={`${styles['feedback_textarea-wrapper']}`}>
                      <TextArea height={'100px'} width={'100%'} label={localization[language]['Add a note']} onChange={onMessageChange} value={inputText} />
                    </div>
                  </Content>
                  <ButtonGroup>
                    <Button variant='secondary'  onPress={() => closeDialog(close)}>
                      {localization[language]['Cancel']}
                    </Button>
                    <Button variant='cta' onPress={() => onSubmitFeedback('THUMBS_DOWN', close)}>
                      {localization[language]['Submit']}
                    </Button>
                  </ButtonGroup>
                </Dialog>
              )}
            </DialogTrigger>
            <DialogTrigger>
              <Link onPress={handleReportClick}>
                <div className={`${styles['feedback-thumbsup']}`}> {abuseFlag(darkMode)} </div>
              </Link>
              {(close) => (
                <Dialog size='M'>
                  <Heading>{localization[language]['Report results']}</Heading>
                  <Divider />
                  <Content UNSAFE_className=''>
                    <Flex gap='size-300'>
                      <CheckboxGroup label={localization[language]['Select all']} value={flagsSelected} onChange={setFlagsSelected}>
                        {feedbackOptions?.inappropriateFlagOptions?.['en']?.filter((flag, index) => flag && index < feedbackOptions.inappropriateFlagOptions['en'].length / 2)
                          .map((flag) => (
                            <Checkbox key={flag.key} value={flag.key}>{flag.value}</Checkbox>
                          ))}
                      </CheckboxGroup>
                      <CheckboxGroup value={flagsSelected} onChange={setFlagsSelected} label='&nbsp;'>
                        {feedbackOptions?.inappropriateFlagOptions?.['en']?.filter((flag, index) => flag && index >= feedbackOptions.inappropriateFlagOptions['en'].length / 2)
                          .map((flag) => (
                            <Checkbox key={flag.key} value={flag.key}>{flag.value}</Checkbox>
                          ))}
                      </CheckboxGroup>
                    </Flex>
                    <div className={`${styles['feedback_textarea-wrapper']}`}>
                      <TextArea height={'100px'} maxLength={chatMaxLength} width={'100%'} label={localization[language]['Add a note']} onChange={onMessageChange} value={inputText} />
                    </div>
                  </Content>
                  <ButtonGroup>
                    <Button variant='secondary' onPress={() => closeDialog(close)}>
                      {localization[language]['Cancel']}
                    </Button>
                    <Button variant='cta' onPress={() => onSubmitFeedback('FLAG_INAPPROPRIATE', close)} isDisabled={flagsSelected.length === 0}>
                      {localization[language]['Send']}
                    </Button>
                  </ButtonGroup>
                </Dialog>
              )}
            </DialogTrigger>
          </div>
        )}
        {feedBackWasSent ? 
            <div className={`${styles['agent-feedback-gratitude']}  ${darkModeAIMessage}`}>{localization[language]['Tanks for feedback']}</div>
         : query?.feedback && ProvidedFeedbackIcon({ type: query?.feedback, darkMode })}
      </div>
    </div>
  )
}

export default AgentFeedback
