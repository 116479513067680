import axios from 'axios';

export async function getAllConvHistory(reqData: any) {
    const client = axios.create({ timeout: 10000 })
    return client.get(`${reqData.apiEndPoint}/api/histories/agent?agentId=${reqData.agentId}&startDate=${reqData.startDate}&endDate=${reqData.endDate}&searchKeyword=${reqData.searchKeyword}`, {
        cancelToken: reqData.source.token , headers: reqData.headers
    });
}

export async function getConversationId(reqData: any) {
    const client = axios.create({ timeout: 10000 })
    return client.get(`${reqData.apiEndPoint}/api/histories/conversation/${reqData.conversationId}`, {
        cancelToken: reqData.source.token , headers: reqData.headers }
    );
}

export interface Conversation {
    contactId: string
    conversationID: string
    agentId: string
    customerId: string
    updateDate: string
    createDate: string
    conversationStartDate: string
    previousContactId: string
    nextContactId: string
    duration: number
    channel: string
    queue: string
    agentFirstName: string
    agentLastName: string
    csat: number
    customerFirstName: string
    customerLastName: string
    transferNote: string
    disconnectReason: string
    contactAttributes: string
    status: string
    response_time: number
}
