import React, { useEffect, useRef, useState } from 'react'
import { TextArea, Flex, ActionButton, ProgressCircle, DialogTrigger, Dialog, Content, TooltipTrigger, Tooltip } from '@adobe/react-spectrum'
import styles from '../Theme/Styles/styles.css'
import { Send, Translate, Attach, CloseCross, Composer } from '../Theme/Icons'
import localization from '../lang/localization'
import WarnIcon from '@spectrum-icons/workflow/Alert';
import usePdcHotkeys from './Hooks/usePdcHotkeys'
import { getTranslationData } from '../api/ccpAPI'

declare const window: any;

type propsData = {
    darkMode: boolean
    lang: string
    predefinedMessage: string
    sourceLang: string
    currentContactId: string
    messageDraft: string
    translationDraft: string
    scrollTop: number
    uploading: boolean,
    apiUploadError: string,
    maxLimitAttachments: number,
    queueName: string,
    chatLanguage: string,
    pdcUrl: string,
    parentContactId: string,
    translateAPI: string,
    isTranslation: boolean,
    customerLang: string,
    apiEndPoint: string,
    setContainerHeight: (height: number) => void
    callBackEvent: (eventType: string, eventData: object) => void
}

export interface FileData {
    file: File | null;
    id: string;
    error: string
}

// const chatMsgLength = 1024;
const defaultContainerHeight = 238;
let prevConvDraft = '';
let prevTranslationDraft = '';
let typingStartTime: any = null;
const fileNameCharLimit = 32;
const fileAllowLimit = 35;


const Footer = React.memo((props: propsData) => {
    const { darkMode, lang, sourceLang, predefinedMessage, messageDraft, currentContactId, uploading, maxLimitAttachments, apiUploadError, parentContactId, chatLanguage,
        queueName, pdcUrl, translateAPI, isTranslation, customerLang, apiEndPoint, translationDraft } = props;
    const mode = darkMode ? 'dark' : 'light';
    const chatMaxLength = 999999999;
    const fileRef = useRef<any>();
    const textareaRef = useRef<any>();
    const translateTextareaRef = useRef<any>()

    const [message, setMessage] = useState('');
    const [translateMsg, setTranslateMsg] = useState('');
    const [pdcMessage, setPdcMessage] = useState('');
    const [messageTracker, setMessageTracker] = useState<any>([]);
    const [fileData, setFile] = useState<FileData>({ file: null, id: '', error: '' });
    const [isComposerViewOpen, setComposerViewOpen] = useState(localStorage.getItem("composerView") === 'true' ? true : false);
    const parentDivRef: any = useRef(null)

    const addPdcMessage = (message: any) => {
        setPdcMessage(message);
    }

    usePdcHotkeys(queueName, chatLanguage, currentContactId, addPdcMessage, sourceLang, lang, pdcUrl, translateAPI, isTranslation);

    useEffect(() => {
        const clientHeight = parentDivRef?.current?.clientHeight || 0;
        const totalHeight = defaultContainerHeight + clientHeight;
        props.setContainerHeight(totalHeight)
    })

    useEffect(() => {
        if (textareaRef?.current) {
            const ele = textareaRef.current.getInputElement();
            ele.spellcheck = true;
        }
        setMessage(messageDraft);
        setTranslateMsg(translationDraft)
        prevConvDraft = messageDraft;
        prevTranslationDraft = translationDraft;
        setFile({
            ...fileData,
            file: null,
            id: parentContactId
        });
        return () => {
            prevConvDraft = '';
            prevTranslationDraft = '';
            typingStartTime = null;
        }
    }, [currentContactId]);

    useEffect(() => {
        prevConvDraft = message;
        if (typingStartTime) {
            const diff = (new Date().getTime() - typingStartTime.getTime()) / 1000;
            if (diff > 3) {
                typingStartTime = new Date();
                props.callBackEvent("sendToAgentTypingEvent", {});
            }
        }
        if (message.length === 0) {
            props.callBackEvent("messageGotEmpty", {});
        }
        adjustTextAreaHeight()
    }, [message]);

    useEffect(() => {
        const appendPdcMessage = () => {
            let updatedPDCMessage = message ? `${message} ${pdcMessage}` : pdcMessage;
            if (isTranslation) {
                updatedPDCMessage = `${translateMsg} ${pdcMessage}`.trim();
                setTranslateMsg(updatedPDCMessage);
                setMessage('');
                prevConvDraft = '';
                prevTranslationDraft = updatedPDCMessage;
                props.callBackEvent('saveDraft', { prevCurrentContactId: currentContactId, messageDraft: prevConvDraft, translationDraft: prevTranslationDraft });
            } else {
                setMessage(updatedPDCMessage);
                prevConvDraft = updatedPDCMessage;
                prevTranslationDraft = '';
                props.callBackEvent('saveDraft', { prevCurrentContactId: currentContactId, messageDraft: prevConvDraft, translationDraft: prevTranslationDraft });
            }
            setPdcMessage('');
            if (textareaRef?.current) {
                const ele = textareaRef.current.getInputElement();
                ele.style.height = "auto";
                ele.style.height = ele.scrollHeight + "px";
                textareaRef.current.focus();
            }
            const messageTracketWithPDC = [...messageTracker];
            messageTracketWithPDC.push(updatedPDCMessage);
            setMessageTracker(messageTracketWithPDC);
        }
        if (pdcMessage) appendPdcMessage();
    }, [pdcMessage]);

    useEffect(() => {
        setPdcMessage(predefinedMessage)
    }, [predefinedMessage]);

    useEffect(() => {
        window.dunamis_analytics = window.dunamis_analytics || {};
    }, []);

    const handleTranslationCall = async () => {
        try {
            if (!isTranslation || !translateMsg) {
                return
            }
            const reqData = {
                endpoint: apiEndPoint,
                body: {
                    "sourceLanguage": lang,
                    "targetLanguage": customerLang,
                    "texts": [{
                        id: currentContactId,
                        text: translateMsg
                    }]
                }
            }
            const resp: any = await getTranslationData(reqData);
            const { data: { texts: [{ text = '' }] } } = resp.data || {}
            const respText = text;
            setMessage(respText);
            prevConvDraft = respText;
            props.callBackEvent('saveDraft', { prevCurrentContactId: currentContactId, messageDraft: prevConvDraft, translationDraft: prevTranslationDraft });
        } catch (e) {
            console.log('Something went wrong', e);
        }
    }

    const adjustTextAreaHeight = () => {
        const ele = textareaRef?.current?.getInputElement();
        if (ele) {
            if (isComposerViewOpen) {
                ele.style.height = "178px"
            } else {
                ele.style.height = "auto"
                ele.style.height = ele.scrollHeight + "px"
            }
            ele.focus()
        }
    }

    const keyPress = (e: any) => {
        const keyCode = e.which || e.keyCode
        if (e.shiftKey && keyCode === 13) {
            return;
        }
        else if ((e.altKey && keyCode === 13 && !isComposerViewOpen)) {
            addNewLineToTextArea();
            return;
        }
        else if ((e.keyCode === 13 && !isComposerViewOpen)) {
            sendMessage();
            e.target.style.height = "32px";
            e.target.value = '';
            return;
        }
        else if (isComposerViewOpen && e.altKey && keyCode === 13) {
            sendMessage();
            e.target.style.height = "178px";
            e.target.value = '';
            return;
        }
        else if (isComposerViewOpen && e.keyCode === 13) {
            addNewLineToTextArea();
            return;
        }
        // Windows - CTRL+Z - Undo, CTRL+Y - Redo
        if (e.keyCode === 90 && e.ctrlKey) { e.preventDefault(); undoMessage(); }
        if (e.keyCode === 89 && e.ctrlKey) { e.preventDefault(); redoMessage(); }
        // Mac CMD+Z - Undo, SHIFT+CMD+Z - Redo
        if (e.shiftKey && e.keyCode === 90 && e.metaKey) { e.preventDefault(); redoMessage(); }
        else if (e.keyCode === 90 && e.metaKey) { e.preventDefault(); undoMessage(); }
    }

    const addNewLineToTextArea = () => {
        isComposerViewOpen ? setMessage(message) : setMessage(message + "\r\n")
    }

    const inputChange = (e: any) => {
        const x = e.target;
        if (x.value === '\n') {
            e.target.value = '';
        }
        if (isComposerViewOpen) {
            x.style.height = "178px !important";
        }
        else {
            x.style.height = "auto";
            x.style.height = x.scrollHeight + "px";
        }
    }

    const numberWithCommas = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const onMessageChange = (e: any) => {
        setMessage(e);
        if (e) {
            const updateMessageTracker = [...messageTracker];
            updateMessageTracker.push(e);
            setMessageTracker(updateMessageTracker);
        }
    }

    const undoMessage = () => {
        const messageAtundoClick = message;
        const indexOfMessage = messageTracker.indexOf(messageAtundoClick);
        if (indexOfMessage > 0) {
            setMessage(messageTracker[indexOfMessage - 1]);
        } else {
            setMessage('')
        }
    }

    const redoMessage = () => {
        const messageAtredoClick = message;
        const indexOfMessage = messageTracker.indexOf(messageAtredoClick);
        if (indexOfMessage < messageTracker.length - 1) {
            setMessage(messageTracker[indexOfMessage + 1]);
        }
    }

    const handleClick = () => {
        fileRef.current.click();
        // dunamis file attacment button click event
        if (typeof window.dunamis_analytics.sendFileAttachmentEvents === 'function') {
            window.dunamis_analytics.sendFileAttachmentEvents({})
        }
    };

    const handleChange = (event: any) => {
        const file = event.target.files[0];
        const lowerCaseAllowFiles = [".pdf", ".jpg", ".jpeg", ".png", ".doc", ".docx", ".xlsx", ".csv", ".wav", ".pptx", ".ppt", ".txt", ".heic", ".jfif", ".mov", ".mp4", ".rtf"];
        const upperCaseAllowFiles = lowerCaseAllowFiles.map((x) => x.toUpperCase());
        const allowFiles = lowerCaseAllowFiles.concat(upperCaseAllowFiles)
        if (file?.name) {
            const fileExtension = file.name.split('.').pop();
            setFile({
                ...fileData,
                file: allowFiles.indexOf(`.${fileExtension}`) >= 0 ? file : null,
                id: currentContactId,
                error: allowFiles.indexOf(`.${fileExtension}`) < 0 ? 'Invalid file type' : ''
            });
            props.callBackEvent("uploadFile", { file: null });
            event.currentTarget.value = null;

            if (typeof window.dunamis_analytics.sendFileAttachmentEvents === 'function') {
                window.dunamis_analytics.sendFileAttachmentEvents({
                    type: "failure",
                    errorDesc: allowFiles.indexOf(`.${fileExtension}`) < 0 ? 'Invalid file type' : '',
                })
            }
        }
    };

    const handleClearFile = () => {
        setFile({ ...fileData, file: null, error: '' });
    }

    const getFileName = () => {
        const file: any = fileData.file;
        let fileFullName = file.name;
        const fileName = file.name.split('.')[0];
        const fileExtension = file.name.split('.')[1];
        if (fileFullName.length > fileNameCharLimit) {
            fileFullName = fileName.substr(0, 30) + "..." + fileName.substr(-5) + "." + fileExtension;
        }
        return fileFullName;
    }

    const sendMessage = async () => {
        const file: any = fileData.file;
        let translateContent = '';
        try {
            translateContent = translateMsg || '';
            if (fileData?.file !== null) {
                props.callBackEvent("uploadFile", { file: fileData?.file });

                if (typeof window.dunamis_analytics.sendFileAttachmentEvents === 'function' && fileData.file) {
                    window.dunamis_analytics.sendFileAttachmentEvents({
                        type: "success",
                        "content.name": file.name,
                        "content.size": file.size,
                        "content.extention": file.name?.split('.')[1],
                        "content.mimetype": file.type,
                        count: maxLimitAttachments
                    })
                }
            }

            if (message !== '') {
                if (isTranslation && !translateMsg) translateContent = message;
                props.callBackEvent("sendMessage", { message, translateMsg: translateContent });
            }
            setMessage('');
            setTranslateMsg('');
            setPdcMessage('');
            setMessageTracker([]);
            props.callBackEvent('saveDraft', { prevCurrentContactId: currentContactId, messageDraft: '', translationDraft: '' });
            prevConvDraft = '';
            prevTranslationDraft = '';
            setFile({ ...fileData, file: null });
            const ele = textareaRef?.current?.getInputElement();
            if (ele) ele.style.height = "auto";
            const eleTr = translateTextareaRef?.current?.getInputElement();
            if (eleTr) eleTr.style.height = "auto";
        } catch (e) {
            props.callBackEvent("EXCEPTION", { 'module': 'oc-agent-toolbar-module-client', 'message': e.message });
        }
    }

    const onBlurFocus = (e: any) => {
        props.callBackEvent('saveDraft', { prevCurrentContactId: currentContactId, messageDraft: prevConvDraft, translationDraft: prevTranslationDraft });
        if (e?.type === 'focus') {
            typingStartTime = new Date();
            props.callBackEvent("sendToAgentTypingEvent", {});
        } else {
            typingStartTime = null;
        }
    }

    const handleTranslateMsg = (val: string) => {
        setTranslateMsg(val);
        prevTranslationDraft = val;

        if (typeof window.dunamis_analytics.sendWidgetToggleEvents === 'function') {
            // dunamis sendWidgetToggleEvents event
            window.dunamis_analytics.sendWidgetToggleEvents({
                workflow: "Conversation",
                subCategory: "Geo Fluency",
                type: 'click',
                subType: "translate",
            })
        }
    }

    const handleLearnMore = () => {
        if (typeof window.dunamis_analytics.sendFileAttachmentEvents === 'function') {
            window.dunamis_analytics.sendFileAttachmentEvents({
                subtype: "learn-more",
                "content.status": ".pdf, .jpg, .jpeg, .png, .doc, .docx, .xlsx, .csv, .wav, .pptx, .ppt, .txt,  .heic, .jfif, .mov, .mp4, .rtf"
            })
        }

    }

    useEffect(() => {
        localStorage.setItem("composerView", isComposerViewOpen.toString())
        adjustTextAreaHeight()
    }, [isComposerViewOpen])

    return (
        <Flex direction="column" UNSAFE_className={`${styles['ccp-chat-send-message-' + mode]} ${message !== '' ? styles['overlap-message-box'] : ''}`}>

            <div className={styles['message-attach-container-ccp']} ref={parentDivRef}>
                <TooltipTrigger>
                    <ActionButton isQuiet UNSAFE_className={styles['ccp-composer-view']} onPress={() => setComposerViewOpen(!isComposerViewOpen)}>
                        <Composer className={isComposerViewOpen ? styles['ccp-composer-view-icon-blue'] : styles['ccp-composer-view-icon-black']} />
                    </ActionButton>
                    <Tooltip> {localization[lang]?.COMPOSER_VIEW} </Tooltip>
                </TooltipTrigger>
                <TooltipTrigger>
                    <ActionButton isQuiet UNSAFE_className={styles['attach-file']} onPress={handleClick} isDisabled={uploading || maxLimitAttachments >= fileAllowLimit}>
                        <Attach className={styles['ccp-attach-icon-black']} />
                        <input type="file" ref={fileRef} hidden onChange={handleChange} accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .xlsx, .csv, .wav, .pptx, .ppt, .txt, .heic, .jfif, .mov, .mp4, .rtf" />
                    </ActionButton>
                    <Tooltip> {localization[lang]?.ATTACH_FILE} </Tooltip>
                </TooltipTrigger>
                <div>
                    {isTranslation && customerLang && customerLang !== lang &&
                        <TextArea UNSAFE_className={styles['translate-textarea-ccp']} id="translate-msg" maxLength={chatMaxLength} placeholder={localization[lang]?.TEXTAREA_PLACEHOLDER} value={translateMsg} ref={translateTextareaRef} flexShrink={0}
                            left={0} width="385px" minHeight="32px" aria-label="message" alignSelf="center"
                            icon={
                                <TooltipTrigger>
                                    <ActionButton UNSAFE_className={styles['msg-btn-ccp']} isQuiet onPress={handleTranslationCall}><Translate /></ActionButton>
                                    <Tooltip> {localization[lang]?.CLICK_HERE_TO_TRANSLATE_TEXT} </Tooltip>
                                </TooltipTrigger>
                            }
                            onChange={handleTranslateMsg} onInput={inputChange} onBlur={onBlurFocus} onFocus={onBlurFocus} />
                    }
                    <TextArea id="message" UNSAFE_className={isComposerViewOpen ? styles['message-textarea-ccp-composer'] : styles['message-textarea-ccp']} maxLength={chatMaxLength} placeholder={isTranslation ? localization[lang]?.CLICK_TRANSLATE_BUTTON_TO_POPULATE : localization[lang]?.TEXTAREA_PLACEHOLDER} value={message} ref={textareaRef} flexShrink={0}
                        left={0} width="385px" aria-label="message" alignSelf="center" height={isComposerViewOpen ? "132px" : "auto"}
                        icon={
                            <TooltipTrigger>
                                <ActionButton UNSAFE_className={styles['msg-btn-ccp']} isQuiet onPress={sendMessage}><Send className={darkMode ? 'send-icon-ccp-dark' : 'send-icon-ccp'} /></ActionButton>
                                <Tooltip> {localization[lang]?.SEND_MESSAGE_TO_CUSTOMER} </Tooltip>
                            </TooltipTrigger>
                        }
                        onChange={onMessageChange} onKeyDown={keyPress} onInput={inputChange} onBlur={onBlurFocus} onFocus={onBlurFocus} />
                </div>
            </div>
            <Flex UNSAFE_className={styles['ccp-file-footer']}>
                {/* {(!fastFollowFlags?.R1_MAY_CCP_SPLIT_MESSAGE) && <div className={`${styles['ccp-chat-charter-limit']} ${darkMode && styles['ccp-chat-charter-limit-dark']}`}>{numberWithCommas(message.length)} of {numberWithCommas(chatMsgLength)}</div>} */}
                <Flex direction="column" height="30px">
                    <Flex UNSAFE_className={styles['ccp-file-container']}>
                        <div className={styles['ccp-file-container-msg']}>
                            {
                                (uploading && fileData?.id === currentContactId) &&
                                <span className={styles['ccp-file-inprogress-txt']}>
                                    {localization[lang].FILE_UPLOAD_IN_PROGRESS} <span className={styles['ccp-file-uploading-spinner']}><ProgressCircle aria-label="Loading…" isIndeterminate size='S' /></span>
                                </span>
                            }
                            {
                                (fileData?.file && fileData?.id === currentContactId) &&
                                <span className={styles['ccp-upload-file-name']}>
                                    <span className={styles['ccp-upload-file-name-truncate']} title={fileData?.file.name}>{getFileName()}</span>
                                    <span className={styles['ccp-upload-file-close']} onClick={handleClearFile}> <CloseCross /> </span>
                                </span>
                            }
                            {
                                (fileData?.id === currentContactId && fileData?.file === null && ((fileData?.file === null && fileData?.error) || (maxLimitAttachments >= fileAllowLimit) || apiUploadError)) &&
                                <span className={styles['ccp-file-err-sec']}>
                                    <WarnIcon />
                                    {(maxLimitAttachments >= fileAllowLimit && !uploading) && <span className={styles['ccp-file-err-sec-msg']}>{localization[lang].ATTACHMENT_LIMIT_FILE_PER_CONVERSATION}</span>}
                                    {(fileData?.error) && <span className={styles['ccp-file-err-sec-msg']}>{localization[lang].INVALID_FILE_TYPE}</span>}
                                    {(!uploading && apiUploadError) && <span className={styles['ccp-file-err-sec-msg']}>{localization[lang].ERROR_HAS_OCCURED_PLEASE_TRY_AGAIN}</span>}
                                    {
                                        fileData?.error &&
                                        <DialogTrigger isDismissable type='popover' placement='top'>
                                            <ActionButton UNSAFE_className={styles['ccp-file-err-sec-learn-more']} onPress={handleLearnMore}> {localization[lang].LEARN_MORE} </ActionButton>
                                            <Dialog size="S" UNSAFE_className={styles['ccp-file-learn-more-modal']}>
                                                <Content>
                                                    <div className={styles['ccp-file-learn-more-modal-heading']}>
                                                        {localization[lang].SUPPORTED_FILE_TYPES_UPLOAD_MAX_SIZE}
                                                    </div>
                                                    <div>
                                                        .pdf, .jpg, .jpeg, .png, .doc, .docx, .xlsx, .csv, .wav, .pptx, .ppt, .txt, .heic, .jfif, .mov, .mp4, .rtf
                                                    </div>
                                                </Content>
                                            </Dialog>
                                        </DialogTrigger>
                                    }
                                </span>
                            }
                        </div>
                        <div className={styles['ccp-file-container-uploaded']}>
                            {maxLimitAttachments} {localization[lang].OF} {fileAllowLimit} {localization[lang].FILES_UPLOADED}
                        </div>
                    </Flex>
                </Flex>
            </Flex>
        </Flex >
    )
})

export default Footer;
