import {
  EMAIL_REGEX,
  PHONE_REGEX,
  STRING_WITH_ONLY_INTEGER
} from '../constants/regex'
import { setSessionStorage } from './sessionStorage'
import timezoneMap from './timezoneToCode.json'
import windowsTimezones from './windowsTimezones.json'
const ZENDESK_QUEUES = ['sign-leg-ind-en', 'sign-leg-ent-en'];
const CHAT_TO_PHONE = 'chat-to-phone'
const MESSAGING = 'messaging'

export function getMappedCaseTypeFromIssueType(issueType: string): string {
  switch (issueType) {
    case 'ADMIN':
      return 'ADMIN'
    case 'CCPROD':
    case 'ACROBAT':
    case 'SIGN':
    case 'PPBU':
    case 'PRODUCT':
      return 'PRODUCT'
    default:
      return ''
  }
}

export function getItemCode(issueType: string, productCodeFromJC?: string): string {
  switch (issueType) {
    case 'ADMIN':
      return 'NA'
    case 'CCPROD':
      return 'photoshop_cc'
    case 'ACROBAT':
      return 'acrobat_dc_pro'
    case 'SIGN':
      return 'acom_esign'
    case 'PPBU':
      return 'presenter'
    case 'PRODUCT':
      return productCodeFromJC || ''
    default:
      return ''
  }
}

export function getLocaleAttribute(language: string, region: string): string {
  return language.concat('_', region)
}

export function getCodeForTimeZone(timezone: string) {
  // timezone example Asia/Calcutta
  const regexp = new RegExp(timezone, 'g')
  let timezoneCode = null
  const timezoneRecord = windowsTimezones.supplementalData.windowsZones.mapTimezones.mapZone.find(
    (zone) => {
      return zone.iana.match(regexp)
    }
  )
  if (timezoneRecord) {
    timezoneCode = timezoneMap[timezoneRecord.windows]
  }
  return timezoneCode
}

export const checkChangeCustomer = (conversationId: string, origin: string) => {
  if (origin === 'inbound-chat' || origin === 'chat-to-phone') {
    setSessionStorage(conversationId, 'enableChangeCustomer', false);
    return false
  }
  setSessionStorage(conversationId, 'enableChangeCustomer', true);
  return true;
}

export const isPhoneNumber = (value: string) => {
  if (!value) return false;
  const phoneRegExp = /^[0-9,(,), ,+]+[(,),\-, ,0-9]*$/;
  const isValid = value && phoneRegExp.test(value) && !value.includes('@')
  return isValid;
}

/**
 * 
 * @param closedPhoneCall 
 * @param closedConversation 
 * @param multipleAgentsConnected
 * @param assignedAgent 
 * @returns widget mode
 * Widget should be set to read only mode only if both chat and phone on the conversation are closed
 * Incase, of Multiparty we should additionaly check for assigned Agent property to determine if widget should be in editable mode
 */
export const getConversationStatus = (closedPhoneCall: boolean, closedConversation: boolean, multipleAgentsConnected: any, assignedAgent: any) => {
  if (closedPhoneCall && closedConversation) {
    return true;
  }
  if (typeof multipleAgentsConnected == "boolean" && multipleAgentsConnected) {
    return !assignedAgent;
  }
  return closedPhoneCall && closedConversation;
}

function checkZendeskSkill(currentSkill: string) {
  return ZENDESK_QUEUES.includes(currentSkill.toLowerCase())
}

export function parseContextData(dataProps: any) {
  const {
    contextData: {
      jcAuthData: {
        customerId: rengaId = '',
        orgId = '',
        firstName = '',
        lastName = '',
        issueType = '',
        email: customerEmail = '',
        timezone = '',
        language = '',
        region = '',
        agentEmail = '',
        queueName: originalQueue = '',
        currentQueue = '',
        isMultipleAccountLinked = false,
        linkedConversationId = '',
        channel = '',
        productCode = '',
      } = {},
      ocPlatformData: {
        chatInfo: { contactId: conversationId = '' } = {},
        chattingAgentInfo: { agentLDAP = '' } = {},
        callInfo: { assignedAgent = undefined, multipleAgentsConnected = undefined,
          initialContactId: phoneConvId = undefined} = {}
      } = {},
      ocAppData: {
        darkMode = false,
        language: lang = 'en',
        closedConversation = false,
        closedPhoneCall = true,
        originForCurrentConversation = 'inbound-chat',
        env_secrets: {
          REACT_APP_ECM_BASE_URL = '',
          REACT_APP_ENT_CUSTOMER_DEEPLINK_URL: REACT_APP_CUSTOMER_DEEPLINK_URL = '',
          REACT_APP_ENT_CASE_DEEPLINK_URL: REACT_APP_CASE_DEEPLINK_URL = '',
          AGENT_CONSOLE_SERVICE_BASE_URL : AGENT_CONSOLE_SERVICE_BASE_URL = '',
          REACT_APP_ECM_SEARCH_CUSTOMER_URL : REACT_APP_ECM_SEARCH_CUSTOMER_URL = ''
        } = {}
      } = {}
    } = {}
  } = dataProps

  let new_conversationId = conversationId;
  const useLinkedConversationId: boolean = originForCurrentConversation === CHAT_TO_PHONE && channel !== MESSAGING
  if (useLinkedConversationId && linkedConversationId) {
    new_conversationId = linkedConversationId;
  }
  return {
    contextData: {
      enterpriseContextData: {
        rengaId: isPhoneNumber(rengaId) ? '' : rengaId,
        orgId,
        firstName,
        lastName,
        conversationId: new_conversationId,
        agentName: agentLDAP,
        customerEmail,
        agentEmail,
        issueType,
        timezone,
        language,
        region,
        closedConversation: getConversationStatus(closedPhoneCall, closedConversation, multipleAgentsConnected, assignedAgent),
        assignedAgent,
        originalQueue,
        isMultipleAccountLinked,
        phoneNumber: isPhoneNumber(rengaId) ? rengaId : "",
        originForCurrentConversation,
        productCode,
        isZendeskSkill : checkZendeskSkill(currentQueue || originalQueue)
      },
      env_secrets: {
        REACT_APP_ECM_BASE_URL,
        REACT_APP_CUSTOMER_DEEPLINK_URL,
        REACT_APP_CASE_DEEPLINK_URL,
        AGENT_CONSOLE_SERVICE_BASE_URL,
        REACT_APP_ECM_SEARCH_CUSTOMER_URL
      },
      darkMode,
      lang,
      alterChannelConversationId: getAlterConvId(linkedConversationId, phoneConvId, conversationId),
      showChangeCustomer: checkChangeCustomer(conversationId, originForCurrentConversation)
    }
  }
}

export const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(String(email).toLowerCase())
}

export const isValidatePhoneNumber = (
  phoneNumber: string,
  validateLength: boolean
): boolean => {
  const phone = phoneNumber
    .toLowerCase()
    ?.replace('extn.', '')
    ?.replace('extn', '')
    ?.replace('ext.', '')
    ?.replace('ext', '')
  const regexValidation = PHONE_REGEX.test(phone)
  const stringOnlyWithInteger = phone.replace(STRING_WITH_ONLY_INTEGER, '')
  const lengthCndn =
    stringOnlyWithInteger.length > 4 && stringOnlyWithInteger.length < 16
  const lengthValidation = validateLength ? lengthCndn : true
  return regexValidation && lengthValidation
}

export const getErrorMessageForSearchForm = (
  email: string,
  phone: string,
  isEmailValid: boolean,
  isPhoneValid: boolean
): string => {
  if (email && phone) {
    if (!isEmailValid && !isPhoneValid) return 'customer_invalid_input_err_msg'
    else if (email && !isEmailValid) return 'customer_invalid_email_err_msg'
    else if (phone && !isPhoneValid) return 'customer_invalid_phone_err_msg'
    else return ''
  } else if (email && !isEmailValid) return 'customer_invalid_email_err_msg'
  else if (phone && !isPhoneValid) return 'customer_invalid_phone_err_msg'
  else return ''
}

function getAlterConvId(linkedConversationId: string, phoneConvId: string, contactId: string) {

  const ids: any = [];
  //push conversationId
  if (contactId) {
    ids.push(contactId)
  }
  //In case of CHAT-TO-Phone and it is transferred leg
  if (linkedConversationId && contactId !== linkedConversationId) {
    ids.push(linkedConversationId)
  }
  //Incase of chat and phone landed in same leg
  if (phoneConvId && !(ids.includes(phoneConvId))) {
    ids.push(phoneConvId)
  }
  return ids;
}
