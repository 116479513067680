import { Button, ProgressCircle, TextField } from '@adobe/react-spectrum'
import Info from '@react/react-spectrum/Icon/Info'
import React from 'react'
import { connect } from 'react-redux'
import { searchCustomer } from '../api/customerApi'
import { SEARCH_CUSTOMER_COLUMNS } from '../constants/searchCustomerColumns'
import localization from '../language/localization'
import { DarkBackIcon, LightBackIcon } from '../Theme/Icons/icons'
import commonStyles from '../Theme/Styles/common.css'
import darkTheme from '../Theme/Styles/main/dark.css'
import lightTheme from '../Theme/Styles/main/light.css'
import darkTable from '../Theme/Styles/table/darkTable.css'
import lightTable from '../Theme/Styles/table/lightTable.css'
import { getSessionStorage, setSessionStorage } from '../utils/sessionStorage'
import {
  getErrorMessageForSearchForm,
  isValidatePhoneNumber,
  isValidEmail
} from '../utils/utils'
import { updateCustomerSearchClick } from "../analytics/ctr-analytics";

interface IProps {
  darkMode?: boolean
  language?: string
  updateContextData: (payload: any, callChangeCustomer?: boolean) => void
  contextData: any
  handleGoback: () => void
  showChangeCustomer?: boolean
  readOnlyMode: boolean
  showBackButton: boolean
  toggleSearchCustomerView: (value: boolean) => void
  toggleLoader: (flag: boolean, message: string) => void
}

interface IState {
  customerInfo: Customers
  showLoader: boolean
  customerNotFound: boolean
  showError: boolean
  email: string
  phone: string
  errorMessage: string
  conversationId: string
}

interface Customer {
  firstName: string
  lastName: string
  email: Array<string>
  orgId: string
  orgName: string
  role: string
  type: string
  rengaId: string
  phone: string
}

interface Customers extends Array<Customer> { }
class SearchCustomer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      customerInfo: [],
      showLoader: false,
      customerNotFound: false,
      showError: false,
      email: '',
      phone: '',
      errorMessage: '',
      conversationId: ''
    }
  }

  /* istanbul ignore next */
  componentDidMount(): void {
    const {
      contextData: {
        enterpriseContextData: {
          conversationId,
          isMultipleAccountLinked,
          phoneNumber
        }
      }
    } = this.props.contextData
    setSessionStorage(conversationId, 'currentPage', 'searchCustomer')
    const persistedSearchCustomerData: any = getSessionStorage(conversationId)
    let {
      email = '',
      phone = '',
      searchCustomerRes = [],
      errorMessage = '',
      showError = false,
      customerNotFound = false
    } = persistedSearchCustomerData || {}
    this.setState({
      conversationId,
      email,
      phone,
      customerInfo: searchCustomerRes,
      errorMessage,
      showError,
      customerNotFound
    })

    // Analytics for value added offers (VAO)
    updateCustomerSearchClick(conversationId, 'No');

    if (searchCustomerRes.length == 0 && isMultipleAccountLinked) {
      phone = phone || phoneNumber;
      this.setState({ phone: phone });
      this.setState({ showLoader: true })
      const isValidNumber = isValidatePhoneNumber(phone, true)
      this.getCustomerDetails(conversationId, phone, isValidNumber, '', false,)
    }
  }

  getCustomerDetails = async (conversationId: string, phoneNumber: string, validPhone: boolean, emailId: string, validEmail: boolean) => {
    const email = emailId || '';
    const phone = phoneNumber || '';
    const errorMessage = getErrorMessageForSearchForm(
      email,
      phone,
      validEmail,
      validPhone
    )
    if (validEmail || validPhone) {
      const searchParams: any = { conversationId: conversationId }
      if (validEmail) searchParams.email = email?.trim()
      if (validPhone) searchParams.phone = phone?.trim()
      try {
        const response = await searchCustomer(searchParams)
        const customerResponse = response?.data?.json || []
        this.setState({
          showLoader: false,
          customerInfo: customerResponse,
          customerNotFound: !customerResponse?.length,
          showError: false,
          errorMessage: ''
        })
        setSessionStorage(conversationId, 'searchCustomerRes', customerResponse)
        setSessionStorage(conversationId, 'email', email)
        setSessionStorage(conversationId, 'phone', phone)
        setSessionStorage(conversationId, 'errorMessage', '')
        setSessionStorage(
          conversationId,
          'customerNotFound',
          !customerResponse?.length
        )
        setSessionStorage(conversationId, 'showError', '')
        if (customerResponse.length == 1) {
          this.handleCustomerRowClick(customerResponse[0])
        }
      } catch (err) {
        console.error("ecm: Search Customer error", err)
        this.setState({
          showLoader: false,
          showError: true,
          customerNotFound: false,
          errorMessage: errorMessage,
          customerInfo: []
        })
        setSessionStorage(conversationId, 'email', '')
        setSessionStorage(conversationId, 'phone', '')
        setSessionStorage(conversationId, 'searchCustomerRes', [])
        setSessionStorage(conversationId, 'errorMessage', errorMessage)
        setSessionStorage(conversationId, 'customerNotFound', false)
        setSessionStorage(conversationId, 'showError', true)
      }
    } else {
      const ignoreError = !phone && !email
      this.setState({
        showLoader: false,
        showError: !ignoreError,
        customerNotFound: false,
        errorMessage: !ignoreError ? errorMessage : '',
        customerInfo: []
      })
      setSessionStorage(conversationId, 'searchCustomerRes', [])
      setSessionStorage(conversationId, 'errorMessage', errorMessage)
      setSessionStorage(conversationId, 'customerNotFound', false)
      setSessionStorage(conversationId, 'showError', !ignoreError)
    }
  }
  /* istanbul ignore next */
  handleCustomerRowClick = (customer: Customer) => {
    if (this.props.readOnlyMode) {
      return;
    }
    const {
      contextData: { enterpriseContextData, ...rest }
    } = this.props.contextData
    const { orgId, rengaId, firstName, lastName, email, phone } = customer || {}
    const { conversationId } = this.state
    const updatedEnterpriseContextData = {
      ...enterpriseContextData,
      orgId,
      rengaId,
      firstName,
      lastName,
      customerEmail: email?.[0],
      phoneNumber: phone ? phone[0] : ''
    }
    const { activeCustomerEmail = '' } = getSessionStorage(conversationId)
    //call change customer
    if (activeCustomerEmail === email?.[0]) {
      this.props.handleGoback()
    } else {
      this.props.toggleLoader(true, '')
      console.log('call change')
      setSessionStorage(conversationId, 'cachedOrgId', orgId)
      setSessionStorage(conversationId, 'cachedRengaId', rengaId)
      setSessionStorage(conversationId, 'cachedFirstName', firstName)
      setSessionStorage(conversationId, 'cachedLastName', lastName)
      setSessionStorage(conversationId, 'enableSearchCustomer', false)
      setSessionStorage(conversationId, 'caseTitle', '')
      setSessionStorage(conversationId, 'caseCategory', '')
      this.props.updateContextData({
        contextData: {
          enterpriseContextData: { ...updatedEnterpriseContextData },
          ...rest
        }
      }, true)
      this.props.toggleSearchCustomerView(false)
    }
  }

  /* istanbul ignore next */
  handleEmailChange = (value: string): void => {
    setSessionStorage(this.state.conversationId, 'email', value)
    this.setState({
      email: value,
      showError: false,
      errorMessage: '',
      customerNotFound: false,
      // customerInfo: []
    })
  }

  /* istanbul ignore next */
  handlePhoneChange = (value: string): void => {
    setSessionStorage(this.state.conversationId, 'phone', value)
    this.setState({
      phone: value,
      showError: false,
      errorMessage: '',
      customerNotFound: false,
      // customerInfo: []
    })
  }

  /* istanbul ignore next */
  renderTableHeader = (columns: Array<string>, localizeString: any) => {
    return columns?.map((column: string): any => (
      <th title={localizeString[column]} key={column}>
        {localizeString[column]}
      </th>
    ))
  }

  /* istanbul ignore next */
  renderTableBody = (customerInfo: Customers) => {
    return customerInfo?.map((customer: Customer, index: Number) => (
      <tr
        key={`${customer.firstName}-${index}`}
        onClick={() => this.handleCustomerRowClick(customer)}
      >
        <td
          key={`table-data-${customer.firstName}`}
          data-testid='ecm-search-customer-table-data'
          title={customer.firstName}
        >
          {customer.firstName}
        </td>
        <td
          key={`table-data-${customer.email?.[0]}`}
          data-testid='ecm-search-customer-table-data'
          title={customer.email?.[0]}
        >
          {customer.email?.[0]}
        </td>
        <td
          key={`table-data-${customer.orgId}`}
          data-testid='ecm-search-customer-table-data'
          title={customer.orgId}
        >
          {customer.orgId}
        </td>
        <td
          key={`table-data-${customer.orgName}`}
          data-testid='ecm-search-customer-table-data'
          title={customer.orgName}
        >
          {customer.orgName}
        </td>
        <td
          key={`table-data-${customer.role?.[0]}`}
          data-testid='ecm-search-customer-table-data'
          title={customer.role?.[0]}
        >
          {customer.role?.[0]}
        </td>
        <td
          key={`table-data-${customer.type}`}
          data-testid='ecm-search-customer-data'
          title={customer.type}
        >
          {customer.type}
        </td>
      </tr>
    ))
  }


  /* istanbul ignore next */
  handleOnPress = async () => {
    const { email, phone, conversationId } = this.state
    const validEmail = isValidEmail(email)
    const validPhone = isValidatePhoneNumber(phone, true)
    this.setState({ showLoader: true })
    this.getCustomerDetails(conversationId, phone, validPhone, email, validEmail);
    // Analytics for value added offers (VAO)
    updateCustomerSearchClick(conversationId, 'Yes');
  }

  /* istanbul ignore next */
  renderTable = (
    styles: any,
    tableStyle: any,
    customerInfo: Customers,
    localizeString: any
  ) => {
    return this.state.showLoader ? (
      <div className={styles['ecm_module_search-customer-loader-wrapper']}>
        <ProgressCircle
          UNSAFE_className={styles['ccm_module_create-case-loader']}
          aria-label='Loading'
          isIndeterminate
        />
        <div className={styles['ecm_module_creating-case-label']}>
          {localizeString.loader_loading_customer_profiles}
        </div>
      </div>
    ) : (
      <React.Fragment>
        {customerInfo?.length ? (
          <React.Fragment>
            <label className={styles['ecm-table-title']}>
              {localizeString.customer_table_top_title}
            </label>
            <div className={`${commonStyles['ecm-search-customer-table-wrapper']}`}>
              <table
                className={`${tableStyle['custom-table']} ${commonStyles['ecm-table']}`}
                data-testid='search-customer-table'
              >
                <thead className={tableStyle.thead}>
                  <tr>
                    {this.renderTableHeader(
                      SEARCH_CUSTOMER_COLUMNS,
                      localizeString
                    )}
                  </tr>
                </thead>
                <tbody>{this.renderTableBody(customerInfo)}</tbody>
              </table>
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }

  /* istanbul ignore next */
  renderBackIcon = () => {
    if (this.props.darkMode) return <LightBackIcon />
    else return <DarkBackIcon />
  }

  render() {
    const { customerInfo, phone, email } = this.state
    const { darkMode, language, handleGoback } = this.props
    const localizeString: any = language
      ? localization[language]
      : localization.en
    const styles = darkMode ? darkTheme : lightTheme
    const tableStyle = darkMode ? darkTable : lightTable
    return (
      <React.Fragment>
        {this.props.showBackButton ? (
          <div
            className={styles['ecm_module_create-case-top-bar']}
            onClick={handleGoback}
            data-testid='ecm-goback-icon'
          >
            {this.renderBackIcon()}
            <div
              className={styles['ecm_module_back-title']}
              onClick={handleGoback}
              data-testid='ecm-goback-text'
            >
              {localizeString.go_back_link_label}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={styles['ecm-search-customer-wrapper']}>
          <div className={styles['search-customer-top']}>
            <label className={styles['search-customer-title']}>
              {localizeString.search_page_title}
            </label>
            <label className={styles['search-customer-subheading']}>
              {localizeString.search_page_subtitle}
            </label>
          </div>
          <div className={styles['ecm-search-wrapper']}>
            <div className={styles['ecm-search-customer-form-container']}>
              <TextField
                id='ecm-email-field'
                name='ecm-email-field'
                label={localizeString.search_page_field_email}
                placeholder={localizeString.search_page_email_place_holder}
                onChange={this.handleEmailChange}
                value={this.state.email}
              />
              <label
                className={styles['ecm_module_search-customer-inputs-divider']}
              >
                {localizeString.search_page_or}
              </label>
              <TextField
                id='ecm-phone-field'
                name='ecm-phone-field'
                label={localizeString.search_page_field_phone}
                placeholder={localizeString.search_page_type_here}
                onChange={this.handlePhoneChange}
                value={this.state.phone}
              />
              <Button
                variant='secondary'
                data-testid='ecm-search-customer-button'
                onPress={this.handleOnPress}
                isDisabled={(!phone && !email) || this.props.readOnlyMode}
              >
                {localizeString.search_page_search_button}
              </Button>
            </div>
            <div className={commonStyles['error-notification-wrapper']}>
              {this.state.customerNotFound && (
                <React.Fragment>
                  <div className={commonStyles['customer-not-found']}>
                    <Info />
                    <p
                      className={
                        styles['ecm_module_customer-not-found-message']
                      }
                    >
                      {localizeString.customer_not_found_text}
                    </p>
                  </div>
                </React.Fragment>
              )}
              {this.state.showError && (
                <React.Fragment>
                  <span
                    className={
                      commonStyles['ecm_module_customer-not-logged-red-dot']
                    }
                  >
                    {' '}
                  </span>
                  <p
                    data-testid='ecm-customer-error-message'
                    className={
                      commonStyles['ecm_module_customer-not-logged-msg']
                    }
                  >
                    {localizeString[this.state.errorMessage]}
                  </p>{' '}
                </React.Fragment>
              )}
            </div>
          </div>
          <div
            className={`${tableStyle['table-wrapper']} ${styles['ecm-table-wrapper']}`}
          >
            {this.renderTable(styles, tableStyle, customerInfo, localizeString)}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  showChangeCustomer: state.uiState.showChangeCustomer,
  readOnlyMode: state.uiState.readOnlyMode
})


export default connect(mapStateToProps)(SearchCustomer)
