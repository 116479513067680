import React from "react";

export const CheckmarkCircle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18" >
        <defs>
            <style>{`.checkmark_circle_a{fill:rgba(255,255,255,0);}.checkmark_circle_b{fill:currentColor;}`}</style>
        </defs>
        <rect id="Canvas" className="checkmark_circle_a" width="18" height="18" />
        <path className="checkmark_circle_b" d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1Zm5.333,4.54L8.009,13.6705a.603.603,0,0,1-.4375.2305H7.535a.6.6,0,0,1-.4245-.1755L3.218,9.829a.6.6,0,0,1-.00147-.84853L3.218,8.979l.663-.6625A.6.6,0,0,1,4.72953,8.315L4.731,8.3165,7.4,10.991l5.257-6.7545a.6.6,0,0,1,.8419-.10586L13.5,4.1315l.7275.5685A.6.6,0,0,1,14.333,5.54Z" />
    </svg >
)

export const Close = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
        <defs>
            <style>{`.close_flyout_icon{fill: #464646;}`}</style>
        </defs>
        <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
        <path className="close_flyout_icon" d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z" />
    </svg>
)

export const Idle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <defs>
            <style>{`.idle_a{fill:rgba(255,255,255,0);}.idle_b{fill:currentColor;}`}</style>
        </defs>
        <rect className="idle_a" width="18" height="18" />
        <path className="idle_b" d="M1.9,9.078A6.877,6.877,0,0,1,5.369,3.1,6.818,6.818,0,0,0,4.2,6.932,6.9,6.9,0,0,0,14.54,12.913,6.9,6.9,0,0,1,1.9,9.078ZM6.785,6.187a5.029,5.029,0,1,1,5.028,5.028A5.033,5.033,0,0,1,6.785,6.187Zm.973,0a4.056,4.056,0,1,0,4.055-4.055A4.057,4.057,0,0,0,7.758,6.187Zm4.818,1.846-1.341-1.34a.24.24,0,0,1-.07-.169V3.992a.239.239,0,0,1,.239-.239h.478a.239.239,0,0,1,.239.239V6.263l1.111,1.112a.238.238,0,0,1,0,.338l-.319.319a.238.238,0,0,1-.337,0Z" transform="translate(-0.373 0.43)" />
    </svg>
);

export const Overdue = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <defs>
            <style>{`.overdue_a{fill:rgba(255,255,255,0);}.overdue_b{fill:currentColor;}`}</style>
        </defs>
        <g transform="translate(12064 16831)">
            <rect className="overdue_a" width="18" height="18" transform="translate(-12064 -16831)" />
            <path className="overdue_b" d="M933.945,543.646V543.3l1.595-1.6v-4.119a4.55,4.55,0,0,1,3.352-4.732l.236-.063V532a.878.878,0,0,1,1.755-.059c0,.02,0,.04,0,.059v.787l.236.063a4.539,4.539,0,0,1,3.353,4.709V541.7l1.6,1.6v.347ZM940,533.679c-.094,0-.187.007-.281.016a3.593,3.593,0,0,0-3.22,3.922v4.076h7.016v-4.1c.007-.063.014-.155.016-.248a3.592,3.592,0,0,0-3.518-3.659H940Zm.054,12.421a1.5,1.5,0,0,0,1.5-1.5h-3A1.5,1.5,0,0,0,940.054,546.1Z" transform="translate(-12995.006 -17360.689)" />
        </g>
    </svg>
);

export const ConversationClosed = (props: any) => (
    <svg id="icon_-_conversation_closed" data-name="icon - conversation closed" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="Group_161501" data-name="Group 161501" transform="translate(12014 16831)">
            <rect id="Rectangle_150784" data-name="Rectangle 150784" width="18" height="18" transform="translate(-12014 -16831)" fill="rgba(255,0,0,0)" />
            <g id="Group_77" data-name="Group 77" transform="translate(-12939.998 -17358.633)">
                <g id="Group_161559" data-name="Group 161559" transform="translate(932.42 534.055)">
                    <line id="Line_293" data-name="Line 293" y2="7.293" transform="translate(5.157) rotate(45)" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="1.4" />
                    <line id="Line_294" data-name="Line 294" x2="7.293" transform="translate(0) rotate(45)" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="1.4" />
                </g>
            </g>
            <path id="noun-success-4213622" d="M77,0a7,7,0,1,1-7,7A7,7,0,0,1,77,0Zm0,1.4A5.6,5.6,0,1,0,82.6,7,5.6,5.6,0,0,0,77,1.4Z" transform="translate(857.998 529.633)" fill="#707070" />
            <path id="noun-success-4213622" d="M77,0a7,7,0,1,1-7,7A7,7,0,0,1,77,0Zm0,1.4A5.6,5.6,0,1,0,82.6,7,5.6,5.6,0,0,0,77,1.4Z" transform="translate(-12082 -16829)" fill={props.color} />
        </g></svg>
);

export const CallIcon = () => (
    <svg className="call-Icon" id="Group_162090" data-name="Group 162090" xmlns="http://www.w3.org/2000/svg" width="17.667" height="17.848" viewBox="0 0 17.667 17.848">
        <path id="Union_66" data-name="Union 66" d="M1.618,6.2V3.42H0L3.162,0,6.324,3.42H4.854V6.2Z" transform="translate(13.195 0) rotate(45)" fill="currentColor" />
        <path id="Icon_map-volume-control-telephone" data-name="Icon map-volume-control-telephone" d="M4.08,4.5H5.174c.328,0,.538-.482.538-.808V.247c0-.326-.21-.235-.538-.235H4.08Zm0,6.528H5.174c.328,0,.538.379.538.705v3.444c0,.324-.21.747-.538.747H4.08ZM2.636,7.764c-.009-3.149.583-3.264.92-3.264h.116V.012h-.14C2.932.012,0,1,0,7.968s2.932,7.957,3.533,7.957h.14v-4.9H3.556C3.218,11.028,2.645,10.911,2.636,7.764Z" transform="translate(0 6.588) rotate(-45)" fill="currentColor" />
    </svg>
)

