const initialState: any = {
  searchCustomerResponse: {},
  validateCustomerResponse: {},
  activeCustomer: {},
  searchParams: { emailId: '', phoneNumber: '' },
  customerPhoneNumber: ''
};

type actionType = {
  type: string;
  payload: any;
};

const customerReducer = (
  state: any = initialState,
  action: actionType = { type: '', payload: '' },
) => {
  switch (action.type) {
    case 'SET_ACTIVE_CUSTOMER': {
      return {
        ...state,
        activeCustomer: action.payload,
      };
    }
    case 'VALIDATE_CUSTOMER_RESPONSE': {
      return {
        ...state,
        validateCustomerResponse: action.payload,
      };
    }
    case 'SEARCH_CUSTOMER_RESPONSE': {
      return {
        ...state,
        searchCustomerResponse: action.payload,
      };
    }
    case 'SET_SEARCH_PARAM': {
      return {
        ...state,
        searchParams: action.payload,
      };
    }
    case 'SET_CUSTOMER_PHONE_NUMBER': {
      return {
        ...state,
        customerPhoneNumber: action.payload,
      };
    }
    case 'CLEAR_CUSTOMER_DATA': {
      return initialState;
    }
    default:
      return state;
  }
};

export default customerReducer;
