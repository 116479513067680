
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button, DialogTrigger, Dialog, Heading, Divider, Content, ButtonGroup } from '@adobe/react-spectrum'
import { SEND_CONSENT } from '../../redux/constants/ru-constants';
import { setConsentStatus } from '../../redux/actions/ru-actions';
import { consentOverlayEvent, initDunamis, consentDialogEvent } from '../../Analytics/vao-danalytics';
import { updateCTRConsentAnalytics } from '../../Analytics/vao-ctr-analytics';
import { URLS, CardDetails } from "../../interfaces";
import themes from '../../themes';
import styles from './consent.module.css'
import { useTheme } from '../../themes/ThemeContext';


interface Props {
    cards: [CardDetails],
    urls: URLS
    customerId: string
    convId: string
    isVoiceContact: boolean
    isSalesContact: boolean
    darkMode: boolean
    vaoWorkflowCategory: string
    currentConvId: string
    callBackEvent: (event: any, eventData: object) => void
}

const ConsentComponent = ({
    cards,
    isVoiceContact,
    isSalesContact,
    darkMode,
    convId,
    urls,
    customerId,
    vaoWorkflowCategory,
    callBackEvent
}: Props) => {
    const { darkModeNoConsent } = themes(darkMode);
    const { RUInfo: { convData = [], customData = {}, multipartyAssignedAgent = false } = {} } = useSelector((state: any) => state)
    const [closeDialog, setCloseDialog] = useState(false)
    const [openDialog, setOpenDialog] = useState(true)
    const [noConsent, setNoConsent] = useState(false)
    const consentMessage = customData?.consentMessage;
    const url = urls.PERSONALIZATION_DOMAIN
    const dispatch: any = useDispatch();
    const convDataKey = convId + customerId;
    const consentStatus = convData?.[convDataKey]?.consentStatus || 'none'


    const { theme } = useTheme();

    useEffect(() => {
        initDunamis()
    }, [])

    /**
     * This method is used to handle the customer consent in the consent dialog
     * when the Customer has given consent or not. if No button click then customer
     * rejected the consent. Otherwise Customer has given consent
     * @param e 
     */
    const handleCustomerConsent = (e) => {
        const btnText = e.target.innerText;
        if (btnText === 'No') {
            dispatch(setConsentStatus({ convId, customerId, url, consentStatus: 'rejected' }))

            consentOverlayEvent(vaoWorkflowCategory, btnText.toLowerCase(), 'customer-consent')
        } else {
            dispatch(setConsentStatus({ convId, customerId, url, consentStatus: 'accepted' }))

            if (btnText === 'Yes') {
                consentOverlayEvent(vaoWorkflowCategory, btnText.toLowerCase(), 'customer-consent')
            } else {
                consentOverlayEvent(vaoWorkflowCategory, 'customer-has-given-consent', '')
            }
        }
    }

    const sendConsent = (close) => {
        close()
        callBackEvent(SEND_CONSENT, { message: consentMessage?.message })
        dispatch(setConsentStatus({ convId, customerId, url, consentStatus: 'sent' }))

        // analytics
        consentDialogEvent(vaoWorkflowCategory, 'send')
    }

    const openConsentDialog = () => {
        // analytics
        consentOverlayEvent(vaoWorkflowCategory, 'send-consent', '')
    }

    const closeConsentDialog = (close) => {
        close()

        //analytics
        consentDialogEvent(vaoWorkflowCategory, 'cancel')
    }

    useEffect(() => {
        const allCampaignNames = cards?.map(card => card.title) || [];

        switch (consentStatus) {
            case undefined || 'none':
                setNoConsent(false)
                setOpenDialog(true)
                setCloseDialog(false)
                break
            case 'sent':
                setNoConsent(false)
                setOpenDialog(false)
                setCloseDialog(true)
                break
            case 'rejected':
                setNoConsent(true)
                setOpenDialog(false)
                setCloseDialog(false)
                break
            case 'accepted':
                setNoConsent(false)
                setOpenDialog(false)
                setCloseDialog(false)
                break
        }

        // CTR analytics
        if (cards.length > 0) {
            updateCTRConsentAnalytics(consentStatus, allCampaignNames, isSalesContact);
        }
    }, [consentStatus])

    return (
        <React.Fragment>
            {
                openDialog && (
                    <div style={{ color: theme.color, borderColor: theme['border-color'] }} className={darkMode ? `${styles.consent_box_dark}` : `${styles.consent_box_light}`}>
                        <div className={`${styles.consent_box_content}`}>
                            <Text UNSAFE_className={`${styles.consent_box_text}`}>Gather consent before you can see offers</Text>
                            <div className={`${styles.modal_pop} ${styles.consent_box_content_buttons}`}>
                                <DialogTrigger>
                                    <Button variant="cta" UNSAFE_className={`${styles.send_consent_buttons}`} isDisabled={!multipartyAssignedAgent} onPress={openConsentDialog}>
                                        {' '}
                                        Send Consent
                                    </Button>
                                    {(close) => (
                                        <Dialog UNSAFE_className={`${styles.consent_dialog_box}`}>
                                            <Heading>Customer Consent</Heading>
                                            <Divider />
                                            <Content>
                                                <Text>{consentMessage?.text}</Text>
                                                <p style={{ marginTop: '20px' }}>{'  '}</p>

                                                <Text>"{consentMessage?.message}"</Text>
                                            </Content>
                                            <ButtonGroup>
                                                <Button variant='secondary' isDisabled={!multipartyAssignedAgent} onPress={() => closeConsentDialog(close)}>
                                                    Cancel
                                                </Button>
                                                <Button variant='cta' isDisabled={!multipartyAssignedAgent}onPress={() => sendConsent(close)}>
                                                    {isVoiceContact ? <Text>Yes, I asked</Text> : <Text>Send</Text>}
                                                </Button>
                                            </ButtonGroup>
                                        </Dialog>
                                    )}
                                </DialogTrigger>
                                <Button variant='primary' UNSAFE_className={`${styles.cust_given_consent_button}`} isDisabled={!multipartyAssignedAgent} onPress={handleCustomerConsent}>
                                    Customer has given consent
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

            {
                closeDialog && (
                    <div style={{ background: theme.consent, color: theme.color, borderColor: theme['border-color'] }} className={darkMode ? `${styles.consent_box_dark}` : `${styles.consent_box_light}`}>
                        <div className={`${styles.consent_box_content}`}>
                            <Text UNSAFE_className={`${styles.consent_box_text}`}>Has the customer given consent?</Text>
                            <div className={`${styles.consent_box_content_buttons}`}>
                                <Button onPress={handleCustomerConsent} isDisabled={!multipartyAssignedAgent} variant='secondary'>Yes</Button>
                                <Button onPress={handleCustomerConsent} isDisabled={!multipartyAssignedAgent} variant='secondary'>No</Button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                noConsent && (
                    <div style={{ background: theme.background, color: theme.color, borderColor: theme['border-color'] }} className={darkMode ? `${styles.consent_box_dark}` : `${styles.consent_box_light}`}>
                        <div className={`${styles.ru_disabled_box} ${darkModeNoConsent}`}>
                            <Text UNSAFE_className={`${styles.consent_box_text}`}>
                                Recommended Offers has been disabled because the user didn't give consent.
                            </Text>
                            <div className={`${styles.consent_box_content_buttons}`}>
                                <Button UNSAFE_style={{ justifyContent: 'center', border: '3px solid #aaa' }} variant="primary" onPress={handleCustomerConsent} isDisabled={!multipartyAssignedAgent} UNSAFE_className={`${styles.disabled_cust_given_consent_button}`}>
                                    Customer has given consent
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
};

export default ConsentComponent;