import { combineReducers } from 'redux'
import caseReducer from './caseReducer'
import customerReducer from './customerReducer'
import ocReducer from './ocReducer'
import uiReducer from './uiReducer'

const reducers = combineReducers({
  caseState: caseReducer,
  uiState: uiReducer,
  customerState: customerReducer,
  ocState: ocReducer
})

export default reducers

export type State = ReturnType<any>
