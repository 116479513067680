import {
    SET_CURRENT_RU_CONTACT_ID,
    SET_LOADING_STATUS,
    SET_REJECTED_REASONS,
    DART_THEME,
    UPDATE_CONSENT_STATUS,
    UPDATE_CONV_CURRENT_OFFER_INDEX,
    UPDATE_CONV_DATA,
    UPDATE_CONV_EXPAND_VIEW_STATUS,
    UPDATE_OFFER_STATUS,
    SET_MULTIPARTY_ASSIGNED_AGENT,
    SET_FETCH_OFFERS_ERROR,
    DISABLED_BTN_NO
} from '../constants/ru-constants'
import { State } from '../constants/models'

const RUReducer = (
    state: State = {
        convData: {},
        convIndex: '',
        customData: {},
        darkMode: false,
        multipartyAssignedAgent: false,
        fetchOffersErrors: {},
        disabledNoButtonConversations: {}
    },
    action: any
): State => {
    console.log('@@@ RU state', state)
    const convDataCopy = { ...state.convData }

    switch (action.type) {
        case DART_THEME:
            return { ...state, darkMode: action.payload }

        case SET_MULTIPARTY_ASSIGNED_AGENT:
            return { ...state, multipartyAssignedAgent: action.payload }

        case UPDATE_CONSENT_STATUS: {
            const { consentStatus, convId, customerId } = action.payload
            
            const convDataKey = convId + customerId;
            const currentConvData = convDataCopy?.[convDataKey] ? convDataCopy[convDataKey] : {}

            currentConvData.consentStatus = consentStatus
            convDataCopy[convDataKey] = currentConvData
            return { ...state, convData: convDataCopy }
        }

        case UPDATE_CONV_DATA: {
            const { customerId, data, convId } = action.payload;

            if (!convId) return state;

            
            const convDataKey = convId + customerId;
            const currentConvData = convDataCopy?.[convDataKey] ? convDataCopy[convDataKey] : {}
            const newConv = Object.keys(currentConvData).length === 0

            if (data) {
                currentConvData.customerId = customerId
                currentConvData.cards = data?.cards 
                currentConvData.consentStatus = data?.consentStatus;
                newConv && (currentConvData.expandView = false)
                newConv && (currentConvData.currentOfferIndex = 0)
            }
            convDataCopy[convDataKey] = currentConvData


            return { ...state, convData: convDataCopy }
        }

        case SET_CURRENT_RU_CONTACT_ID:
            return { ...state, convIndex: action.payload }

        case SET_LOADING_STATUS: {
            const { loading, convId, customerId } = action.payload
            
            const convDataKey = convId + customerId;
            const currentConvData = convDataCopy?.[convDataKey] ? convDataCopy[convDataKey] : {}

            currentConvData.loading = loading
            convDataCopy[convDataKey] = currentConvData
            return { ...state, convData: convDataCopy }
        }

        case SET_REJECTED_REASONS: {
            return { ...state, customData: action.payload }
        }

        case UPDATE_OFFER_STATUS: {
            const { status, convId, index, rejectReason,lastUpdatedTimestamp, customerId } = action.payload;

            if (!convId) return { ...state }

            
            const convDataKey = convId + customerId;
            const currentConvData = convDataCopy?.[convDataKey]
            if (!currentConvData) return { ...state }
            currentConvData.cards[index].offerStatus = status
            currentConvData.cards[index].lastUpdatedTimestamp = lastUpdatedTimestamp
            if (rejectReason) currentConvData.cards[index].rejectReason = rejectReason
            convDataCopy[convDataKey] = currentConvData

            return { ...state, convData: convDataCopy }
        }

        case UPDATE_CONV_EXPAND_VIEW_STATUS: {
            const { status, convId, customerId } = action.payload

            if (!convId) return { ...state }
            
            
            const convDataKey = convId + customerId;
            const currentConvData = convDataCopy?.[convDataKey]
            if (!currentConvData) return { ...state }
            currentConvData.expandView = status
            convDataCopy[convDataKey] = currentConvData

            return { ...state, convData: convDataCopy }
        }

        case UPDATE_CONV_CURRENT_OFFER_INDEX: {
            const { index, convId, customerId } = action.payload;

            if (!convId) return { ...state }

            const convDataKey = convId + customerId;

            const currentConvData = convDataCopy?.[convDataKey]
            if (!currentConvData) return { ...state }
            currentConvData.currentOfferIndex = index
            convDataCopy[convDataKey] = currentConvData;

            return { ...state, convData: convDataCopy }
        }

        case SET_FETCH_OFFERS_ERROR: {
          const errorsCopy = { ...state.fetchOffersErrors }
          errorsCopy[action.payload.convId] = action.payload.value
          return { ...state, fetchOffersErrors: errorsCopy }
        }

        case DISABLED_BTN_NO: {
            const { convId, offerId, disabledBtnNo } = action.payload;
            if (!convId) return { ...state }
            const newDisabledNoButtonState = {
              ...state.disabledNoButtonConversations,
              [convId]: {
                ...state.disabledNoButtonConversations?.[convId],
                [offerId]: disabledBtnNo
              }
            }
            return { ...state, disabledNoButtonConversations: newDisabledNoButtonState }
        }

        default:
            return state;
    }
}

export default RUReducer
