import getClient from '../utils/client';
type linkCaseAPIPayload = {
  conversationId: string;
  allowToLinkCaseForReturnCustomer?: boolean;
  linkedCaseData_CCM: {
    guid: string;
    id: string;
    linkedByAgent: string;
    caseCreated: boolean;
  };
};

/* istanbul ignore next */
export default async function linkCase(payload: linkCaseAPIPayload): Promise<any> {
  return getClient()
    .put(`/api/v1/cases/link`, payload)
    .then(res => {
      const receivedRes = res.data ? res.data : {};
      console.log('oc-ccm-module, linkCasesResponse :', JSON.stringify(receivedRes));
      return receivedRes;
    })
    .catch(e => {
      console.log('oc-ccm-module, linkCasesResponse : Exception Occured', e);
      return {};
    });
}
