import { useState } from 'react'
import axios from "axios"

/**
 * Hook for getting the AA feedback options from floodgate
 */
const useGetFloodgateFeedbackOptionsHook = (): {
  isLoading: boolean,
  submit: (url: string, language?: string) => Promise<{
    THUMBS_DOWN_OPTIONS: {[key: string]: any},
    INAPPROPRIATE_FLAGS: {[key: string]: any}
  }>
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const submit = (
    FGUrl: string = '',
    language: string = 'en'
  ): Promise<{
    THUMBS_DOWN_OPTIONS: {[key: string]: any},
    INAPPROPRIATE_FLAGS: {[key: string]: any}
  }> => {
    setIsLoading(true)
  
    return axios.get(`${FGUrl}`, {
      headers: {
        'x-api-key': 'oac-custom-panel',
        Authorization: localStorage.getItem('customPanelAccessToken')?.replaceAll('"', '') ?? ''
      }
    })
    .then((response: any) => {
      setIsLoading(false)
      const THUMBS_DOWN_OPTIONS = {}
      const INAPPROPRIATE_FLAGS = {}
      THUMBS_DOWN_OPTIONS[language] = []
      INAPPROPRIATE_FLAGS[language] = []

      response?.data?.releases?.[0]?.meta?.forEach(({ k = '', v = '' }: any) => {
        const decodedValue = JSON.parse(atob(v))
        if (decodedValue?.type === 'THUMBS_DOWN') {
          THUMBS_DOWN_OPTIONS[language].push({ key: k, value: decodedValue[language] })
        } else if (decodedValue?.type === 'FLAG_INAPROPRIATE') {
          INAPPROPRIATE_FLAGS[language].push({ key: k, value: decodedValue[language] })
        }
      })

      THUMBS_DOWN_OPTIONS[language].sort((a, b) => {
        if (a.value === 'Other') return 1 //Other should be last always
        else if (b.value === 'Other') return -1 // "Other" should be last always
        else if (a.value > b.value) return 1
        else return -1
      })

      INAPPROPRIATE_FLAGS[language].sort((a, b) => {
        if (a.value > b.value) return 1
        else return -1
      })

      if (
        !response?.data?.releases?.[0]?.meta ||
        !THUMBS_DOWN_OPTIONS[language].length ||
        !INAPPROPRIATE_FLAGS[language].length
      ) {
        throw Error('Empty response from FloodGate')
      }

      return {
        THUMBS_DOWN_OPTIONS,
        INAPPROPRIATE_FLAGS
      }
    })
    .catch((e) => {
      setIsLoading(false)
      console.error('!!!Error for getting feed back string values. Error: ', e)
      throw e;
    })
  }

  return {
    isLoading,
    submit
  }
}

export default useGetFloodgateFeedbackOptionsHook
