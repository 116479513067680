import * as React from 'react'
import App from './Components/App'

interface Props {
  contextData: object;
  callBackEvent : (eventType : string, eventData : object) => void;
}

export const LibraryModule = ( {contextData, callBackEvent} : Props) => {
  return <App contextData={contextData} callBackEvent={callBackEvent}/>
}
