import React, { useEffect, useRef, useState } from 'react'
import { ActionButton, DialogTrigger, Tooltip, TooltipTrigger } from '@adobe/react-spectrum'
import styles from '../Theme/Styles/styles.css'
import { Call, Pause, Hold, BannerClose } from '../Theme/Icons'
import CallButtonsPopup from './EndCallPopup'
import localization from '../lang/localization'
import parsePhoneNumber from 'libphonenumber-js'
import { getBannerTimer, setBannerTimer } from '../util'

type propsData = {
    darkMode: boolean
    lang: string
    connectionCount: number
    queueNames: any
    callConnection: {
        contactId: string;
        connectionId: string;
        isHold: boolean;
        state: string;
        endPoint: string;
    }
    callBackEvent: (eventType: string, eventData: object) => void
}

export const CONNECTION_STATUS = { ACTIVE: 'ACTIVE', CONNECTING: 'CONNECTING', CONNECTED: 'CONNECTED' }
export const INTERNAL_TRANSFER = 'INTERNAL-TRANSFER';
export const BANNER_ACTIVE_CALL_HOLD_RESUME = 'BANNER_ACTIVE_CALL_HOLD_RESUME';
export const BANNER_ACTIVE_CLOSE_CONNECTION = 'BANNER_ACTIVE_CLOSE_CONNECTION';

const ActiveBanner = React.memo((props: propsData) => {
    const {
        callBackEvent, lang = 'en', connectionCount = 0,
        queueNames = {},
        callConnection: {
            contactId = '',
            connectionId = '',
            isHold = false,
            state = '',
            endPoint = '',
        } = {}
    } = props;
    const clockInterval = useRef<any>(null);
    const bannerTimer = getBannerTimer(contactId);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const timerVal = bannerTimer?.[connectionId] ? (Math.round((new Date().getTime() - new Date(bannerTimer[connectionId]).getTime()) / 1000) || 0) : 0;
        setTimer(timerVal);
        return () => {
            clockInterval.current && clearInterval(clockInterval.current);
        }
    }, [connectionId]);

    useEffect(() => {
        isHold ? handleTimer() : resetClock();
    }, [isHold])

    const handleTimer = () => {
        const newBannerTimer = { ...bannerTimer } || {};
        if (!newBannerTimer[connectionId]) newBannerTimer[connectionId] = new Date();
        setBannerTimer({ [contactId]: newBannerTimer });
        clockInterval.current = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
    }

    const resetClock = () => {
        clockInterval.current && clearInterval(clockInterval.current);
        setTimer(0)
        const newBannerTimer = { ...bannerTimer } || {};
        newBannerTimer[connectionId] = '';
        setBannerTimer({ [contactId]: newBannerTimer });
    }

    const handleHold = () => {
        callBackEvent(BANNER_ACTIVE_CALL_HOLD_RESUME, { isHold: !isHold, connectionId });
    }

    const handleCloseCallConnection = () => {
        callBackEvent(BANNER_ACTIVE_CLOSE_CONNECTION, { connectionId });
    }

    const getHeaderClass = () => {
        if (isHold && timer >= 120) return 'ccp-banner-hold-mute-long'
        if (isHold) {
            return 'ccp-banner-hold'
        }
        return ''
    }

    const getEndPoint = (endPoint: string) => {
        if (!endPoint) return ''
        if (endPoint === INTERNAL_TRANSFER && queueNames[connectionId]) return queueNames[connectionId];
        if (endPoint === INTERNAL_TRANSFER) return 'Internal Transfer';
        const phoneNumber = parsePhoneNumber(endPoint);
        return phoneNumber?.formatInternational() || endPoint;
    }

    const getEndPointText = () => {
        return (state === CONNECTION_STATUS.CONNECTING) ? <i>{localization[lang]?.TELEPHONY.CONNECTING}</i> : getEndPoint(endPoint)
    }

    const clockTimer = new Date(timer * 1000).toISOString().substring(11, 19) || '';
    return (
        <div className={`${styles['ccp-banner-active-call']} ${getHeaderClass() ? styles[getHeaderClass()] : ''}`}>
            <div className={styles['ccp-banner-left']}>
                <Call />
                <TooltipTrigger>
                    <ActionButton UNSAFE_className={styles['ccp-telephony-banner-content']}>
                        {getEndPointText()}</ActionButton>
                    <Tooltip>{getEndPointText()}</Tooltip>
                </TooltipTrigger>
            </div>
            <div className={styles['ccp-banner-middle']}>
                {
                    isHold &&
                    <span className={styles['ccp-banner-middle-sec']}>
                        <TooltipTrigger>
                            <ActionButton UNSAFE_className={styles['ccp-telephony-banner-content']} onPress={handleHold}>
                                <Pause width="15" height="15" />
                            </ActionButton>
                            <Tooltip>{localization[lang]?.TELEPHONY.RESUME}</Tooltip>
                        </TooltipTrigger>
                        <span>{clockTimer}</span>
                    </span>
                }
            </div>
            <div className={styles['ccp-banner-right']}>
                <TooltipTrigger>
                    <ActionButton onPress={handleHold} UNSAFE_className={styles['ccp-telephony-banner-content']}>
                        <Hold width="30" height="30" background={isHold ? "#ffff" : "#FFFFFF4D"} stroke={isHold ? "#ff8700" : "#ffff"} />
                    </ActionButton>
                    <Tooltip>{isHold ? localization[lang]?.TELEPHONY.RESUME : localization[lang]?.TELEPHONY.PAUSE}</Tooltip>
                </TooltipTrigger>
                <TooltipTrigger>
                    <DialogTrigger>
                        <ActionButton UNSAFE_className={styles['ccp-telephony-banner-content']}>
                            <BannerClose width="30" height="30" />
                        </ActionButton>
                        {(cancel) => (
                            <CallButtonsPopup
                                isAgent={endPoint === INTERNAL_TRANSFER}
                                handleEndCall={handleCloseCallConnection}
                                lang={lang}
                                connections={connectionCount}
                                cancel={cancel}
                                callBackEvent={callBackEvent}
                            />
                        )}
                    </DialogTrigger>
                    <Tooltip>{localization[lang]?.TELEPHONY.CLOSE}</Tooltip>
                </TooltipTrigger>
            </div>
        </div>
    )
})

export default ActiveBanner;