import React from 'react'
import { Button, ButtonGroup, Flex, Tooltip, TooltipTrigger } from '@adobe/react-spectrum'
import localization from '../Language/localization'

interface Props {
  assistIdForAnalytics: string
  id: string
  deleteQuery: (
    assistId: string,
    queryId: string,
    isPinnedQuery: boolean
  ) => void
  pinUnpinQuery: (
    assistId: string,
    queryId: string,
    isPinned: boolean
  ) => void
  actionsDisabled: boolean
  numberOfPinnedQueries: number
  pinned: boolean
  language: string,
  pinOperation: string | undefined
}

const AAQueryButtons = (props: Props) => {
  const {
    id = '',
    assistIdForAnalytics = '',
    language = 'en',
    deleteQuery = null,
    pinUnpinQuery = null,
    actionsDisabled,
    numberOfPinnedQueries,
    pinned,
    pinOperation = undefined
  } = props

  return (
    <Flex alignItems='center' height='100%' UNSAFE_style={{ opacity: 1 }}>
      <ButtonGroup align='center' alignSelf='center' width='100%' zIndex={1}>
        {!pinned && numberOfPinnedQueries > 7 ? (
          <TooltipTrigger delay={0}>
            <Button UNSAFE_style={{ fontSize: 'smaller' }} height='24px' minHeight='unset' minWidth='unset' variant='primary'>
              {pinned ? localization[language]['Unpin'] : localization[language]['Pin']}
            </Button>
            <Tooltip>{localization[language]['Maximum of 8 pinned']}</Tooltip>
          </TooltipTrigger>
        ) 
        : pinOperation ? (
          <TooltipTrigger delay={0}>
            <Button UNSAFE_style={{ fontSize: 'smaller' }} height='24px' minHeight='unset' minWidth='unset' variant='primary'>
              {pinned ? localization[language]['Unpin'] : localization[language]['Pin']}
            </Button>
            <Tooltip>{localization[language]['Pin operation forbitten']}</Tooltip>
          </TooltipTrigger>
        )
        : (
          <Button
            UNSAFE_style={{ fontSize: 'smaller' }}
            height='24px'
            minHeight='unset'
            minWidth='unset'
            variant='primary'
            onPress={() => pinUnpinQuery?.(assistIdForAnalytics, id, pinned)}>
            {pinned ? localization[language]['Unpin'] : localization[language]['Pin']}
          </Button>
        )}
        <Button
          onPress={() => deleteQuery?.(assistIdForAnalytics, id, pinned)}
          UNSAFE_style={{ fontSize: 'smaller' }}
          isDisabled={actionsDisabled}
          height='24px'
          marginStart='4px'
          minHeight='unset'
          minWidth='unset'
          variant='negative'
        >
          {localization[language]['Delete']}
        </Button>
      </ButtonGroup>
    </Flex>
  )
}
export default AAQueryButtons
