export const SEND_CONSENT = 'SEND_CONSENT'
export const EXPANDED_VIEW = 'EXPANDED_VIEW'
export const SEND_OFFER = 'SEND_OFFER'
export const OFFER_ID_COPY = 'OFFER_ID_COPY'
export const BENEFITS_COPY = 'BENEFITS_COPY'
export const UPDATE_CONSENT_STATUS = 'UPDATECONSENT_STATUS'
export const REJECT_REASON = 'REJECT_REASON'
export const CONV_ID = 'CONV_ID'
export const UPDATE_CONV_DATA = 'UPDATE_CONV_DATA'
export const SET_CURRENT_RU_CONTACT_ID = 'SET_CURRENT_RU_CONTACT_ID'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_REJECTED_REASONS = 'SET_REJECTED_REASONS'
export const UPDATE_OFFER_STATUS = 'UPDATE_OFFER_STATUS'
export const UPDATE_CONV_EXPAND_VIEW_STATUS = 'UPDATE_CONV_EXPAND_VIEW_STATUS'
export const UPDATE_CONV_CURRENT_OFFER_INDEX = 'UPDATE_CONV_CURRENT_OFFER_INDEX'
export const DART_THEME = 'DART_THEME'
export const SET_MULTIPARTY_ASSIGNED_AGENT = 'SET_MULTIPARTY_ASSIGNED_AGENT'
export const SET_FETCH_OFFERS_ERROR = 'SET_FETCH_OFFERS_ERROR'
export const COLLAPSE_RU_DETAILS_HANDLER = 'COLLAPSE_RU_DETAILS_HANDLER';
export const COLLAPSE = 'COLLAPSE';
export const DISABLED_BTN_NO = 'DISABLED_BTN_NO';
export const SET_RU_WIDGET_SHOW = 'SET_RU_WIDGET_SHOW'
export const UNMOUNT_RU_WIDGET_BUTTOM = 'UNMOUNT_RU_WIDGET_BUTTOM'
export const MOUNT_RU_WIDGET_BUTTOM = 'MOUNT_RU_WIDGET_BUTTOM'
