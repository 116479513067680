import React, { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { TextArea, Flex, ActionButton, Button, TooltipTrigger, Tooltip, Link } from '@adobe/react-spectrum'
import { ArrowLeft, InfoTip } from '../Themes/Icons'
//import Avatar from '@react/react-spectrum/Avatar';
import styles from '../Themes/Styles/styles.module.css'
import localization from '../Language/localization'
import AACardList from './AACardList'
import themes from '../Themes/Themes'
import { FEATURE_FLAG_AA_Q } from '../Store/constants/AAConstants'
import AAAnswers from './AAAnswers'
import { initDunamis, querySearchEvent, trackAnswerFeedbackEvent, trackPinnedRecentQueryAndCopyEvents } from '../Analytics'
import { getFastFollowFlags } from '../utilities/Utility'
import useGetQueriesHook from '../hooks/useGetQueriesHook';
import useGetFloodgateFeedbackOptionsHook from '../hooks/useGetFloodgateFeedbackOptionsHook';
import useGetLeoSessionHook from '../hooks/useGetLeoSessionHook';
import useSubmitLeoQueryHook from '../hooks/useSubmitLeoQueryHook';
import useAddRecentItemsHook from '../hooks/useAddRecentItemsHook';
import useDeletePinUnpinQueryHook from '../hooks/useDeletePinUnpinQueryHook';
import useSubmitLeoFeedbackHook from '../hooks/useSubmitLeoFeedbackHook';

interface Props {
  conversationSummary?: any, 
  conversationId: string
  agentDetails: any
  contextData: string
  callBackEvent: (event: any, eventData: object) => void
  darkMode: boolean
  language: string
  urls: {
    GENAI_MIDDLEWARE_URL: string,
    FG_ENDPOINT_AAFeedback: string
  }
}

export type Query = {
  id?: string,
  date?: string,
  description?: string,
  question?: string,
  answers?: any[],
  index?: number,
  sessionId?:string,
  timestamp?:number,
  answer?:string,
  assistId?: string,
  feedback?:string,
  error?:boolean,
  pinOperation?:string,
  linkSources?: Array<any>
}

/**
 * This is the main app component.
 * Adobe Answers module allows agents to query LLM Ai with questions to help assist the customer
 * 
 * - AdobeAnswersFrame -
 * 
 *  onLoad:
 *    fetchQueries from backend using hook. Returns recent and pinned queries []
 *    fetchSelectedQueries from localStorage. Returns object with conversationId as key and queryId as value
 * 
 *  onSelectQuery:
 *    setSelectedQueries state for that conversationId with the id of the query clicked
 *      setSelectedQueries state in localStorage
 * 
 *  onSelectNewQuery:
 *    clear selectedQueries state for that conversationId
 *      clear selectedQueries state for conversationId in localStorage
 * 
 *  onSubmitQuery:
 *    set Loading state for that conversationId to true
 *    get sessionId
 *    submit query to LEO
 *      set loading state to false
 *      handle errors if needed
 * 
 *    set selectedQueries state for that conversationId
 *    set selectedQueries state for that conversationId in localStorage
 *    add query to recentQueries state
 */
const AdobeAnswersFrame = (props: Props) => {
  const [loading, setLoading] = useState<{
    [conversationId: string]: boolean
  }>({});
  const [queryAiErrors, setQueryAiErrors] = useState<{
    [conversationId: string]: {
      question: string,
      answer: string,
      linkSources?: Array<any>
    } | null
  }>({})
  const [recentQueries, setRecentQueries] = useState<Array<Query>>([])
  const [pinnedQueriesList, setPinnedQueriesList ] = useState<Array<Query>>([])
  const [selectedQueries, setSelectedQueries] = useState<{
    [conversationId: string]: string | null
  }>({})
  const [thumbsDownFeedbackOptions, setThumbsDownFeedbackOptions] = useState<{[key: string]: any}>([])
  const [inappropriateFlagOptions, setInappropriateFlagOptions] = useState<{[key: string]: any}>([])

  const {
    errors: loadingQueriesErrors,
    getQueries: fetchQueries,
    isLoading: isLoadingQueries
  } = useGetQueriesHook();

  const {
    isLoading: isLoadingFeedbackOptions,
    submit: fetchFeedbackOptions
  } = useGetFloodgateFeedbackOptionsHook();
  
  const {
    getSession,
    isLoading: isLoadingSession
  } = useGetLeoSessionHook();

  const {
    submit: submitLeoQuery
  } = useSubmitLeoQueryHook();

  const {
    submit: submitNewRecentItem
  } = useAddRecentItemsHook();

  const {
    deleteQuery: submitDeleteQuery,
    isUpdating,
    pinUnpinQuery: submitPinUnpinQuery,
    updateQuery
  } = useDeletePinUnpinQueryHook();

  const {
    isSubmitting,
    submit
  } = useSubmitLeoFeedbackHook()

  const [inputText, setInputText] = useState<string>('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [isFastFollowFlagEnabled, setIsFastFollowFlagEnabled] = useState<boolean>(false);
  const { conversationSummary, conversationId, agentDetails, darkMode, language, urls, callBackEvent } = props
  const chatMaxLength = 999999999
  const urlParams = new URLSearchParams(window.location.search)
  const questionsFeatureFlag = urlParams.get(FEATURE_FLAG_AA_Q)
  const isSuggestedQuestionsFeatureEnabled = isFastFollowFlagEnabled || questionsFeatureFlag === 'true'

  const numberOfPinnedQueries = pinnedQueriesList?.length ?? 0

  const { darkModeBG, darkModeBorder, darkModeTitle, darkModeInfo, darkModeBorderLeft, darkModeTextAreaWrapper } = themes(darkMode)

  /**
   * The currently selected query for this conversation
   */
  const currentSelectedQuery = useMemo(() => {
    return recentQueries?.find((query: Query) => query.id === selectedQueries[conversationId]) ??
      pinnedQueriesList?.find((query: Query) => query.id === selectedQueries[conversationId])
  }, [recentQueries, pinnedQueriesList, selectedQueries?.[conversationId]])

  useEffect(() => {
    // only check localStorage fast follow flags on mount and unmount
    if (getFastFollowFlags()?.FAST_FOLLOWS_GENAI_SUGGESTED_QUESTIONS === true) {
      setIsFastFollowFlagEnabled(true);
    }
  }, [])

  useEffect(() => {
    initDunamis()
    fetchQueries(urls.GENAI_MIDDLEWARE_URL, agentDetails?.ldap)
    .then(({
      pinnedQueries,
      recentQueries
    }) => {
      setPinnedQueriesList(pinnedQueries ?? [])
      setRecentQueries(recentQueries ?? [])
    })
  }, [])

  useEffect(() => {
    // get selectedQueries from localStorage and set in state on load
    let selectedQueriesFromLocalStorage: {[k: string]: string} = {}
    try {
      selectedQueriesFromLocalStorage = JSON.parse(localStorage.getItem('conversationQuery') ?? '')
    } catch (err) {
      console.error('Error parsing local storage for ', conversationId)
    }

    if (Object.keys(selectedQueriesFromLocalStorage ?? {}).length > 0) {
      setSelectedQueries(selectedQueriesFromLocalStorage)
    }
  }, [])

  useEffect(() => {
    // fetch feedback options from floodgate on load
    fetchFeedbackOptions(urls.FG_ENDPOINT_AAFeedback)
    .then((options) => {
      setThumbsDownFeedbackOptions(options.THUMBS_DOWN_OPTIONS)
      setInappropriateFlagOptions(options.INAPPROPRIATE_FLAGS)
    })
  }, [])

  const onMessageChange = (e: any): void => {
    setInputText(e)
  }

  const isSuggestedQuery = (question: string): boolean => {
    return isSuggestedQuestionsFeatureEnabled &&
      conversationSummary?.summary?.questions?.includes(question)
  }

  /**
   * Method to invoke when user submits a query to AA
   * - set loading state to true for convoId
   * - optimistically update recentQueries and selectedQueries state with new query
   * - get sessionId from LEO
   * - submit query to LEO using SessionId
   *   - update recentQueries with new Query info and answer from LEO
   *   - update recent items in backend with new query
   *   - onError:
   *     - remove query from recentQueries and selectedQueries state
   * 
   */
  const onSubmitQuery = (question: string) => {
    setQueryAiErrors(errors => {
      const errorsCopy = {...errors}
      errorsCopy[conversationId] = null
      return errorsCopy
    })
    setLoading(loading => ({
        ...loading,
        [conversationId]: true
      })
    )

    // because we don't have a query id yet, use this as temp id
    const id = uuidv4()
    const queryToSubmit: Query = {
      description: question,
      id,
      question,
      timestamp: (new Date(Date.now())).getTime()
    }

    // optimistically add the query to the state
    setRecentQueries(queries => {
      const recentQueriesCopy = [...queries]
      recentQueriesCopy.unshift(queryToSubmit)
      return recentQueriesCopy
    })

    // optimistically select the new query
    setSelectedQueriesInStateAndLocalStorage(conversationId, id)

    // get the leo session id and submit query
    getSession(urls.GENAI_MIDDLEWARE_URL, conversationId)
    .then((sessionId) => {
      return submitLeoQuery(
        urls.GENAI_MIDDLEWARE_URL,
        question,
        sessionId,
        conversationId,
        language
      )
    })
    .then((query) => {
      // now update the db with the new query
      return submitNewRecentItem(
        urls.GENAI_MIDDLEWARE_URL,
        query.answer ?? '',
        query.assistId ?? '',
        query.description ?? '',
        agentDetails.ldap,
        query.sessionId ?? '',
        question,
        query.linkSources ?? []
      )
    })
    .then(({ recentItems, queryAdded }) => {
      // now update state with data from db (needed?)
      const sortedRecentQueries = recentItems.sort((a, b) => {
        if (a.timestamp > b.timestamp) return -1
        if (a.timestamp < b.timestamp) return 1
        return 0
      })
      setRecentQueries(sortedRecentQueries)

      // update selectedQueries state with the query id returned from the db
      setSelectedQueriesInStateAndLocalStorage(conversationId, queryAdded.id)

      // dunamis analytics event - click event for search
      querySearchEvent(queryAdded.question, queryAdded.assistId, isSuggestedQuery(queryAdded.question))
      return recentItems
    })
    .catch((err) => {
      console.log('Error in the submitQuery handler: ', err?.message ?? 'Internal Error')

      // if any part of the process fails, revert the optimistic state updates
      // remove query from recent queries
      const recentQueriesCopy = [...recentQueries]
      recentQueriesCopy.filter((query) => query.id !== id)
      setRecentQueries(recentQueriesCopy)

      // remove query from selectedQueries
      setSelectedQueriesInStateAndLocalStorage(conversationId, null)

      // set the error state
      setQueryAiErrors(errors => {
        const errorsCopy = {...errors}
        errorsCopy[conversationId] = {
          question,
          answer: err?.message ?? 'Internal Error'
        }
        return errorsCopy
      })
    })
    .finally(() => {
      setLoading(loading => ({
        ...loading,
        [conversationId]: false
      }))
    })
  }

  const onClickSubmit = (suggestion: string = '') => {
    const question = suggestion?.length && isSuggestedQuestionsFeatureEnabled ? suggestion : inputText
    setInputText('')
    setShowSuggestions(false)
    onSubmitQuery(question)
  }

  /**
   * This method sets the selectedQueries in state and localStorage
   */
  const setSelectedQueriesInStateAndLocalStorage = (
    conversationId: string,
    queryId: string | null,
  ) => {
    const selectedQueriesCopy = {...selectedQueries}
    selectedQueriesCopy[conversationId] = queryId
    setSelectedQueries(selectedQueriesCopy)

    try {
      localStorage.setItem('conversationQuery', JSON.stringify(selectedQueriesCopy))
    } catch (err) {
      console.log('Error writing selectedQueries to localStorage: ', err?.message ?? 'Internal Error')
    }
  }

  const deleteQuery = (
    assistId: string,
    queryId: string,
    isPinnedQuery: boolean
  ) => {
    trackPinnedRecentQueryAndCopyEvents(assistId, 'delete', '', '')
    submitDeleteQuery(isPinnedQuery, agentDetails?.ldap, queryId, urls.GENAI_MIDDLEWARE_URL)
    .then(() => {
      // now update state
      const recentQueriesCopy = [...recentQueries]
      const pinnedQueriesCopy = [...pinnedQueriesList]
      const filteredRecentQueries = recentQueriesCopy.filter((query) => query.id !== queryId)
      const filteredPinnedQueries = pinnedQueriesCopy.filter((query) => query.id !== queryId)
      setPinnedQueriesList(filteredPinnedQueries)
      setRecentQueries(filteredRecentQueries)

      // and selectedQueries if the deleted query is selected
      if (selectedQueries[conversationId] === queryId) {
        const selectedQueriesCopy = { ...selectedQueries }
        selectedQueriesCopy[conversationId] = null
        setSelectedQueries(selectedQueriesCopy)
      }
    })
    .catch((err) => {
      console.log('Error deleting query: ', err?.message ?? 'Internal Error')
    })
  }

  /**
   * Method for sending feedback for a query:
   * - optimistically update the state with the feedback for the query
   * - submit the feedback to LEO
   * - submit the feedback to AA db for persistence
   * - rollback feedback changes from state if there's an error
   * @param comment comment text input
   * @param feedbackType the feedback type (thumbs up, down, flag inappropriate)
   * @param options the feedback options
   * @param query the current selected query
   * @param close 
   */
  const sendFeedBack = (
    comment: string,
    feedbackType: string,
    options: any[],
  ) => {
    const data = {
      assistId: currentSelectedQuery?.assistId,
      feedback: {
        options,
        comment,
        expectedSourceUrls: [],
        feedbackType
      },
      conversationId: conversationId
    }

    // analytics event - Feedback - thumbs up or down
    const combinedOptions = data.feedback.options.join(" | ")
    const categoryForAnalytics = feedbackType === "THUMBS_DOWN" ?
      "what-went-wrong" :
      feedbackType === "FLAG_INAPPROPRIATE" ?
      "report-results-options" :
      ""
    const valueForAnalytics = feedbackType === "FLAG_INAPPROPRIATE" ?
      "report" :
      feedbackType === "THUMBS_UP" ?
      "positive" :
      "negative"
    trackAnswerFeedbackEvent(
      currentSelectedQuery?.assistId,
      feedbackType === "THUMBS_UP" ? "click" : "success",
      feedbackType === "FLAG_INAPPROPRIATE" ? "report" : feedbackType === "THUMBS_UP" ? "positive" : "feedback",
      valueForAnalytics,
      categoryForAnalytics,
      feedbackType === "THUMBS_UP" ? "" : combinedOptions,
      feedbackType === "THUMBS_UP" ? "" : inputText
    );

    const updatedQuery = {
      ...currentSelectedQuery,
      feedback: feedbackType
    }

    const queryCopyForRollback = {
      ...currentSelectedQuery
    }

    // first optimistically update the query in state
    const recentQueriesCopy = [...recentQueries]
    const pinnedQueriesCopy = [...pinnedQueriesList]
    const queryIndexRecentQueries = recentQueriesCopy.findIndex((query: Query) => query.id === updatedQuery.id)
    if (queryIndexRecentQueries !== -1) {
      recentQueriesCopy[queryIndexRecentQueries] = updatedQuery
      setRecentQueries(recentQueriesCopy)
    }
    const queryIndexPinnedQueries = pinnedQueriesCopy.findIndex((query: Query) => query.id === updatedQuery.id)
    if (queryIndexPinnedQueries !== -1) {
      pinnedQueriesCopy[queryIndexPinnedQueries] = updatedQuery
      setPinnedQueriesList(pinnedQueriesCopy)
    }
    
    // submit feedback to LEO
    submit(
      updatedQuery,
      conversationId,
      urls.GENAI_MIDDLEWARE_URL,
      options,
      inputText,
      feedbackType
    )
    .then(() => {
      // feedback sent to LEO, now save to AA db
      return updateQuery(
        updatedQuery,
        urls.GENAI_MIDDLEWARE_URL,
        pinnedQueriesList?.findIndex((query) => query.id === updatedQuery.id) !== -1,
        agentDetails?.ldap,
        currentSelectedQuery?.id ?? ''
      )
    })
    .catch((err) => {
      console.log('Error submitting feedback: ', err?.message ?? 'Internal Error')
      // revert state changes in case of error
      const recentQueriesCopy = [...recentQueries]
      const pinnedQueriesCopy = [...pinnedQueriesList]
      const queryIndexRecentQueries = recentQueriesCopy.findIndex((query: Query) => query.id === updatedQuery.id)
      if (queryIndexRecentQueries !== -1) {
        recentQueriesCopy[queryIndexRecentQueries] = queryCopyForRollback
        setRecentQueries(recentQueriesCopy)
      }
      const queryIndexPinnedQueries = pinnedQueriesCopy.findIndex((query: Query) => query.id === updatedQuery.id)
      if (queryIndexPinnedQueries !== -1) {
        pinnedQueriesCopy[queryIndexPinnedQueries] = queryCopyForRollback
        setPinnedQueriesList(pinnedQueriesCopy)
      }      
    })
  }

  /**
   * 
   * @param assistId query assistId from LEO
   * @param queryId queryId
   * @param isPinned boolean is this query pinned
   */
  const pinUnpinQuery = (
    assistId: string,
    queryId: string,
    isPinned: boolean
  ) => {
    trackPinnedRecentQueryAndCopyEvents(assistId, isPinned ? 'unpin' : 'pin', '', '')
    submitPinUnpinQuery(urls.GENAI_MIDDLEWARE_URL, isPinned, agentDetails?.ldap, queryId)
    .then(() => {
      // now update the state
      const recentQueriesCopy = [...recentQueries]
      const pinnedQueriesCopy = [...pinnedQueriesList]
      const queryToPinOrUnpin = isPinned ?
        pinnedQueriesCopy.find((query) => query.id === queryId) :
        recentQueriesCopy.find((query) => query.id === queryId)
      const filteredRecentQueries = recentQueriesCopy.filter((query) => query.id !== queryId)
      const filteredPinnedQueries = pinnedQueriesCopy.filter((query) => query.id !== queryId)
      if (queryToPinOrUnpin != null) {
        isPinned ?
          filteredRecentQueries.unshift(queryToPinOrUnpin) :
          filteredPinnedQueries.unshift(queryToPinOrUnpin)
      }
      
      setRecentQueries(filteredRecentQueries)
      setPinnedQueriesList(filteredPinnedQueries)
    })
    .catch((err) => {
      console.log('Error pinning or unpinning query: ', err?.message ?? 'Internal Error')
    })
  }

  const newQuery = () => {
    setQueryAiErrors(errors => {
      const errorsCopy = {...errors}
      errorsCopy[conversationId] = null
      return errorsCopy
    })
    setInputText('')
    setSelectedQueriesInStateAndLocalStorage(conversationId, null)

    if (conversationSummary?.summary?.questions?.length && isSuggestedQuestionsFeatureEnabled) {
      setShowSuggestions(true);
    }

    trackPinnedRecentQueryAndCopyEvents(recentQueries?.[0]?.assistId, "new-query", "", undefined)
  }

  const onEnter = (e: any) => {
    const keyCode = e.which || e.keyCode
    if (keyCode === 13) {
      onClickSubmit()
      e.preventDefault();
    }
  }

  const onAnswerClick = async (eventType: string, eventData: any) => {
    callBackEvent(eventType, eventData)
    //will keep it in case when regenerate answer will be required
    //await dispatch<any>(updateAIAnswer())
  }

  useEffect(() => {
    if(!selectedQueries[conversationId] && conversationSummary?.summary?.questions?.length) {
      setShowSuggestions(true)
    }
  }, [conversationId, conversationSummary])

  const onSelectQuery = (
    conversationId: string,
    queryId: string,
  ) => {
    setQueryAiErrors(errors => {
      const errorsCopy = {...errors}
      errorsCopy[conversationId] = null
      return errorsCopy
    })

    setSelectedQueriesInStateAndLocalStorage(conversationId, queryId)
  }

  const queryAiError = queryAiErrors?.[conversationId]

  try {
    return (
      <div className={`${styles['aa_module_wrapper']}` + darkModeBG + darkModeBorder}>
        <div className={`${styles['aa_module_aa-header']}` + darkModeBG + darkModeBorder + darkModeTitle}>
          <div className={`${styles['aa_module_widget-icon']}`}>AA</div>
          <span>{localization[language]['Adobe Answers']}</span>
          <span className={`${styles['aa_module_aa-header-info']}` + darkModeInfo}>
            <InfoTip currentColor='#bebebe' darkMode={darkMode} />
          </span>
        </div>
        <div className={`${styles['aa_module_container']}`}>
          <div className={`${styles['aa_module_container_left']}` + darkModeBorderLeft}>
            <div className={`${styles['aa_module_container_left_content']}`}>
              <AAAnswers
                conversationId={conversationId}
                feedbackOptions={{
                  inappropriateFlagOptions,
                  thumbsDownFeedbackOptions
                }}
                currentQuery={queryAiError != null ? queryAiError : currentSelectedQuery}
                error={queryAiError != null}
                language={language}
                isLoading={loading[conversationId]}
                darkMode={darkMode}
                agentDetails={agentDetails}
                callBackEvent={onAnswerClick}
                showSuggestions={showSuggestions && isSuggestedQuestionsFeatureEnabled}
                suggestions={conversationSummary?.summary?.questions}
                getSuggestion={onClickSubmit}
                sendFeedBack={sendFeedBack}
                currentSelectedQuery={currentSelectedQuery}
              // regenerate={regenerateAnswer}
              />
            </div>

            <div className={`${styles['aa_module_input-container']}`}>
              <div className={`${styles['aa_module_textarea-wrapper']}` + darkModeTextAreaWrapper}>
                <Flex direction='row' width='100%'>
                  <TextArea
                    maxLength={chatMaxLength}
                    maxWidth='275px'
                    left={0}
                    minHeight='32px'
                    maxHeight='75px'
                    alignSelf='center'
                    onKeyDown={onEnter}
                    placeholder={localization[language]['Type an inquiry']}
                    isDisabled={selectedQueries[conversationId] != null || loading[conversationId]}
                    isQuiet
                    flexShrink={0}
                    onChange={onMessageChange}
                    flexGrow={1}
                    value={inputText}
                    UNSAFE_style={{ overflowY: 'auto', overflowX: 'hidden' }}
                    id='message'
                    aria-label='message'
                  />
                  <TooltipTrigger>
                    <ActionButton
                      UNSAFE_className={`${styles['aa-msg-btn']}`}
                      isDisabled={selectedQueries[conversationId] != null || loading[conversationId] || !inputText.length}
                      isQuiet
                      onPress={() => onClickSubmit()}
                      //ref={buttonRef}
                    >
                      <ArrowLeft />
                    </ActionButton>
                    <Tooltip> {localization[language]?.['Send Question']} </Tooltip>
                  </TooltipTrigger>
                </Flex>
              </div>
            </div>
          </div>
          <div className={`${styles['aa_module_container_right']}`}>
            <div className={`${styles['aa_module_container_right_content']}`}>
              <Button variant='cta' UNSAFE_style={{ width: '100%' }} isDisabled={loading[conversationId] === true} onPress={() => newQuery()}>
                {localization[language]['New Query']}
              </Button>
              <AACardList
                conversationId={conversationId}
                deleteQuery={deleteQuery}
                key={1}
                darkMode={darkMode}
                numberOfPinnedQueries={numberOfPinnedQueries}
                isLoading={loading[conversationId]}
                pinned={true}
                language={language}
                pinUnpinQuery={pinUnpinQuery}
                queries={pinnedQueriesList}
                selectedQueryId={selectedQueries[conversationId]}
                setSelectedQuery={onSelectQuery}
              //disabledNewQuestion={disabledNewQuestion}
              />
              <AACardList
                conversationId={conversationId}
                deleteQuery={deleteQuery}
                key={2}
                darkMode={darkMode}
                numberOfPinnedQueries={numberOfPinnedQueries}
                isLoading={loading[conversationId]}
                pinned={false}
                pinUnpinQuery={pinUnpinQuery}
                queries={recentQueries}
                language={language}
                selectedQueryId={selectedQueries[conversationId]}
                setSelectedQuery={onSelectQuery}
              //disabledNewQuestion={disabledNewQuestion}
              />
            </div>
          </div>
        </div>
      </div>
    )
  } catch (err) {
    return <div>Internal Error</div>
  }
}

export default AdobeAnswersFrame
