const localization = {
    en: {
        "TOOLBAR": {
            "TCP_TOOLTIP": "Transfer Chat",
            "ACTION_MENU_TOOLTIP": "Action Menu",
            "RESUME_CONV": "Resume Conversation",
            "RETURN_TO_QUEUE": "Return to Queue",
            "COPY": "Copy as plain text",
            "PRIVATE_MESSAGE": "Private Message",
            "CLOSE_CONV": "Close Chat",
            "TRANSFER_PREV_AGENT": "Transfer to Previous Agent",
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "You have been disconnected from VPN. Please reconnect to VPN and update your status to “Available” or “Outbound” before continuing to take new conversations or making outbound calls.",
            "CALL_RECORDING_DISABLED" : "Disable Call Recording",
            "COPY_TRANSCRIPT" : "Copy Transcript to Clipboard"
        },
        "ACTION_MODAL": {
            "CLOSE_HEADING": "Close Conversation",
            "CLOSE_PRIMARY_TEXT": "Close Conversation",
            "CLOSE_POPUP_TEXT": "Are you sure you want to close this conversation?",
            "COPY_HEADING": "Copy Transcript",
            "COPY_PRIMARY_TEXT": "Copy Selected Text",
            "PRIVATE_MESSAGE_HEADING": "Private Message",
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "Add to Chat",
            "CANCEL": "Cancel",
            "RECORD_DISABLE_HEADING":"Disable Call Recording",
            "RECORD_DISABLE_POPUP_TEXT": "Are you sure you would like to disable call recording for this call? This option is only to be used if the customer has explicitly requested the call not to be recorded. Once disabled, the call recording can not be re-enabled. This action to disable call recording will be documented along with your username and the contactId",
            "RECORD_DISABLE_PRIMARY_TEXT" : "Accept"
        },
        "TRANSFER_CALL": {
            "TRANSFER_TO": "Transfer To",
            "NUMBER_OF_TRANSFERS": "Number of Transfers",
            "ADD_TRANSFER_NOTES": "Add Transfer Notes",
            "TRANSFER": "Transfer",
            "SELECT_QUEUE": "Select",
            "TEXTAREA_PLACEHOLDER": "Type a new message",
            "QUEUE": "Queue",
            "MANAGER": "Manager",
            "SPECIALIST": "Specialist",
            "OF": "of",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "Nature of Contact",
            "SALES": "Sales",
            "SUPPORT": "Support",
            "CONTACT": "Contact",    //
            "MESSAGING": "Messaging",
            "PHONE": "Phone",
            "LICENSE": "License",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "Issue Type",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "Transfer to Suggested Queue",
            "SUGGESTED_QUEUE_TEXT": "Suggested Queue for Transfer",
            "OVERRIDE": "Override suggestion",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "Reason for Overriding Suggestion",
            "DIRECT":"Direct Transfer",
            "BACK":"Back",
            "QUEUE_TRANSFER_ASSISTANCE":"Queue Transfer Assistance",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"Start Transfer",
            "WARM_TRANSFER":"Warm Transfer",
            "COLD_TRANSFER":"Cold Transfer",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "The recommended queue is not configured in your routing profile",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "Your routing profile is not configured with any transfer destinations",
            "QUEUE_IS_AFTER_HOURS": "Queue is after hours",
            "DO_NOT_TRANSFER_MESSAGE": "DO NOT TRANSFER. This issue is supported in your queue. Consult your supervisor if you have doubts."
        },
        "TELEPHONY":{
            "END":"End",
            "HOLD":"Hold",
            "MUTE":"Mute",
            "DIALPAD":"Dialpad",
            "JOIN":"Join",
            "SWAP":"Swap",
            "CALL":"Call",
            "NUMBER":"Number",
            "END_CALL":"End Call",
            "DISCONNECT":"Are you sure you want to disconnect the current call?",
            "COUNTRY_CODE":"Country Code",
            "INVALID_PHONE_TOOLTIP":"The country code and/or number format are incorrect",
            "SELECT":"Select...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    ja: {
        "TOOLBAR": {
            "TCP_TOOLTIP": "チャットを転送",
            "ACTION_MENU_TOOLTIP": "アクションメニュー",
            "RESUME_CONV": "会話を再開",
            "RETURN_TO_QUEUE": "キューに戻る",
            "COPY": "テキストとしてコピー",
            "PRIVATE_MESSAGE": "プライベートメッセージ",
            "CLOSE_CONV": "チャットをクローズする",
            "TRANSFER_PREV_AGENT": "前担当者に転送",
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "VPN との接続が切断されました。新しい会話やアウトバウンドコールを続ける前に、VPN に再接続し、ステータスを「利用可能」または「アウトバウンド」に更新してください。",
            "CALL_RECORDING_DISABLED" : "通話録音を無効にする",
            "COPY_TRANSCRIPT" : "トランスクリプトをクリップボードにコピー"
        },
        "ACTION_MODAL": {
            "CLOSE_HEADING": "会話を終了",
            "CLOSE_PRIMARY_TEXT": "会話を終了",
            "CLOSE_POPUP_TEXT": "この会話を終了しますか？",
            "COPY_HEADING": "トランスクリプトをコピー",
            "COPY_PRIMARY_TEXT": "選択したテキストをコピー",
            "PRIVATE_MESSAGE_HEADING": "プライベートメッセージ",
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "チャットに追加",
            "CANCEL": "キャンセル",
            "RECORD_DISABLE_HEADING":"通話録音を無効にする",
            "RECORD_DISABLE_POPUP_TEXT": "この通話の録音を無効にしてよろしいですか？このオプションは、お客様が通話を録音しないように明示的に要求した場合にのみ使用してください。一度無効にすると、通話録音を再度有効にすることはできません。通話録音を無効にすると、ユーザー名とContactIDとともに無効化が記録されます",
            "RECORD_DISABLE_PRIMARY_TEXT" : "承認"
        },
        "TRANSFER_CALL": {
            "TRANSFER_TO": "転送先",
            "NUMBER_OF_TRANSFERS": "転送数",
            "ADD_TRANSFER_NOTES": "転送メモを追加",
            "TRANSFER": "転送",
            "SELECT_QUEUE": "選択",
            "TEXTAREA_PLACEHOLDER": "新しいメッセージを入力",
            "QUEUE": "キュー",
            "MANAGER": "マネージャー",
            "SPECIALIST": "スペシャリスト",
            "OF": "/",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "問い合わせの内容",
            "SALES": "セールス ",
            "SUPPORT": "サポート ",
            "CONTACT": "Contact",    //
            "MESSAGING": "メッセージング ",
            "PHONE": "電話",
            "LICENSE": "ライセンス",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "問題のタイプ",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "提案されたキューに転送する",
            "SUGGESTED_QUEUE_TEXT": "転送用に提案されたキュー",
            "OVERRIDE": "提案を無視して直接選択する",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "提案内容をオーバーライドする理由",
            "DIRECT":"ダイレクト転送",
            "BACK":"前へ戻る",
            "QUEUE_TRANSFER_ASSISTANCE":"キュー転送支援",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"転送を開始",
            "WARM_TRANSFER":"ウォーム転送",
            "COLD_TRANSFER":"コールド転送",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "推奨キューがルーティングプロファイルに設定されていません",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "ルーティングプロファイルには転送先が設定されていません",
            "QUEUE_IS_AFTER_HOURS": "そのキューは営業時間外です",
            "DO_NOT_TRANSFER_MESSAGE": "転送しないでください。この問題はキューでサポートされています。疑問がある場合は、上司に相談してください。"
        },
        "TELEPHONY":{
            "END":"終了",
            "HOLD":"保留",
            "MUTE":"ミュート",
            "DIALPAD":"ダイヤルパッド",
            "JOIN":"参加",
            "SWAP":"スワップ",
            "TRANSFER":"転送",
            "CALL":"コール",
            "DISCONNECT":"現在のコールを切断してもよろしいですか？",
            "COUNTRY_CODE":"国コード",
            "NUMBER":"番号",
            "END_CALL":"コール終了",
            "INVALID_PHONE_TOOLTIP":"国コードまたは番号の形式が正しくありません",
            "SELECT":"選択...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    fr: {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "Menu Action",
            "CLOSE_CONV": "Fermer le chat",
            "COPY": "Copier en tant que texte brut",
            "PRIVATE_MESSAGE": "Message privé",
            "RESUME_CONV": "Reprendre la conversation",
            "RETURN_TO_QUEUE": "Revenir à la file d’attente",
            "TCP_TOOLTIP": "Transfert de chat",
            "TRANSFER_PREV_AGENT": "Transfert à l’agent précédent",  
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "Vous avez été déconnecté du VPN. Veuillez vous reconnecter au VPN et mettre à jour votre statut sur « Disponible » ou « Sortant » avant de continuer à prendre de nouvelles conversations ou à passer des appels sortants." ,
            "CALL_RECORDING_DISABLED" : "Désactiver l'enregistrement des appels",
            "COPY_TRANSCRIPT" : "Copier la transcription dans le presse-papiers"     
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "Ajouter au chat",
            "CLOSE_POPUP_TEXT": "Voulez-vous vraiment fermer cette conversation ?",
            "CANCEL": "Annuler",
            "CLOSE_HEADING": "Fermer la conversation",
            "CLOSE_PRIMARY_TEXT": "Fermer la conversation",
            "COPY_PRIMARY_TEXT": "Copier le texte sélectionné",
            "COPY_HEADING": "Copier la transcription",
            "PRIVATE_MESSAGE_HEADING": "Message privé",
            "RECORD_DISABLE_HEADING":"Désactiver l'enregistrement des appels",
            "RECORD_DISABLE_POPUP_TEXT": "Êtes-vous sûre de vouloir désactiver l'enregistrement des appels pour cet appel ? Cette option ne doit être utilisée que si le client a explicitement demandé que l'appel ne soit pas enregistré. Une fois désactivé, l'enregistrement des appels ne peut pas être réactivé. Cette action visant à désactiver l'enregistrement des appels sera documentée, ainsi que votre nom d'utilisateur et votre identifiant de contact.",
            "RECORD_DISABLE_PRIMARY_TEXT" : "Accepter"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "Ajouter des notes de transfert",
            "MANAGER": "Manager",
            "NUMBER_OF_TRANSFERS": "Nombre de transferts",
            "OF": "de",
            "QUEUE": "File d’attente",
            "SELECT_QUEUE": "Sélectionner",
            "SPECIALIST": "Spécialiste",
            "TRANSFER": "Transfert",
            "TRANSFER_TO": "Transfert à",
            "TEXTAREA_PLACEHOLDER": "Saisissez un nouveau message",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //  //   //
            "NATURE_OF_CONTACT": "Nature du contact",
            "SALES": "Ventes ",
            "SUPPORT": "Support ",
            "CONTACT": "Contact",   //
            "MESSAGING": "Messages ",
            "PHONE": "Téléphone",
            "LICENSE": "Licence",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //  //
            "ISSUE_TYPE": "Type du problème",
            "CANCELLATION_REASON": "Cancellation Reason",    //   //
            "TRANSFER_TO_SUGGESTED": "Transfert vers la file d’attente suggérée",
            "SUGGESTED_QUEUE_TEXT": "File d’attente suggérée pour le transfert",
            "OVERRIDE": "Ignorer la suggestion",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "Motif de rejet de la suggestion",
            "DIRECT":"Transfert direct",
            "BACK":"Retour",
            "QUEUE_TRANSFER_ASSISTANCE":"Assitance tranfert",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"Démarrer le transfert",
            "WARM_TRANSFER":"Transfert avec annonce",
            "COLD_TRANSFER":"Transfert à froid",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "La file d'attente recommandée n'est pas configurée dans votre profil de routage",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "Votre profil de routage n'est configuré avec aucune destination de transfert",
            "QUEUE_IS_AFTER_HOURS": "La file d'attente est en dehors des heures de bureau",
            "DO_NOT_TRANSFER_MESSAGE": "NE TRANSFÉREZ PAS. Ce problème est pris en charge dans votre file d'attente. Consultez votre supérieur en cas de doute."
        },
        "TELEPHONY":{
            "END":"Terminer",
            "HOLD":"Attente",
            "MUTE":"Couper le son",
            "DIALPAD":"Clavier numérique",
            "JOIN":"Rejoindre",
            "SWAP":"Basculer",
            "CALL":"Appeler",
            "DISCONNECT":"Voulez-vous vraiment déconnecter l’appel en cours ?",
            "NUMBER":"Numéro",
            "END_CALL":"Terminer l’appel",
            "TRANSFER":"Transfert",
            "COUNTRY_CODE":"Code pays",
            "INVALID_PHONE_TOOLTIP":"Le code pays et/ou le format du nombre sont incorrects",
            "SELECT":"Sélectionner...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    de: {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "Menü Aktion",
            "CLOSE_CONV": "Chat schließen",
            "COPY": "Als Standardtext kopieren",
            "PRIVATE_MESSAGE": "Privatnachricht",
            "RESUME_CONV": "Unterhaltung wieder aufnehmen",
            "RETURN_TO_QUEUE": "Zurück zur Warteschlange",
            "TCP_TOOLTIP": "Chat übertragen",
            "TRANSFER_PREV_AGENT": "Übertragen an vorherigen Agenten",
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "Sie wurden vom VPN getrennt. Bitte stellen Sie erneut eine Verbindung zum VPN her und aktualisieren Sie Ihren Status auf „Verfügbar“ oder „Ausgehend“, bevor Sie weitere Konversationen annehmen oder ausgehende Anrufe tätigen."  ,
            "CALL_RECORDING_DISABLED" : "Anrufaufzeichnung deaktivieren",
            "COPY_TRANSCRIPT" : "Transkript in die Zwischenablage kopieren" 
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "Zum Chat hinzufügen",
            "CLOSE_POPUP_TEXT": "Möchten Sie diese Unterhaltung wirklich schließen?",
            "CANCEL": "Abbrechen",
            "CLOSE_HEADING": "Unterhaltung schließen",
            "CLOSE_PRIMARY_TEXT": "Unterhaltung schließen",
            "COPY_PRIMARY_TEXT": "Ausgewählten Text kopieren",
            "COPY_HEADING": "Transkript kopieren",
            "PRIVATE_MESSAGE_HEADING": "Privatnachricht",
            "RECORD_DISABLE_HEADING":"Anrufaufzeichnung deaktivieren",
            "RECORD_DISABLE_POPUP_TEXT": "Sind Sie sicher, dass Sie die Anrufaufzeichnung für diesen Anruf deaktivieren möchten? Diese Option darf nur verwendet werden, wenn der Kunde ausdrücklich verlangt hat, dass der Anruf nicht aufgezeichnet wird. Nach der Deaktivierung kann die Anrufaufzeichnung nicht wieder aktiviert werden. Diese Aktion zur Deaktivierung der Anrufaufzeichnung wird zusammen mit Ihrem Benutzernamen und der Kontakt-ID dokumentiert.",
            "RECORD_DISABLE_PRIMARY_TEXT" : "Akzeptieren"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "Übertragungshinweise hinzufügen",
            "MANAGER": "Manager",
            "NUMBER_OF_TRANSFERS": "Anzahl der Übertragungen",
            "OF": "von",
            "QUEUE": "Warteschlange",
            "SELECT_QUEUE": "Auswählen",
            "SPECIALIST": "Spezialist",
            "TRANSFER": "Übertragung",
            "TRANSFER_TO": "Übertragen an",
            "TEXTAREA_PLACEHOLDER": "Neue Nachricht eingeben",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "Art des Kontakts",
            "SALES": "Verkaufsabteilung",
            "SUPPORT": "Support",
            "CONTACT": "Contact",    //
            "MESSAGING": "Nachrichtenübermittlung ",
            "PHONE": "Telefon",
            "LICENSE": "Lizenz",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //  //
            "ISSUE_TYPE": "Art des Problems",
            "CANCELLATION_REASON": "Cancellation Reason",    //   //
            "TRANSFER_TO_SUGGESTED": "Weiterleitung in die vorgeschlagene Warteschlange",
            "SUGGESTED_QUEUE_TEXT": "Vorgeschlagene Warteschlange für die Weiterleitung",
            "OVERRIDE": "Vorschlag überschreiben",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "Grund für eine Überschreibung",
            "DIRECT":"Direkte Weiterleitung",
            "BACK":"Zurück",
            "QUEUE_TRANSFER_ASSISTANCE":"Transferassistent",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"Weiterleitung starten",
            "WARM_TRANSFER":"Warme Weiterleitung",
            "COLD_TRANSFER":"Kalt weiterleiten",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "Die empfohlene Warteschlange ist in Ihrem Weiterleitungsprofil nicht konfiguriert",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "Ihr Weiterleitungsprofil ist mit keinen Übertragungszielen konfiguriert",
            "QUEUE_IS_AFTER_HOURS": "Die Warteschlange ist nach Geschäftsschluss",
            "DO_NOT_TRANSFER_MESSAGE": "NICHT ÜBERTRAGEN. Dieses Problem wird in Ihrer Warteschlange unterstützt. Fragen Sie Ihren Vorgesetzten, falls Sie Zweifel haben."
        },
        "TELEPHONY":{
            "END":"Ende",
            "HOLD":"Halt",
            "MUTE":"Stummschalten",
            "DIALPAD":"Wähltastatur",
            "JOIN":"Verbinden",
            "SWAP":"Tauschen",
            "CALL":"Anruf",
            "NUMBER":"Zahl",
            "END_CALL":"Anruf beenden",
            "DISCONNECT":"Sind Sie sicher, dass Sie das laufende Gespräch beenden wollen?",
            "TRANSFER":"Übertragung",
            "COUNTRY_CODE":"Ländercode",
            "INVALID_PHONE_TOOLTIP":"Die Landesvorwahl und/oder das Nummernformat sind falsch",
            "SELECT":"Auswählen...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    es: {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "Menú Acción",
            "CLOSE_CONV": "Cerrar chat",
            "COPY": "Copiar como texto sin formato",
            "PRIVATE_MESSAGE": "Mensaje privado",
            "RESUME_CONV": "Reanudar conversación",
            "RETURN_TO_QUEUE": "Volver a la cola",
            "TCP_TOOLTIP": "Transferir chat",
            "TRANSFER_PREV_AGENT": "Transferir a agente anterior",  
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "Se le ha desconectado de la VPN. Vuelva a conectarse a la VPN y actualice su estado a «Disponible» o «Saliente» antes de continuar manteniendo nuevas conversaciones o realizar llamadas salientes."  ,
            "CALL_RECORDING_DISABLED" : "Inhabilitar la grabación de llamadas",
            "COPY_TRANSCRIPT" : "Copiar la transcripción al portapapeles"    
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "Añadir al chat",
            "CLOSE_POPUP_TEXT": "¿Confirma que desea cerrar esta conversación?",
            "CANCEL": "Cancelar",
            "CLOSE_HEADING": "Cerrar conversación",
            "CLOSE_PRIMARY_TEXT": "Cerrar conversación",
            "COPY_PRIMARY_TEXT": "Copiar texto seleccionado",
            "COPY_HEADING": "Copiar transcripción",
            "PRIVATE_MESSAGE_HEADING": "Mensaje privado",
            "RECORD_DISABLE_HEADING":"Inhabilitar la grabación de llamadas",
            "RECORD_DISABLE_POPUP_TEXT": "¿Está seguro de que quiere deshabilitar la grabación de llamadas para esta llamada? Esta opción solo debe usarse si el cliente ha solicitado explícitamente que no se grabe la llamada. Una vez deshabilitada, la grabación de llamadas no se puede volver a habilitar. Esta acción para deshabilitar la grabación de llamadas se documentará junto con su nombre de usuario y el ID de contacto.",
            "RECORD_DISABLE_PRIMARY_TEXT" : "Aceptar"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "Añadir notas de transferencia",
            "MANAGER": "Responsable",
            "NUMBER_OF_TRANSFERS": "Número de transferencias",
            "OF": "de",
            "QUEUE": "Cola",
            "SELECT_QUEUE": "Seleccionar",
            "SPECIALIST": "Especialista",
            "TRANSFER": "Transferir",
            "TRANSFER_TO": "Transferir a",
            "TEXTAREA_PLACEHOLDER": "Escribir un nuevo mensaje",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //   //
            "NATURE_OF_CONTACT": "Naturaleza del contacto",
            "SALES": "Ventas ",
            "SUPPORT": "Asistencia técnica ",
            "CONTACT": "Contact",    //
            "MESSAGING": "Mensajería ",
            "PHONE": "Teléfono",
            "LICENSE": "Licencia",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //  //
            "ISSUE_TYPE": "Tipo de problema",
            "CANCELLATION_REASON": "Cancellation Reason",    //   //
            "TRANSFER_TO_SUGGESTED": "Transferir a la cola sugerida",
            "SUGGESTED_QUEUE_TEXT": "Cola sugerida para transferir",
            "OVERRIDE": "Sustituir la sugerencia",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "Motivo para sustituir la sugerencia",
            "DIRECT":"Transferencia directa",
            "BACK":"Atrás",
            "QUEUE_TRANSFER_ASSISTANCE":"Apoyo con Transaferencia",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"Iniciar transferencia",
            "WARM_TRANSFER":"Transferencia en caliente",
            "COLD_TRANSFER":"Transferencia directa",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "La cola recomendada no está configurada en su perfil de direccionamiento",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "Su perfil de direccionamiento no está configurado con ningún destino de transferencia",
            "QUEUE_IS_AFTER_HOURS": "La cola está fuera de horario",
            "DO_NOT_TRANSFER_MESSAGE": "NO TRANSFERIR. Este número es compatible en su cola. Consulte a su supervisor si tiene dudas."
        },
        "TELEPHONY":{
            "END":"Fin",
            "HOLD":"Retener",
            "MUTE":"Silenciar",
            "DIALPAD":"Teclado de marcado",
            "JOIN":"Unirse",
            "SWAP":"Intercambiar",
            "CALL":"Llamar",
            "NUMBER":"Número",
            "END_CALL":"Finalizar llamada",
            "DISCONNECT":"¿Confirma que desea desconectar la llamada actual?",
            "TRANSFER":"Transferir",
            "COUNTRY_CODE":"Código de país",
            "INVALID_PHONE_TOOLTIP":"El código de país y/o el formato del número son incorrectos",
            "SELECT":"Seleccionar...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    it: {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "Menu Azioni",
            "CLOSE_CONV": "Chiudi chat",
            "COPY": "Copia come testo semplice",
            "PRIVATE_MESSAGE": "Messaggio privato",
            "RESUME_CONV": "Riprendi conversazione",
            "RETURN_TO_QUEUE": "Torna alla coda",
            "TCP_TOOLTIP": "Trasferisci chat",
            "TRANSFER_PREV_AGENT": "Trasferisci all'agente precedente",  
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "Sei stato disconnesso dalla VPN. Riconnettiti alla VPN e aggiorna il tuo stato su «Disponibile» o «In uscita» prima di continuare a rispondere a nuove conversazioni o effettuare chiamate in uscita.",
            "CALL_RECORDING_DISABLED" : "Desactivar la grabación de llamadas",
            "COPY_TRANSCRIPT" : "Copia la trascrizione negli appunti"
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "Aggiungi alla chat",
            "CLOSE_POPUP_TEXT": "Sei sicuro di voler chiudere questa conversazione?",
            "CANCEL": "Annulla",
            "CLOSE_HEADING": "Chiudi conversazione",
            "CLOSE_PRIMARY_TEXT": "Chiudi conversazione",
            "COPY_PRIMARY_TEXT": "Copia il testo selezionato",
            "COPY_HEADING": "Copia trascrizione",
            "PRIVATE_MESSAGE_HEADING": "Messaggio privato",
            "RECORD_DISABLE_HEADING":"Desactivar la grabación de llamadas",
            "RECORD_DISABLE_POPUP_TEXT": "¿Estás seguro de que quieres desactivar la grabación de esta llamada? Esta opción sólo debe utilizarse si el cliente ha solicitado explícitamente que no se grabe la llamada. Una vez desactivada, la grabación de la llamada no puede volver a activarse. Esta acción de desactivar la grabación de llamadas quedará documentada junto con tu nombre de usuario y el ID del contacto",
            "RECORD_DISABLE_PRIMARY_TEXT" : "Accetta"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "Aggiungi note di trasferimento",
            "MANAGER": "Manager",
            "NUMBER_OF_TRANSFERS": "Numero di trasferimenti",
            "OF": "di",
            "QUEUE": "Coda",
            "SELECT_QUEUE": "Seleziona",
            "SPECIALIST": "Esperto",
            "TRANSFER": "Trasferimento",
            "TRANSFER_TO": "Trasferisci a",
            "TEXTAREA_PLACEHOLDER": "Digita un nuovo messaggio",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "Tipo di contatto",
            "SALES": "Vendite ",
            "SUPPORT": "Supporto ",
            "CONTACT": "Contact",    //
            "MESSAGING": "Messaggistica ",
            "PHONE": "Telefono",
            "LICENSE": "Licenza",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "Tipo di problema",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "Trasferisci alla coda suggerita",
            "SUGGESTED_QUEUE_TEXT": "Coda suggerita per il trasferimento",
            "OVERRIDE": "Sostituisci suggerimento",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "Motivo della sostituzione suggerimento",
            "DIRECT":"Trasferimento diretto",
            "BACK":"Indietro",
            "QUEUE_TRANSFER_ASSISTANCE":"Assistenza Trasferimento",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"Avvia il trasferimento",
            "WARM_TRANSFER":"Trasferimento a caldo",
            "COLD_TRANSFER":"Trasferimento diretto",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "La coda consigliata non è configurata nel tuo profilo di instradamento",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "Il tuo profilo di instradamento non è configurato con nessuna destinazione di trasferimento",
            "QUEUE_IS_AFTER_HOURS": "La coda è fuori orario",
            "DO_NOT_TRANSFER_MESSAGE": "NON TRASFERIRE. Questo problema è supportato nella sua coda. Consulti il suo supervisore se ha dei dubbi."
        },
        "TELEPHONY":{
            "END":"Fine",
            "HOLD":"Mantieni",
            "MUTE":"Disattiva volume",
            "DIALPAD":"Tastiera",
            "JOIN":"Unisciti",
            "SWAP":"Scambia",
            "CALL":"Chiama",
            "NUMBER":"Numero",
            "END_CALL":"End Call",
            "DISCONNECT":"Confermi di voler terminare la chiamata in corso?",
            "TRANSFER":"Trasferimento",
            "COUNTRY_CODE":"Codice del Paese",
            "INVALID_PHONE_TOOLTIP":"Il codice del paese e/o il formato del numero non sono corretti",
            "SELECT":"Seleziona...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    'pt-BR': {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "Menu Ação",
            "CLOSE_CONV": "Fechar bate-papo",
            "COPY": "Copiar como texto comum",
            "PRIVATE_MESSAGE": "Mensagem Privada",
            "RESUME_CONV": "Retomar Conversa",
            "RETURN_TO_QUEUE": "Retornar para Fila",
            "TCP_TOOLTIP": "Transferir Conversa",
            "TRANSFER_PREV_AGENT": "Transferir para Agente Anterior",  
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "Você foi desconectado da VPN. Reconecte-se à VPN e atualize seu status para “Disponível” ou “De saída” antes de continuar fazendo novas conversas ou fazendo chamadas externas." ,
            "CALL_RECORDING_DISABLED" : "Desativar gravação de chamadas",
            "COPY_TRANSCRIPT" : "Copiar a transcrição para a área de transferência"
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "Adicionar ao Chat",
            "CLOSE_POPUP_TEXT": "Tem certeza de que deseja encerrar esta conversa?",
            "CANCEL": "Cancelar",
            "CLOSE_HEADING": "Fechar Conversa",
            "CLOSE_PRIMARY_TEXT": "Fechar Conversa",
            "COPY_PRIMARY_TEXT": "Copiar texto selecionado",
            "COPY_HEADING": "Copiar transcrição",
            "PRIVATE_MESSAGE_HEADING": "Mensagem Privada",
            "RECORD_DISABLE_HEADING":"Desativar gravação de chamadas",
            "RECORD_DISABLE_POPUP_TEXT": "Tem certeza de que deseja  desativar a gravação de chamadas para essa chamada? Essa opção só deve ser usada se o cliente tiver solicitado explicitamente que a chamada não seja gravada. Uma vez desativada, a gravação da chamada não pode ser reativada. Essa ação para desativar a gravação de chamadas será documentada junto com seu nome de usuário e o ContactID.",
            "RECORD_DISABLE_PRIMARY_TEXT" : "Aceitar"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "Adicionar Notas de Transferência",
            "MANAGER": "Gerenciador",
            "NUMBER_OF_TRANSFERS": "Número de Transferências",
            "OF": "de",
            "QUEUE": "Fila",
            "SELECT_QUEUE": "Selecionar",
            "SPECIALIST": "Especialista",
            "TRANSFER": "Transferência",
            "TRANSFER_TO": "Transferir para",
            "TEXTAREA_PLACEHOLDER": "Digite nova mensagem",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "Natureza do contato",
            "SALES": "Vendas ",
            "SUPPORT": "Suporte ",
            "CONTACT": "Contact",    //
            "MESSAGING": "Mensagens ",
            "PHONE": "Telefone",
            "LICENSE": "License",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "Tipo de problema",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "Transferir para fila (queue) sugerida",
            "SUGGESTED_QUEUE_TEXT": "Fila (queue) sugerida para transferência",
            "OVERRIDE": "Anular a sugestão",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "Motivo para anular a sugestão",
            "DIRECT":"Transferência direta",
            "BACK":"Voltar",
            "QUEUE_TRANSFER_ASSISTANCE":"Apoio à Transferência",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"Iniciar transferência",
            "WARM_TRANSFER":"Transferência quente",
            "COLD_TRANSFER":"Transferência direta (cold transfer)",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "A fila recomendada não está configurada em seu perfil de roteamento",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "Seu perfil de roteamento não está configurado com nenhum destino de transferência",
            "QUEUE_IS_AFTER_HOURS": "A fila esta fora do horário",
            "DO_NOT_TRANSFER_MESSAGE": "NÃO TRANSFIRA. Esse problema é suportado na sua fila. Consulte seu supervisor se tiver dúvidas."
        },
        "TELEPHONY":{
            "END":"Encerrar",
            "HOLD":"Em espera",
            "MUTE":"Silenciar",
            "DIALPAD":"Teclado de discagem",
            "JOIN":"Associar-se",
            "SWAP":"Trocar",
            "CALL":"Chamada",
            "NUMBER":"Número",
            "END_CALL":"Encerrar chamada",
            "DISCONNECT":"Tem certeza de que deseja desconectar a chamada atual?",
            "TRANSFER":"Transferência",
            "COUNTRY_CODE":"Código do país",
            "INVALID_PHONE_TOOLTIP":"O código do país e/ou formato numérico estão incorretos",
            "SELECT":"Selecionar...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    'zh-TW': {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "動作選單",
            "CLOSE_CONV": "關閉對話",
            "COPY": "複製為純文字",
            "PRIVATE_MESSAGE": "私人訊息",
            "RESUME_CONV": "繼續對話",
            "RETURN_TO_QUEUE": "返回佇列",
            "TCP_TOOLTIP": "轉移對話",
            "TRANSFER_PREV_AGENT": "轉移至上一個客服專員",
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "您已中斷與 VPN 的連線。在繼續進行新對話或撥出電話之前，請重新連接 VPN 並將狀態更新為「可用」或「出站」。",
            "CALL_RECORDING_DISABLED" : "停用通話錄音",
            "COPY_TRANSCRIPT" : "將文字稿複製到剪貼簿"
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "新增至聊天",
            "CLOSE_POPUP_TEXT": "確定要關閉此對話？",
            "CANCEL": "取消",
            "CLOSE_HEADING": "關閉對話",
            "CLOSE_PRIMARY_TEXT": "關閉對話",
            "COPY_PRIMARY_TEXT": "複製已選取的文字",
            "COPY_HEADING": "複製副本",
            "PRIVATE_MESSAGE_HEADING": "私人訊息",
            "RECORD_DISABLE_HEADING":"停用通話錄音",
            "RECORD_DISABLE_POPUP_TEXT": "您確定要停用此通話的通話錄音嗎？只有在客戶明確要求不要錄製呼叫時，才能使用此選項。禁用後，無法重新啟用通話記錄。禁用呼叫記錄的此操作將與您的用戶名和 ContactID 一起記錄",
            "RECORD_DISABLE_PRIMARY_TEXT" : "接受"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "新增轉移備註",
            "MANAGER": "管理員",
            "NUMBER_OF_TRANSFERS": "轉移次數",
            "OF": "/",
            "QUEUE": "佇列",
            "SELECT_QUEUE": "選取",
            "SPECIALIST": "專家",
            "TRANSFER": "轉移",
            "TRANSFER_TO": "轉移至",
            "TEXTAREA_PLACEHOLDER": "輸入新訊息",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "聯絡内容",
            "SALES": "銷售 ",
            "SUPPORT": "支援 ",
            "CONTACT": "Contact",    //
            "MESSAGING": "傳送訊息 ",
            "PHONE": "電話",
            "LICENSE": "授權",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "問題類型",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "轉接至建議的佇列",
            "SUGGESTED_QUEUE_TEXT": "建議轉接使用的佇列",
            "OVERRIDE": "覆寫建議",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "覆寫建議的原因",
            "DIRECT":"直接轉接",
            "BACK":"返回",
            "QUEUE_TRANSFER_ASSISTANCE":"佇列轉接協助",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"開始轉接",
            "WARM_TRANSFER":"交接轉移",
            "COLD_TRANSFER":"通話前轉接 (Cold Transfer)",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "建議的佇列未在您的路由設定檔中設定",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "您的路由描述檔未設定任何轉移目的地",
            "QUEUE_IS_AFTER_HOURS": "服務時間外佇列",
            "DO_NOT_TRANSFER_MESSAGE": "請勿轉移。您的佇列支援此問題。如果您有疑問，請諮詢您的上司。"
        },
        "TELEPHONY":{
            "END":"結束",
            "HOLD":"保留",
            "MUTE":"靜音",
            "DIALPAD":"撥號盤",
            "JOIN":"加入",
            "SWAP":"互換",
            "CALL":"通話",
            "NUMBER":"號碼",
            "END_CALL":"結束通話",
            "DISCONNECT":"確定要中斷目前的通話？",
            "TRANSFER":"轉移",
            "COUNTRY_CODE":"國家代碼",
            "INVALID_PHONE_TOOLTIP":"國家 代碼和 / 或號碼格式有誤",
            "SELECT":"選取...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    'zh-CN': {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "操作菜单",
            "CLOSE_CONV": "关闭聊天",
            "COPY": "复制为纯文本",
            "PRIVATE_MESSAGE": "私人消息",
            "RESUME_CONV": "恢复对话",
            "RETURN_TO_QUEUE": "返回队列",
            "TCP_TOOLTIP": "转接聊天",
            "TRANSFER_PREV_AGENT": "转接至上一位客服专员",  
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "您已与 VPN 断开连接。在继续进行新对话或拨打外线电话之前，请重新连接到 VPN 并将您的状态更新为 “可用” 或 “出站”。",
            "CALL_RECORDING_DISABLED" : "由于技术困难，禁用通话录音的请求失败。",
            "COPY_TRANSCRIPT" : "将笔录复制到剪贴板"
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "添加到聊天",
            "CLOSE_POPUP_TEXT": "是否确定要结束本次对话？",
            "CANCEL": "取消",
            "CLOSE_HEADING": "关闭对话",
            "CLOSE_PRIMARY_TEXT": "关闭对话",
            "COPY_PRIMARY_TEXT": "复制选中的文本",
            "COPY_HEADING": "复制聊天记录",
            "PRIVATE_MESSAGE_HEADING": "私人消息",
            "RECORD_DISABLE_HEADING":"由于技术困难，禁用通话录音的请求失败。",
            "RECORD_DISABLE_POPUP_TEXT": "您确定要禁用此通话的通话录音吗？只有当客户明确要求不对通话进行录音时，才可使用此选项。通话录音一旦禁用，就无法重新启用。此禁用通话录音的操作将与您的用户名和 contactID 一起记录。",
            "RECORD_DISABLE_PRIMARY_TEXT" : "接受"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "添加转接注释",
            "MANAGER": "经理",
            "NUMBER_OF_TRANSFERS": "转接数量",
            "OF": "/",
            "QUEUE": "队列",
            "SELECT_QUEUE": "选择",
            "SPECIALIST": "专家",
            "TRANSFER": "转接",
            "TRANSFER_TO": "转接至",
            "TEXTAREA_PLACEHOLDER": "键入一条新信息",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "联系内容",
            "SALES": "销售 ",
            "SUPPORT": "支持 ",
            "CONTACT": "Contact",    //
            "MESSAGING": "消息传送 ",
            "PHONE": "电话",
            "LICENSE": "许可证",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "问题类型",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "转接到推荐队列",
            "SUGGESTED_QUEUE_TEXT": "推荐转接队列",
            "OVERRIDE": "重写推荐",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "重写推荐",
            "DIRECT":"直接转接",
            "BACK":"返回",
            "QUEUE_TRANSFER_ASSISTANCE":"队列转接帮助",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"开始转接",
            "WARM_TRANSFER":"询转",
            "COLD_TRANSFER":"通话前转接 (Cold Transfer)",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "您的路由配置文件中未配置推荐队列",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "您的路由配置文件未配置任何传输目的地",
            "QUEUE_IS_AFTER_HOURS": "服务时间外排队",
            "DO_NOT_TRANSFER_MESSAGE": "不要转移。您的队列中支持此问题。如有疑问，请咨询您的主管。"
        },
        "TELEPHONY":{
            "END":"结束",
            "HOLD":"暂挂",
            "MUTE":"静音",
            "DIALPAD":"拨号键盘",
            "JOIN":"加入",
            "SWAP":"交换",
            "CALL":"通话",
            "NUMBER":"号码",
            "END_CALL":"结束通话",
            "DISCONNECT":"是否确定要断开当前通话？",
            "TRANSFER":"转接",
            "COUNTRY_CODE":"国家代码",
            "INVALID_PHONE_TOOLTIP":"国家代码和/或号码格式错误",
            "SELECT":"选择...",
            "TYPE_NUMBER":"Type number...",
        }
    },
    ko: {
        "TOOLBAR": {
            "ACTION_MENU_TOOLTIP": "동작 메뉴",
            "CLOSE_CONV": "채팅 닫기",
            "COPY": "일반 텍스트로 복사",
            "PRIVATE_MESSAGE": "비공개 메시지",
            "RESUME_CONV": "대화 다시 시작",
            "RETURN_TO_QUEUE": "대기열로 돌아가기",
            "TCP_TOOLTIP": "채팅 이전",
            "TRANSFER_PREV_AGENT": "이전 상담사로 이전",  
            "VPN_NOTIFICATION_ON_CLICKING_DIALPAD" : "VPN 연결이 끊겼습니다.새 대화를 계속하거나 아웃바운드 전화를 걸기 전에 VPN에 다시 연결하고 상태를 “사용 가능” 또는 “아웃바운드”로 업데이트하세요.",
            "CALL_RECORDING_DISABLED" : "통화 녹음 비활성화",
            "COPY_TRANSCRIPT" : "스크립트를 클립보드에 복사"
        },
        "ACTION_MODAL": {
            "PRIVATE_MESSAGE_PRIMARY_TEXT": "채팅에 추가",
            "CLOSE_POPUP_TEXT": "이 대화를 종료하시겠습니까?",
            "CANCEL": "취소",
            "CLOSE_HEADING": "대화 종료",
            "CLOSE_PRIMARY_TEXT": "대화 종료",
            "COPY_PRIMARY_TEXT": "선택한 텍스트 복사",
            "COPY_HEADING": "대화내용 복사",
            "PRIVATE_MESSAGE_HEADING": "비공개 메시지",
            "RECORD_DISABLE_HEADING":"통화 녹음 비활성화",
            "RECORD_DISABLE_POPUP_TEXT": "이 통화에 대한 통화 녹음을 비활성화하시겠습니까? 이 옵션은 고객이 통화를 녹음하지 않도록 명시적으로 요청한 경우에만 사용됩니다. 비활성화되면 통화 녹음을 다시 활성화할 수 없습니다. 통화 녹음을 비활성화하는 이 작업은 사용자 이름 및 contactId와 함께 문서화됩니다.",
            "RECORD_DISABLE_PRIMARY_TEXT" : "수락"
        },
        "TRANSFER_CALL": {
            "ADD_TRANSFER_NOTES": "전송 노트 추가",
            "MANAGER": "관리자",
            "NUMBER_OF_TRANSFERS": "전송 횟수",
            "OF": "/",
            "QUEUE": "대기열",
            "SELECT_QUEUE": "선택",
            "SPECIALIST": "전문가",
            "TRANSFER": "전송",
            "TRANSFER_TO": "이전 대상:",
            "TEXTAREA_PLACEHOLDER": "새 메시지 입력",
            "TRANSFER_ASSISTANCE": "Transfer Assistance",  //
            "NATURE_OF_CONTACT": "문의 내용",
            "SALES": "세일즈",
            "SUPPORT": "서포트",
            "CONTACT": "Contact",    //
            "MESSAGING": "메시징 ",
            "PHONE": "전화",
            "LICENSE": "라이선스",
            "PROFILE_CCT_CUSTOMER": "Profile of CCT Customer",    //
            "ISSUE_TYPE": "문제 유형",
            "CANCELLATION_REASON": "Cancellation Reason",    //
            "TRANSFER_TO_SUGGESTED": "제안된 대기열로 전송",
            "SUGGESTED_QUEUE_TEXT": "전송을 위해 제안된 대기열",
            "OVERRIDE": "제안 무시",
            "DIRECT_TRANSFER": "Direct transfer to a different queue",     //
            "SUGGESTED_BY": "Queue suggested by",    //
            "TAT": "Transfer Assistance Tool",   //
            "OVERRIDE_REASON": "제안 무시 이유",
            "DIRECT":"직접 전송",
            "BACK":"뒤로",
            "QUEUE_TRANSFER_ASSISTANCE":"대기열 전송 지원",
            "QUEUE_SUGGESTION":"Queue suggestion",    //
            "START_TRANSFER":"전송 시작",
            "WARM_TRANSFER":"사전 안내 후 통화 전송(웜 트랜스퍼)",
            "COLD_TRANSFER":"설명 없이 통화 전송(Cold Transfer)",
            "IVR":"Secure IVR",
            "RECOMMENDED_QUEUE_NOT_CONFIGURE_ROUTING_PROFILE": "권장 대기열이 라우팅 프로필에 구성되어 있지 않습니다.",
            "YOUR_ROUTING_PROFILE_NOT_CONFIGURE_TRANSFER_DESTINATOIONS": "라우팅 프로필이 전송 목적지로 구성되지 않았습니다.",
            "QUEUE_IS_AFTER_HOURS": "해당 큐는 현재 영업 외 시간입니다.",
            "DO_NOT_TRANSFER_MESSAGE": "양도하지 마세요.이 문제는 님 대기열에서 지원돼요.의문 있으면 상사에게 문의하세요."
        },
        "TELEPHONY":{
            "END":"종료",
            "HOLD":"대기",
            "MUTE":"음소거",
            "DIALPAD":"다이얼패드",
            "JOIN":"참여",
            "SWAP":"교환",
            "CALL":"통화",
            "NUMBER":"번호",
            "END_CALL":"통화 종료",
            "DISCONNECT":"현재 통화를 끊으시겠습니까?",
            "TRANSFER":"전송",
            "COUNTRY_CODE":"국가 코드",
            "INVALID_PHONE_TOOLTIP":"국가 코드 및/또는 숫자 형식이 올바르지 않습니다",
            "SELECT":"선택...",
            "TYPE_NUMBER":"Type number...",
        }
    }
}

export default localization;