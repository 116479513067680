import React from 'react'
import styles from '../Styles/styles.module.css'

type Props = {
  darkMode?: boolean
}

export const Send = (props: any) => (
  <svg id="icon_-_send" data-name="icon - send-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <rect id="Canvas" width="18" height="18" className={props.className} opacity="0" />
      <path id="Path_39033" data-name="Path 39033" d="M16.6,2.556.9,7.239a.25.25,0,0,0-.04.464L4.82,9.679Z" className={props.className} />
      <path id="Path_39034" data-name="Path 39034" d="M6.544,10.516l5.969,3a.5.5,0,0,0,.671-.222h0L17.818,3.182Z" className={props.className} />
      <path id="Path_39035" data-name="Path 39035" d="M5.04,11.625v3.819a.356.356,0,0,0,.587.272l2.68-2.258Z" className={props.className} />
  </svg>
)

export const OpenAi = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="20px" height="20px" viewBox="0 0 24 24" role="img">
    <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z"/>
  </svg>
)

export const AdobeIcon = () => (
  <svg height="16px" width="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#000000">
    <path d="M15 0A15 15 0 110 15 15 15 0 0115 0z" fill="transparent"></path>
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round"></g>
      <g id="SVGRepo_iconCarrier"> 
        <polygon fill='#E83B36' points="185.858,44.35 10.199,44.35 10.199,467.649 "></polygon> 
        <path fill='#E83B36' d="M10.203,477.849c-0.661,0-1.329-0.064-1.997-0.198C3.435,476.701,0,472.514,0,467.649V44.35 c0-5.633,4.566-10.199,10.199-10.199h175.66c3.408,0,6.59,1.701,8.482,4.536c1.892,2.834,2.244,6.425,0.938,9.573L19.619,471.558 C18.017,475.422,14.258,477.849,10.203,477.849z M20.398,54.549v361.912L170.584,54.549H20.398z"></path> 
        <polygon fill='#E83B36' points="259.054,382.25 294.426,467.649 367.612,467.649 254.174,205.375 177.323,382.25 "></polygon> 
        <path fill='#E83B36' d="M367.612,477.848h-73.186c-4.126,0-7.844-2.486-9.423-6.296l-32.764-79.103h-74.915 c-3.437,0-6.644-1.732-8.529-4.606c-1.885-2.874-2.195-6.505-0.826-9.658l76.851-176.875c1.619-3.725,5.293-6.135,9.355-6.135 c0.003,0,0.005,0,0.008,0c4.064,0.003,7.739,2.42,9.353,6.15L376.973,463.6c1.364,3.153,1.048,6.778-0.837,9.649 C374.251,476.119,371.046,477.848,367.612,477.848z M301.241,457.45h50.848l-97.935-226.431l-61.278,141.033h66.179 c4.126,0,7.844,2.486,9.423,6.296L301.241,457.45z"></path> 
        <polygon fill='#E83B36' points="318.818,44.35 501.801,467.649 501.801,44.35 "></polygon> 
      <g> 
        <path fill='#E83B36' d="M501.797,477.849c-4,0-7.72-2.362-9.358-6.153L309.456,48.397c-1.363-3.152-1.047-6.778,0.838-9.647 c1.886-2.87,5.089-4.598,8.523-4.598h182.983c5.633,0,10.199,4.566,10.199,10.199V467.65c0,4.836-3.397,9.008-8.133,9.987 C503.175,477.78,502.481,477.849,501.797,477.849z M334.339,54.549l157.263,363.802V54.549H334.339z"></path> 
        <path fill='#E83B36' d="M469.163,193.785c-5.633,0-10.199-4.566-10.199-10.199V79.554c0-5.633,4.566-10.199,10.199-10.199 s10.199,4.566,10.199,10.199v104.032C479.363,189.218,474.796,193.785,469.163,193.785z"></path> 
        <path fill='#E83B36' d="M469.163,234.582c-5.633,0-10.199-4.566-10.199-10.199v-7.139c0-5.633,4.566-10.199,10.199-10.199 s10.199,4.566,10.199,10.199v7.139C479.363,230.014,474.796,234.582,469.163,234.582z"></path> 
      </g> 
    </g>
  </svg>  
)

export const ArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
  </svg>
)

export const InfoTip = ({ currentColor, darkMode }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={ currentColor } className={`bi bi-info-circle-fill ${darkMode ? 'aa-info-dark' : ''}`} viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
</svg>
)

export const Pin = ({ className }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 18 18" width="12" className={`${styles[className]}`}>
    <rect className={className} id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><polygon fill='#464646' points="5.823 10.999 7.012 12.189 1.962 17.203 0 18 0.822 16.013 5.823 10.999" />
    <path fill='#464646' d="M11.9775,13.1605,12,10.1445,16.1465,6l1.135-.0115a.7262.7262,0,0,0,.505-1.2395L15.5205,2.48,13.25.2415A.723.723,0,0,0,12.0155.747L12,1.854,7.8535,6,4.841,6.022a.72345.72345,0,0,0-.554,1.233l.001.001,3.2295,3.229,3.2285,3.2295a.721.721,0,0,0,1.2315-.554Z" />
  </svg>)
}

export const More = () => {
  <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
  <title>S MoreSmallListVert 18 N</title>
  <rect id="Canvas" fill='#464646' opacity="0" width="18" height="18" /><circle className="fill" cx="9" cy="13.5" r="1.425" />
  <circle className="fill" cx="9" cy="9" r="1.425" />
  <circle className="fill" cx="9" cy="4.5" r="1.425" />
</svg>
}

export const ThumbsDown = () => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
      <title>No</title>
      <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
      <path
        className='fill'
        fill='#464646'
        d='M16.277,9.85,14.285,4.212A2.318,2.318,0,0,0,12.222,3H5a.5.5,0,0,0-.5-.5h-3A.5.5,0,0,0,1,3v8a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,5,11v-.238c1.2725.587,3.5885,2.415,3.82,4.656A1.6635,1.6635,0,0,0,10.4605,17a1.9023,1.9023,0,0,0,1.5865-1.9685,10.73831,10.73831,0,0,0-.4-3.0405l3.275.005A1.5,1.5,0,0,0,16.277,9.85ZM14.9235,11h-4.75a7.52559,7.52559,0,0,1,.873,4.0315c-.026.6-.2815.966-.5865.9685a.687.687,0,0,1-.6405-.6c-.245-2.9365-3.3865-5.1225-4.82-5.7V4l7.4955-.01a.921.921,0,0,1,.871.616l2.0085,5.678a.5.5,0,0,1-.451.716Z'
      />
    </svg>
)

export const ThumbsDownDark = () => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
    <title>No</title>
    <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
    <rect className='fill' height='9' rx='0.5' width='3' x='1' y='3' />
    <path
      className='fill'
      fill='#1e272e'
      d='M15.5385,10.945H10.555A31.9301,31.9301,0,0,1,11,15.54c0,.8305-.516,1.46-1,1.46a.9195.9195,0,0,1-1-1,5.663,5.663,0,0,0-1.258-3.129A23.17516,23.17516,0,0,0,5,10.479V3s1.4045.0165,7,0a1.973,1.973,0,0,1,1.8385,1.212l2.564,5.394a.931.931,0,0,1-.864,1.339Z'
    />
  </svg>
)

export const ThumbsUp = () => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
    <defs></defs>
    <title>Yes</title>
    <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
    <path
      className='fill'
      fill='#464646'
      d='M14.922,6.004l-3.275.005a10.73706,10.73706,0,0,0,.4-3.04A1.90165,1.90165,0,0,0,10.4605,1,1.6635,1.6635,0,0,0,8.82,2.582C8.5845,4.8595,6.1935,6.7135,4.936,7.2685A.495.495,0,0,0,4.5,7h-3a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V15h7.222a2.318,2.318,0,0,0,2.063-1.2115L16.277,8.15a1.5,1.5,0,0,0-1.355-2.146Zm.4525,1.712L13.366,13.394a.921.921,0,0,1-.871.616L5,14V8.3c1.4335-.5765,4.575-2.7625,4.82-5.7a.687.687,0,0,1,.6405-.6c.305,0,.5605.371.5865.969A7.52457,7.52457,0,0,1,10.174,7h4.75a.5.5,0,0,1,.4505.716Z'
    />
  </svg>
)

export const ThumbsUpDark = () => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
    <title>Yes</title>
    <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
    <rect className='fill' height='9' rx='0.5' width='3' x='1' y='7' />
    <path
      className='fill'
      fill='#1e272e'
      d='M15.4835,7H10.5A27.46988,27.46988,0,0,0,11,2.46C11,1.6295,10.484,1,10,1A.9195.9195,0,0,0,9,2,5.663,5.663,0,0,1,7.742,5.129,23.17516,23.17516,0,0,1,5,7.521V15s1.4045-.0165,7,0a1.973,1.973,0,0,0,1.8385-1.212l2.564-5.394A1,1,0,0,0,15.4835,7Z'
    />
  </svg>
)

export const Flag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-flag-fill" viewBox="0 0 16 16">
    <title>Inappropriate</title>
    <path 
      className='fill'
      fill='#464646'
      d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
  </svg>
)

export const FlagDark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="bi bi-flag-fill" viewBox="0 0 16 16">
    <title>Inappropriate</title>
    <path 
      className='fill'
      fill='#1e272e'
      d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
  </svg>
)

export const ThumbsUpDisabled = () => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
    <title>Yes</title>
    <rect id='Canvas' fill='#D5D5D5' opacity='0' width='18' height='18' />
    <rect className='fill' fill='#D5D5D5' height='9' rx='0.5' width='3' x='1' y='7' />
    <path
      className='fill'
      fill='#D5D5D5'
      d='M15.4835,7H10.5A27.46988,27.46988,0,0,0,11,2.46C11,1.6295,10.484,1,10,1A.9195.9195,0,0,0,9,2,5.663,5.663,0,0,1,7.742,5.129,23.17516,23.17516,0,0,1,5,7.521V15s1.4045-.0165,7,0a1.973,1.973,0,0,0,1.8385-1.212l2.564-5.394A1,1,0,0,0,15.4835,7Z'
    />
  </svg>
)

export const ThumbsDownDisabled = () => (
  <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
    <title>No</title>
    <rect id='Canvas' fill='#D5D5D5' opacity='0' width='18' height='18' />
    <rect className='fill' fill='#D5D5D5' height='9' rx='0.5' width='3' x='1' y='3' />
    <path
      className='fill'
      fill='#D5D5D5'
      d='M15.5385,10.945H10.555A31.9301,31.9301,0,0,1,11,15.54c0,.8305-.516,1.46-1,1.46a.9195.9195,0,0,1-1-1,5.663,5.663,0,0,0-1.258-3.129A23.17516,23.17516,0,0,0,5,10.479V3s1.4045.0165,7,0a1.973,1.973,0,0,1,1.8385,1.212l2.564,5.394a.931.931,0,0,1-.864,1.339Z'
    />
  </svg>
)


export const FlagDisabled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="bi bi-flag-fill" viewBox="0 0 16 16">
    <title>Inappropriate</title>
    <path 
      className='fill'
      fill='#D5D5D5'
      d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
  </svg>
)

export const ShareToChat = ({ darkMode }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={darkMode ? "#F7F7F7" : "#464646"} className="bi bi-copy" viewBox="0 0 16 16">
    <title>Copy to Chat</title>
    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
  </svg>
)

export const ArrowReturnRight = ({ darkMode }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-arrow-return-right" viewBox="0 0 18 18">
    <path fill={darkMode ? "#F7F7F7" : "#464646"} d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
  </svg>
)

